import { MdFiberManualRecord as Record } from "react-icons/md";
import { useMemo } from "react";
import {
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";

import { Flex, Text } from "@skillup/design-system";

import { DSAvatarGroup } from "@skillup/ui";
import { renderCellDate } from "@skillup/shared-utils";
import { ColdReview, ReviewResultStatus, ReviewUser } from "@skillup/training-bridge";

import { getUserInitials } from "utils/User";
import { TranslationType } from "hooks/useTranslation";
import { ReviewRow } from "../utils/types";

import ReviewStatus from "../components/ReviewStatus";
import ActionsCell from "../components/ActionsCell";

type Props = {
  reviews: Array<ColdReview>;
  t: TranslationType;
  refetch: () => void;
  selectedRows: GridRowSelectionModel;
};

function useReviewsTableData({ reviews, t, refetch, selectedRows }: Props) {
  const tableData = useMemo(
    () => ({
      rows: generateTableData(reviews),
      columns: generateColumns({ t, refetch, selectedRows }),
    }),
    [reviews, t, refetch, selectedRows]
  );

  return tableData;
}

export default useReviewsTableData;

const generateColumns = ({
  t,
  refetch,
  selectedRows,
}: {
  t: TranslationType;
  refetch: () => void;
  selectedRows: GridRowSelectionModel;
}): GridColDef<any>[] => {
  const formattedTrainers = (trainers: ReviewUser[]) => {
    return trainers.map((t) => ({
      uuid: t.uuid,
      fullName: t.fullName,
      initials: getUserInitials(t),
    }));
  };

  const formatScoreStatus = (scoreStatus: ReviewResultStatus) => {
    switch (scoreStatus) {
      case ReviewResultStatus.POSITIVE:
        return t("reviews.list.label.positiveAnswers", {
          defaultValue: "réponses positives",
        });

      case ReviewResultStatus.NEGATIVE:
        return t("reviews.list.label.negativeAnswers", {
          defaultValue: "réponses négatives",
        });
    }

    return "";
  };

  const scoreColor = (scoreStatus: ReviewResultStatus) => {
    switch (scoreStatus) {
      case ReviewResultStatus.POSITIVE:
        return "green";
      case ReviewResultStatus.NEGATIVE:
        return "red";
    }

    return "";
  };

  return [
    {
      field: "reviewStatus",
      headerName: t("reviews.list.label.reviewStatus"),
      width: 180,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Flex alignItems="center" justifyContent="center">
            <ReviewStatus status={params.row.data.status} />
          </Flex>
        );
      },
    },
    {
      field: "traineeName",
      headerName: t("reviews.list.label.traineeName"),
      width: 150,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Flex alignItems="center">
            <Text>{params.row.data.trainee ?? "-"}</Text>
          </Flex>
        );
      },
    },
    {
      field: "trainingName",
      headerName: t("reviews.list.label.trainingName"),
      width: 250,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Flex alignItems="center">
            <Text>{params.row.data.trainingName}</Text>
          </Flex>
        );
      },
    },
    {
      field: "trainingOrganization",
      headerName: t("reviews.list.label.trainingOrganization"),
      width: 180,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Flex alignItems="center">
            <Text>{params.row.data.trainingOrganization ?? "-"}</Text>
          </Flex>
        );
      },
    },
    {
      field: "score",
      headerName: t("reviews.list.label.score", {
        defaultValue: "Note",
      }),
      sortable: false,
      filterable: false,
      width: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Flex alignItems="center">
            {params.row.data.scoreStatus ? (
              <>
                <Record color={scoreColor(params.row.data.scoreStatus)} size="1.5rem" />
                <Text paddingLeft="xxs">
                  {params.row.data.score}/{params.row.data.max}{" "}
                  {formatScoreStatus(params.row.data.scoreStatus)}
                </Text>
              </>
            ) : (
              "-"
            )}
          </Flex>
        );
      },
    },
    {
      field: "sessionStartDate",
      headerName: t("reviews.list.label.sessionStartDate"),
      width: 150,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Flex alignItems="center">
            <Text>{renderCellDate(params.row.data.startDate)}</Text>
          </Flex>
        );
      },
    },
    {
      field: "sessionReference",
      headerName: t("reviews.list.label.sessionReference"),
      width: 150,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Flex alignItems="center">
            <Text>{params.row.data.sessionReference ?? "-"}</Text>
          </Flex>
        );
      },
    },
    {
      field: "trainerName",
      headerName: t("reviews.list.label.trainerName"),
      width: 150,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Flex alignItems="center">
            {params.row.data.trainers.length !== 0 ? (
              <DSAvatarGroup
                users={formattedTrainers(params.row.data.trainers)}
                type="Initials"
                size="S"
              />
            ) : (
              "-"
            )}
          </Flex>
        );
      },
    },
    {
      field: "comment",
      headerName: t("reviews.list.label.comment", {
        defaultValue: "Commentaire",
      }),
      sortable: false,
      filterable: false,
      width: 150,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Flex alignItems="center">
            <Text>{params.row.data.comment}</Text>
          </Flex>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      sortable: false,
      filterable: false,
      width: 50,
      getActions: (params: GridRowParams<ReviewRow<ColdReview>>) => [
        <ActionsCell {...params} refetch={refetch} selectedRows={selectedRows} />,
      ],
    },
  ];
};

function generateTableData(reviews: ColdReview[]) {
  const tableRows = reviews?.map(parseReviewIntoRow);
  return tableRows;
}

function parseReviewIntoRow(review: ColdReview): ReviewRow<ColdReview> {
  return {
    id: review.uuid,
    data: {
      url: review.url,
      status: review.status,
      trainee: review.trainee.fullName,
      trainingName: review.session.training.name,
      trainingOrganization: review.session.training.trainingOrganization,
      scoreStatus: review?.data?.status,
      score: review?.data?.value,
      max: review?.data?.max,
      comment: review?.data?.comment,
      startDate: new Date(review.session.startDate),
      sessionReference: review.session.reference,
      trainers: review.trainers,
    },
  };
}
