import { useEffect, forwardRef, RefObject, useMemo } from "react";

import Highcharts from "highcharts";
import HighchartsReact, { HighchartsReactRefObject } from "highcharts-react-official";

import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import { DashboardCardData, QuestionData } from "../../types";

import styles from "./Pie.module.scss";

import { createDonutOptions } from "./utils";

export type PieChartProps = {
  data: QuestionData;
  card: DashboardCardData;
  loading: boolean;
};

export const PieChart = forwardRef(
  ({ data, card, loading }: PieChartProps, ref: RefObject<HighchartsReactRefObject>) => {
    const { t, i18n } = useTranslation();
    const { settings } = useSettings();

    const isMoneyValue = card.question_meta.axes[0].data_type === "money";
    const { currency } = settings;

    const options = useMemo(
      () => createDonutOptions(styles, currency, data, card, t, loading, i18n),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data, card, isMoneyValue, loading]
    );

    useEffect(() => {
      if (!ref.current?.chart) {
        return;
      }

      if (!loading) ref.current?.chart?.hideLoading();
      else ref.current?.chart?.showLoading();
    }, [loading, ref]);

    return <HighchartsReact ref={ref} highcharts={Highcharts} options={options} />;
  }
);
