import {
  type InterviewActiveAttendeeRoles,
  type InterviewRoles,
  type QuestionReply,
} from "./campaign";
import { type ISO8601Date } from "./ISO08601Date";

export type Completion = {
  max?: number;
  value: number;
  createdAt?: ISO8601Date;
  updatedAt?: ISO8601Date;
};

export enum TargetLogType {
  CREATE = "create",
  UPDATE = "update",
  COMMENT = "comment",
  EVALUATION = "evaluation",
  SIGN = "sign",
  UNSIGN = "unsign",
  OBSOLETE = "obsolete",
  UNOBSOLETE = "unobsolete",
}

export type TargetLogAuthor = {
  fullName: string;
  initials: string;
  role: InterviewActiveAttendeeRoles;
};

export interface TargetHistory {
  name: string;
  description: string;
  scale: "ordinal" | "numeric";
  goal?: number;
  unit?: string;
  choices: string[];
  category: string;
  completion?: {
    [InterviewRoles.EMPLOYEE]?: Completion;
    [InterviewRoles.MANAGER]?: Completion;
  };
}

export type TargetLogData =
  | { before: TargetHistory; after: TargetHistory }
  | TargetHistory
  | string;

export interface TargetLog {
  createdAt: ISO8601Date;
  type: `${TargetLogType}`;
  author: TargetLogAuthor;
  data: TargetLogData;
}

export interface TargetActivity {
  context: {
    interviewUuid: string;
    campaignName: string;
    signedAt: ISO8601Date;
  };
  logs: TargetLog[];
}

export namespace Target {
  export type Client = {
    uuid: string;
    name: string;
    description?: string;
    generatedBy?: InterviewActiveAttendeeRoles;
    period?: {
      startDate: ISO8601Date;
      endDate: ISO8601Date;
    };
    completion?: {
      [InterviewRoles.EMPLOYEE]?: Partial<Completion>;
      [InterviewRoles.MANAGER]?: Partial<Completion>;
    };
    weight?: number;
    category: string;
    activities?: TargetActivity[];
  };
}

export interface OrdinalTarget extends Target.Client {
  scale: "ordinal";
  choices: string[];
  completionRate?: number | undefined;
}

export interface NumericTarget extends Target.Client {
  scale: "numeric";
  unit: string;
  goal?: number;
}

export type TargetItem = (OrdinalTarget | NumericTarget) & {
  data?: {
    [role in InterviewActiveAttendeeRoles]?: {
      comment?: QuestionReply<string>;
    };
  };
};

export type TargetWithCompletion = TargetItem & {
  completionRate?: number | undefined;
  readOnly: boolean;
  invalidRate?: boolean;
  invalidWeight?: boolean;
};

export type TargetGroup = {
  category: string;
  targets: TargetWithCompletion[];
  total: {
    weight: number;
    sum: number;
    avg?: number;
  };
};

export type TargetGroups = {
  groups: TargetGroup[];
  displayGlobalCompletion: boolean;
  total: {
    weight: number;
    sum: number;
    avg?: number;
  };
  errors: {
    invalidRate: boolean;
    invalidWeight: boolean;
  };
};

export interface TargetGlobalCompletion {
  numeric?: number | string; // string when weights are incompatible
  ordinal?: Record<string, number> | string; // string when scales are incompatible
}
