import { RecoilRoot } from "recoil";
import { useMount } from "react-use";
import * as Sentry from "@sentry/react";
import { httpBatchLink } from "@trpc/client";
import { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ToastProvider, ToastProps, AppearanceTypes } from "react-toast-notifications";

import { DSAlert, DSAlertDisplay, DSAlertType } from "@skillup/ui";
import { DesignSystemProvider, Themes } from "@skillup/design-system";

import AppModal from "components/AppModal";
import AppMessages from "components/AppMessages";
import ApplicationError from "components/ApplicationError";
import DeveloperEnvironment from "components/DeveloperEnvironment";

import User from "utils/User";
import { trpc } from "utils/trpc";

import storage, { allPersistentStorages } from "services/storage";

import useJimo from "hooks/useJimo";
import useFrill from "hooks/useFrill";
import useUsetiful from "hooks/useUsetiful";
import useAnalytics from "hooks/useAnalytics";
import useEnvironment from "hooks/useEnvironment";
import useTranslation from "hooks/useTranslation";
import useCookieConsent from "hooks/useCookieConsent";
import { hasOnePackageSkillsEnabled } from "utils/User";

import Routes from "./Routes";
import Process from "./Process";

const alertTypeMatching: { [key in AppearanceTypes]: DSAlertType } = {
  info: DSAlertType.INFO,
  success: DSAlertType.SUCCESS,
  error: DSAlertType.ERROR,
  warning: DSAlertType.WARNING,
};

const skillsApiVersion = hasOnePackageSkillsEnabled() ? "gepp-api" : "skills-api";

const App = (): JSX.Element => {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${process.env.REACT_APP_PUBLIC_APP_URL + skillsApiVersion}`,
          headers() {
            return {
              "X-Auth-Token": storage.get("X-Auth-Token", allPersistentStorages),
            };
          },
        }),
      ],
      transformer: null,
    })
  );

  const { pathname } = useLocation();
  const { isProduction, isDevelopment } = useEnvironment();

  const { cookieConsentState } = useCookieConsent();
  useAnalytics({ consent: cookieConsentState });
  useUsetiful({ consent: cookieConsentState });
  useJimo({ consent: cookieConsentState });
  useFrill();

  const hasEnvironmentWarning = !isProduction || User.isSkillupDeveloper();

  const checkRedirect = useCallback(() => {
    const pathnameIsIn = (paths) => paths.some((path) => pathname.indexOf(path) === 0);

    // Do not redirect on auth pages
    const noAuthPaths = ["/connexion", "/authentification"];
    if (pathnameIsIn(noAuthPaths)) {
      return;
    }

    // Do not redirect on those pages if properly connected
    const allowUserPaths = ["/uuid/", "/entreprise/inscription"];
    if (pathnameIsIn(allowUserPaths) && User.isCompanyUser()) {
      return;
    }

    if (!User.isRF()) {
      if (User.isCompanyUser()) {
        window.location.replace("/collaborateur/");
      } else if (User.isOF()) {
        window.location.replace("/admin-organisme/");
      } else {
        window.location.replace("/connexion/");
      }
    } else if (pathname.indexOf("/responsable") !== 0) {
      window.location.replace("/responsable");
    }
  }, [pathname]);

  useMount(() => {
    checkRedirect();
  });

  const { t, i18n, ready } = useTranslation();

  if (!ready) return null;

  return (
    <DesignSystemProvider theme={Themes.ESPACE_RH} language={i18n.language} t={t}>
      <Sentry.ErrorBoundary
        dialogOptions={{ user: User.getUserData() }}
        showDialog={isDevelopment}
        fallback={ApplicationError}
      >
        <ToastProvider
          placement="bottom-right"
          components={{
            Toast: (props: ToastProps) => {
              return (
                <DSAlert
                  type={alertTypeMatching[props.appearance]}
                  display={DSAlertDisplay.FLOATING}
                  onClose={props.onDismiss}
                  closeButton
                >
                  {props.children}
                </DSAlert>
              );
            },
          }}
          autoDismiss
        >
          <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              {hasEnvironmentWarning && <DeveloperEnvironment />}
              <RecoilRoot>
                <Process />
                <Routes />
                <AppModal />
                <AppMessages />
              </RecoilRoot>
            </QueryClientProvider>
          </trpc.Provider>
        </ToastProvider>
      </Sentry.ErrorBoundary>
    </DesignSystemProvider>
  );
};

export default App;
