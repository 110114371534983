import { FormikProps } from "formik";

import { Label as DSLabel } from "@skillup/ui";
import {
  Grid,
  Text,
  Flex,
  FormikInputText,
  FormikInputSelect,
  FormikInputSimpleTextArea,
} from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import { Category, SkillFields } from "types/skills";

import { Level, CustomField } from "./components";

interface SimplifiedEvaluationScale {
  uuid: string;
  name: string;
  description?: string;
  levels: Array<{
    uuid: string;
    name: string;
    level: number;
    description?: string;
    customDescription?: string;
  }>;
}

interface SkillFormikState {
  skillName: string;
  description: string;
  fields: SkillFields[];
  categoryUuid?: string;
  evaluationScale?: SimplifiedEvaluationScale;
}

interface SkillFormProps {
  categories: Category[];
  formikProps: FormikProps<SkillFormikState>;
  evaluationScales: SimplifiedEvaluationScale[];
}

export default function SkillForm(props: SkillFormProps) {
  const { t } = useTranslation();
  const {
    categories,
    evaluationScales,
    formikProps: { values },
  } = props;

  const selectOptions =
    evaluationScales?.map((evalScale) => ({
      label: evalScale.name,
      value: evalScale.uuid,
    })) ?? [];

  const categoryOptions =
    categories?.map((category) => ({
      label: category.label,
      value: category.uuid,
    })) ?? [];

  const sortedEvaluationScaleLevels = values.evaluationScale?.levels.sort(
    (a, b) => a.level - b.level
  );

  return (
    <Flex width="100%" overflow="auto" backgroundColor="surface-light-darker">
      <Flex width="100%" marginVertical="s" height="fit-content" justifyContent="center">
        <Flex gap="s" width="650px" paddingBottom="jumbo" flexDirection="column">
          <Flex alignItems="flex-end">
            <Flex width="100%" marginRight="s" flexDirection="column">
              <DSLabel
                required
                label={t("portal.config.skills.createSkill.nameLabel", {
                  defaultValue: "Nom de la compétence",
                })}
              />
              <FormikInputText
                name="skillName"
                value={values.skillName ?? ""}
                placeholder={t("portal.config.skills.createSkill.nameLabel", {
                  defaultValue: "Nom de la compétence",
                })}
              />
            </Flex>
          </Flex>
          <Grid
            gridRowGap="s"
            gridColumnGap="s"
            gridTemplateRows={"auto"}
            gridTemplateColumns={{
              columnNumbers: 3,
              columnWidth: "1fr",
            }}
          >
            <Flex flexDirection="column">
              <DSLabel
                label={t("portal.config.skills.createSkill.categoryLabel", {
                  defaultValue: "Catégorie",
                })}
              />
              <FormikInputSelect
                name="categoryUuid"
                placeholder="Catégorie"
                options={categoryOptions}
                value={values.categoryUuid ?? ""}
              />
            </Flex>
            {values.fields.map((field, index) => {
              return <CustomField key={index} index={index} field={field} />;
            })}
          </Grid>
          <Flex height="220px" marginBottom="s" flexDirection="column">
            <DSLabel
              label={t("portal.config.skills.createSkill.description", {
                defaultValue: "Description",
              })}
            />
            <FormikInputSimpleTextArea
              name="description"
              value={values.description ?? ""}
              placeholder={t("skills.form.label.placeholder", {
                defaultValue:
                  "Saisissez ici la description de la compétence ou copiez-collez depuis un fichier",
              })}
            />
          </Flex>
          <Flex flexDirection="column">
            <Text marginBottom="xxs" espaceFont="body2Bold" color="plainText-onLight-lighter">
              {t("portal.config.skills.createEvaluationScale.evaluationLevels", {
                defaultValue: "Niveaux d'évaluation",
              })}
            </Text>
            <DSLabel
              required
              label={t("portal.config.skills.createSkill.selectSkillEvaluationScale", {
                defaultValue: "Sélectionnez l'échelle d'évaluation à appliquer à cette compétence",
              })}
            />
            <FormikInputSelect
              name="evaluationScale"
              options={selectOptions}
              dependentObjects={evaluationScales}
              value={values.evaluationScale?.uuid ?? ""}
              placeholder="Sélectionner une échelle d'évaluation"
            />
          </Flex>
          <Flex gap="s" flexDirection="column" justifyContent="center">
            {sortedEvaluationScaleLevels.map((level, index) => {
              return <Level key={index} item={level} index={index} />;
            })}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
