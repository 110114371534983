import { useState, useRef, useEffect } from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import { isNil } from "lodash";

import { useBaseUrl } from "@skillup/hooks";

import User from "utils/User";
import scrollTo from "utils/scrollTo";
import injectSafeHtml from "utils/sanitizer";
import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import Icon from "components/Icon";
import TrainingScore from "components/TrainingScore";
import TrainingStars from "components/TrainingStars";
import InteractiveButton from "components/InteractiveButton";
import Footer from "components/Footer";
import Breadcrumb from "components/Breadcrumb";
import DSLayout from "components/DSLayout";
import TrainingHeader from "components/TrainingHeader";
import {
  mapPin as mapPinIcon,
  trainee as traineeIcon,
  list as listIcon,
  calendar as calendarIcon,
} from "uiAssets/StrokeIcons";

import ActionsBox from "./components/ActionsBox";
import Skills from "./components/Skills";
import { renderContents, isNotEmptyContent, computePlaceFromQuery } from "./utils";
import { getSessionDateWithHours } from "./components/utils";
import styles from "./TrainingPageStyles.module.scss";

interface IState {
  activePlace?: string;
}

export default (props: any): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sectionContents = useRef<HTMLDivElement>(null);
  const sectionSessions = useRef<HTMLElement>(null);
  const sectionReviews = useRef<HTMLElement>(null);
  const [activePlace, setActivePlace] = useState<IState>({});
  const {
    categories,
    entities,
    organization,
    places,
    properties,
    reviews,
    sessions,
    sessionsByPlace,
    location,
    history,
  } = props;

  const { hash } = useParams<{ hash?: string }>();
  const { baseUrl } = useBaseUrl();
  const settings = useSettings();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (hash && containerRef.current && sectionSessions.current) {
      scrollTo(sectionSessions.current.offsetTop - 150, containerRef.current);
    }
  }, [hash]);

  const contents = renderContents(properties);
  const nav = [
    {
      label: "Contenus",
      ref: sectionContents,
      icon: listIcon,
      canDisplay: true,
    },
    {
      label: "Sessions",
      ref: sectionSessions,
      icon: calendarIcon,
      canDisplay: Boolean(sessions.length),
    },
    {
      label: "Avis",
      ref: sectionReviews,
      icon: traineeIcon,
      canDisplay: Boolean(reviews.length),
    },
  ].filter(({ canDisplay }) => !!canDisplay);

  let focusedReview;

  if (reviews) {
    focusedReview =
      reviews.find((r) => r.weight === 1) || reviews.find((r) => !isNil(r.traineeComment));
  }

  const isSupervisor = baseUrl === "/responsable";

  const tags = categories && categories[0];
  const breadcrumbLinks =
    tags &&
    tags
      .slice()
      .reverse()
      .map((t) => ({ name: t.name, path: t.seoSlug }));
  const placeFromQuery = computePlaceFromQuery(location);
  const actionsBoxProps = {
    categories,
    isSupervisor,
    organization,
    places,
    properties,
    sessions,
    sessionsByPlace,
    placeFromQuery,
  };

  const isIntra = properties.labels?.includes?.("Intra");
  const contentBlockClass = isIntra ? styles.intraBlock : "";
  const { t } = useTranslation();

  return (
    <DSLayout
      title={properties.name}
      parent={{
        title: isIntra
          ? t("trainings.intra.companyCatalog", { defaultValue: "Catalogue entreprise" })
          : t("trainings.intra.skillupCatalog", { defaultValue: "Catalogue Skillup" }),
        titleUrl: generatePath(
          isIntra ? "/responsable/programmes/gestion-intras" : "/responsable/programmes/catalogue"
        ),
      }}
    >
      <div className={styles.TrainingPage} ref={containerRef}>
        <TrainingHeader {...props} />

        <div>
          <header>
            <Link
              to={
                properties.isCompanyIntra
                  ? "#"
                  : `${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/formations-${
                      organization.slug || ""
                    }`
              }
              style={{ backgroundImage: `url("${organization.logoUrl}")` }}
              title={organization.name}
            />
            <div>
              <h1>{properties.name}</h1>
              <p>Par {organization.name}</p>
              {properties.subtitle && <h2>{properties.subtitle}</h2>}
              <div className={styles.tags}>
                {properties.certification && (
                  <span className={styles.certification}>Certifiante</span>
                )}
                {properties.isCPF && <span className={styles.cpf}>CPF</span>}
                {properties.isNew && <span className={styles.cpf}>Nouvelle</span>}
              </div>
              {!!properties.reviewsGlobalScore && !!reviews?.length && (
                <TrainingScore
                  score={properties.reviewsGlobalScore}
                  reviewsCount={reviews.length}
                />
              )}
            </div>
          </header>

          {!!breadcrumbLinks?.length && (
            // @ts-ignore
            <Breadcrumb links={breadcrumbLinks} training id="breadcrumb" />
          )}
          {User.isSkillupAdmin() && <Skills entities={entities} />}

          <div>
            <ActionsBox
              history={history}
              currency={settings.settings.currency}
              language={i18n.language}
              {...actionsBoxProps}
            />
            <div>
              {reviews && focusedReview && (
                <blockquote>
                  {`${(focusedReview.traineeComment || "")
                    .substring(0, 250)
                    .replace(/\/\//g, "\n\n")}${
                    (focusedReview.traineeComment || "").length > 250 ? "..." : ""
                  }`}
                </blockquote>
              )}
              {nav.length > 1 && (
                <nav>
                  {nav.map((navElement) => (
                    <InteractiveButton
                      label={navElement.label}
                      key={navElement.label}
                      onClick={() => {
                        // @ts-ignore
                        const top = props.scrollTop?.top ?? navElement.ref.current?.offsetTop - 150;
                        containerRef.current && scrollTo(top, containerRef.current);
                      }}
                      strokeIconLeft={navElement.icon}
                      style={{ margin: "0 10px 0 0" }}
                    />
                  ))}
                </nav>
              )}
              {properties.deletedAt && User.isSkillupAdmin() && (
                <div className={styles.deleted}>
                  Cette formation a été supprimée le{" "}
                  {new Date(properties.deletedAt).toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                  .
                </div>
              )}
              {isNotEmptyContent(properties?.description) && (
                <section className={styles.description}>
                  <h3>Description</h3>
                  <blockquote
                    className={contentBlockClass}
                    cite={organization.profileWebsite}
                    {...injectSafeHtml(properties.description)}
                  />
                </section>
              )}
              {properties.trailer && (
                <iframe
                  src={properties.trailer}
                  width="100%"
                  height="400"
                  frameBorder="0"
                  allowFullScreen
                  title="trailer"
                  style={{ background: "#333", margin: "15px 0" }}
                />
              )}
              <div ref={sectionContents} />
              {contents.map((content) => (
                <section key={content.title}>
                  <h3>{content.title}</h3>
                  {content.type === "quote" && (
                    <blockquote
                      className={contentBlockClass}
                      cite={organization.profileWebsite}
                      {...injectSafeHtml(content.content)}
                    />
                  )}
                </section>
              ))}
              {sessions?.length > 0 && (
                <section ref={sectionSessions}>
                  <h3>Prochaines sessions</h3>
                  <div className={styles.sessionsContainer}>
                    <div>
                      {(places || []).map((place) => (
                        <button
                          key={place}
                          onClick={() => setActivePlace(place)}
                          className={activePlace && activePlace === place ? styles.selected : ""}
                        >
                          <Icon strokeIcon={mapPinIcon} width={12} style={{ marginRight: 8 }} />
                          {place}
                        </button>
                      ))}
                    </div>
                    <div>
                      {activePlace &&
                        // @ts-ignore
                        (sessionsByPlace[activePlace] || []).map((session) => (
                          <div key={session.uuid}>
                            <Icon strokeIcon={calendarIcon} width={15} style={{ marginRight: 8 }} />
                            {getSessionDateWithHours(session).label}
                          </div>
                        ))}
                    </div>
                  </div>
                </section>
              )}
              {reviews?.length > 0 && (
                <section ref={sectionReviews}>
                  <h3>{`Avis clients (${reviews.length})`}</h3>
                  <div className={styles.aggregatedScore}>
                    {[
                      ["Note générale", properties.reviewsGlobalScore],
                      ["Organisation", properties.reviewsOrganizationScore],
                      ["Animation", properties.reviewsAnimationScore],
                      ["Contenu", properties.reviewsContentScore],
                    ].map((element) => (
                      <div key={element[0] as string}>
                        {element[0]}
                        <div>
                          {Math.round((element[1] as number) * 10) / 10}
                          <span>/ 10</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  {reviews.map((review) => (
                    <div className={styles.review} key={review.uuid}>
                      <div>
                        <TrainingStars score={review.globalScore || 0} />
                      </div>
                      <div>
                        <p>
                          {review.traineeComment && review.traineeComment.replace(/\/\//g, "\n\n")}
                        </p>
                      </div>
                    </div>
                  ))}
                </section>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </DSLayout>
  );
};
