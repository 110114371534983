import { DSDropdown, DSDropdownItem } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";

import { CollaboratorRow } from "../utils/parseCollaboratorIntoRow";

const ActionsCell = ({
  actions,
  row,
  t,
}: {
  t: TranslationType;
  row: CollaboratorRow;
  actions: { editRowCollaborator: (row: CollaboratorRow) => void };
}) => {
  const { editRowCollaborator } = actions;

  return (
    <DSDropdown>
      <DSDropdownItem
        onClick={() => editRowCollaborator(row)}
        label={t("collaborators.list.label.editCollaborator", {
          defaultValue: "Modifier les informations du collaborateur",
        })}
      />
    </DSDropdown>
  );
};

export default ActionsCell;
