import { DSModal } from "@skillup/ui";

import { DashboardCardData, QuestionData } from "../types";

import { HistogramChart } from "./Charts/Histogram";

import styles from "./AllDataModal.module.scss";
import { useRef } from "react";
import { HighchartsReactRefObject } from "highcharts-react-official";

export type AllDataModalProps = {
  open: boolean;
  card: DashboardCardData;
  data: QuestionData;
  onClose: () => void;
};

export const AllDataModal = ({ open, card, data, onClose }: AllDataModalProps) => {
  const ref = useRef<HighchartsReactRefObject>(null);

  return (
    <DSModal isOpen={open} className={styles.allDataModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title={card.short_name} />
      </DSModal.Header>

      <DSModal.Content>
        <HistogramChart
          ref={ref}
          card={card}
          data={data}
          isPaginated={false}
          loading={false}
          allData
        />
      </DSModal.Content>

      <DSModal.FooterEmpty />
    </DSModal>
  );
};
