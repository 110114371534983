const mock = {
  id: "c521951d-0c8d-46b6-b589-5f2bde36a4d0",
  version: 1,
  description:
    "Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.",
  options: [
    {
      value: 1,
      label: "Faible",
      description:
        "Le collaborateur ne parvient pas à atteindre les objectifs fixés. Il montre des lacunes significatives dans l'exécution de ses missions et a besoin d'un accompagnement régulier pour progresser.",
    },
    {
      value: 2,
      label: "Moyen",
      description:
        "Le collaborateur atteint la plupart des objectifs mais avec une marge d'amélioration. Sa performance est globalement satisfaisante, mais pourrait gagner en consistance ou en efficacité.",
    },
    {
      value: 3,
      label: "Élevé",
      description:
        "Le collaborateur dépasse régulièrement les attentes. Il produit des résultats de haute qualité, fait preuve d'initiative et apporte une contribution positive et visible à l'équipe.",
    },
  ],
  title: "Potentiel",
  key: "review.performance",
};
export const getReviewCriteriaCacheKey = (id: string) => ["core-hr", "review-criteria", id];
export async function getReviewCriteria() {
  return mock;
}
