import { Currency } from "@skillup/shared-utils";
import { formatValueWithUnit } from "./formatter";
import { DashboardCardData } from "../../../types";

const histogramLabelStyle =
  'font-family: "Noto Sans Display";font-size: 12px;font-weight: 600;line-height: 18px;text-align: left;color: #69798c;';
const histogramLabelInlineStyle =
  "text-align: center;display: block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;max-height: 1lh;";
const exportFontSize = "font-size: 8px;";

export const histogramAllDataLabel = (exporting = false) => ({
  useHTML: true,
  formatter: function () {
    return `<span style='${histogramLabelStyle}${exporting ? exportFontSize : ""}' title="${this.value}">${this.value}</span>`;
  },
});

export const histogramPaginatedDataLabel = (exporting = false) => ({
  autoRotation: false,
  ...(!exporting && { maxStaggerLines: 1, staggerLines: undefined }),
  formatter: function () {
    return `<span style='${histogramLabelStyle}${histogramLabelInlineStyle}${exporting ? exportFontSize : ""}' title="${this.value}">${this.value}</span>`;
  },
  useHTML: true,
});

export const histogramYLabel = (
  card: DashboardCardData,
  language: string,
  currency: Currency,
  exporting = false
) => ({
  formatter: function () {
    const value = formatValueWithUnit(
      this.value,
      card.question_meta.axes[0]?.data_type,
      currency,
      language
    );

    return `<span style='${histogramLabelStyle}${exporting ? exportFontSize : ""}'>${value}</span>`;
  },
  useHTML: true,
});

export const pieLabel = (card: DashboardCardData, language: string, currency: Currency) => ({
  enabled: true,
  useHTML: true,
  inside: true,
  distance: 18,
  connectorWidth: 0,
  formatter: ((currency) =>
    function () {
      return `<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
          <span  style='${histogramLabelStyle}'>${this.point.name} : ${this.point.percentage.toFixed(1)}%</span>
          <span style='font-family: "Noto Sans Display";color: #1a304d;font-weight: 600;line-height: 1.5rem;font-size: 16px;'>${formatValueWithUnit(
            this.y,
            card.question_meta.axes[0]?.data_type,
            currency,
            language
          )}</span>
        </div>`;
    })(currency),
});
