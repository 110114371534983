import { BasicRouteType } from "@skillup/http-server-utils";

export enum Modules {
  TrainingDashboard = "training-dashboard",
  InterviewDashboard = "interview-dashboard",
  Trainings = "trainings",
  Interviews = "interviews",
  Skills = "competences",
  Regulatory = "reglementaire",
  CampaignCreation = "campaign-creation",

  // for v2 compatibility
  AccessListV2 = "access-list-v2",

  TrainingsSchedulePlan = "trainings-schedule-plan",
  TrainingsScheduleCollection = "trainings-schedule-collection",

  TrainingsRegulatoryHabilitations = "trainings-regulatory-habilitations",
  TrainingsRegulatoryTrackings = "trainings-regulatory-trackings",

  TrainingsIntrasOrganization = "trainings-intras-organization",
  TrainingsIntrasSkillup = "trainings-intras-skillup",

  TrainingsSessions = "trainings-sessions",

  TrainingHotEvaluations = "trainings-hot-evaluations",
  TrainingColdEvaluations = "trainings-cold-evaluations",

  InterviewsTrackings = "interviews-trackings",
  InterviewsTemplates = "interviews-templates",

  Employees = "employees",

  ConfigurationAdminPlatform = "configuration-admin-platform",
  ConfigurationPlans = "configuration-plans",
  ConfigurationPortail = "configuration-portail",
  ConfigurationImports = "configuration-imports",

  SkillsJobs = "skills-jobs",
  SkillsRequired = "skills-required",
  SkillsEmployees = "skills-employees",

  PeopleReview = "people-review",

  CareerData = "career-data",
  SalaryData = "salary-data",

  DisableDisableCustomTrainingFields = "custom-training-fields",

  MyAccount = "my-account",
  Users = "users",
}

export enum ModulesAccessList {
  AccessListV2 = "access-list-v2",

  TrainingDashboard = "training-dashboard",
  InterviewDashboard = "interview-dashboard",

  TrainingsSchedulePlan = "trainings-schedule-plan",
  TrainingsScheduleCollection = "trainings-schedule-collection",

  TrainingsRegulatoryHabilitations = "trainings-regulatory-habilitations",
  TrainingsRegulatoryTrackings = "trainings-regulatory-trackings",

  TrainingsIntrasOrganization = "trainings-intras-organization",
  TrainingsIntrasSkillup = "trainings-intras-skillup",

  TrainingsSessions = "trainings-sessions",

  TrainingHotEvaluations = "trainings-hot-evaluations",
  TrainingColdEvaluations = "trainings-cold-evaluations",

  InterviewsTrackings = "interviews-trackings",
  InterviewsTemplates = "interviews-templates",

  SkillsJobs = "skills-jobs",
  SkillsRequired = "skills-required",
  SkillsEmployees = "skills-employees",

  Employees = "employees",

  ConfigurationAdminPlatform = "configuration-admin-platform",
  ConfigurationPlans = "configuration-plans",
  ConfigurationPortail = "configuration-portail",
  ConfigurationImports = "configuration-imports",

  CampaignCreation = "campaign-creation",

  PeopleReview = "people-review",

  CareerData = "career-data",
  SalaryData = "salary-data",

  DisableCustomTrainingFields = "custom-training-fields",

  MyAccount = "my-account",
  Users = "users",
}

export type ModuleType = `${Modules}`;

export interface GetModulesRouteType extends BasicRouteType {
  method: "GET";
  path: "/user/modules";
  response: ModuleType[];
}
