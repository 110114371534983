import React from "react";
import { getIn, Field, type FieldProps } from "formik";

import { DSFormGroupTextInput, DSTextInput, AssistiveArea } from "@skillup/ui";

import style from "../../views/Configuration.module.scss";

interface Props {
  readonly name: string;
  readonly label: string;
  readonly value?: string;
  readonly placeholder?: string;
  readonly required?: boolean;
  readonly assistiveText?: React.ReactNode;
  readonly className?: string;
}

export const DashboardTextInput = ({
  name,
  placeholder,
  value,
  label,
  required,
  assistiveText,
  className,
}: Props) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          const errorValue = getIn(form.errors, field.name);
          const hasError = errorValue && getIn(form.touched, field.name);

          return (
            <DSFormGroupTextInput label={label} required={required}>
              <>
                <DSTextInput
                  value={value}
                  name={field.name}
                  placeholder={placeholder}
                  onBlur={form.handleBlur}
                  onChange={(_, e) => form.handleChange(e)}
                  className={className}
                />
                <AssistiveArea
                  className={style.textErrorAssistiveArea}
                  mode={hasError ? "error" : "default"}
                  text={hasError ? errorValue : ""}
                  area={assistiveText as any}
                />
              </>
            </DSFormGroupTextInput>
          );
        }}
      </Field>
    </>
  );
};
