import { Either } from "@skillup/monads";
import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export async function removeEmployees({
  campaignID,
  reviewIDs,
}: {
  campaignID: string;
  reviewIDs: string[];
}) {
  try {
    await buildRequest<CampaignsRoutes.RemoveReview>({
      method: "POST",
      params: {
        campaignID,
      },
      path: "/campaign/{campaignID}/remove-review",
      payload: {
        reviewIDs: reviewIDs,
      },
      target: "PEOPLE_REVIEW",
    })();

    return Either.right(undefined);
  } catch (err) {
    return Either.left(err);
  }
}
