import { Route, Switch, Redirect } from "react-router-dom";

import { PPRCampaign } from "./PPRCampaign/PPRCampaign";
import { CampaignCreation } from "./CampaignCreation/CampaignCreation";
import { PPRCampaignsList } from "./PPRCampaignsList/PPRCampaignsList";

export const PeopleReviewRouter = () => {
  return (
    <Switch>
      <Route exact component={PPRCampaignsList} path={PeopleReviewRoutes.PPRCampaignsList} />
      <Route
        component={PPRCampaign}
        path={[
          PeopleReviewRoutes.PPRCampaignWithPositionedDrawer,
          PeopleReviewRoutes.PPRCampaignWithNotPositionedDrawer,
          PeopleReviewRoutes.PPRCampaign,
        ]}
      />
      <Route component={CampaignCreation} path={PeopleReviewRoutes.CampaignCreation} />
      <Redirect to={PeopleReviewRoutes.PPRCampaignsList} />
    </Switch>
  );
};

export const BaseUrl = "/responsable/people-review" as const;
export const PeopleReviewRoutes = {
  BaseUrl,
  CampaignCreation: `${BaseUrl}/campaign-creation`,
  PPRCampaign: `${BaseUrl}/campaigns/:campaignID/:currentView`,
  PPRCampaignsList: `${BaseUrl}/campaigns`,
  PPRCampaignWithNotPositionedDrawer: `${BaseUrl}/campaigns/:campaignID/:currentView/:drawerType`,
  PPRCampaignWithPositionedDrawer: `${BaseUrl}/campaigns/:campaignID/:currentView/:drawerType/:xAxisValue/:yAxisValue`,
} as const; 
