import { useMutation, useQueryClient } from "@tanstack/react-query";

import { DSModal } from "@skillup/ui";
import { Text } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import { startCampaign } from "services/peopleReview/useCampaignDetails/updateCampaignState";

import { Campaign } from "../../types";

import styles from "./ManagerPreparationWarningModal.module.scss";

type ManagerPreparationWarningModalProps = {
  campaign: Campaign;
  onClose: () => void;
};
export const ManagerPreparationWarningModal = ({
  campaign,
  onClose,
}: ManagerPreparationWarningModalProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate } = useMutation(() => startCampaign(campaign.id), {
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
      onClose();
    },
  });

  return (
    <DSModal isOpen={true}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("peopleReview.button.managerPreparationWarningModal.title", {
            defaultValue: "Notifier {{managerCount}} manager(s) du démarrage de la campagne",
            managerCount: campaign.managerCount,
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.modal}>
        <Text espaceFont="body2Regular" color="plainText-onLight-default">
          {t("peopleReview.button.managerPreparationWarningModal.summary", {
            defaultValue:
              "Cette campagne concerne {{reviewCount}} collaborateur(s), répartis sous la responsabilité de {{managerCount}} manager(s).",
            managerCount: campaign.managerCount,
            reviewCount: campaign.reviewCount,
          })}
        </Text>
        {/* TODO: Uncomment it when real users data is given */}
        {/* <Flex marginTop="s" width={"100%"} marginBottom="s">
          <Flex flex={1} flexDirection="column">
            <Flex marginBottom="xs">
              <Label
                label={t("peopleReview.button.managerPreparationWarningModal.collaborators", {
                  defaultValue: "Collaborateur(s).",
                })}
              />
            </Flex>
            <DSAvatarGroup
              size="S"
              maxItems={5}
              totalItems={collaboratorsLength}
              users={usersData.collaborators.map((collab) => ({
                uuid: collab.uuid,
                fullName: collab.fullName,
                initials: getUserInitials({ fullName: collab.fullName }),
              }))}
            />
          </Flex>
          <Flex flex={1} flexDirection="column">
            <Flex marginBottom="xs">
              <Label
                label={t("peopleReview.button.managerPreparationWarningModal.managers", {
                  defaultValue: "Manager(s).",
                })}
              />
            </Flex>
            <DSAvatarGroup
              size="S"
              maxItems={5}
              totalItems={managerCount}
              users={usersData.managers.map((manager) => ({
                uuid: manager.uuid,
                fullName: manager.fullName,
                initials: getUserInitials({ fullName: manager.fullName }),
              }))}
            />
          </Flex>
        </Flex> */}
        <Text espaceFont="body2Regular" color="plainText-onLight-default">
          {t("peopleReview.button.managerPreparationWarningModal.whatWillHappenMessage", {
            defaultValue:
              "Le démarrage de la campagne déclenchera l'envoi automatique des mails de notification à destination des managers.",
          })}
        </Text>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("peopleReview.button.managerPreparationWarningModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          onClick={() => mutate()}
          label={t("peopleReview.button.managerPreparationWarningModal.launch", {
            defaultValue: "Démarrer la campagne",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
