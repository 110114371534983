import { Currency } from "@skillup/shared-utils";
import { TranslationFunction } from "@skillup/design-system";

import { CustomI18n } from "hooks/useTranslation";
import { DashboardCardData, QuestionData } from "../../../types";
import { CHARTS_PALETTE } from "../../../utils/graph";

import { defaultTooltip } from "./tooltip";
import {
  histogramAllDataLabel,
  histogramPaginatedDataLabel,
  histogramYLabel,
  pieLabel,
} from "./label";
import { formatValue } from "./formatter";

export const createHistogramOptions = (
  type: "page" | "all",
  sort: "asc" | "desc" | undefined,
  styles: any,
  currency: Currency,
  data: QuestionData,
  card: DashboardCardData,
  t: TranslationFunction,
  i18n: CustomI18n
) => ({
  chart: {
    type: "column",
  },
  title: {
    text: "",
  },
  subtitle: {
    test: "",
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        crop: true,
        allowOverlap: true,
      },
    },
  },
  tooltip: defaultTooltip(card.question_meta.axes[0]?.data_type, styles, currency),
  xAxis: {
    min: 0,
    reversed: sort === "asc",
    lineColor: "#E5E8EB",
    crosshair: {
      color: "rgba(192,226,241,.25)",
    },
    labels: type === "all" ? histogramAllDataLabel() : histogramPaginatedDataLabel(),
    categories: data.data.map(
      (d) => d[1] ?? t("trainings.view.dashboard.empty_label", { defaultValue: "N/A" })
    ),
  },
  yAxis: {
    min: 0,
    max: data.data.reduce(
      (acc, curr) => Math.max(acc, formatValue(curr[0], card.question_meta.axes[0]?.data_type)),
      0
    ),
    gridLineColor: "#E5E8EB",
    gridLineDashStyle: "Dash",
    title: "",
    labels: histogramYLabel(card, i18n.language, currency),
  },
  series: [
    {
      dataSorting: {
        enabled: sort !== undefined,
      },
      name: card.short_name,
      color: CHARTS_PALETTE[0],
      data: data.data.map((d) => ({
        name:
          d[1] ??
          t("trainings.view.dashboard.empty_label", {
            defaultValue: "N/A",
          }),
        y: formatValue(d[0], card.question_meta.axes[0]?.data_type),
      })),
    },
  ],
});

export const createDonutOptions = (
  styles: any,
  currency: Currency,
  data: QuestionData,
  card: DashboardCardData,
  t: TranslationFunction,
  loading: boolean,
  i18n: CustomI18n
) => ({
  chart: {
    type: "pie",
    style: loading ? { opacity: 0.3 } : {},
  },
  title: {
    text: "",
  },
  subtitle: {
    test: "",
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: defaultTooltip(card.question_meta.axes[0]?.data_type, styles, currency),
  plotOptions: {
    pie: {
      dataLabels: pieLabel(card, i18n.language, currency),
    },
  },
  series: [
    {
      name: card.short_name,
      type: "pie",
      size: "90%",
      innerSize: "80%",
      data: data.data
        .sort((a, b) => b[0] - a[0])
        .map((d, index) => ({
          name:
            d[1] ??
            t("trainings.view.dashboard.empty_label", {
              defaultValue: "N/A",
            }),
          y: formatValue(d[0], card.question_meta.axes[0]?.data_type),
          color: CHARTS_PALETTE[index],
        })),
    },
  ],
});
