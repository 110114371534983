import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { DSTooltip } from "@skillup/ui";
import { Flex, Text, Grid } from "@skillup/design-system";

import {
  getReviewCriteriaList,
  getReviewCriteriaListCacheKey,
} from "services/coreHR/fields/getReviewCriteriaList";

import { CoreHRRoutes } from "..";

import styles from "./ReviewCriteriaList.module.scss";

export function ReviewCriteriaList() {
  const { t } = useTranslation();
  const { data } = useQuery(getReviewCriteriaListCacheKey(), getReviewCriteriaList);

  return (
    <Flex gap="xs" padding="l" flexDirection="column">
      <Text espaceFont="h5" color="plainText-onLight-lighter">
        {t("core-hr.review-criteria-list.title", {
          defaultValue: "Critères d'évaluation standards",
        })}
      </Text>
      <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
        {t("core-hr.review-criteria-list.description", {
          defaultValue:
            "Ces critères sont nécessaires au bon fonctionnement de l'application. Elles ne peuvent pas être supprimées et certaines de leurs propriétés ne peuvent pas être modifiées.",
        })}
      </Text>
      {data && (
        <Grid gap="s" gridTemplateColumns={{ columnNumbers: 3, columnWidth: "auto" }}>
          {data.map(({ id, description, options, title }) => (
            <Link
              key={id}
              className={styles.link}
              to={generatePath(CoreHRRoutes.reviewCriteria, { id })}
            >
              <Flex
                gap="s"
                padding="xs s"
                flexDirection="column"
                className={styles.card}
                backgroundColor="surface-light"
              >
                <Flex gap="xxs" flexDirection="column">
                  <Text espaceFont="body1Bold" color="plainText-onLight-default">
                    {title}
                  </Text>
                  {/* 
                  @not-working enabledOnOverflow
                  @not-working enabledOnOverflow
                  @not-working enabledOnOverflow
                  @not-working enabledOnOverflow
                  @not-working enabledOnOverflow
                  @not-working enabledOnOverflow
                */}
                  <DSTooltip enabledOnOverflow label={description}>
                    <Text
                      hasEllipsis
                      lineClamp={3}
                      espaceFont="captionRegular"
                      color="plainText-onLight-lighter"
                    >
                      {description}
                    </Text>
                  </DSTooltip>
                </Flex>
                <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                  {t("core-hr.review-criteria-list.card.options-count", {
                    count: options.length,
                    defaultValue:
                      options.length > 1
                        ? "Critère évalué sur {{count}} niveaux"
                        : "Critère évalué sur {{count}} niveau",
                  })}
                </Text>
              </Flex>
            </Link>
          ))}
        </Grid>
      )}
    </Flex>
  );
}
