import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";
import type { ScheduleRoutes } from "types/api";
/**
 * This action is called when the user wants to
 * export the data table as a csv
 *
 * @export
 * @param {string} type
 * @param {string} uuid
 */

export type PlanType = "plan" | "collection" | undefined;

const filenameForType = {
  collection: "Recueil des besoins.xlsx",
  plan: "Mon plan de formation.xlsx",
  undefined: "Recueil des besoins et du plan.xlsx",
};

export interface ExportCSVParams {
  type?: PlanType;
  scheduleUuid: string;
  activeAreas: Array<string>;
}

export default async function exportCSV({ scheduleUuid, type, activeAreas }: ExportCSVParams) {
  const fileName = filenameForType[type];

  await downloadTypedFileAsUser<ScheduleRoutes.GetExcel>(
    {
      method: "GET",
      path: "/schedule/export/{scheduleUuid}/{filename}",
      params: {
        scheduleUuid,
        filename: encodeURI(fileName),
      },
      query: {
        timestamp: new Date().valueOf(),
        type,
        areas: activeAreas && activeAreas.join(","),
      },
    },
    {
      target: "API",
      deduceFileDataFromResponseHeaders: true,
    }
  );
}

export const exportNewXLSX = async ({
  scheduleUuid,
  type,
  activeAreas,
}: {
  scheduleUuid: string;
  type: PlanType;
  activeAreas: Array<string>;
}) => {
  const fileName = filenameForType[type];

  const form = document.createElement("form");
  form.method = "POST";
  form.action =
    process.env.REACT_APP_TRAINING_API_ROOT +
    `/schedule-rows/export/${scheduleUuid}/${encodeURI(fileName)}`;
  form.target = "_blank";
  form.style.display = "none";

  const typeInput = document.createElement("input");
  typeInput.type = "hidden";
  typeInput.name = "type";
  typeInput.value = type ?? "default";

  const areasInput = document.createElement("input");
  areasInput.type = "hidden";
  areasInput.name = "areas";
  areasInput.value = activeAreas?.join(",") ?? "";

  form.appendChild(typeInput);
  form.appendChild(areasInput);

  document.body.append(form);

  form.submit();

  setTimeout(() => {
    form.remove();
  }, 1000);
};
