import { useEffect } from "react";
import { useFormikContext } from "formik";

import { Flex } from "@skillup/ui";
import { PanelConfigTypeEnum } from "@skillup/training-bridge";

import { type DashboardConfigForm, type PanelConfigForm } from "../../../types";
import { useTrainingDashboardContext } from "../../../context";
import style from "../../../views/Configuration.module.scss";
import { SortableCard } from "../Cards/SortableCard";

type Props = {
  panelIndex: number;
  panel: PanelConfigForm;
};

export const Panel = ({ panelIndex, panel }: Props) => {
  const { setFieldValue } = useFormikContext<DashboardConfigForm>();
  const { summaryQuestion, monthlyQuestion } = useTrainingDashboardContext();

  const { DETAIL_INDICATORS, KEY_INDICATORS, SUMMARY, MONTHLY } = PanelConfigTypeEnum;

  // TODO: get error from { getFieldMeta } useFormikContext. For define error message on empty cards
  // const cardsError = getFieldMeta(`panels[${panelIndex}].cards`).error;

  useEffect(() => {
    if (panel.type === SUMMARY) {
      setFieldValue(`panels[${panelIndex}].cards`, [
        {
          question_name: summaryQuestion.name,
          short_name: summaryQuestion.recommended_short_name,
        },
      ]);
    }

    if (panel.type === MONTHLY) {
      setFieldValue(`panels[${panelIndex}].cards`, [
        {
          question_name: monthlyQuestion.name,
          short_name: monthlyQuestion.recommended_short_name,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasCards = panel.type === DETAIL_INDICATORS || panel.type === KEY_INDICATORS;

  return (
    <Flex row className={style.panelContent}>
      {hasCards && (
        <>
          <Flex row>
            <SortableCard
              cards={panel.cards}
              type={panel.type}
              panelIndex={panelIndex}
              setFieldValue={setFieldValue}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};
