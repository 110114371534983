import { createContext, useContext } from "react";

import { type ScheduleRowTypes } from "@skillup/training-bridge";

export type TrainingDashboardContextType = {
  detailedIndicatorsUtilities: {
    questionsMap: Record<
      string,
      {
        indicator: string;
        dimension: string;
      }
    >;
    indicatorDimensionMap: Record<string, Record<string, string>>;
  };
  keyIndicators: ScheduleRowTypes.Question[];
  detailedIndicators: ScheduleRowTypes.Question[];
  summaryQuestion: ScheduleRowTypes.Question;
  monthlyQuestion: ScheduleRowTypes.Question;
  summaryQuestionWithTax?: ScheduleRowTypes.Question;
  monthlyQuestionWithTax?: ScheduleRowTypes.Question;
};

export const TrainingDashboardContext = createContext<TrainingDashboardContextType>(null);

export const useTrainingDashboardContext = () => {
  const context = useContext(TrainingDashboardContext);

  if (!context) {
    throw new Error(
      "useTrainingDashboardFields must be used within a TrainingDashboardContextProvider"
    );
  }

  return context;
};


export type TrainingDahsboardFreshnessContextType = {
  isReady: boolean;
  isRefreshing: boolean;
  lastRefreshTime?: string;
  freshnessTime?: string;
};

export const TrainingDashboardFreshnessContext =
  createContext<TrainingDahsboardFreshnessContextType>(null);

export const useTrainingDashboardFreshnessContext = () => {
  const context = useContext(TrainingDashboardFreshnessContext);

  if (!context) {
    throw new Error(
      "useTrainingDashboardFreshnessContext must be used within a TrainingDashboardFreshnessContextProvider"
    );
  }

  return context;
};
