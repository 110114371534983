import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { DSButton, DSCheckbox, DSSortable, Flex, Loader, Select } from "@skillup/ui";
import { useToasts } from "react-toast-notifications";
import { TrainingDomainRoutes } from "@skillup/training-bridge";

import DSLayout from "components/DSLayout";
import useTranslation from "hooks/useTranslation";

import styles from "./BringUpNeedsModal.module.scss";
import { buildRequest } from "utils/buildRequest";

export type Field = {
  index: number;
  binding: string;
  required: boolean;
  label?: string;
};

type SortableItem = {
  id: number;
  content: string;
  required: boolean;
  title: string;
};

export const BringUpNeedsModal = () => {
  const [currentFieldsInModal, setCurrentFieldsInModal] = useState<SortableItem[]>([]);
  const [fields, setFields] = useState<SortableItem[]>([]);
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useQuery(["get-bringup-needs-fields"], () =>
    buildRequest<TrainingDomainRoutes.FIELD.GetFieldsForModalRoute>({
      method: "GET",
      path: "/field/modalFields/{modalName}",
      target: "TRAINING_API",
      params: {
        modalName: "BringUpNeedsManager",
      },
    })().then((data) => {
      setCurrentFieldsInModal(sort(map(data.companyFields)));
      setFields(sort(map(data.companyFields)));
      return data;
    })
  );

  const allFields = data?.allFields ?? [];

  const { addToast } = useToasts();
  const { t } = useTranslation();

  const handleReordering = (fields, fromIndex, toIndex) => {
    const updatedItems = [...fields];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);

    // Update the ids of the affected fields
    const start = Math.min(fromIndex, toIndex);
    const end = Math.max(fromIndex, toIndex);

    for (let i = start; i <= end; i++) {
      updatedItems[i].id = i;
    }

    setFields(updatedItems);

    return updatedItems;
  };

  const handleRequired = (id: number) => (required: boolean) => {
    const updatedItems = fields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          required,
        };
      }

      return field;
    });

    setFields(updatedItems);

    return updatedItems;
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await buildRequest<TrainingDomainRoutes.FIELD.SetFieldsForModalRoute>({
        method: "POST",
        path: "/field/modalFields",
        target: "TRAINING_API",
        payload: {
          fields: fields.map((field) => ({
            binding: field.content,
            index: field.id,
            required: field.required,
          })),
          modalName: "BringUpNeedsManager",
        },
      })();
      addToast(t("training.modals.success", { defaultValue: "Modifications enregistrées" }), {
        appearance: "success",
      });
    } catch (e) {
      addToast(t("training.modals.error", { defaultValue: "Une erreur est survenue" }), {
        appearance: "error",
      });
    }
    setLoading(false);
  };

  const handleRemove = (id: number) => {
    const updatedItems = fields.filter((f) => f.id !== id);
    setFields(updatedItems);

    return updatedItems;
  };

  const selectedFields = useMemo(() => {
    return fields.map((field) => ({
      key: field.content,
      value: { binding: field.content, label: field.title },
      label: field.title,
    }));
  }, [fields]);

  const disableSubmit = useMemo(() => {
    return JSON.stringify(fields) === JSON.stringify(currentFieldsInModal);
  }, [fields, currentFieldsInModal]);

  return (
    <DSLayout
      title={t("training.modals.configuration", {
        defaultValue: "Configurations des modales",
      })}
      activeLayout="bringup-needs"
      layouts={[
        {
          tab: {
            id: "bringup-needs",
            label: "Remontée des besoins manager",
            url: "/responsable/modals/remontee-besoin-manager",
          },
        },
      ]}
    >
      <div className={styles.fields}>
        <Flex column className={styles.content}>
          <Flex column>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Select
                  defaultValue={selectedFields}
                  options={allFields.map((field) => ({
                    key: field.uuid,
                    value: { binding: field.binding, label: field.label },
                    label: field.label,
                  }))}
                  onChange={(values) => {
                    if (!values) return;

                    values.map((value, index) => {
                      if (fields.find((field) => field.content === value.binding)) {
                        handleRemove(fields.find((field) => field.content === value.binding).id);
                      } else {
                        setFields(
                          sort([
                            ...fields,
                            {
                              id: fields.length + index + 1,
                              title: value.label ?? "",
                              content: value.binding,
                              required: false,
                            },
                          ])
                        );
                      }
                    });
                  }}
                  placeholder={t("training.modals.add.field", {
                    defaultValue: "Ajouter un champ",
                  })}
                  multi
                />
              </>
            )}
          </Flex>
          <Flex column>
            <div className={styles.title}>
              {t("training.bringup-needs.fields", {
                defaultValue: "Les champs de la modale de remontée de besoin :",
              })}
            </div>
            <DSSortable items={fields} reorderItems={handleReordering} />
          </Flex>
          <Flex column>
            <div className={styles.title}>
              {t("training.modals.required.fields", {
                defaultValue: "Champs requis :",
              })}
            </div>
            {fields.map((field) => (
              <Flex key={field.id} className={styles.field}>
                <div>{field.title}</div>
                <DSCheckbox label="" checked={field.required} onChange={handleRequired(field.id)} />
              </Flex>
            ))}
          </Flex>
          <DSButton
            label={t("training.actions.save", {
              defaultValue: "Enregistrer les modifications",
            })}
            disabled={disableSubmit || loading || isLoading}
            onClick={handleUpdate}
          />
        </Flex>
      </div>
    </DSLayout>
  );
};

const map = (fields: Field[]): SortableItem[] => {
  return fields.map((field) => {
    return {
      id: field.index,
      title: field.label ?? "",
      content: field.binding,
      required: field.required || false,
    };
  });
};

const sort = (fields: SortableItem[]) => fields.sort((a, b) => a.id - b.id);
