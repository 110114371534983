import { useToasts } from "react-toast-notifications";
import { useMemo, useState, useCallback } from "react";

import { MdExpandMore as ExpandMore } from "react-icons/md";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Text } from "@skillup/design-system";
import { DSModal, DSButton, DSDropdown, DSDropdownItem } from "@skillup/ui";
import { ReviewActionState, ReviewActionStates } from "@skillup/people-review-bridge";

import useTranslation from "hooks/useTranslation";
import { assignActionsToEmployee } from "services/peopleReview/field/assignActionToEmployee";

import { Review, Campaign } from "../../types";
import { Action } from "./components/Action/Action";
import { ActionDenied } from "./components/ActionDenied/ActionDenied";

import styles from "./AssignActionsModal.module.scss";

export type SelectedAction = {
  deletedAt?: Date;
  actionID: string;
  managerComment?: string;
  state: ReviewActionState;
  coordinatorComment?: string;
  origin: "MANAGER" | "COORDINATOR";
};

interface AssignActionsModalProps {
  review: Review;
  campaign: Campaign;
  onClose: () => void;
}

export function AssignActionsModal({ campaign, onClose, review }: AssignActionsModalProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { isLoading, mutate } = useMutation(assignActionsToEmployee, {
    onError: () => {
      addToast(
        t("peopleReview.talentGrid.assignActionsModal.failed", {
          defaultValue: "Un erreur est survenue",
        }),
        {
          appearance: "error",
        }
      );
    },
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign-reviews", campaign.id]);
      onClose();
    },
  });

  const [actions, setActions] = useState<SelectedAction[]>(review.assignedActions);
  const { addToast } = useToasts();

  const isReadonly = !campaign.permissions["assign-actions"].isEnabled;

  const labelsMap = campaign.actions.reduce(
    (acc, action) => ({ ...acc, [action.id]: action.label }),
    {}
  );

  const handleRestore = useCallback(
    (selectedAction: SelectedAction) => {
      if (isReadonly) return;

      const newActions = [...actions];
      const action = newActions.find((a) => a.actionID === selectedAction.actionID);
      if (action) {
        action.state = ReviewActionStates.ASSIGNED;
      }
      setActions(newActions);
    },
    [actions, isReadonly]
  );

  const handleClickAction = useCallback(
    (selectedAction: SelectedAction) => {
      if (isReadonly) return;

      const newActions = [...actions];

      const isActionArleadySelected = newActions.find(
        (a) => a.actionID === selectedAction.actionID
      );
      if (!isActionArleadySelected) {
        newActions.push(selectedAction);
        setActions(newActions);
        return;
      }

      const arleadySelectedActionIndex = newActions.findIndex(
        (a) => a.actionID === selectedAction.actionID
      );
      newActions[arleadySelectedActionIndex].state = ReviewActionStates.ASSIGNED;
      setActions(newActions);
    },
    [actions, isReadonly]
  );

  const handleRemove = useCallback(
    (selectedAction: SelectedAction) => {
      if (isReadonly) return;

      const newActions = [...actions];
      const action = newActions.find((a) => a.actionID === selectedAction.actionID);

      if (action) {
        if (action.origin === "MANAGER" || !!action.managerComment) {
          action.state = ReviewActionStates.DENIED;
        }
        if (action.state === ReviewActionStates.ASSIGNED) {
          action.deletedAt = new Date();
        }
      }

      setActions(newActions);
    },
    [actions, isReadonly]
  );

  const actionsToList = useMemo(() => {
    return actions.filter((a) => a.deletedAt === undefined);
  }, [actions]);
  const noActionsYet = actionsToList.length === 0;
  const availableActions = campaign.actions.length !== actionsToList.length;

  return (
    <DSModal isOpen className={styles.ActionsModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title={`Actions pour ${review.fullName}`} />
      </DSModal.Header>

      <DSModal.Content className={styles.ActionsModal__Content}>
        {noActionsYet && (
          <Text marginBottom="s" espaceFont="body1Regular">
            Aucune action définie pour {review.fullName}.
          </Text>
        )}
        {actionsToList.map((selectedAction) => {
          if (selectedAction.state === ReviewActionStates.DENIED)
            return (
              <ActionDenied
                labelsMap={labelsMap}
                key={selectedAction.actionID}
                handleRestore={handleRestore}
                selectedAction={selectedAction}
              />
            );
          return (
            <Action
              actions={actions}
              labelsMap={labelsMap}
              setActions={setActions}
              isReadonly={isReadonly}
              handleRemove={handleRemove}
              key={selectedAction.actionID}
              selectedAction={selectedAction}
              campaignActions={campaign.actions}
            />
          );
        })}
        {availableActions && !isReadonly && (
          <DSDropdown
            placementShift={[0, 3]}
            button={
              <DSButton
                buttonSize="S"
                emphasis="Mid"
                actionIcon={<ExpandMore />}
                label={t("peopleReview.talentGrid.assignActionsModal.addActions", {
                  defaultValue: "Ajouter une action",
                })}
              />
            }
          >
            {campaign.actions.map((a) => (
              <DSDropdownItem
                key={a.id}
                label={a.label}
                disabled={actionsToList.some((s) => s.actionID === a.id)}
                onClick={() =>
                  handleClickAction({
                    actionID: a.id,
                    origin: "COORDINATOR",
                    state: ReviewActionStates.ASSIGNED,
                  })
                }
              />
            ))}
          </DSDropdown>
        )}
      </DSModal.Content>
      {isReadonly ? (
        <DSModal.FooterInfo>
          <DSModal.FooterInfo.CancelButton
            label={t("common.close", { defaultValue: "Fermer" })}
            onClick={onClose}
          />
        </DSModal.FooterInfo>
      ) : (
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            disabled={isLoading}
            label={t("common.cancel", { defaultValue: "Annuler" })}
            onClick={onClose}
          />
          <DSModal.Footer.PrimaryButton
            loading={isLoading}
            label={t("common.save", { defaultValue: "Enregistrer" })}
            onClick={() =>
              mutate({
                campaignID: campaign.id,
                employeeActions: actions
                  .filter(({ deletedAt, state }) => state !== "DENIED" && !deletedAt)
                  .map(({ actionID, coordinatorComment }) => ({ actionID, coordinatorComment })),
                employeeToReviewID: review.id,
              })
            }
          />
        </DSModal.Footer>
      )}
    </DSModal>
  );
}
