import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import z from "zod";
import { Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { DSModal } from "@skillup/ui";

import { useTemplate, type Template } from "services/peopleReview";

import { TemplateForm } from "./TemplateForm";

import styles from "./EditTemplate.module.scss";

const TemplateSchema = z.object({
  title: z
    .string({
      required_error: "Ce champ est obligatoire",
    })
    .trim()
    .min(1, "Ce champ ne doit pas être vide"),
});

type State = { type: "saving" } | { type: "editing" };

interface EditTemplateModalProps {
  isOpen: boolean;
  template: Template;
  closeModal: () => void;
}

export const EditTemplateModal = ({ closeModal, isOpen, template }: EditTemplateModalProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({ type: "editing" });

  const { addToast } = useToasts();
  const { mutations } = useTemplate(template.uuid);

  const editTemplate = async ({ actions, fields, title }) => {
    try {
      setState({ type: "saving" });
      mutations.edit.mutateAsync({
        actionsIds: actions,
        fieldsUuids: fields,
        title,
      });
      addToast("Template mis-à-jour avec succès", { appearance: "success" });
      closeModal();
    } catch (err) {
      addToast("Une erreur est survenue", { appearance: "error" });
      setState({ type: "editing" });
    }
  };

  const initialValues = useMemo(() => {
    return {
      actions: template?.actions?.map((a) => a.id) ?? [],
      fields: template?.coreHRFields?.map((f) => f.id) ?? [],
      title: template.title,
    };
  }, [template]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={toFormikValidationSchema(TemplateSchema)}
      onSubmit={editTemplate}
    >
      {({ handleSubmit, values }) => (
        <DSModal isOpen={isOpen} portalTarget={document.body} className={styles.editTemplate}>
          <DSModal.Header onClose={closeModal}>
            <DSModal.Header.Title
              title={t("peoplereviewsConfiguration.templates.modals.creation.title", {
                defaultValue: "Nouveau modèle de campagne",
              })}
            />
          </DSModal.Header>
          <TemplateForm title={values.title} fields={values.fields} actions={values.actions} />
          <DSModal.Footer>
            <DSModal.Footer.CancelButton
              disabled={state.type === "saving"}
              onClick={closeModal}
              label={t("peoplereviewsConfiguration.templates.modals.edition.cancel", {
                // shouldn't that be a common translation ?
                defaultValue: "Annuler",
              })}
            />
            <DSModal.Footer.PrimaryButton
              loading={state.type === "saving"}
              onClick={() => handleSubmit()}
              label={t("peoplereviewsConfiguration.templates.modals.edition.confirm", {
                // same
                defaultValue: "Enregistrer les modifications",
              })}
            />
          </DSModal.Footer>
        </DSModal>
      )}
    </Formik>
  );
};
