import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { z } from "zod";
import { getIn, Formik, ErrorMessage } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { MdInfoOutline as InfoOutline } from "react-icons/md";

import { Label, Switch, Select, DSButton } from "@skillup/ui";
import { Text, Flex, FormikInputText } from "@skillup/design-system";

import { useTemplates } from "services/peopleReview";
import { CampaignCreationState } from "types/peopleReview";

import styles from "../steps.module.scss";

interface DetailsProps {
  templateID: string;
  titleForHR: string;
  hasManagers: boolean;
  titleForManagers: string;
  toggleManagersStep: (boolean) => void;
  nextStep: (data: Partial<CampaignCreationState>) => void;
}

export function Details({
  hasManagers,
  nextStep,
  templateID,
  titleForHR,
  titleForManagers,
  toggleManagersStep,
}: DetailsProps) {
  const { t } = useTranslation();
  const { templates } = useTemplates();
  const [managerStepChecked, setManagerStepChecked] = useState(hasManagers);

  const onSubmit = (data: {
    titleForHR: string;
    templateID: string;
    hasManagers: boolean;
    titleForManagers: string;
  }) => {
    nextStep({
      hasManagers: data.hasManagers,
      templateID: data.templateID,
      titleForHR: data.titleForHR,
      titleForManagers: data.titleForManagers,
    });
  };

  const detailsValidationSchema = useMemo(
    () =>
      toFormikValidationSchema(
        z.object({
          templateID: z
            .string({
              required_error: t("peopleReview.campaignCreationSteps.hrTitle.shouldBeDefined", {
                defaultValue: "Veuillez sélectionner un modèle de campagne",
              }),
            })
            .min(1),
          titleForHR: z
            .string({
              invalid_type_error: t("peopleReview.campaignCreationSteps.hrTitle.shouldBeDefined", {
                defaultValue: "Veuillez saisir un intitulé pour les RH",
              }),
              required_error: t("peopleReview.campaignCreationSteps.hrTitle.shouldBeDefined", {
                defaultValue: "Veuillez saisir un intitulé pour les RH",
              }),
            })
            .min(1, {
              message: t("peopleReview.campaignCreationSteps.hrTitle.shouldBeDefined", {
                defaultValue: "Veuillez saisir un intitulé pour les RH",
              }),
            }),
          titleForManagers: z.string().optional().nullable(),
        })
      ),
    [t]
  );

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={detailsValidationSchema}
      onSubmit={onSubmit}
      initialValues={{
        hasManagers,
        templateID,
        titleForHR,
        titleForManagers,
      }}
    >
      {({ errors, handleChange, handleSubmit, isValid, setFieldValue, touched, values }) => {
        return (
          <Flex
            gap="l"
            width="100%"
            maxWidth="30rem"
            flexDirection="column"
            marginHorizontal="auto"
          >
            <Flex flexDirection="column">
              <Label
                required
                label={t("peopleReview.campaignCreationSteps.hrTitle", {
                  defaultValue: "Intitulé de la campagne pour les RH",
                })}
              />
              <FormikInputText name="titleForHR" value={values.titleForHR} />
            </Flex>

            <Flex gap="s" flexDirection="column">
              <Flex flexDirection="column">
                <Label
                  required
                  label={t("peopleReview.campaignCreationSteps.modelLabel", {
                    defaultValue: "Modèle de campagne",
                  })}
                />
                <Select
                  value={values.templateID}
                  error={getIn(errors, "templateID") && getIn(touched, "templateID")}
                  onChange={(newTemplate: string) => {
                    setFieldValue("templateID", newTemplate);
                    handleChange("templateID");
                  }}
                  noOptionsMessage={t("peopleReview.campaignCreationSteps.noModelAvailable", {
                    defaultValue: "Aucun modèle de campagne disponible",
                  })}
                  placeholder={t("peopleReview.campaignCreationSteps.modelSelectionPlaceholder", {
                    defaultValue: "Sélectionnez un modèle de campagne",
                  })}
                  options={
                    templates?.map((template) => ({
                      label: template.title,
                      value: template.uuid,
                    })) ?? []
                  }
                />
                <ErrorMessage
                  name="templateID"
                  render={(msg) => (
                    <Text fontSize="0.75rem" espaceFont="body1Regular" color="status-error-darker">
                      <Flex gap="xs" alignItems="center">
                        <InfoOutline />
                        {msg}
                      </Flex>
                    </Text>
                  )}
                />
              </Flex>
              {templates.find(({ uuid }) => uuid === values.templateID) && (
                <Flex width="100%">
                  <Flex width="100%" flexDirection="column">
                    <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                      {t("peopleReview.campaignCreationSteps.template.fields", {
                        defaultValue: "Critères d’évaluation",
                      })}
                    </Text>
                    <ul className={styles.columnsText}>
                      {templates
                        .find(({ uuid }) => uuid === values.templateID)
                        .coreHRFields.map((field) => (
                          <li key={field.id}>
                            <Text espaceFont="body1Regular" color="plainText-onLight-default">
                              {field.label}
                            </Text>
                          </li>
                        ))}
                    </ul>
                  </Flex>
                  <Flex width="100%" flexDirection="column">
                    <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                      {t("peopleReview.campaignCreationSteps.template.actions", {
                        defaultValue: "Actions possibles",
                      })}
                    </Text>
                    <ul className={styles.columnsText}>
                      {templates
                        .find(({ uuid }) => uuid === values.templateID)
                        .actions.map((action) => (
                          <li key={action.id}>
                            <Text espaceFont="body1Regular" color="plainText-onLight-default">
                              {action.label}
                            </Text>
                          </li>
                        ))}
                    </ul>
                  </Flex>
                </Flex>
              )}
            </Flex>

            <Flex>
              <Switch
                active={managerStepChecked}
                label={t("peopleReview.campaignCreationSteps.managerStepSwitch", {
                  defaultValue: "Inclure une phase de préparation par les managers",
                })}
                onToggle={(status) => {
                  setFieldValue("hasManagers", status);
                  toggleManagersStep(!managerStepChecked);
                  setManagerStepChecked(!managerStepChecked);
                }}
              />
            </Flex>

            {managerStepChecked && (
              <Flex flexDirection="column">
                <Label
                  label={t("peopleReview.campaignCreationSteps.managersTitle", {
                    defaultValue: "Intitulé de la campagne pour les managers",
                  })}
                />
                <FormikInputText name="titleForManagers" value={values.titleForManagers} />
                <Text espaceFont="captionRegular">
                  {t("campaignCreation.managersTitleHelpText", {
                    defaultValue:
                      "Saisissez une valeur différente si vous ne souhaitez pas que l'intitulé pour les RH soit visible aux participants de la campagne.",
                  })}
                </Text>
              </Flex>
            )}

            <Flex justifyContent="flex-end">
              <DSButton
                type="submit"
                disabled={!(isValid && touched)}
                onClick={() => handleSubmit()}
                label={t("nextStep", {
                  defaultValue: "Suivant",
                })}
              />
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}
