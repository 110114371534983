import { useMemo, Dispatch, useCallback } from "react";

import { MdTipsAndUpdates as TipsAndUpdates } from "react-icons/md";

import { Flex, Text } from "@skillup/design-system";

import { TranslationType } from "hooks/useTranslation";
import { DatatableJob, SuggestionsJob } from "types/skills";

import { AssignModalSelect } from "../Assignments.styled";

interface JobSelectionDropdownProps {
  t: TranslationType;
  jobs: Array<DatatableJob>;
  selectedJobUuid: null | string;
  suggestions: Array<SuggestionsJob>;
  setSelectedJobUuid: Dispatch<string>;
}

export const JobSelectionDropdown = ({
  jobs,
  selectedJobUuid,
  setSelectedJobUuid,
  suggestions,
  t,
}: JobSelectionDropdownProps) => {
  const onChange = useCallback(
    (jobUuid: string | undefined) => {
      setSelectedJobUuid(jobUuid);
    },
    [setSelectedJobUuid]
  );

  const employeeSuggestions = useMemo(() => {
    const uniqueSuggestions = new Map();
    suggestions.forEach(({ employees }) =>
      employees.forEach(({ employeeSuggestions }) =>
        employeeSuggestions.forEach(({ jobId, jobLabel }) => {
          if (!uniqueSuggestions.has(jobId)) {
            uniqueSuggestions.set(jobId, { label: jobLabel, value: jobId });
          }
        })
      )
    );
    return Array.from(uniqueSuggestions.values()).sort((a, b) => a.label.localeCompare(b.label));
  }, [suggestions]);

  const jobList = useMemo(() => {
    const suggestionJobIds = new Set(employeeSuggestions.map(({ value }) => value));
    return jobs
      .filter(({ uuid }) => !suggestionJobIds.has(uuid))
      .map(({ uuid, name }) => ({ label: name, value: uuid }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [jobs, employeeSuggestions]);

  const concatenedJobList = useMemo(() => {
    const suggestionHeader =
      suggestions.length > 0
        ? [
            {
              label: "",
              title: (
                <Flex gap="s" alignItems="center">
                  <TipsAndUpdates size="1rem" />
                  <Text espaceFont="body2Bold" color="plainText-onLight-default">
                    {t("skills.suggestions.modal.list.suggestion", {
                      defaultValue: "Suggestions de fiches de poste",
                    })}
                  </Text>
                </Flex>
              ),
              value: "",
            },
          ]
        : [];

    const jobHeader =
      suggestions.length > 0
        ? [
            {
              label: "",
              title: (
                <Text espaceFont="body2Bold" color="plainText-onLight-default">
                  {t("skills.suggestions.modal.list.job", {
                    defaultValue: "Toutes les fiches de poste",
                  })}
                </Text>
              ),
              value: "",
            },
          ]
        : [];

    return [...suggestionHeader, ...employeeSuggestions, ...jobHeader, ...jobList];
  }, [suggestions, employeeSuggestions, jobList, t]);

  return (
    <AssignModalSelect
      value={selectedJobUuid}
      options={concatenedJobList}
      onChange={onChange}
      placeholder={t("skills.list.collaborator.selectJob", {
        defaultValue: "Sélectionnez une fiche de poste",
      })}
    />
  );
};
