import React, { useEffect } from "react";

import { useTheme } from "context";
import Highcharts from "highcharts/highcharts";
import exporting from "highcharts/modules/exporting";
import exportdata from "highcharts/modules/export-data";
import { Themes, DSColor, FontFamily, EspaceFont, SkuiSpacing } from "commons";
import HighchartsReact, { type HighchartsReactRefObject } from "highcharts-react-official";

exporting(Highcharts);
exportdata(Highcharts);

interface HistogramProps {
  tooltipNameValue: string;
  tooltipNameMoreValue?: string;
  tooltipNameComparedValue?: string;
  chartRef?: React.MutableRefObject<null | HighchartsReactRefObject>;
  values: Array<{
    name: string;
    value: number;
    pathUrl?: string;
    moreValue?: number;
    comparedValue?: number;
  }>;
}

interface ExtendedHighchartOptions extends Highcharts.Options {
  urlPath: Record<string, string | undefined>;
}
declare module "highcharts" {
  interface Point {
    moreValue?: string;
  }
}

const Histogram = ({
  chartRef,
  tooltipNameComparedValue,
  tooltipNameMoreValue,
  tooltipNameValue,
  values,
}: HistogramProps) => {
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .labelLink {
        font-size: 0.75rem;
        color: ${DSColor["plainText-onLight-default"]};
        font-family: ${fontFamily};
      }
      .custom-highcharts-tooltip {
        background: ${DSColor["surface-light"]};
        padding: ${SkuiSpacing.s};
        font-family: ${fontFamily};
        border-radius: 0.25rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        p {
          text-align: center;
        }

        .label {
          font-size: 0.75rem;
          color: ${DSColor["plainText-onLight-lighter"]};
          font-weight: bold;
        }
        .valueContainer {
          display: flex;
          align-items: center;

          .round {
            width: 8px;
            height: 8px;
            color: #6dc6d9;
            border-radius: 50%;
            margin-right: ${SkuiSpacing.xxs};
          }

          .value {
            color: ${DSColor["plainText-onLight-default"]};
            font-size: 0.75rem;
          }
        }
      }
    `;
    document.head.appendChild(style);
  }, []);

  const theme = useTheme();
  const isRHTheme = theme === Themes.ESPACE_RH;

  const fontFamily = isRHTheme ? FontFamily.rh : FontFamily.collab;
  const espaceFont = isRHTheme ? EspaceFont.rh : EspaceFont.collab;

  const urlPath = values.reduce(
    (acc, value) => {
      acc[value.name] = value.pathUrl;
      return acc;
    },
    {} as Record<string, string | undefined>
  );

  const options: ExtendedHighchartOptions = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        grouping: false,
      },
      series: {
        borderWidth: 0,
      },
    },
    series: [
      {
        type: "column",
        data: values.map((val) => {
          return {
            color: DSColor["greyscale-light"],
            name: val.name,
            ownURL: val.pathUrl,
            y: val.comparedValue,
          };
        }),
        dataLabels: {
          style: {
            color: DSColor["plainText-onLight-default"],
            fontFamily: fontFamily,
            fontSize: espaceFont.captionRegular.fontSize,
          },
        },
        linkedTo: "main",
        name: tooltipNameComparedValue,
        pointPlacement: -0.15,
      },
      {
        id: "main",
        type: "column",
        data: values.map((val) => {
          return {
            color:
              (val.comparedValue && val.value >= val.comparedValue) || !val.comparedValue
                ? DSColor["decoration-blue-base"]
                : DSColor["decoration-blue-lighter"],
            moreValue: val.moreValue && `${tooltipNameMoreValue}: <b>${val.moreValue}</b>`,
            name: val.name,
            y: val.value,
          };
        }),
        dataLabels: {
          style: {
            color: DSColor["plainText-onLight-default"],
            fontFamily: fontFamily,
            fontSize: espaceFont.captionRegular.fontSize,
          },
        },
        name: tooltipNameValue,
      },
    ],
    title: {
      text: "",
    },
    tooltip: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      formatter: function () {
        return `
          <div class="custom-highcharts-tooltip">
            <p class="label">${this.key}</p>
            <div class="valueContainer">
              <p class="value">${this.series.name} : <b>${this.y}</b> <br/> ${this.point.moreValue ?? ""}</p>
            </div>
          </div>
        `;
      },
      useHTML: true,
    },
    urlPath,
    xAxis: {
      type: "category",
      accessibility: {
        description: tooltipNameValue,
      },
      labels: {
        formatter: function () {
          return `<a href="${urlPath[this.value]}" class="labelLink">${this.value}</a>`;
        },
        style: {
          textAlign: "center",
        },
        useHTML: true,
      },
      lineColor: "#E5E8EB",
      minRange: 1,
    },
    yAxis: [
      {
        gridLineDashStyle: "Dash",
        labels: {
          style: {
            color: DSColor["plainText-onLight-default"],
          },
        },
        max: values.reduce((acc, val) => Math.max(acc, val.value, val.comparedValue || 0), 0),
        showFirstLabel: false,
        title: {
          text: "",
        },
      },
    ],
  };

  return <HighchartsReact ref={chartRef} options={options} highcharts={Highcharts} />;
};

export default Histogram;
