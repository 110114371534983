import { RenameCategoryParams, TargetCategoriesRoutes } from "@skillup/espace-rh-bridge";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { buildRequest } from "utils/buildRequest";

async function renameCategoryQuery(params: RenameCategoryParams) {
  try {
    const { uuid } = await buildRequest<TargetCategoriesRoutes.updateTargetCategory>({
      method: "PUT",
      path: "/targetCategories/{uuid}",
      params: { uuid: params.uuid },
      payload: params,
    })();
    if (uuid === undefined) throw new Error("Category update failed");
    return uuid;
  } catch (err) {
    return undefined;
  }
}

export const useRenameTargetCategories = () => {
  const queryClient = useQueryClient();
  const renameCategory = useCallback(
    async (params) => {
      const result = await renameCategoryQuery(params);
      await queryClient.invalidateQueries(["targetCategories"]);
      return result;
    },
    [queryClient]
  );

  return {
    renameCategory,
  };
};
