import styled from "styled-components";
import { MdWarning } from "react-icons/md";

import { Flex } from "@skillup/design-system";
import { DSColor, SkuiSpacing } from "@skillup/ui";

export const Container = styled(Flex)`
  background: ${DSColor["surface-light"]};
  border: 1px solid ${DSColor["border-onLight"]};
  border-radius: ${SkuiSpacing.s};
  padding: ${SkuiSpacing.s};
  flex: 1;
  flex-direction: column;
`;

export const RadarContainer = styled(Flex)`
  flex-direction: column;
  border-radius: ${SkuiSpacing.xxs};
  margin-bottom: ${SkuiSpacing.xs};
  height: 420px;
  width: 100%;
  margin-top: ${SkuiSpacing.s};
`;

export const Legend = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 12px;
  margin-right: ${SkuiSpacing.xxs};
`;

export const AverageLegend = styled(Legend)`
  background-color: ${DSColor["action-onLight-default"]};
`;

export const ExpectedLegend = styled(Legend)`
  background-color: ${DSColor["decoration-red-darker"]};
`;

export const WarningIcon = styled(MdWarning)`
  color: ${DSColor["plainText-onLight-lighter"]};
`;
