import { useRef } from "react";
import { useToasts } from "react-toast-notifications";
import cx from "classnames";
import { MdContentCopy } from "react-icons/md";

import { TextInput, Tooltip } from "@skillup/ui";

import styles from "../ConnectionModes.module.scss";

const ClipboardText = ({
  value,
  tooltipText,
  className,
}: {
  value: string;
  tooltipText: string;
  className?: string;
}) => {
  const clipboardInput = useRef<HTMLInputElement>();

  const { addToast } = useToasts();

  async function handleCopy(input: HTMLInputElement) {
    if (!input) return;
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(input.value);
    } else if (document.execCommand) {
      input.focus();
      input.select();
      if (!document.execCommand("copy")) return;
      input.blur();
    }
    addToast("Texte copié dans le presse-papier", { appearance: "success" });
  }

  return (
    <div className={cx(className, styles.ClipboardText)}>
      <TextInput
        className={styles.clipboardInput}
        inputRef={clipboardInput}
        value={value}
        onFocus={(e) => e.currentTarget.select()}
        readOnly
      />
      <Tooltip content={tooltipText} position="top" className={styles.clipboardBtn}>
        <MdContentCopy size={22} onClick={() => handleCopy(clipboardInput.current)} />
      </Tooltip>
    </div>
  );
};

export default ClipboardText;
