import { t } from "i18next";

import { GridColDef } from "@mui/x-data-grid-pro";

import { Review } from "../../../types";

export { getActionMenuColDef } from "./ActionMenu";
export { getAssignedActionsColDef } from "./AssignedActions";
export { getCampaignFieldsColDefs } from "./CampaignFields";
export { coordinatorCommentColDef } from "./CoordinatorComment";
export { getCustomFieldColDefs } from "./CustomFields";
export { emailColDef } from "./Email";
export { managerCommentColDef } from "./ManagerComment";
export { roleColDef } from "./Role";
export { statusColDef } from "./Status";
export { userPopoverColDef } from "./UserPopover";

export const managerColDef: GridColDef<Review> = {
  type: "string",
  field: "manager" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.review-manager", {
    defaultValue: "Manager",
  }),
  minWidth: 150,
};

export const firstNameColDef: GridColDef<Review> = {
  type: "string",
  field: "firstName" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.firstName", {
    defaultValue: "Prénom",
  }),
  minWidth: 150,
};

export const lastNameColDef: GridColDef<Review> = {
  type: "string",
  field: "lastName" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.lastName", {
    defaultValue: "Nom",
  }),
  minWidth: 150,
};

export const divisionColDef: GridColDef<Review> = {
  type: "string",
  field: "division" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.division", {
    defaultValue: "Division",
  }),
  minWidth: 150,
};

export const serviceColDef: GridColDef<Review> = {
  type: "string",
  field: "service" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.service", {
    defaultValue: "Service",
  }),
  minWidth: 150,
};

export const siteColDef: GridColDef<Review> = {
  type: "string",
  field: "site" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.site", {
    defaultValue: "Site",
  }),
  minWidth: 150,
};

export const joinDateColDef: GridColDef<Review> = {
  type: "string",
  field: "joinDate" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.joinDate", {
    defaultValue: "Date d'entrée",
  }),
  minWidth: 150,
  valueGetter(params) {
    return params.row.joinDate ? new Date(params.row.joinDate).toLocaleDateString() : "";
  },
};

export const contractColDef: GridColDef<Review> = {
  type: "string",
  field: "contract" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.contract", {
    defaultValue: "Contrat",
  }),
  minWidth: 150,
};

export const branchColDef: GridColDef<Review> = {
  type: "string",
  field: "branch" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.branch", {
    defaultValue: "Direction",
  }),
  minWidth: 150,
};

export const registrationNumberColDef: GridColDef<Review> = {
  type: "string",
  field: "registrationNumber" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.registrationNumber", {
    defaultValue: "Matricule",
  }),
  minWidth: 150,
};

export const perimetersColDef: GridColDef<Review> = {
  type: "string",
  field: "perimeters" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.perimeters", {
    defaultValue: "Périmètres",
  }),
  minWidth: 150,
  valueGetter(params) {
    return params.row.perimeters.map(({ name }) => name);
  },
};
