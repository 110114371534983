import { MdOpenInNew as OpenInNew } from "react-icons/md";
import React from "react";

import { isNil } from "lodash";

import { plural } from "utils/locale";

import { DataTable, DSDropdownItem } from "@skillup/ui";
import { useBaseUrl } from "@skillup/hooks";

import { TrainingResults } from "../types";
import styles from "../UserTraining.module.scss";
import useTableData from "./useTableData";

interface Props {
  readonly trainings: TrainingResults;
}

export default ({ trainings }: Props) => {
  const { baseUrl } = useBaseUrl();

  const getBodyUrlByState = (state: string) => {
    switch (state) {
      case "pending_for_HR":
      case "pending_for_additionnal_validation":
      case "denied":
        return "recueil-des-besoins/collection-all";
      default:
        return "plan-de-formation/plan-all";
    }
  };

  const { columns, data: tableData } = useTableData({ trainings: trainings ?? [] });

  return (
    <DataTable
      className={styles.table}
      header={{
        totalRowsLabel: (total) => {
          let label = plural(total, "%n demande%s de formation%s");
          if (total < trainings.length) {
            label += `${plural(total, " filtrée%s")} sur ${trainings.length}`;
          }
          return label;
        },
      }}
      columns={columns}
      rows={tableData}
      mode="compact"
      actions={(row) => [
        <DSDropdownItem
          key="download-pdf"
          label={
            <>
              Accéder au programme{" "}
              <OpenInNew style={{ fontSize: "1rem", verticalAlign: "middle" }} />
            </>
          }
          disabled={isNil(row.data.seoSlug)}
          tooltipLabel={
            isNil(row.data.seoSlug)
              ? "Aucun programme n’est lié à cette formation dans Skillup"
              : ""
          }
          onClick={() => {
            const win = window.open(
              `${baseUrl}/programmes/${row.data.seoSlug ? "catalogue" : "gestion-intras"}/${
                row.data.seoSlug
              }`,
              "_blank"
            );
            win.focus();
          }}
        />,
        <DSDropdownItem
          key="show-interview"
          label={
            <>
              Consulter la ligne associée dans le plan{" "}
              <OpenInNew style={{ fontSize: "1rem", verticalAlign: "middle" }} />
            </>
          }
          onClick={() => {
            const win = window.open(
              `${baseUrl}/${row.data.schedule?.uuid}/${getBodyUrlByState(row.data.state)}/${
                row.id
              }`,
              "_blank"
            );
            win.focus();
          }}
        />,
      ]}
      pagination={{
        rowsPerPageLabel: "Formations par page",
        itemsCountLabel: "Formations %range% sur %count%",
        pageLabel: "Page",
      }}
    />
  );
};
