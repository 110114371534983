import z from "zod";

export const FieldDataTypeSchema = z.enum(["money", "number", "duration"]);
export type FieldDataType = z.infer<typeof FieldDataTypeSchema>;

export const CardChartTypeSchema = z.enum(["table", "bar", "pie", "number", "combo_with_goal"]);
export type CardChartType = z.infer<typeof CardChartTypeSchema>;

export const DashboardCardConfigSchema = z.object({
  short_name: z.string(),
  relative_width: z.number(),
  charts: z.array(CardChartTypeSchema),
  filters: z.array(z.string()),
  x_label: z.string(),
  y_label: z.string(),
  question_name: z.string(),
});
export type DashboardCardConfig = z.infer<typeof DashboardCardConfigSchema>;

export const DashboardPanelConfigSchema = z.object({
  short_name: z.string(),
  relative_width: z.number(),
  description: z.string(),
  cards: z.array(DashboardCardConfigSchema),
});
export type DashboardPanelConfig = z.infer<typeof DashboardPanelConfigSchema>;

export const DashboardConfigSchema = z.object({
  short_name: z.string(),
  description: z.string(),
  panels: z.array(DashboardPanelConfigSchema),
  feature_tags: z.array(z.string()),
});
export type DashboardConfig = z.infer<typeof DashboardConfigSchema>;

export const DashboardCardConfigForEditSchema = z.object({
  description: z.string(),
  question_name: z.string(),
  selected_charts: z.array(CardChartTypeSchema),
  selected_filters: z.array(z.string()),
  short_name: z.string(),
  relative_width: z.number(),
  x_label: z.string(),
  y_label: z.string(),
});
export type DashboardCardConfigForEdit = z.infer<typeof DashboardCardConfigForEditSchema>;

export const DashboardPanelForEditSchema = z.object({
  short_name: z.string(),
  relative_width: z.number(),
  cards: z.array(DashboardCardConfigForEditSchema),
});
export type DashboardPanelForEdit = z.infer<typeof DashboardPanelForEditSchema>;

export const DashboardConfigForEditSchema = z.object({
  short_name: z.string(),
  description: z.string(),
  panels: z.array(DashboardPanelForEditSchema),
  feature_tags: z.array(z.string()),
});
export type DashboardConfigForEdit = z.infer<typeof DashboardConfigForEditSchema>;

export const FieldSchema = z.object({
  name: z.string(),
  data_type: FieldDataTypeSchema,
  recommended_display_name: z.string(),
  recommended_description: z.string(),
  recommended_value_bindings: z.any(),
  recommended_coalescing_value: z.string(),
});
export type Field = z.infer<typeof FieldSchema>;

export const QuestionSchema = z.object({
  name: z.string(),
  recommended_short_name: z.string(),
  recommended_description: z.string(),
  fields: z.array(z.string()),
  available_charts: z.array(CardChartTypeSchema),
  recommended_x_label: z.string(),
  recommended_y_label: z.string(),
  available_filters: z.array(z.string()),
});
export type Question = z.infer<typeof QuestionSchema>;

export const DashboardAxeSchema = z.object({
  field_name: z.string(),
  short_name: z.string(),
  description: z.string(),
  value_bindings: z.record(z.string()).optional(),
});
export type DashboardAxe = z.infer<typeof DashboardAxeSchema>;

export const PanelConfigTypeEnum = {
  DETAIL_INDICATORS: "detail-indicators",
  KEY_INDICATORS: "key-indicators",
  SUMMARY: "summary",
  MONTHLY: "monthly",
  SUMMARY_WITH_TAX: "summary-with-tax",
  MONTHLY_WITH_TAX: "monthly-with-tax",
} as const;
export type PanelConfigType = (typeof PanelConfigTypeEnum)[keyof typeof PanelConfigTypeEnum];
