import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { DSRadio, DSButton } from "@skillup/ui";
import { Flex, Grid, Text } from "@skillup/design-system";

import { CampaignFeedbackFormData } from "services/peopleReview/campaign/useCampaignFeedbackForm";

import { Campaign } from "../../types";

import styles from "./CampaignFeedbackForm.module.scss";

interface CampaignFeedbackFormProps {
  campaign: Campaign;
  hasSubmitButton?: boolean;
  data: CampaignFeedbackFormData;
  onChange: (data: CampaignFeedbackFormData) => void;
  onSubmit?: (data: CampaignFeedbackFormData) => void;
}

export function CampaignFeedbackForm({
  campaign,
  data,
  hasSubmitButton = false,
  onChange,
  onSubmit = () => {},
}: CampaignFeedbackFormProps) {
  const { t } = useTranslation();

  const { isCommentSharedToManager, sharedToManagerActionIds, sharedToManagerCoreHRScaleIDs } =
    data;

  return (
    <>
      <Text paddingBottom="m" espaceFont="body1Regular" color="plainText-onLight-default">
        {t("peopleReview.campaign.feedbackSettingsModal.description", {
          defaultValue:
            "Pour chacun des éléments ci-dessous, choisissez ceux qui seront partagés avec les managers une fois la campagne clôturée :",
        })}
      </Text>
      <Flex
        gap="m"
        flexDirection="column"
        padding={hasSubmitButton ? "s" : ""}
        backgroundColor={hasSubmitButton ? "greyscale-lighter" : "surface-light"}
      >
        <Grid gridTemplateColumns={{ columnNumbers: 3, columnWidth: "auto" }}>
          <div className={styles.gridCellTitle}>
            <Text espaceFont="captionBold" color="plainText-onLight-lighter">
              {t("peopleReview.campaign.feedbackSettingsModal.evaluationCriteria", {
                defaultValue: "Critères d'évaluation",
              })}
            </Text>
          </div>

          {campaign.scales.map((scale) => (
            <Fragment key={scale.id}>
              <div className={styles.gridCell}>
                <Text espaceFont="body1Regular" color="plainText-onLight-default">
                  {scale.label}
                </Text>
              </div>

              <div className={styles.gridCell}>
                <DSRadio
                  checked={sharedToManagerCoreHRScaleIDs.includes(scale.id)}
                  label={t("peopleReview.campaign.feedbackSettingsModal.shared", {
                    defaultValue: "Partagé",
                  })}
                  onChange={() => {
                    onChange({
                      isCommentSharedToManager,
                      sharedToManagerActionIds,
                      sharedToManagerCoreHRScaleIDs: [...sharedToManagerCoreHRScaleIDs, scale.id],
                    });
                  }}
                />
              </div>

              <div className={styles.gridCell}>
                <DSRadio
                  checked={!sharedToManagerCoreHRScaleIDs.includes(scale.id)}
                  label={t("peopleReview.campaign.feedbackSettingsModal.notShared", {
                    defaultValue: "Non partagé",
                  })}
                  onChange={() => {
                    onChange({
                      isCommentSharedToManager,
                      sharedToManagerActionIds,
                      sharedToManagerCoreHRScaleIDs: sharedToManagerCoreHRScaleIDs.filter(
                        (id) => id !== scale.id
                      ),
                    });
                  }}
                />
              </div>
            </Fragment>
          ))}

          <div className={styles.gridCellTitle}>
            <Text espaceFont="captionBold" color="plainText-onLight-lighter">
              {t("peopleReview.campaign.feedbackSettingsModal.actionsAndComments", {
                defaultValue: "Actions et commentaires associés",
              })}
            </Text>
          </div>

          {campaign.actions.map((action) => (
            <Fragment key={action.id}>
              <div className={styles.gridCell}>
                <Text espaceFont="body1Regular" color="plainText-onLight-default">
                  {action.label}
                </Text>
              </div>

              <div className={styles.gridCell}>
                <DSRadio
                  checked={sharedToManagerActionIds.includes(action.id)}
                  label={t("peopleReview.campaign.feedbackSettingsModal.shared", {
                    defaultValue: "Partagé",
                  })}
                  onChange={() => {
                    onChange({
                      isCommentSharedToManager,
                      sharedToManagerActionIds: [...sharedToManagerActionIds, action.id],
                      sharedToManagerCoreHRScaleIDs,
                    });
                  }}
                />
              </div>

              <div className={styles.gridCell}>
                <DSRadio
                  checked={!sharedToManagerActionIds.includes(action.id)}
                  label={t("peopleReview.campaign.feedbackSettingsModal.notShared", {
                    defaultValue: "Non partagé",
                  })}
                  onChange={() => {
                    onChange({
                      isCommentSharedToManager,
                      sharedToManagerActionIds: sharedToManagerActionIds.filter(
                        (id) => id !== action.id
                      ),
                      sharedToManagerCoreHRScaleIDs,
                    });
                  }}
                />
              </div>
            </Fragment>
          ))}

          <div className={styles.gridCellTitle}>
            <Text espaceFont="captionBold" color="plainText-onLight-lighter">
              {t("peopleReview.campaign.feedbackSettingsModal.other", {
                defaultValue: "Autres",
              })}
            </Text>
          </div>

          <div className={styles.gridCell}>
            <Text espaceFont="body1Regular" color="plainText-onLight-default">
              {t("peopleReview.campaign.feedbackSettingsModal.generalComment", {
                defaultValue: "Commentaire général du coordinateur",
              })}
            </Text>
          </div>

          <div className={styles.gridCell}>
            <DSRadio
              checked={isCommentSharedToManager}
              label={t("peopleReview.campaign.feedbackSettingsModal.shared", {
                defaultValue: "Partagé",
              })}
              onChange={() =>
                onChange({
                  isCommentSharedToManager: true,
                  sharedToManagerActionIds,
                  sharedToManagerCoreHRScaleIDs,
                })
              }
            />
          </div>

          <div className={styles.gridCell}>
            <DSRadio
              checked={!isCommentSharedToManager}
              label={t("peopleReview.campaign.feedbackSettingsModal.notShared", {
                defaultValue: "Non partagé",
              })}
              onChange={() =>
                onChange({
                  isCommentSharedToManager: false,
                  sharedToManagerActionIds,
                  sharedToManagerCoreHRScaleIDs,
                })
              }
            />
          </div>
        </Grid>
        {hasSubmitButton && (
          <Flex flexDirection="row-reverse">
            <DSButton
              buttonSize="S"
              onClick={() => onSubmit(data)}
              label={t("peopleReview.campaign.feedbackSettingsModal.submit", {
                defaultValue: "Enregistrer les modifications",
              })}
            />
          </Flex>
        )}
      </Flex>
    </>
  );
}
