import {
  useMemo,
  useState,
  Dispatch,
  useEffect,
  useContext,
  createContext,
  SetStateAction,
} from "react";

import { GridSortModel } from "@mui/x-data-grid-pro";

import { FilterProp } from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";

import getUsers, { Collaborator } from "../../../User/api/getUsers";
import { useCollaboratorsGridFilters } from "./utils/useCollaboratorsGridFilters";

type PaginationProperties = {
  page: number;
  pageSize: number;
};

type GetUsers = Awaited<ReturnType<typeof getUsers>>;

type CollaboratorsContextType = {
  users: {
    maxLevel: number;
    isLoading: boolean;
    collabs: Array<Collaborator>;
  };
  sortHook: {
    sortModel: GridSortModel;
    setSortModel: Dispatch<SetStateAction<GridSortModel>>;
  };
  filtersHook: {
    conf: ListUtils.FilterConfigurationMap;
    filterValues: ListUtils.FilterConfigurationMap;
    filters: FilterProp<ListUtils.FilterConfigurationMap>;
    setFilterValues: Dispatch<SetStateAction<ListUtils.FilterConfigurationMap>>;
  };
  paginationHook: {
    total: number;
    actualPaginationModel: PaginationProperties;
    paginationModel: ListUtils.PaginationProperties;
    setPaginationModel: Dispatch<
      SetStateAction<{
        page: number;
        pageSize: number;
      }>
    >;
  };
};

const CollaboratorsContext = createContext<CollaboratorsContextType>(null);

const useCollaboratorsContext = () => {
  const context = useContext(CollaboratorsContext);
  if (!context) {
    throw new Error("useCollaboratorsContext must be used within an CollaboratorsContext");
  }
  return context;
};

interface CollaboratorsProviderProps {
  children: React.ReactNode;
}

const CollaboratorsProvider = ({ children }: CollaboratorsProviderProps) => {
  const [conf, filters, filterValues, setFilterValues] = useCollaboratorsGridFilters();
  const [paginationModel, setPaginationModel] = useState<PaginationProperties>({
    page: 0,
    pageSize: 20,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>(undefined);
  const [users, setUsers] = useState<GetUsers>(null);
  const [loading, setLoading] = useState(false);

  const getUsersQuery = async () => {
    setLoading(true);
    const users = await getUsers();
    setUsers(users);
    setLoading(false);
  };

  useEffect(() => {
    getUsersQuery();
  }, []);

  const totalRows = useMemo(() => {
    // TODO: Get total rows from the pagination object that the API will return
    return users?.collaborators.length || 0;
  }, [users?.collaborators]);

  return (
    <CollaboratorsContext.Provider
      value={{
        filtersHook: {
          conf,
          filters,
          filterValues,
          setFilterValues,
        },
        paginationHook: {
          actualPaginationModel: paginationModel,
          paginationModel: paginationModel,
          setPaginationModel,
          total: totalRows,
        },
        sortHook: {
          setSortModel,
          sortModel,
        },
        users: {
          collabs: users?.collaborators,
          isLoading: loading,
          maxLevel: users?.maxLevel,
        },
      }}
    >
      {children}
    </CollaboratorsContext.Provider>
  );
};

export { CollaboratorsContext, CollaboratorsProvider, useCollaboratorsContext };
