import { MdMoreVert as MoreVert } from "react-icons/md";
import { useToasts } from "react-toast-notifications";

import { DSButton, DSDropdown, DSDropdownItem } from "@skillup/ui";
import { ReviewStatus, TrainingUtils } from "@skillup/training-bridge";

import useTranslation from "hooks/useTranslation";

import copyUrl from "../../utils/copyUrl";

import styles from "./MenuActions.module.scss";

export function MenuActions({ status, url }: { status: ReviewStatus; url: string }) {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { canSendReviewRecall } = TrainingUtils;

  if (canSendReviewRecall(status)) {
    return (
      <DSDropdown
        button={<DSButton darkMode icon={<MoreVert />} iconOnly />}
        className={styles.dropdownMenu}
      >
        <DSDropdownItem
          label={t("trainings.manage.reviews.sidepanel.action.copy.url", {
            defaultValue: "Copier l’url du questionnaire d’évaluation",
          })}
          onClick={() => copyUrl(addToast, t, url)}
        />
      </DSDropdown>
    );
  }

  return <></>;
}
