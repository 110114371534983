import type { GetDashboardCampaignRoute } from "./dashboard/campaign";
import type { GetDashboardChildRoutes } from "./dashboard/getChild";
import type { GetDashboardQuestionRepliesRoute } from "./dashboard/getQuestionReplies";
import type { ExportDashboardChildRoutes } from "./dashboard/exportChild";
import type { SendCampaignRemindersRoute } from "./interviews/sendReminders";
import type { RunCampaignCheckRoute } from "./runCheck";
import type { ArchiveCampaignRoute } from "./archiveCampaign";
import type { GetCampaignsRoute } from "./getCampaigns";
import type { GetCampaignRoute } from "./getCampaign";
import type { ModifyCampaignRoute } from "./modifyCampaign";
import type { DeleteCampaignRoute } from "./deleteCampaign";
import type { GetCampaignTemplateRoute } from "./getCampaignTemplate";
import type { GetCampaignsTemplatesRoute } from "./getCampaignsTemplates";
import type { CreateCampaignRoute } from "./createCampaign";
import type { ExportCampaignInterviewsRoute } from "./exportCampaignInterviews";
import type { GetStructureRoute } from "./getStructure";
import type { UpdateStructureRoute } from "./updateStructure";
import type { GetStructuresRoute } from "./getStructures";

export namespace CampaignsRoutes {
  export namespace Dashboard {
    export type GetCampaign = GetDashboardCampaignRoute;
    export type GetChild = GetDashboardChildRoutes;
    export type GetQuestionReplies = GetDashboardQuestionRepliesRoute;
    export type ExportChild = ExportDashboardChildRoutes;
  }

  export namespace Interviews {
    export type SendReminders = SendCampaignRemindersRoute;
  }

  export type Archive = ArchiveCampaignRoute;
  export type GetList = GetCampaignsRoute;
  export type GetByUuid = GetCampaignRoute;
  export type Modify = ModifyCampaignRoute;
  export type Delete = DeleteCampaignRoute;
  export type RunCheck = RunCampaignCheckRoute;
  export type GetTemplate = GetCampaignTemplateRoute;
  export type GetTemplates = GetCampaignsTemplatesRoute;
  export type Create = CreateCampaignRoute;
  export type ExportCampaignInterviews = ExportCampaignInterviewsRoute;
  export type GetStructure = GetStructureRoute;
  export type UpdateStructure = UpdateStructureRoute;
  export type GetStructures = GetStructuresRoute;
}

export * from "./dashboard";
export { InterviewCreationRuleError } from "./runCheck";
