import { CSVLink } from "react-csv";

import { Flex, Text } from "@skillup/design-system";
import { DSAlert, DSAlertType, DSAlertDisplay } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import styles from "./CollaboratorOwnManagerWarningAlert.module.scss";

type CollaboratorOwnManagerWarningAlertProps = {
  emails: string[];
};

export const CollaboratorOwnManagerWarningAlert = ({
  emails,
}: CollaboratorOwnManagerWarningAlertProps) => {
  const { t } = useTranslation();

  const csvData = [
    ["collaborator_email", "manager_email"],
    ...emails.map((email) => [email, email]),
  ];

  return (
    <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
      <Flex flexDirection="column">
        <Flex flexDirection="column">
          <Text color="status-warning-darker">
            {t("peoplereview.campaigncreation.collaborators.warning.collabOwnManager1", {
              defaultValue:
                "Votre fichier contient {{nbrCollaborators}} lignes pour lesquelles le collaborateur et le manager associé sont la même personne.",
              nbrCollaborators: emails.length,
            })}
          </Text>
          <Text espaceFont="body1Bold" color="status-warning-darker">
            {t("peoplereview.campaigncreation.collaborators.warning.collabOwnManager2", {
              defaultValue: "Ces lignes seront ignorées.",
              nbrCollaborators: emails.length,
            })}
          </Text>
          <Text color="status-warning-darker">
            {t("peoplereview.campaigncreation.collaborators.warning.collabOwnManager3", {
              defaultValue:
                "Vous pouvez télécharger un fichier reprenant ces {{nbrCollaborators}} lignes pour les corriger puis les réimporter une fois la campagne créée.",
              nbrCollaborators: emails.length,
            })}
          </Text>
        </Flex>
        <Flex marginTop="s" justifyContent="flex-end">
          <CSVLink
            data={csvData}
            filename="collaborators.csv"
            className={styles.warningCollabOwnManagerDownloadLink}
          >
            {t("peoplereview.upload.template.download.text", {
              defaultValue: "Télécharger le fichier",
            })}
          </CSVLink>
        </Flex>
      </Flex>
    </DSAlert>
  );
};
