import { useState } from "react";
import { useToggle } from "react-use";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { MdClose as Close } from "react-icons/md";
import { MdTipsAndUpdates as TipsAndUpdates } from "react-icons/md";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Flex, Text } from "@skillup/design-system";
import { getInitials } from "@skillup/shared-utils";
import { Popover, DSModal, DSButton, UserPopover, DSUserPreview, DSAvatarGroup } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import type { SuggestionsJob } from "types/skills";

import { useSuggestionsContext } from "./../SuggestionsContext";
import { associateJob, associateMultipleJobs } from "./handler/SuggestionAcceptHandler";
import {
  Divider,
  FullSelect,
  SwitchButton,
  ModalContainer,
  PopoverContent,
} from "./SuggestionCard.styled";

interface ModalProps {
  role: string;
  isOpen: boolean;
  closeModal: () => void;
  employees: SuggestionsJob["employees"];
  jobList: Array<{ label: string; value: string }>;
}

const ApproveSuggestionModal = ({ closeModal, employees, isOpen, jobList, role }: ModalProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const suggestionsJobs = useSuggestionsContext();

  const [loading, toggleLoading] = useToggle(false);
  const [opened, toggleOpen] = useToggle(false);
  const [closePopover, setClosePopover] = useState<() => void>();

  const { refetch: refetchPending } = suggestionsJobs.getJobSuggestions("pending");
  const { refetch: refetchCancelled } = suggestionsJobs.getJobSuggestions("cancelled");

  const employeeSuggestions = employees[0].employeeSuggestions.map((suggestion) => ({
    label: suggestion.jobLabel,
    value: suggestion.jobId,
  }));

  const suggestionJobIds = employeeSuggestions.map((s) => s.value);

  const concatenedJobList = [
    {
      label: "",
      title: (
        <Flex gap="s" alignItems="center">
          <TipsAndUpdates size="1rem" />
          <Text espaceFont="body2Bold" color="plainText-onLight-default">
            {t("skills.suggestions.modal.list.suggestion", {
              defaultValue: "Suggestions de fiches de poste",
            })}
          </Text>
        </Flex>
      ),
      value: "",
    },
    ...employeeSuggestions,
    {
      label: "",
      title: (
        <Text espaceFont="body2Bold" color="plainText-onLight-default">
          {t("skills.suggestions.modal.list.job", {
            defaultValue: "Toutes les fiches de poste",
          })}
        </Text>
      ),
      value: "",
    },
    ...jobList
      .filter((job) => !suggestionJobIds.includes(job.value))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];

  const [selectedJobId, setSelectedJobId] = useState<string>(concatenedJobList[1].value);
  const [selectedJobIdPerEmployee, setSelectedJobIdPerEmployee] = useState<{
    [employeeId: string]: string;
  }>(
    employees.reduce(
      (acc, employee) => ({ ...acc, [employee.employeeID]: concatenedJobList[1].value }),
      {}
    )
  );

  const setSelectedJobIdForEmployee = (employeeId: string) => {
    return (value: string) =>
      setSelectedJobIdPerEmployee((prevState) => ({
        ...prevState,
        [employeeId]: value,
      }));
  };

  const handleSubmit = async () => {
    toggleLoading(true);

    const payload = opened
      ? associateMultipleJobs(employees, selectedJobIdPerEmployee)
      : associateJob(employees, selectedJobId);

    if (!payload.accepted.length && !payload.manualAssignments.length) {
      closeModal();
      return;
    }

    try {
      await suggestionsJobs.acceptSuggestions(payload);

      refetchPending();
      refetchCancelled();

      addToast(
        t("skills.suggestions.accept.success", {
          defaultValue: "Suggestion appliquée",
        }),
        { appearance: "success" }
      );
    } catch (e) {
      addToast(
        t("skills.suggestions.accept.error", {
          defaultValue: "Une erreur est survenue",
        }),
        { appearance: "error" }
      );
    } finally {
      closeModal();
    }
  };

  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("skills.suggestions.modal.title", {
            defaultValue: "Traiter la suggestion",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <ModalContainer>
          <Text espaceFont="body1Bold" color="plainText-onLight-default">
            {employees.length > 1
              ? t("skills.suggestions.employees", {
                  count: employees.length,
                  defaultValue:
                    "Associer une fiche de poste aux {{count}} collaborateurs suivants:",
                })
              : t("skills.suggestions.employee", {
                  defaultValue: "Associer une fiche de poste au collaborateur suivant:",
                })}
          </Text>
          <Flex marginVertical="s">
            <DSAvatarGroup
              size="S"
              maxItems={5}
              firstElementForeground
              totalItems={employees.length}
              users={employees.map((employee) => ({
                uuid: employee.employeeID,
                fullName: employee.fullName,
                initials: getInitials(employee),
              }))}
            />
            {employees.length > 5 && (
              <Popover
                actions={({ close }) => {
                  setClosePopover(() => close);
                }}
                children={
                  <DSButton
                    buttonSize="S"
                    emphasis="Low"
                    label={t("skills.suggestions.seeList", {
                      defaultValue: "Voir la liste",
                    })}
                  />
                }
                content={() => (
                  <PopoverContent>
                    <Flex marginBottom="xs" justifyContent="space-between">
                      <Text espaceFont="captionBold" color="plainText-onLight-default">
                        {t("skills.suggestions.associatedEmployees", {
                          count: employees.length,
                          defaultValue: "{{count}} collaborateurs associés",
                        })}
                      </Text>
                      <DSButton iconOnly buttonSize="S" icon={<Close />} onClick={closePopover} />
                    </Flex>
                    <Flex gap="xs" overflow="auto" flexDirection="column">
                      {employees.map((employee) => {
                        const { employeeID, fullName } = employee;
                        return (
                          <UserPopover
                            position="down-right"
                            user={{
                              uuid: employeeID,
                              fullName,
                              initials: getInitials({ fullName }),
                            }}
                            profileBtn={{
                              label: t("skills.dashboard.sidePanel.seeProfile", {
                                defaultValue: "Voir le profil",
                              }),
                              onClick: () => {
                                history.push(
                                  `/responsable/collaborateurs/${employeeID}/competences`
                                );
                              },
                            }}
                          >
                            <DSUserPreview
                              size="S"
                              fullName={fullName}
                              randomColorSeedString={employeeID}
                              initials={getInitials({ fullName })}
                            />
                          </UserPopover>
                        );
                      })}
                    </Flex>
                  </PopoverContent>
                )}
              />
            )}
          </Flex>
          <Flex marginBottom="s" alignItems="center">
            <Flex flex={1} flexDirection="column">
              <Text espaceFont="captionRegular">
                {t("skills.suggestions.role", {
                  defaultValue: "Rôle",
                })}
              </Text>
              <Text espaceFont="body1Bold" color="plainText-onLight-default">
                {role}
              </Text>
            </Flex>
            <Flex marginTop="s" marginHorizontal="s">
              <ArrowForwardIcon />
            </Flex>
            <Flex flex={1} flexDirection="column">
              <Text espaceFont="captionRegular">
                {t("skills.suggestions.job", {
                  defaultValue: "Fiche de poste",
                })}
              </Text>
              <Flex width="100%">
                <FullSelect
                  disabled={opened}
                  options={concatenedJobList}
                  defaultValue={concatenedJobList[1]}
                  onChange={setSelectedJobId}
                />
              </Flex>
            </Flex>
          </Flex>
          <Divider />
          {employees.length > 1 && (
            <SwitchButton
              active={opened}
              onToggle={() => toggleOpen(!opened)}
              label={t("skills.suggestions.modal.associateCollab", {
                defaultValue: "Associer les fiches de poste collaborateur par collaborateur",
              })}
            />
          )}
          {opened && (
            <Flex
              marginBottom="s"
              alignItems="center"
              marginHorizontal="m"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Flex width="100%" justifyContent="space-between">
                <Flex width="50%">
                  <Text espaceFont="captionRegular">
                    {t("skills.suggestions.collab", {
                      defaultValue: "Collaborateur",
                    })}
                  </Text>
                </Flex>
                <Flex width="50%">
                  <Text espaceFont="captionRegular">
                    {t("skills.suggestions.job", {
                      defaultValue: "Fiche de poste",
                    })}
                  </Text>
                </Flex>
              </Flex>
              {employees.map((employee) => (
                <Flex
                  width="100%"
                  marginBottom="xxs"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex width="50%" flexDirection="column">
                    <Text espaceFont="body1Regular" color="plainText-onLight-default">
                      {employee.fullName}
                    </Text>
                  </Flex>
                  <Flex width="50%" flexDirection="column" align-items="flex-end">
                    <Flex width="100%">
                      <FullSelect
                        clearable
                        options={concatenedJobList}
                        defaultValue={concatenedJobList[1]}
                        onChange={setSelectedJobIdForEmployee(employee.employeeID)}
                        placeholder={t("skills.suggestions.modal.select.not-assigned", {
                          defaultValue: "Aucune fiche de poste associée",
                        })}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          )}
        </ModalContainer>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("skills.suggestions.modal.cancel", {
            defaultValue: "Retour",
          })}
        />
        <DSModal.Footer.PrimaryButton
          loading={loading}
          onClick={handleSubmit}
          label={t("skills.suggestions.modal.associate", {
            defaultValue: "Associer ces fiches de poste",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default ApproveSuggestionModal;
