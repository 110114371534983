import React from "react";

import { MdInfoOutline as InfoOutline } from "react-icons/md";
import { getIn, Field, ErrorMessage, type FieldProps } from "formik";

import { DSTextArea } from "@skillup/ui";

import { Flex } from "../Flex";
import { Text } from "../Text";

interface Props {
  readonly name: string;
  readonly value?: string;
  readonly placeholder?: string;
  readonly toolbarButtons?: string[];
}

export const FormikInputTextArea = ({ name, placeholder, toolbarButtons, value }: Props) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <DSTextArea
            value={value}
            id={field.name}
            fitParentHeight
            debounceValue={1}
            placeholder={placeholder}
            toolbarButtons={toolbarButtons}
            error={getIn(form.errors, field.name) && getIn(form.touched, field.name)}
            onChange={(value) => form.setFieldValue(field.name, value)}
          />
        )}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg) => (
          <Text fontSize="0.75rem" espaceFont="body1Regular" color="status-error-darker">
            <Flex gap="xs" alignItems="center">
              <InfoOutline />
              {msg}
            </Flex>
          </Text>
        )}
      />
    </>
  );
};
