import { ReactNode, RefObject, useState } from "react";
import { HighchartsReactRefObject } from "highcharts-react-official";
import { MdMoreVert as MoreVert } from "react-icons/md";

import { DSButton, DSDropdown, DSDropdownItem, DSDropdownDivider } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { handleCopy } from "../utils/menu";
import { DashboardCardData, QuestionData } from "../types";

import { ExportModal } from "./ExportModal";
import { AllDataModal } from "./AllDataModal";

export type CardMenuProps = {
  data: QuestionData;
  card: DashboardCardData;
  graphRef: RefObject<HighchartsReactRefObject>;
};

export const CardMenu = ({ data, card, graphRef }: CardMenuProps) => {
  const { t } = useTranslation();
  const [displayAllData, setDisplayAllData] = useState(false);
  const [displayExport, setDisplayExport] = useState(false);

  const initialCopyText = t("training.actions.dashboard.card.copy", {
    defaultValue: "Copier dans le presse-papier",
  });
  const copiedMessage = t("training.actions.dashboard.card.copied", {
    defaultValue: "Copié dans le presse-papier",
  });

  const [copyText, setCopyText] = useState<ReactNode>(initialCopyText);

  const canDisplayAll = data?.data.length > 8;

  return (
    <>
      <AllDataModal
        open={displayAllData}
        card={card}
        data={data}
        onClose={() => setDisplayAllData(false)}
      />
      {displayExport && (
        <ExportModal
          open={displayExport}
          card={card}
          graphRef={graphRef}
          onClose={() => setDisplayExport(false)}
          canExportAllData={canDisplayAll}
          data={data}
        />
      )}

      <DSDropdown button={<DSButton iconOnly icon={<MoreVert />} />}>
        {canDisplayAll && (
          <>
            <DSDropdownItem
              label={t("training.dashboard.card.actions.display-all", {
                defaultValue: "Afficher l'intégralité de la données",
              })}
              onClick={() => setDisplayAllData(true)}
            />
            <DSDropdownDivider />
          </>
        )}
        <DSDropdownItem
          label={copyText}
          onClick={() => handleCopy(graphRef, setCopyText, copiedMessage, initialCopyText)}
        />
        <DSDropdownDivider />
        <DSDropdownItem
          label={t("training.dashboard.card.actions.export", {
            defaultValue: "Télécharger le graphique...",
          })}
          onClick={() => setDisplayExport(true)}
        />
      </DSDropdown>
    </>
  );
};
