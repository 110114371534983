import { ReviewRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

type Params = ReviewRoutes.RestoreManagerPreparation["params"];

export async function restoreManagerPreparation({ campaignID, reviewID }: Params) {
  const response = await buildRequest<ReviewRoutes.RestoreManagerPreparation>({
    method: "PUT",
    params: { campaignID, reviewID },
    path: "/campaign/{campaignID}/review/{reviewID}/restore-manager-preparation",
    target: "PEOPLE_REVIEW",
  })();

  return response;
}
