import { MdSupervisorAccount as SupervisorAccount } from "react-icons/md";
import { MdOutlineFileDownload as FileDownload } from "react-icons/md";
import { MdMoreVert as MoreVert } from "react-icons/md";
import { useCallback, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import DataLayer from "utils/DataLayer";
import {
  DSButton,
  Label,
  DSDropdown,
  DSDropdownItem,
  HorizontalDivider,
  DSTooltip,
} from "@skillup/ui";
import { Either } from "@skillup/monads";
import { TrackingRoutes } from "@skillup/espace-rh-api/src/routes/tracking";

import downloadFileAsUser from "utils/downloadFileAsUser";
import { buildSafeRequest } from "utils/buildRequest";
import { TrackingDocument } from "../../../types";

import DocumentDeleteConfirmModal from "./DocumentDeleteConfirmModal/DocumentDeleteConfirmModal";

import styles from "./DocumentListItem.module.scss";

type DocumentActionProps = {
  document: TrackingDocument;
  trackingUuid: string;
  onDeleteFinish: () => void;
  refetchTrackings: () => void;
};

export enum TrackingDocumentType {
  "training_certificate" = "Attestation de formation",
  "license" = "Permis",
  "medical_certificate" = "Certificat médical",
  "summons" = "Convocation",
  "driving_authorisation" = "Autorisation de conduite",
  "other" = "Divers",
}

type UpdateDocumentParams = {
  isShared: boolean;
};

const updateDocument = async (
  trackingUuid: string,
  documentUuid: string,
  { isShared }: UpdateDocumentParams
): Promise<
  Either<TrackingRoutes.UpdateDocument["errors"], TrackingRoutes.UpdateDocument["response"]>
> => {
  const request = await buildSafeRequest<TrackingRoutes.UpdateDocument>({
    method: "POST",
    path: "/tracking/{trackingUuid}/document/{documentUuid}",
    params: {
      trackingUuid,
      documentUuid,
    },
    payload: {
      isShared,
    },
  });

  return request.run();
};

const DocumentListItem = ({
  document,
  trackingUuid,
  onDeleteFinish,
  refetchTrackings,
}: DocumentActionProps) => {
  const { isShared } = document;
  const [downloading, toggleDownloading] = useState(false);
  const [deleting, toggleDeleting] = useState(false);
  const [showDeleteModal, toggleShowDeleteModal] = useState(false);
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const handleDownload = async (document: TrackingDocument) => {
    toggleDownloading(true);
    const url = `/v1/tracking/${trackingUuid}/document/${document.uuid}`;
    try {
      await downloadFileAsUser(url, document.fileName, "API");
    } catch (e) {
      addToast(
        t("app.errors.message", {
          defaultValue: "Une erreur est survenue lors du téléchargement de votre fichier.",
        }),
        { appearance: "error" }
      );
    } finally {
      toggleDownloading(false);
    }
  };

  const handleDelete = async (document: TrackingDocument) => {
    toggleDeleting(true);
    const url = `/v1/tracking/${trackingUuid}/document/${document.uuid}`;
    try {
      await DataLayer.request({
        method: "DELETE",
        url,
      });
      addToast(
        t("tracking.document.deletion.success", {
          defaultValue: "Document supprimé avec succès",
        }),
        {
          appearance: "success",
        }
      );
    } catch (e) {
      addToast(
        t("app.errors.message", {
          defaultValue: "Une erreur est survenue lors de la suppression de votre fichier.",
        }),
        { appearance: "error" }
      );
    } finally {
      toggleShowDeleteModal(false);
      toggleDeleting(false);
      refetchTrackings();
    }
  };

  const setShareStatus = useCallback(
    async (documentUuid: string, newSharedStatus: boolean) => {
      try {
        await updateDocument(trackingUuid, documentUuid, { isShared: newSharedStatus });
        refetchTrackings();
        addToast(
          t("tracking.document.share.success", {
            defaultValue: "Le paramètre de partage du document a bien été modifié",
          }),
          {
            appearance: "success",
          }
        );
      } catch (e) {
        addToast(
          t("app.errors.message", {
            defaultValue: "Erreur lors de la modification de l’option de partage du document",
          }),
          { appearance: "error" }
        );
      }
    },
    [addToast, t, trackingUuid, refetchTrackings]
  );

  const actions = useMemo(() => {
    const notSharedActions = [
      {
        label: "Partager le document avec le \ncollaborateur et son manager",
        tooltip:
          "Le collaborateur et son manager retrouveront ce document dans leur espace personnel",
        handler: () => setShareStatus(document.uuid, true),
        disabled: false,
      },
    ];
    const sharedActions = [
      {
        label: "Ne plus partager le document \navec le collaborateur et son manager",
        tooltip:
          "Le collaborateur et son manager ne verront plus ce document dans leur espace personnel",
        handler: () => setShareStatus(document.uuid, false),
        disabled: false,
      },
    ];
    return isShared ? sharedActions : notSharedActions;
  }, [document.uuid, isShared, setShareStatus]);

  return (
    <div className={styles.fileList}>
      <div>
        <Label label={TrackingDocumentType[document.type]} className={styles.documentType} />
        <p className={styles.documentName}>
          {document.fileName}{" "}
          {isShared && (
            <DSTooltip label="Document partagé avec le collaborateur et son manager">
              <SupervisorAccount />
            </DSTooltip>
          )}
        </p>
      </div>
      <div className={styles.fileListBtnSection}>
        <DSButton
          label="download"
          className={styles.fileBtn}
          emphasis="Low"
          icon={<FileDownload />}
          iconOnly
          onClick={() => handleDownload(document)}
          loading={downloading}
        />

        <DSDropdown
          className={styles.fileDropdown}
          button={<DSButton label="dropdown" icon={<MoreVert />} iconOnly />}
        >
          {actions.map((action) => (
            <DSDropdownItem
              tooltipLabel={action.tooltip}
              label={action.label}
              disabled={action.disabled}
              onClick={action.handler}
            />
          ))}
          <HorizontalDivider top={"s"} bottom={"s"} />
          <DSDropdownItem
            label="Supprimer le document"
            onClick={() => toggleShowDeleteModal(true)}
          />
        </DSDropdown>
      </div>

      {showDeleteModal && (
        <DocumentDeleteConfirmModal
          documentName={document.fileName}
          deleting={deleting}
          onClose={() => toggleShowDeleteModal(false)}
          onConfirm={() => handleDelete(document)}
        />
      )}
    </div>
  );
};

export default DocumentListItem;
