import { Currency, formatMonetary, getMonetarySymbol } from "@skillup/shared-utils";
import moment from "moment";

export const formatValue = (value: any, dataType: "money" | "number" | "duration"): number => {
  switch (dataType) {
    case "number":
    case "money":
      return Number(value);
    case "duration":
      return Math.round(moment.duration(value).asHours());
  }
};

export const formatValueWithUnit = (
  value: any,
  dataType: "money" | "number" | "duration",
  currency: Currency,
  language: string
): string => {
  switch (dataType) {
    case "money":
      return formatMonetary(value, {
        currency,
      });
    case "number":
      return Intl.NumberFormat(language, {}).format(value);
    case "duration":
      return value + "h";
  }
};

const BILLION = 1_000_000_000;
const MILLION = 1_000_000;

export const formatValueForKeyIndicator = (
  value: any,
  dataType: "money" | "number" | "duration",
  language: string,
  translation: {
    billion: string;
    million: string;
  }
) => {
  const formatter = new Intl.NumberFormat(language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });
  const formatedValue = formatValue(value, dataType);

  if (formatedValue >= BILLION) {
    return `${formatter.format(formatedValue / BILLION)} ${translation.billion}`;
  }

  if (formatedValue >= MILLION) {
    return `${formatter.format(formatedValue / MILLION)} ${translation.million}`;
  }

  return formatter.format(formatedValue);
};

export const getUnitFromDataType = (
  dataType: "money" | "number" | "duration",
  currency: Currency
) => {
  switch (dataType) {
    case "money":
      return getMonetarySymbol({ currency });
    case "duration":
      return "h";
    case "number":
      return "";
  }
};
