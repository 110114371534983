import { GridColDef } from "@mui/x-data-grid-pro";

import type { Review, Campaign } from "../../../types";

export function getCampaignFieldsColDefs(campaign: Campaign): GridColDef<Review>[] {
  return campaign.scales.map((scale) => {
    return {
      type: "singleSelect",
      editable: campaign.permissions.review.isEnabled,
      field: scale.id,
      flex: 1,
      getOptionLabel: (option: (typeof scale.options)[number]) => option?.label ?? "",
      getOptionValue: (option: (typeof scale.options)[number]) => option?.value ?? "",
      headerName: scale.label,
      minWidth: 150,
      valueGetter: (params) => params.row.fields[scale.id],
      valueOptions: scale.options.map(({ label, value }) => ({ label, value: value.toString() })),
      valueSetter: (params) => {
        return {
          ...params.row,
          fields: {
            ...params.row.fields,
            [scale.id]: params.value,
          },
        };
      },
    };
  });
}
