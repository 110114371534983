import { MdEdit as Edit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useMemo, useEffect, useState } from "react";
import {
  DSButton,
  DSTextInput,
  DSFormGroupTextInput,
  AssistiveArea,
  DSSimpleTextArea,
} from "@skillup/ui";
import { TrainingFields } from "@skillup/training-bridge";

import {
  CategorySelect,
  DurationInput,
  ModeSelect,
  PriceInput,
  PropertyInput,
  PropertyWYSIWYG,
  TrainingOrganizationSelect,
} from "./FormElements";
import { TranslationType } from "hooks/useTranslation";
import User from "utils/User";
import { useTags } from "../utils/useTags";

import {
  ErrorCode,
  MAX_REFERENCE_LENGTH,
  MIN_REFERENCE_LENGTH,
  ReferenceValidityError,
  useIntras,
} from "services/intras/useIntras";

import CustomFieldsRenderer from "components/CustomFieldsRenderer";

import { IForm } from "../../User/components/UserForm";
import { parseReferenceFromName } from "../utils/parseReferenceFromName";
import { TrainingForm } from "../EditionView/utils";

import styles from "./IntraForm.module.scss";

export function CustomTextArea({
  label,
  name,
  onChange,
  value,
}: {
  label: string;
  name: string;
  value: string;
  onChange: (newValue: string) => void;
}) {
  return (
    <div className={styles.input}>
      <DSFormGroupTextInput label={label}>
        <DSSimpleTextArea
          placeholder="Écrivez le contenu désiré"
          id={name}
          value={value}
          onChange={onChange}
        />
      </DSFormGroupTextInput>
    </div>
  );
}

type IntraFormProps = {
  t: TranslationType;
  trainingForm: TrainingForm;
  fields: TrainingFields;
  setTrainingForm: (intraData: TrainingForm) => void;
  fieldsForm: IForm;
  setFieldsForm: (intraFields: TrainingFields) => void;
  creation?: boolean;
};

export function IntraFormV2({
  t,
  fields,
  trainingForm,
  setTrainingForm,
  fieldsForm,
  setFieldsForm,
  creation = false,
}: IntraFormProps) {
  const { tags } = useTags();
  const { trainingOrganizations } = useIntras();
  const { intraUuid } = useParams<{ intraUuid: string }>();

  const canShowTrailer = User.isSkillupAdmin() || User.isDemoUser();

  useEffect(() => {
    if (creation && trainingForm.name) {
      setTrainingForm({ ...trainingForm, reference: parseReferenceFromName(trainingForm.name) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingForm.name]);

  return (
    <form>
      <PropertyInput
        label={t("trainings.intra.edition.form.title", {
          defaultValue: "Titre",
        })}
        name="name"
        placeholder={t("trainings.intra.edition.form.titlePlaceholder", {
          defaultValue: "Ex : Anglais avancé",
        })}
        value={trainingForm.name}
        onChange={(value) => setTrainingForm({ ...trainingForm, name: value })}
        required
      />
      <TrainingOrganizationSelect
        trainingOrganizations={trainingOrganizations}
        onChange={(value) => setTrainingForm({ ...trainingForm, trainingOrganization: value })}
        selectedTrainingOrganization={trainingForm.trainingOrganization}
      />
      <ModeSelect
        selectedMode={trainingForm.mode}
        onChange={(value) => setTrainingForm({ ...trainingForm, mode: value as any })}
      />
      <CategorySelect
        tags={tags}
        selectedTag={trainingForm.tag?.value}
        onChange={(tag) => setTrainingForm({ ...trainingForm, tag })}
      />
      <div className={styles.doubleInput}>
        <DurationInput
          label={t("trainings.intra.edition.form.duration", {
            defaultValue: "Durée (en heures)",
          })}
          name="duration"
          placeholder={t("trainings.intra.edition.form.durationPlaceholder", {
            defaultValue: "Ex : 7",
          })}
          onChange={(value) => setTrainingForm({ ...trainingForm, duration: value })}
          rawValue={trainingForm.duration}
          disablePadding
          value={trainingForm.duration != null ? trainingForm.duration.toString() : undefined}
        />

        <PriceInput
          label={t("trainings.intra.edition.form.previsionalPrice", {
            defaultValue: "Prix prévisionnel par participant HT",
          })}
          name="price"
          placeholder={t("trainings.intra.edition.form.pricePlaceholder", {
            defaultValue: "Ex : 200",
          })}
          onChange={(value) => setTrainingForm({ ...trainingForm, price: value })}
          rawValue={trainingForm.price}
          disablePadding
          value={trainingForm.price != null ? trainingForm.price.toString() : undefined}
        />

        {User.isCompanyWithTax() && (
          <PriceInput
            label={t("trainings.intra.edition.form.previsionalPriceTTC", {
              defaultValue: "Prix prévisionnel par participant TTC",
            })}
            name="priceWithTax"
            placeholder={t("trainings.intra.edition.form.pricePlaceholder", {
              defaultValue: "Ex : 200",
            })}
            onChange={(value) => setTrainingForm({ ...trainingForm, priceWithTax: value })}
            rawValue={trainingForm.priceWithTax}
            disablePadding
            value={
              trainingForm.priceWithTax != null ? trainingForm.priceWithTax.toString() : undefined
            }
          />
        )}
      </div>
      <ReferenceBlock
        t={t}
        intraUuid={intraUuid}
        reference={trainingForm.reference}
        setProperty={(value) => setTrainingForm({ ...trainingForm, reference: value })}
      />
      {fieldsForm && (
        <CustomFieldsRenderer
          fieldsToDisplay={fields}
          form={fieldsForm}
          setForm={setFieldsForm}
          translationPrefix="supervisor.intra.form"
        />
      )}
      {canShowTrailer ? (
        <PropertyInput
          name="trailer"
          label={t("trainings.intra.edition.form.label", {
            defaultValue: "Vidéo de présentation",
          })}
          placeholder={t("trainings.intra.edition.form.labelPlaceholder", {
            defaultValue: "Ex : https://www.youtube.com/embed/dQw4w9wgXcQ",
          })}
          value={trainingForm.trailer ?? ""}
          onChange={(value) => setTrainingForm({ ...trainingForm, trailer: value })}
        />
      ) : null}
      <PropertyWYSIWYG
        label={t("trainings.intra.edition.form.description", {
          defaultValue: "Description",
        })}
        name="description"
        value={trainingForm.description ?? ""}
        onChange={(value) => setTrainingForm({ ...trainingForm, description: value })}
      />
      <PropertyWYSIWYG
        label={t("trainings.intra.edition.form.target", {
          defaultValue: "Public cible",
        })}
        name="targetAudience"
        value={trainingForm.targetAudience ?? ""}
        onChange={(value) => setTrainingForm({ ...trainingForm, targetAudience: value })}
      />
      <PropertyWYSIWYG
        label={t("trainings.intra.edition.form.objectives", {
          defaultValue: "Objectifs",
        })}
        name="objectives"
        value={trainingForm.objectives ?? ""}
        onChange={(value) => setTrainingForm({ ...trainingForm, objectives: value })}
      />
      <PropertyWYSIWYG
        label={t("trainings.intra.edition.form.program", {
          defaultValue: "Programme",
        })}
        name="program"
        value={trainingForm.program ?? ""}
        onChange={(value) => setTrainingForm({ ...trainingForm, program: value })}
      />
      <PropertyWYSIWYG
        label={t("trainings.intra.edition.form.prerequisites", {
          defaultValue: "Prérequis",
        })}
        name="prerequisites"
        value={trainingForm.prerequisites ?? ""}
        onChange={(value) => setTrainingForm({ ...trainingForm, prerequisites: value })}
      />
    </form>
  );
}

type ReferenceInputState =
  | {
      step: "editing";
      value: string;
    }
  | { step: "displaying" };

function ReferenceBlock({
  t,
  reference,
  intraUuid,
  setProperty,
}: {
  t: TranslationType;
  reference?: string;
  intraUuid?: string;
  setProperty: (value: string) => void;
}) {
  const [state, setState] = useState<ReferenceInputState>({
    step: "displaying",
  });

  const { validateReference } = useIntras();
  const referenceInvalidError = useMemo(() => {
    const { step } = state;
    if (step === "editing") {
      const { value } = state;
      const error = validateReference(value, intraUuid);
      if (error.isLeft()) return error.left();
      return undefined;
    }

    return { error: ErrorCode.UNKNOWN };
  }, [validateReference, state, intraUuid]);

  const referenceIsEmpty = reference === "" || reference == null;
  const userHasNotFilledProgramYet = state.step === "displaying" && referenceIsEmpty;

  if (userHasNotFilledProgramYet) {
    return (
      <div className={styles.reference}>
        <p>_</p>
        <AssistiveArea
          text={t("trainings.intra.edition.form.assistiveProgramId", {
            defaultValue: "Cet identifiant unique est généré à la création du programme",
          })}
        />
      </div>
    );
  }

  if (state.step === "displaying") {
    return (
      <div className={styles.reference}>
        <div className={styles.referenceDisplay}>
          <div>{reference}</div>
          <DSButton
            iconOnly
            icon={<Edit />}
            onClick={() =>
              setState({
                step: "editing",
                value: reference || "",
              })
            }
          />
        </div>
        <AssistiveArea
          text={t("trainings.intra.edition.form.assistiveProgramId", {
            defaultValue: "Cet identifiant unique est généré à la création du programme",
          })}
        />
      </div>
    );
  }

  return (
    <div className={styles.reference}>
      <DSFormGroupTextInput label="ID" required>
        <>
          <div className={styles.referenceEdit}>
            <DSTextInput
              name="reference"
              value={state.value}
              error={!!referenceInvalidError}
              onChange={(newValue) => {
                setState({ step: "editing", value: newValue });
              }}
              actionButton={
                <DSButton
                  label={t("trainings.intra.edition.form.validate", {
                    defaultValue: "Valider",
                  })}
                  buttonSize="S"
                  disabled={!!referenceInvalidError}
                  tooltip={getErrorTooltipLabel(t, referenceInvalidError)}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setProperty(state.value);
                    setState({ step: "displaying" });
                  }}
                />
              }
            />
          </div>
          {getErrorTooltipLabel(t, referenceInvalidError) === undefined ? (
            <AssistiveArea
              text={t("trainings.intra.edition.form.assistiveProgramId", {
                defaultValue: "Cet identifiant unique est généré à la création du programme",
              })}
            />
          ) : (
            <AssistiveArea text={getErrorTooltipLabel(t, referenceInvalidError)} mode="error" />
          )}
        </>
      </DSFormGroupTextInput>
    </div>
  );
}

function getErrorTooltipLabel(t: TranslationType, error: ReferenceValidityError) {
  switch (error?.error) {
    case ErrorCode.ALREADY_USED:
      return t("trainings.intra.edition.form.error.programId.alreadyExists", {
        defaultValue: "L'ID de programme est déjà utilisé par le programme {{ name }}",
        name: error.data?.programName,
      });
    case ErrorCode.UNKNOWN:
      return t("trainings.intra.edition.form.error.programId.invalid", {
        defaultValue: "Cet ID n'est pas valide",
      });
    case ErrorCode.TOO_LONG:
      return t("trainings.intra.edition.form.error.programId.max", {
        defaultValue: "L'ID de programme est limité à {{ max }} caractères",
        max: MAX_REFERENCE_LENGTH,
      });
    case ErrorCode.TOO_SHORT:
      return t("trainings.intra.edition.form.error.programId.min", {
        defaultValue: "L'ID de programme doit contenir au moins {{ min }} caractères",
        min: MIN_REFERENCE_LENGTH,
      });
    case ErrorCode.EMPTY:
      return t("trainings.intra.edition.form.error.programId.empty", {
        defaultValue: "Veuillez renseigner un ID de programme",
      });
    default:
      return undefined;
  }
}
