import { MdCheck as Check } from "react-icons/md";

import { Label, DSColor } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

import { ColorDot } from "./ColorDot.styled";

const colors = [
  DSColor["greyscale-dark"],
  DSColor["decoration-blue-base"],
  DSColor["decoration-yellow-base"],
  DSColor["decoration-red-base"],
  DSColor["decoration-green-base"],
  DSColor["decoration-pink-base"],
  DSColor["decoration-orange-base"],
];

type ColorPickerProps = {
  selected: string;
  onSelect: (color: string) => void;
};

function ColorPicker({ onSelect, selected }: ColorPickerProps) {
  return (
    <Flex paddingTop="s" flexDirection="column">
      <Label required aria-required label="Couleur d'affichage" />
      <Flex gap="xs" marginTop="s">
        {colors.map((color) => (
          <ColorDot key={color} color={color} selected={selected} onClick={() => onSelect(color)}>
            {selected === color && <Check color="white" />}
          </ColorDot>
        ))}
      </Flex>
    </Flex>
  );
}

export default ColorPicker;
