import { MdOpenInNew as OpenInNew } from "react-icons/md";
import { Link } from "react-router-dom";

import { Flex } from "@skillup/ui";
import { useBaseUrl } from "@skillup/hooks";

import { plural } from "utils/locale";
import styles from "./TemplateRelatedCampaigns.module.scss";

type RelatedCampaign = {
  uuid: string;
  title: string;
};
export const TemplateRelatedCampaigns = ({
  campaigns,
  hasOtherCampaigns = false,
}: {
  campaigns: RelatedCampaign[];
  hasOtherCampaigns?: boolean;
}) => {
  const { baseUrl } = useBaseUrl();
  if (campaigns?.length > 0) {
    return (
      <>
        <Flex column className={styles.TemplateRelatedCampaigns}>
          <p>
            {plural(
              campaigns.length,
              "Cette trame est utilisée pour le lancement de %n campagne%s :"
            )}
          </p>
          <Flex column className={styles.list}>
            {campaigns.map((campaign) => (
              <div key={campaign.uuid} className={styles.list__item}>
                <Link to={`${baseUrl}/campagne/${campaign.uuid}`} target="_blank">
                  {campaign.title}
                </Link>
                <OpenInNew className={styles.icon} />
              </div>
            ))}
          </Flex>
          {hasOtherCampaigns && <p>Et d’autres campagnes hors de votre périmètre</p>}
        </Flex>
      </>
    );
  } else {
    if (hasOtherCampaigns) {
      return <p>Cette trame est utilisée par des campagnes en dehors de votre périmètre.</p>;
    }
    return <></>;
  }
};
