import { useEffect, useMemo, useRef } from "react";

import { Loader, useMediaQueries } from "@skillup/ui";

import DSLayout from "components/DSLayout";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";
import useTranslation from "hooks/useTranslation";

import { useDashboardFreshness } from "../adapters/insights";
import { TrainingDashboardFreshnessContext } from "../context";

import styles from "./Dashboard.module.scss";

import { TrainingDashboardContainer } from "./DashboardContainer";
import { UnavailableDashboard } from "../components/UnavailableDashboard";

export const TrainingDashboard = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQueries();
  const container = useRef(null);

  const { data } = useDashboardFreshness();

  const context = useMemo(() => {
    if (!data) {
      return {
        isReady: false,
        needsRefresh: false,
        isRefreshing: false,
        lastRefreshTime: null,
        freshnessTime: null,
      };
    }

    return {
      isReady: true,
      needsRefresh: data.last_successful_run_datetime === null && !data?.refresh_running,
      isRefreshing: data.refresh_running ?? false,
      lastRefreshTime: data.last_run_trigger_datetime,
      freshnessTime: data.last_successful_run_datetime,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.last_run_trigger_datetime, data?.last_successful_run_datetime, data?.refresh_running]);

  useEffect(() => {
    if (context.isRefreshing) {
      container.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [context.isRefreshing]);

  if (isMobile) {
    return (
      <DSLayout title="" isHiddenOnMobile showMenu className={styles.mobileView}>
        <NotAvailableInMobileView />
      </DSLayout>
    );
  }

  if (!context.isReady) {
    return (
      <DSLayout
        layouts={[]}
        title={t("trainings.view.dashboard.title", {
          defaultValue: "Dashboard - Formation",
        })}
      >
        <Loader />
      </DSLayout>
    );
  }

  if (context.needsRefresh || context.isRefreshing) {
    return (
      <DSLayout
        layouts={[]}
        title={t("trainings.view.dashboard.title", {
          defaultValue: "Dashboard - Formation",
        })}
      >
        <UnavailableDashboard isRefreshing={context.isRefreshing} />
      </DSLayout>
    );
  }

  return (
    <TrainingDashboardFreshnessContext.Provider value={context}>
      <TrainingDashboardContainer />
    </TrainingDashboardFreshnessContext.Provider>
  );
};
