import { MdSettings as Settings } from "react-icons/md";
import { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { orderBy } from "lodash";

import { DSButton, ToggleButtonGroup } from "@skillup/ui";

import { useFetch, useTypedFetch } from "hooks";
import useSettings from "hooks/useSettings";
import type { FieldRoutesType } from "types/api";
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

import FieldsSettings from "../Navigation/FieldsSettings";
import tabs from "../scheduleMenuTabs";
import styles from "./NewNavigation.module.scss";
interface IProps {
  scheduleViewUuid?: string;
  activeTab: string;
  type: "collection" | "plan";
  onChange?: (columns: string[]) => void;
}

interface IField {
  index: number;
  binding: string;
  fromStandard?: boolean;
  type: "number" | "text" | "select" | "monetary";
  defaultValue: string;
  label: string;
  uuid: string;
  options?: { key: string; value: string }[];
  schedules: string[];
}

const NewNavigation = ({ scheduleViewUuid, activeTab, type, onChange }: IProps) => {
  const [showFieldsSettings, setShowFieldsSettings] = useState<boolean>(false);

  const history = useHistory();

  const { data } = useTypedFetch<FieldRoutesType.Get>({
    method: "GET",
    path: "/fields",
    query: {},
  });

  const { data: userViews, refetch } = useFetch<
    {
      plan: {
        binding: string;
        label: string;
      }[];
      collection: {
        binding: string;
        label: string;
      }[];
    }[]
  >({
    url: "/v1/configuration/user",
  });

  const { settings } = useSettings();

  const visibleTabs = useMemo(
    () => Object.values(tabs[type]).filter((tab) => tab.isVisible?.(settings) ?? true),
    [settings, type]
  );

  const userView = (userViews ?? {})[type];
  if (!userView) return <div />;

  // Method used to patch configuration & refresh interfaces afterwards.
  const updateAndRefresh = async (newConfiguration: string[]) => {
    try {
      await DataLayer.request({
        method: "POST",
        url: `/v1/configuration/user/${type}`,
        body: JSON.stringify({
          fields: newConfiguration,
        }),
      });
      onChange?.(newConfiguration);
      refetch();
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue ; si elle persiste, veuillez contacter le support.",
        type: "error",
      });
    }
  };

  const activatedFields: string[] =
    orderBy(userView, ["index"], ["asc"]).map((f) => f.binding) ?? [];
  const addFieldToConfiguration = async (additionalField: IField) => {
    const newConfiguration = activatedFields.includes(additionalField.binding)
      ? activatedFields.filter((binding) => binding !== additionalField.binding)
      : activatedFields.concat(additionalField.binding);

    await updateAndRefresh(newConfiguration);
  };

  const reorderConfiguration = async (bindings: string[]) => {
    await updateAndRefresh(bindings);
  };

  const removeFieldFromConfiguration = async (bindingToRemove: string) => {
    const newConfiguration = activatedFields.filter((binding) => binding !== bindingToRemove);
    await updateAndRefresh(newConfiguration);
  };

  const hideFieldsSettings = (event) => {
    // Check if we're not clicking on the show fields gear
    if (event.path?.some?.((el) => el.classList?.contains?.(styles.toggle))) {
      return;
    }
    setShowFieldsSettings(false);
  };

  const typeUrl = type === "plan" ? "plan-de-formation" : "recueil-des-besoins";

  return (
    <nav className={styles.navigation}>
      <>
        {scheduleViewUuid && (
          <ToggleButtonGroup
            value={activeTab}
            options={visibleTabs.map((tab) => ({
              label: tab.label,
              value: tab.key,
            }))}
            onChange={(value) => {
              history.push(`/responsable/${scheduleViewUuid}/${typeUrl}/${value}`);
            }}
          />
        )}

        <div className={styles.toggle}>
          <DSButton
            iconOnly
            icon={<Settings />}
            onClick={() => setShowFieldsSettings(!showFieldsSettings)}
            aria-label="Choix des colonnes"
          />

          {showFieldsSettings && (
            <FieldsSettings
              fields={data.fields}
              activatedFields={activatedFields}
              type={type}
              addFieldToConfiguration={addFieldToConfiguration}
              reorderConfiguration={reorderConfiguration}
              removeFieldFromConfiguration={removeFieldFromConfiguration}
              onClickAway={hideFieldsSettings}
            />
          )}
        </div>
      </>
    </nav>
  );
};

export default NewNavigation;
