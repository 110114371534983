import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo, useState, useEffect } from "react";

import { z } from "zod";
import { useFormik } from "formik";
import { MdSearch as Search } from "react-icons/md";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GridColDef, useGridApiRef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Text, Flex } from "@skillup/design-system";
import {
  Label,
  DSAlert,
  DSModal,
  DSDataGrid,
  DSTextInput,
  DSAlertType,
  DSAlertDisplay,
} from "@skillup/ui";

import User from "utils/User";
import { useHRs } from "services/company";
import { editCampaignSettings } from "services/peopleReview/useCampaignDetails/editCampaignSettings";

import { Campaign } from "../../types";
import { PeopleReviewRoutes } from "../../pages/router";
import { DataGridRadio } from "../DataGridRadio/DataGridRadio";

import styles from "./ObserversCoordinatorsModal.module.scss";

type AdminGridRow = {
  id: string;
  noRole: boolean;
  observator: boolean;
  coordinator: boolean;
  administrator: string;
  setRole: (userId: string, role: "noRole" | "observator" | "coordinator") => void;
};

type ObserversCoordinatorsModalProps = {
  campaign: Campaign;
  onClose: () => void;
};

const coordinatorsAndObserversValidationSchema = toFormikValidationSchema(
  z.object({
    coordinators: z.array(z.string()),
    observers: z.array(z.string()),
  })
);

const rowToUser = ({ id, administrator }) => ({ id, fullName: administrator });

const columns: GridColDef[] = [
  {
    field: "administrator",
    flex: 1.5,
    headerName: "Responsables",
    resizable: false,
  },
  {
    type: "boolean",
    editable: false,
    field: "coordinator",
    flex: 1,
    headerName: "Coordinateur",
    renderCell: (params: GridRenderCellParams<AdminGridRow>) => <DataGridRadio {...params} />,
    resizable: false,
  },
  {
    type: "boolean",
    editable: false,
    field: "observator",
    flex: 1,
    headerName: "Observateur",
    renderCell: (params: GridRenderCellParams<AdminGridRow>) => <DataGridRadio {...params} />,
    resizable: false,
  },
  {
    type: "boolean",
    editable: false,
    field: "noRole",
    flex: 1,
    headerName: "Aucun rôle",
    renderCell: (params: GridRenderCellParams<AdminGridRow>) => <DataGridRadio {...params} />,
    resizable: false,
  },
];

export function ObserversCoordinatorsModal({ campaign, onClose }: ObserversCoordinatorsModalProps) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const userData = User.getUserData();
  const [hrSearch, setHrSearch] = useState("");
  const { hrs, isError, isLoading, mutations: hrMutations } = useHRs();
  const { mutate } = useMutation(editCampaignSettings, {
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries({ queryKey: ["people-reviews-campaign", campaign.id] });

      if (!values.coordinators.includes(userData.uuid)) {
        history.push(PeopleReviewRoutes.PPRCampaignsList);
      }
      onClose();
    },
  });

  const [selectedRoles, setSelectedRoles] = useState({
    ...campaign.coordinators.reduce((acc, id) => ({ ...acc, [id]: "coordinator" }), {}),
    ...campaign.observers.reduce((acc, id) => ({ ...acc, [id]: "observator" }), {}),
  });

  const handleSetRole = (userId: string, role: "noRole" | "observator" | "coordinator") => {
    setSelectedRoles((prev) => ({ ...prev, [userId]: role }));
  };

  const rows = useMemo(() => {
    return hrs
      .map((hr) => {
        return {
          id: hr.properties.uuid,
          administrator: hr.properties.fullName,
          coordinator: selectedRoles[hr.properties.uuid] === "coordinator",
          noRole:
            !selectedRoles[hr.properties.uuid] || selectedRoles[hr.properties.uuid] === "noRole",
          observator: selectedRoles[hr.properties.uuid] === "observator",
          setRole: handleSetRole,
        };
      })
      .sort((a, b) => a.administrator.localeCompare(b.administrator))
      .sort((a) => (a.id === userData.uuid ? -1 : 0));
  }, [hrs, selectedRoles, userData.uuid]);

  const onSubmit = async () => {
    const { coordinators, observers } = getManagers();

    mutate({
      campaignId: campaign.id,
      settings: {
        coordinators: coordinators.map((coordinator) => coordinator.id),
        observers: observers.map((observator) => observator.id),
      },
    });
  };

  const { handleSubmit, values } = useFormik({
    initialValues: {
      coordinators: campaign.coordinators,
      observers: campaign.observers,
    },
    onSubmit: onSubmit,
    validateOnMount: false,
    validationSchema: coordinatorsAndObserversValidationSchema,
  });

  const getManagers = () => {
    const rowModelsMap = apiRef.current.getRowModels();
    const selectedHrsArray = Array.from(rowModelsMap.values());
    const filteredObservers = selectedHrsArray.filter((hr) => hr.observator);
    const filteredCoordinators = selectedHrsArray.filter((hr) => hr.coordinator);
    const coordinators = filteredCoordinators.map(rowToUser);
    const observers = filteredObservers.map(rowToUser);

    return { coordinators, observers };
  };

  useEffect(() => {
    if (rows.length > 0 && Object.keys(apiRef?.current).length) {
      return apiRef.current.setRows(rows);
    }
  }, [apiRef, rows]);

  const noCoordinatorsSelected = Object.values(selectedRoles).every(
    (role) => role !== "coordinator"
  );
  const removedSelf = Object.entries(selectedRoles).some(
    ([id, role]) => id === userData.uuid && role !== "coordinator"
  );

  return (
    <DSModal isOpen className={styles.manageCoordinatorsModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("peopleReview.campaignSupervision.observatorsCoordinatorsModal.title", {
            defaultValue: "Gérer les responsables",
          })}
        />
      </DSModal.Header>

      <DSModal.Content className={styles.content}>
        <Flex gap="s" flexDirection="column">
          <Flex>
            <Flex width="100%" flexDirection="column">
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                {t(
                  "peopleReview.campaignSupervision.observatorsCoordinatorsModal.coordinatorsPermissionsTitle",
                  {
                    defaultValue: "Permissions des coordinateurs :",
                  }
                )}
              </Text>
              <ul className={styles.columnsText}>
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t(
                      "peopleReview.campaignSupervision.observatorsCoordinatorsModal.coordinatorsPermissions.one",
                      {
                        defaultValue: "Visualiser et modifier les valeurs",
                      }
                    )}
                  </Text>
                </li>
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t(
                      "peopleReview.campaignSupervision.observatorsCoordinatorsModal.coordinatorsPermissions.two",
                      {
                        defaultValue: "Retirer ou ajouter des collaborateurs",
                      }
                    )}
                  </Text>
                </li>
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t(
                      "peopleReview.campaignSupervision.observatorsCoordinatorsModal.coordinatorsPermissions.three",
                      {
                        defaultValue: "Clôturer la campagne",
                      }
                    )}
                  </Text>
                </li>
              </ul>
            </Flex>

            <Flex width="100%" flexDirection="column">
              <Label
                label={t(
                  "peopleReview.campaignSupervision.observatorsCoordinatorsModal.observersPermissionsTitle",
                  {
                    defaultValue: "Permissions des observateurs :",
                  }
                )}
              />
              <ul className={styles.columnsText}>
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t(
                      "peopleReview.campaignSupervision.observatorsCoordinatorsModal.observersPermissions.one",
                      {
                        defaultValue: "Visualiser les arbitrages",
                      }
                    )}
                  </Text>
                </li>
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t(
                      "peopleReview.campaignSupervision.observatorsCoordinatorsModal.observersPermissions.two",
                      {
                        defaultValue: "Visualiser les actions",
                      }
                    )}
                  </Text>
                </li>
              </ul>
            </Flex>
          </Flex>

          <DSTextInput
            name="searchHr"
            value={hrSearch}
            actionButton={<Search />}
            className={styles.searchbar}
            onChange={(str) => {
              setHrSearch(str);
              hrMutations.search(str);
            }}
            placeholder={t(
              "peopleReview.campaignSupervision.observatorsCoordinatorsModal.searchHr",
              {
                defaultValue: "Rechercher un responsable",
              }
            )}
          />

          <Flex marginBottom="s" maxHeight="30rem">
            <DSDataGrid
              autoHeight
              rows={rows}
              apiRef={apiRef}
              hideFooter={true}
              disableColumnMenu
              columns={columns}
              isError={isError}
              loading={isLoading}
              disableRowSelectionOnClick
              emptyOverlay={{
                text: t(
                  "peopleReview.campaignSupervision.observatorsCoordinatorsModal.emptyOverlayText",
                  {
                    defaultValue: "Aucun responsable trouvé.",
                  }
                ),
              }}
              entityName={t(
                "peopleReview.campaignSupervision.observatorsCoordinatorsModal.datagrid.entityName",
                {
                  count: rows.length,
                  defaultValue: `{{count}} responsable${rows.length > 1 ? "s" : ""}`,
                }
              )}
              errorOverlay={{
                text: [
                  t(
                    "peopleReview.campaignSupervision.observatorsCoordinatorsModal.errorOverlayText.firstSentence",
                    {
                      defaultValue: "Une erreur est survenue lors du chargement des responsables.",
                    }
                  ),
                  t(
                    "peopleReview.campaignSupervision.observatorsCoordinatorsModal.errorOverlayText.secondSentence",
                    {
                      defaultValue: "Veuillez réessayer ultérieurement.",
                    }
                  ),
                  t(
                    "peopleReview.campaignSupervision.observatorsCoordinatorsModal.errorOverlayText.thirdSentence",
                    {
                      defaultValue: "Si l'erreur persiste, contactez votre interlocuteur Skillup.",
                    }
                  ),
                ],
              }}
            />
          </Flex>

          <Flex gap="s" flexDirection="column">
            {(noCoordinatorsSelected && (
              <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.INLINE}>
                {t(
                  "peopleReview.campaignSupervision.observatorsCoordinatorsModal.errorOverlayText.firstSentence",
                  {
                    defaultValue:
                      "Une campagne doit obligatoirement avoir au moins un coordinateur.",
                  }
                )}
              </DSAlert>
            )) ||
              (removedSelf && (
                <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
                  {t(
                    "peopleReview.campaignSupervision.observatorsCoordinatorsModal.errorOverlayText.firstSentence",
                    {
                      defaultValue:
                        "Si vous confirmez ces changements, vous ne serez plus coordinateur de cette campagne. En cas d’erreur, il faudra donc demander à un autre coordinateur de vous ré-assigner les droits de coordination.",
                    }
                  )}
                </DSAlert>
              ))}
          </Flex>
        </Flex>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("peopleReview.campaignSupervision.observatorsCoordinatorsModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          disabled={values.coordinators.length === 0}
          onClick={() => handleSubmit()}
          label={t("peopleReview.campaignSupervision.observatorsCoordinatorsModal.confirm", {
            defaultValue: "Enregistrer les modifications",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
}
