import styled from "styled-components";

import { Select, DSColor, DSModal, DSButton, SkuiSpacing, DSTextInput } from "@skillup/ui";

export const SuggestionButton = styled(DSButton)`
  > button {
    color: ${DSColor["surface-light"]}!important;
    border-color: ${DSColor["surface-light"]}!important;
  }
`;

export const MassAssignButton = styled(DSButton)`
  margin-bottom: ${SkuiSpacing.s};
`;

export const SearchInput = styled(DSTextInput)`
  input {
    width: 255px;
    border-color: $border-onLight;
    font-size: $skui-spacing-s;
    &::placeholder {
      font-style: italic;
    }
  }
  svg {
    fill: $plainText-onLight-lighter;
    height: $skui-spacing-m;
    width: $skui-spacing-m;
    margin-left: -$skui-spacing-l;
  }
`;

export const AssignModal = styled(DSModal)`
  width: 35rem;
`;

export const AssignModalSelect = styled(Select)`
  width: 100%;
`;
