import { Currency } from "@skillup/shared-utils";
import { formatValueWithUnit } from "./formatter";
import i18n from "i18n";

export const defaultTooltip = (
  dataType: "money" | "number" | "duration",
  styles: any,
  currency?: Currency
) => ({
  useHTML: true,
  shadow: false,
  shared: true,
  padding: 0,
  borderRadius: 0,
  backgroundColor: "transparent",
  formatter: function () {
    const value = formatValueWithUnit(this.y, dataType, currency, i18n.language);

    return `<div class='${styles.defaultTooltip}'>
      <span class='${styles.name}'>${this.x ?? this.name ?? this.key}</span>
      <span class='${styles.value}'>${value}</span>
    </div>`;
  },
});
