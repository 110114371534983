import { MdSearch as Search } from "react-icons/md";
import React from "react";
import { Link } from "react-router-dom";

import Loader from "components/Loader";
import Icon from "components/Icon";
import { chevronRight } from "uiAssets/StrokeIcons";

import DataLayer from "utils/DataLayer";
import escapeStringRegexp from "utils/escape-string-regexp";

import tabs from "../scheduleMenuTabs";

import styles from "./NewSearchInScheduleStyles.module.scss";

export interface IProps {
  type: "plan" | "collection";
  scheduleUuid?: string;
  areas: string[];
  hasAllAreasActive: boolean;
}

export interface IState {
  error: boolean;
  isLoading: boolean;
  results: Array<ISearchItem> | null;
  rowsForSearch: Array<ISearchItem> | null;
  searchValue: string;
}

export interface ISearchItem {
  tab: string;
  traineeName: string;
  trainingName: string;
  trainingOrganizationName: string;
  uuid: string;
}

export default class NewSearchInSchedule extends React.PureComponent<IProps, IState> {
  public state: IState = {
    error: false,
    isLoading: false,
    results: null,
    rowsForSearch: [],
    searchValue: "",
  };
  private exitPrevented: boolean;
  private searchInput: HTMLInputElement | null;
  private debounceTimer: number;

  public componentDidMount(): void {
    this.initSearch();
  }

  public componentDidUpdate(prevProps) {
    if (this.props.scheduleUuid !== prevProps.scheduleUuid || this.props.type !== prevProps.type) {
      this.initSearch();
    }
  }

  private debounceSearch = () => {
    if (!this.state.searchValue) {
      // first search is instant
      this.search();
    } else {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(
        () => this.search(),
        this.searchInput.value !== "" ? 200 : 0
      );
    }
  };

  private initSearch = async (): Promise<void> => {
    // reset component
    if (this.searchInput) {
      this.searchInput.value = "";
    }

    // fetch the data
    const { scheduleUuid, type } = this.props;
    const rowsCollection = await this.fetchRows(type, scheduleUuid);

    this.setState({
      error: !rowsCollection,
      isLoading: false,
      rowsForSearch: rowsCollection,
      searchValue: "",
    });
  };

  private fetchRows = async (type: string, uuid?: string): Promise<Array<ISearchItem> | null> => {
    const url = `/v1/scheduleRow/search-data/${type}${uuid ? `/${uuid}` : ""}`;

    try {
      const rows = (await DataLayer.request({ url })) as Array<ISearchItem>;
      return rows;
    } catch {
      return null;
    }
  };

  private matchString = (valToTest: string, valToMatch: string) => {
    return (
      valToTest
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .indexOf(valToMatch) > -1
    );
  };

  private matchResult = (searchValue: string) => {
    const searchVal = searchValue
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    return (row) => {
      const computeAreasFilter = () => {
        if (this.props.hasAllAreasActive) {
          return true;
        }

        if (!this.props.areas?.length) {
          return false;
        }

        return this.props.areas.some((area) => row.areas?.includes(area));
      };

      return (
        computeAreasFilter() &&
        (this.matchString(row.traineeName, searchVal) ||
          this.matchString(row.trainingName, searchVal) ||
          this.matchString(row.trainingOrganizationName || "", searchVal)) // Temporary until old intra trainings (without trainingOrganization) are deleted
      );
    };
  };

  private search = () => {
    const { rowsForSearch, error } = this.state;

    if (error || !rowsForSearch || !this.searchInput) {
      this.setState({
        error: true,
        results: [],
      });
      return;
    }

    const searchValue = this.searchInput.value;

    if (!searchValue) {
      this.setState({
        results: null,
        searchValue,
      });
      return;
    }

    this.setState({
      results: rowsForSearch.filter(this.matchResult(searchValue)).slice(0, 50),
      searchValue,
    });
  };

  private formatString = (strToFormat: string, searchValue: string): React.ReactNode => {
    if (!strToFormat) return <></>;

    const startIndex = strToFormat.search(new RegExp(escapeStringRegexp(searchValue), "i"));

    if (startIndex === -1) return strToFormat;

    return (
      <>
        {strToFormat.slice(0, startIndex)}
        <b>{strToFormat.slice(startIndex, startIndex + searchValue.length)}</b>
        {strToFormat.slice(startIndex + searchValue.length)}
      </>
    );
  };

  private onBlurInput = (forceBlur = false): void => {
    if (!this.exitPrevented || forceBlur) {
      this.setState({
        results: null,
      });
    }
  };

  private onFocusInput = (): void => {
    if (this.state.searchValue) {
      this.search();
    }
  };

  public render(): JSX.Element {
    const { error, results, searchValue, isLoading } = this.state;
    const { type, scheduleUuid } = this.props;
    const tabsToState = tabs[type] || {};

    return (
      <div className={styles.SearchInSchedule}>
        <div>
          {isLoading ? <Loader width={25} /> : <Search />}
          <input
            ref={(input: HTMLInputElement) => (this.searchInput = input)}
            placeholder={`Rechercher une demande dans le ${type === "plan" ? "plan" : "recueil"}`}
            autoComplete="off"
            autoFocus
            onChange={this.debounceSearch}
            onBlur={() => this.onBlurInput()}
            onFocus={this.onFocusInput}
          />
        </div>
        {results && (
          <>
            <div
              className={styles.results}
              onMouseEnter={() => (this.exitPrevented = true)}
              onMouseLeave={() => (this.exitPrevented = false)}
            >
              {results.length > 0 &&
                results.map((result) => (
                  <Link
                    key={result.uuid}
                    to={{
                      pathname: `/responsable${scheduleUuid ? `/${scheduleUuid}` : ""}/${
                        type === "collection" ? "recueil-des-besoins" : "plan-de-formation"
                      }/${result.tab}/${result.uuid}`,
                      state: { searchBar: true },
                    }}
                    onClick={() => window.setTimeout(() => this.onBlurInput(true), 10)}
                  >
                    <div className={styles.details}>
                      <h2>{this.formatString(result.traineeName, searchValue)}</h2>
                      <h3>{this.formatString(result.trainingName, searchValue)}</h3>
                      {result.trainingOrganizationName && (
                        <p>par {this.formatString(result.trainingOrganizationName, searchValue)}</p>
                      )}
                    </div>
                    <div className={styles.arrow}>
                      <p>Voir cette demande</p>
                      <Icon strokeIcon={chevronRight} width={12} />
                    </div>
                    <div className={styles.state}>
                      {tabsToState && tabsToState[result.tab] ? (
                        <>
                          <Icon
                            strokeIcon={tabsToState[result.tab].icon}
                            width={12}
                            stroke={tabsToState[result.tab].iconColor}
                          />
                          {tabsToState[result.tab].label}
                        </>
                      ) : (
                        result.tab
                      )}
                    </div>
                  </Link>
                ))}
              {results && results.length === 0 && (
                <p onClick={() => this.onBlurInput(true)}>
                  {error
                    ? "Une erreur est survenue. Si le problème persiste, merci de nous contacter."
                    : `Aucun resultat ne correspond à ${searchValue}`}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}
