import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type CampaignFeedback = CampaignsRoutes.GetCampaignFeedbackByCampaignID["response"];

export async function getCampaignFeedbackByCampaignID(campaignID: string) {
  const response = await buildRequest<CampaignsRoutes.GetCampaignFeedbackByCampaignID>({
    method: "GET",
    params: { campaignID },
    path: "/campaigns/{campaignID}/feedback",
    target: "PEOPLE_REVIEW",
  })();

  return response;
}
