import { MdInfoOutline as InfoOutline } from "react-icons/md";
import React from "react";

import { getIn, Field, type FieldProps, ErrorMessage } from "formik";

import { DSSimpleTextArea } from "@skillup/ui";

import { Flex } from "../Flex";
import { Text } from "../Text";

interface Props {
  readonly name: string;
  readonly value?: string;
  readonly placeholder?: string;
}

export const FormikInputSimpleTextArea = ({ name, placeholder, value }: Props) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <DSSimpleTextArea
            rows={2}
            value={value}
            name={field.name}
            placeholder={placeholder}
            error={getIn(form.errors, field.name) && getIn(form.touched, field.name)}
            onChange={(value) => form.setFieldValue(field.name, value)}
          />
        )}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg) => (
          <Text fontSize="0.75rem" espaceFont="body1Regular" color="status-error-darker">
            <Flex gap="xs" alignItems="center">
              <InfoOutline />
              {msg}
            </Flex>
          </Text>
        )}
      />
    </>
  );
};
