import { MdContentCopy as ContentCopy } from "react-icons/md";
import { MdDelete as Delete } from "react-icons/md";
import { DSButton, DSTooltip } from "@skillup/ui";

import styles from "./ButtonsMutateContentItem.module.scss";

type ButtonsMutateContentItemProps = {
  duplicationTooltipLabel: string;
  deletionTooltipLabel: string;
  showActionsButtons: boolean;
  onDuplication: () => void;
  onDeletion: () => void;
};

export const ButtonsMutateContentItem = ({
  duplicationTooltipLabel,
  deletionTooltipLabel,
  showActionsButtons,
  onDuplication,
  onDeletion,
}: ButtonsMutateContentItemProps) => {
  if (!showActionsButtons) {
    return <div className={styles["content__item__no__actions"]} />;
  }

  return (
    <div className={styles["content__item__actions"]}>
      <DSTooltip label={duplicationTooltipLabel} lightColor>
        <DSButton buttonSize="S" iconOnly darkMode icon={<ContentCopy />} onClick={onDuplication} />
      </DSTooltip>
      <DSTooltip label={deletionTooltipLabel} lightColor>
        <DSButton buttonSize="S" iconOnly darkMode icon={<Delete />} onClick={onDeletion} />
      </DSTooltip>
    </div>
  );
};
