const mock = {
  id: "c521951d-0c8d-46b6-b589-5f2bde36a4d0",
  title: "Potentiel",
  key: "review.performance",
  versions: [
    {
      version: 1,
      title: "Performance",
      description:
        "Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: {
        id: "802fb046-cdfd-44d5-9e68-eeea67e4bf01",
        fullname: "Machin Truc",
        initials: "MT",
      },
      options: [
        {
          value: 1,
          label: "Faible",
          description:
            "Le collaborateur ne parvient pas à atteindre les objectifs fixés. Il montre des lacunes significatives dans l'exécution de ses missions et a besoin d'un accompagnement régulier pour progresser.",
        },
        {
          value: 2,
          label: "Moyen",
          description:
            "Le collaborateur atteint la plupart des objectifs mais avec une marge d'amélioration. Sa performance est globalement satisfaisante, mais pourrait gagner en consistance ou en efficacité.",
        },
        {
          value: 3,
          label: "Élevé",
          description:
            "Le collaborateur dépasse régulièrement les attentes. Il produit des résultats de haute qualité, fait preuve d'initiative et apporte une contribution positive et visible à l'équipe.",
        },
      ],
      updatedBy: "la.li.lu.le.lo",
    },
    {
      version: 2,
      title: "Performance",
      description:
        "Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: {
        id: "11893d36-7caa-4c48-bc14-fcc6118eb208",
        fullname: "Machin Truc",
        initials: "MT",
      },
      options: [
        {
          value: 1,
          label: "Faible",
          description:
            "Le collaborateur ne parvient pas à atteindre les objectifs fixés. Il montre des lacunes significatives dans l'exécution de ses missions et a besoin d'un accompagnement régulier pour progresser.",
        },
        {
          value: 2,
          label: "Moyen",
          description:
            "Le collaborateur atteint la plupart des objectifs mais avec une marge d'amélioration. Sa performance est globalement satisfaisante, mais pourrait gagner en consistance ou en efficacité.",
        },
        {
          value: 3,
          label: "Élevé",
          description:
            "Le collaborateur dépasse régulièrement les attentes. Il produit des résultats de haute qualité, fait preuve d'initiative et apporte une contribution positive et visible à l'équipe.",
        },
      ],
      updatedBy: "la.li.lu.le.lo",
    },
    {
      version: 3,
      title: "Performance",
      description:
        "Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: {
        id: "891914d1-cb45-4379-a260-c6b53fa7c037",
        fullname: "Machin Truc",
        initials: "MT",
      },
      options: [
        {
          value: 1,
          label: "Faible",
          description:
            "Le collaborateur ne parvient pas à atteindre les objectifs fixés. Il montre des lacunes significatives dans l'exécution de ses missions et a besoin d'un accompagnement régulier pour progresser.",
        },
        {
          value: 2,
          label: "Moyen",
          description:
            "Le collaborateur atteint la plupart des objectifs mais avec une marge d'amélioration. Sa performance est globalement satisfaisante, mais pourrait gagner en consistance ou en efficacité.",
        },
        {
          value: 3,
          label: "Élevé",
          description:
            "Le collaborateur dépasse régulièrement les attentes. Il produit des résultats de haute qualité, fait preuve d'initiative et apporte une contribution positive et visible à l'équipe.",
        },
      ],
      updatedBy: "la.li.lu.le.lo",
    },
    {
      version: 4,
      title: "Performance",
      description:
        "Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: {
        id: "c62d3661-f41f-4650-bc35-960cd9c95aa4",
        fullname: "Machin Truc",
        initials: "MT",
      },
      options: [
        {
          value: 1,
          label: "Faible",
          description:
            "Le collaborateur ne parvient pas à atteindre les objectifs fixés. Il montre des lacunes significatives dans l'exécution de ses missions et a besoin d'un accompagnement régulier pour progresser.",
        },
        {
          value: 2,
          label: "Moyen",
          description:
            "Le collaborateur atteint la plupart des objectifs mais avec une marge d'amélioration. Sa performance est globalement satisfaisante, mais pourrait gagner en consistance ou en efficacité.",
        },
        {
          value: 3,
          label: "Élevé",
          description:
            "Le collaborateur dépasse régulièrement les attentes. Il produit des résultats de haute qualité, fait preuve d'initiative et apporte une contribution positive et visible à l'équipe.",
        },
      ],
      updatedBy: "la.li.lu.le.lo",
    },
  ],
};
export const getReviewCriteriaHistoryCacheKey = (id: string) => [
  "core-hr",
  "review-criteria",
  "history",
  id,
];
export async function getReviewCriteriaHistory() {
  return mock;
}
