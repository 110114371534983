export const SortBottomToTop = () => {
  return (
    <svg width="14" height="20" viewBox="0 0 14 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 14H0V16H10L7.5 18.5L9 20L14 15L9 10L7.5 11.5L10 14Z" />
      <path d="M0 6H2V8H0V6Z" />
      <path d="M4 4H6V8H4V4Z" />
      <path d="M8 2H10V8H8V2Z" />
      <path d="M12 0H14V8H12V0Z" />
    </svg>
  );
};
