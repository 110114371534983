import { DSButton, Flex, Loader } from "@skillup/ui";
import { FormatDate, ParseDate } from "@skillup/shared-utils";

import useTranslation from "hooks/useTranslation";

import styles from "./DataFreshness.module.scss";

import { useRequestRefresh } from "../adapters/insights";
import { useTrainingDashboardFreshnessContext } from "../context";
import { useState } from "react";

export const DataFreshness = () => {
  const { t } = useTranslation();

  const [requestingRefresh, setRequestingRefresh] = useState(false);

  const { lastRefreshTime: rawLastRefreshTime, freshnessTime: rawFreshnessTime } =
    useTrainingDashboardFreshnessContext();
  const requestFreshness = useRequestRefresh();

  const lastRefreshTime = rawLastRefreshTime
    ? ParseDate.FromISO(rawLastRefreshTime, "utc")
    : undefined;
  const freshnessTime = rawFreshnessTime ? ParseDate.FromISO(rawFreshnessTime, "utc") : undefined;

  return (
    <Flex row className={styles.dataFreshness}>
      {!lastRefreshTime ? (
        <Loader />
      ) : (
        <>
          <span>
            {t("trainings.view.dashboard.last_refresh", {
              defaultValue: "Dernière mise à jour le {{date}}",
              date: FormatDate.ToStringFormat(
                freshnessTime.toLocal(),
                FormatDate.getDateTimeFormat()
              ),
            })}
          </span>
          <DSButton
            loading={requestingRefresh}
            disabled={requestingRefresh}
            onClick={async () => {
              try {
                setRequestingRefresh(true);

                await requestFreshness();
              } finally {
                setRequestingRefresh(false);
              }
            }}
            emphasis="Mid"
            label={t("trainings.view.dashboard.action.refresh_data", {
              defaultValue: "Rafraîchir les données",
            })}
          />
        </>
      )}
    </Flex>
  );
};
