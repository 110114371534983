import { NewCategoryParams } from "@skillup/espace-rh-bridge";
import { DSModal } from "@skillup/ui";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useTargetCategories } from "../hooks/useTargetCategories";
import styles from "./CreateOrRenameCategoryCommons.module.scss";
import {
  TargetGoal,
  TargetLabel,
  TargetProperties,
  TargetScale,
  Scale,
} from "./CreateOrRenameCategoryCommons";

export const CreateCategoryModal = ({
  close,
  targetCategoriesLabels,
}: {
  close: () => void;
  targetCategoriesLabels: string[];
}) => {
  const [categoryLabel, setCategoryLabel] = useState<string | undefined>(undefined);
  const [scale, setScale] = useState<Scale>("numeric");
  const [goal, setGoal] = useState<boolean>(false);
  const [period, setPeriod] = useState<boolean>(false);
  const [weight, setWeight] = useState<boolean>(false);

  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const { createNewCategory } = useTargetCategories();

  const onTargetSave = async (params: NewCategoryParams) => {
    setIsLoading(true);
    const response = await createNewCategory(params);
    if (response) {
      addToast("Nouvelle catégorie créée", { appearance: "success" });
    } else {
      addToast("Échec lors de la création de la nouvelle catégorie", {
        appearance: "error",
      });
    }
    close();
    setIsLoading(false);
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={close}>
        <DSModal.Header.Title title="Définir une catégorie d'objectifs" />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <TargetLabel
          targetCategoriesLabels={targetCategoriesLabels}
          categoryLabel={categoryLabel}
          setCategoryLabel={setCategoryLabel}
          isDuplicate={isDuplicate}
          setIsDuplicate={setIsDuplicate}
        />
        <TargetScale scale={scale} setScale={setScale} disabled />
        {scale !== "ordinal" && <TargetGoal goal={goal} setGoal={setGoal} disabled />}
        <TargetProperties
          period={period}
          setPeriod={setPeriod}
          weight={weight}
          setWeight={setWeight}
          disabled
        />
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton label="Annuler" onClick={close} />
        <DSModal.Footer.PrimaryButton
          label="Créer la catégorie"
          onClick={() => onTargetSave({ label: categoryLabel, scale, goal, period, weight })}
          disabled={!categoryLabel || categoryLabel.length === 0 || isDuplicate}
          loading={isLoading}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
