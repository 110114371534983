import { useToggle } from "react-use";
import { useMemo, Suspense } from "react";
import { useHistory, generatePath } from "react-router-dom";

import { Formik, useFormikContext } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { useMediaQueries } from "@skillup/ui";
import { Flex, Loader, Footer } from "@skillup/design-system";

import DSLayout from "components/DSLayout";
import EmptyView from "containers/Supervisor/components/Empty";
import useTranslation, { TranslationType } from "hooks/useTranslation";
import { Field, SkillFields, CreateSkillFormProps } from "types/skills";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";

import { SkillForm } from "../../components/Form";
import { SkillSchema } from "../../components/Form/Schemas";
import { SkillsProvider, useSkillsContext } from "../../SkillsContext";

interface ButtonProps {
  loading: boolean;
  t: TranslationType;
}

const HandleSubmitButton = ({ loading, t }: ButtonProps) => {
  const { dirty, isValid, submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const isDisabled = !isValid || !dirty;

  const tooltipStr = t("portal.config.skills.tooltip", {
    defaultValue:
      "Vous devez remplir tous les champs obligatoires (indiqués par une astérisque rouge)",
  });

  return (
    <DSNewHeaderButton
      type="submit"
      loading={loading}
      disabled={isDisabled}
      tooltipDarkMode={false}
      tooltip={isDisabled ? tooltipStr : undefined}
      onClick={handleSubmit}
      label={t("portal.config.skills.button.createSkill.label", {
        defaultValue: "Créer la compétence",
      })}
    />
  );
};

const parseFormFields = (fields: Field[] | undefined): SkillFields[] => {
  const formFieldValues = (fields || []).map((field) => ({
    uuid: field.uuid,
    index: field.index,
    name: field.name,
    value: "",
    version: field.version,
  }));

  return formFieldValues;
};

const CreateSkill = () => {
  return (
    <SkillsProvider>
      <Flex height="100%" flexDirection="column">
        <Suspense fallback={<Loader fillSpace />}>
          <Layout />
        </Suspense>
      </Flex>
    </SkillsProvider>
  );
};

const Layout = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, toggleLoading] = useToggle(false);
  const { isMobile } = useMediaQueries();

  const { createSkill, getCategories, getFields, getSkillEvaluationsScales } = useSkillsContext();
  const skillEvaluationsScales = getSkillEvaluationsScales();
  const categories = getCategories();
  const fields = getFields();

  const defaultEvaluationScale = useMemo(() => {
    const defaultSkillEvaluationScales = skillEvaluationsScales.data?.find((e) => e.isDefault);
    const firstSkillEvaluationScales = skillEvaluationsScales?.[0];
    return defaultSkillEvaluationScales ?? firstSkillEvaluationScales;
  }, [skillEvaluationsScales]);

  const handleSubmit = async (values: CreateSkillFormProps) => {
    toggleLoading(true);

    const customDescriptionLevels = values.evaluationScale.levels.some(
      (e) => e.customDescription && e.customDescription !== e.description
    );

    const fieldValues = values.fields
      .filter((field) => field.value)
      .map((field) => ({
        uuid: field.uuid,
        index: field.index,
        value: field.value,
        version: field.version,
      }));

    const payload = {
      description: values.description,
      name: values.skillName,
      ...(customDescriptionLevels
        ? {
            customEvaluationScale: {
              uuid: values.evaluationScale.uuid,
              levels: values.evaluationScale.levels.map((level) => ({
                uuid: level.uuid,
                description: level.customDescription,
                level: level.level,
                name: level.name,
              })),
            },
          }
        : {
            evaluationScaleUuid: values.evaluationScale.uuid,
          }),
      categoryId: values.categoryUuid,
      fields: fieldValues ?? [],
      generatedWithAi: false,
    };

    createSkill(payload);
    toggleLoading(false);
  };

  const initialValues = useMemo(() => {
    return {
      description: "",
      evaluationScale: defaultEvaluationScale,
      fields: parseFormFields(fields.data),
      skillName: "",
    };
  }, [defaultEvaluationScale, fields]);

  if (skillEvaluationsScales.isLoading || fields.isLoading || categories.isLoading) {
    return (
      <DSLayout
        title={t("portal.config.skills.title.createSkill.label", {
          defaultValue: "Nouvelle compétence",
        })}
      >
        <Loader />
      </DSLayout>
    );
  }

  if (skillEvaluationsScales.data.length === 0) {
    return (
      <DSLayout
        title={t("skills.title.newSkill", {
          defaultValue: "Nouvelle compétence",
        })}
        parent={{
          title: t("skills.title.skills", {
            defaultValue: "Compétences",
          }),
          titleUrl: generatePath("/responsable/referentiels/competences"),
        }}
      >
        <Flex flex="1" flexDirection="column">
          <EmptyView
            withStyle
            buttonLabel={t("skills.createSkill.label.createSkillEvaluationScale", {
              defaultValue: "Créer une échelle d'évaluation",
            })}
            message={t("skills.createSkill.label.noSkillEvaluationScale", {
              defaultValue: "Vous n'avez aucune échelle d'évaluation.",
            })}
            buttonClick={() =>
              history.push(
                "/responsable/configuration-du-module-competences/gestion-des-echelles-d-evaluation"
              )
            }
          />
        </Flex>
      </DSLayout>
    );
  }

  return (
    <>
      {isMobile ? (
        <NotAvailableInMobileView />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(SkillSchema)}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(formikProps) => {
            return (
              <DSLayout
                isRelative={true}
                title={t("skills.title.newSkill", {
                  defaultValue: "Nouvelle compétence",
                })}
                parent={{
                  title: t("skills.title.skills", {
                    defaultValue: "Compétences",
                  }),
                  titleUrl: generatePath("/responsable/referentiels/competences"),
                }}
              >
                <SkillForm
                  formikProps={formikProps}
                  categories={categories.data}
                  evaluationScales={skillEvaluationsScales.data}
                />
                <Footer
                  containerWidth={"650px"}
                  firstColumnChildren={<HandleSubmitButton t={t} loading={loading} />}
                />
              </DSLayout>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default CreateSkill;
