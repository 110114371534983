import { Flex } from "@skillup/design-system";
import { DSButton, DSRadio, Select, generateModalContext } from "@skillup/ui";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Collaborator } from "../../api";
import { EvaluatedUserTargetsDataGrid, InProgressUserTargetsDataGrid } from "./components";
import { useUserTargetDetailModal } from "./hooks";
import { useUserCampaigns } from "./hooks/useUserCampaign";
import { ObsoleteTargetsModal } from "./components/EvaluatedUserTargets/ObsoleteTargets";

interface Props {
  readonly user: Collaborator;
}

export type GlobalFilters = {
  isEvaluated?: boolean;
  isObsolete?: boolean;
  campaigns?: string[];
};

const useModal = generateModalContext();

export const UserTargets = ({ user }: Props) => {
  const { t } = useTranslation();

  const [globalFilters, setGlobalFilters] = useState<GlobalFilters>({
    isEvaluated: true,
    isObsolete: false,
    campaigns: [],
  });

  const { campaigns, isLoading: isLoadingCampaigns } = useUserCampaigns({ userUuid: user.uuid });

  const { userTargetDetailModal, openUserTargetDetailModal } = useUserTargetDetailModal({
    userUuid: user.uuid,
  });

  const handleCampaignChange = useCallback(
    (values?: string[]) => {
      const campaignValues = values?.every((v) => v === undefined) ? [] : values;
      setGlobalFilters((globalFilters) => {
        return { ...globalFilters, campaigns: campaignValues };
      });
    },
    [setGlobalFilters]
  );

  const {
    isOpen: isOpenObsoleteModal,
    openModal: openObsoleteModal,
    closeModal: closeObsoleteModal,
  } = useModal();

  return (
    <>
      <Flex flexDirection="column" gap="m" padding="l">
        <Flex flexDirection="row" gap="l">
          <Flex flexDirection="column" gap="xs">
            <DSRadio
              label={t("collaborator.targets.filters.radio.evaluated", {
                defaultValue: "Objectifs évalués",
              })}
              checked={globalFilters.isEvaluated === true}
              onChange={() => {
                setGlobalFilters((globalFilters) => {
                  return { ...globalFilters, isEvaluated: true, isObsolete: false };
                });
              }}
            />
            {globalFilters.isEvaluated && campaigns?.length > 0 && (
              <Flex paddingLeft="m">
                <Select
                  canSelectAll
                  placeholder="Tous les objectifs évalués"
                  allValuesLabel="Tous les objectifs évalués"
                  extraValuesLabel={(count) => `Objectifs évalués dans ${count} campagnes`}
                  options={
                    !isLoadingCampaigns &&
                    campaigns.map((campaign) => ({
                      label: campaign.title,
                      value: campaign.uuid,
                    }))
                  }
                  onChange={handleCampaignChange}
                  clearable
                  multi
                  value={globalFilters.campaigns}
                  paginationCustomStyle={{
                    width: "22.75em",
                  }}
                />
              </Flex>
            )}
          </Flex>
          <DSRadio
            label={t("collaborator.targets.filters.radio.inProgress", {
              defaultValue: "Objectifs en cours",
            })}
            checked={globalFilters.isEvaluated === false}
            onChange={() => {
              setGlobalFilters((globalFilters) => {
                return { ...globalFilters, isEvaluated: false, isObsolete: false };
              });
            }}
          />
        </Flex>
        {globalFilters.isEvaluated ? (
          <EvaluatedUserTargetsDataGrid
            user={user}
            openTargetDetail={openUserTargetDetailModal}
            globalFilters={globalFilters}
          />
        ) : (
          <InProgressUserTargetsDataGrid user={user} openTargetDetail={openUserTargetDetailModal} />
        )}
        <DSButton
          buttonSize="S"
          emphasis="Low"
          label="Afficher les objectifs obsolètes"
          onClick={() => openObsoleteModal()}
        />
        {isOpenObsoleteModal && (
          <ObsoleteTargetsModal
            isOpen={true}
            closeModal={() => closeObsoleteModal()}
            userUuid={user.uuid}
          />
        )}
      </Flex>
      {userTargetDetailModal}
    </>
  );
};
