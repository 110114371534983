import { PanelConfigType, ScheduleRowTypes } from "@skillup/training-bridge";

export const getPanelType = (
  panel: ScheduleRowTypes.DashboardPanelConfig,
  questions: ScheduleRowTypes.Question[],
  summaryQuestion: ScheduleRowTypes.Question,
  monthlyQuestion: ScheduleRowTypes.Question,
  summaryQuestionWithTax?: ScheduleRowTypes.Question,
  monthlyQuestionWithTax?: ScheduleRowTypes.Question
): PanelConfigType => {
  const card = panel.cards[0];

  if (!card) {
    console.warn("No card found in panel", panel);

    return undefined;
  }

  const question = questions.find((q) => q.name === card.question_name);

  if (!question) {
    console.error("No question found for card", card);

    return undefined;
  }

  if (question.name === summaryQuestion.name) {
    return "summary";
  }

  if (question.name === summaryQuestionWithTax?.name) {
    return "summary-with-tax";
  }

  if (question.name === monthlyQuestion.name) {
    return "monthly";
  }

  if (question.name === monthlyQuestionWithTax?.name) {
    return "monthly-with-tax";
  }

  if (question.fields.length === 1) {
    return "key-indicators";
  }

  if (question.fields.length === 2) {
    return "detail-indicators";
  }

  console.error("Unknown panel type", panel);

  return undefined;
};
