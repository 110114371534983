import { generatePath, useHistory } from "react-router-dom";
import { useToggle } from "react-use";
import { useToasts } from "react-toast-notifications";
import { Formik, useFormikContext } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { useMediaQueries } from "@skillup/ui";

import { trpc } from "utils/trpc";
import useTranslation, { TranslationType } from "hooks/useTranslation";

import DSLayout from "components/DSLayout";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import EvaluationScaleForm, { rewriteLevels } from "../../components/Form/EvaluationScaleForm";
import { FormEvaluationLevel, FormEvaluationScale } from "types/skills";
import { SkillEvaluationScaleSchema } from "../../SkillEvaluationScalesFormSchemas";

interface ButtonProps {
  t: TranslationType;
  loading: boolean;
}

const getDefaultLevels = (t: TranslationType): Array<FormEvaluationLevel> => {
  return [
    {
      level: 1,
      name: "",
      description: "",
      deletable: false,
      placeholder: t("portal.config.skills.createEvaluationScale.skillApprentice", {
        defaultValue: "Ex: Apprenti",
      }),
    },
    {
      level: 2,
      name: "",
      description: "",
      deletable: false,
      placeholder: t("portal.config.skills.createEvaluationScale.skillIntermediate", {
        defaultValue: "Ex: Intermédiaire",
      }),
    },
    {
      level: 3,
      name: "",
      description: "",
      deletable: true,
      placeholder: t("portal.config.skills.createEvaluationScale.skillConfirmed", {
        defaultValue: "Ex: Confirmé",
      }),
    },
    {
      level: 4,
      name: "",
      description: "",
      deletable: true,
      placeholder: t("portal.config.skills.createEvaluationScale.skillExpert", {
        defaultValue: "Ex: Expert",
      }),
    },
  ];
};

const HandleSubmitButton = ({ t, loading }: ButtonProps) => {
  const { submitForm, isValid, dirty } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const isDisabled = !isValid || !dirty;
  const tooltipStr = t("portal.config.skills.tooltip", {
    defaultValue:
      "Vous devez remplir tous les champs obligatoires (indiqués par une astérisque rouge)",
  });

  return (
    <DSNewHeaderButton
      label={t("portal.config.skills.button.create.label", {
        defaultValue: "Créer l'échelle d'évaluation",
      })}
      onClick={handleSubmit}
      loading={loading}
      type="submit"
      darkMode={true}
      disabled={isDisabled}
      tooltip={isDisabled ? tooltipStr : undefined}
      tooltipDarkMode={false}
    />
  );
};

const CreateEvaluationScale = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, toggleLoading] = useToggle(false);
  const { isMobile } = useMediaQueries();
  const { addToast } = useToasts();

  const creationMutator = trpc.skillEvaluationsScales.create.useMutation({
    onSuccess: () => {
      toggleLoading(false);
      addToast(
        t("portal.config.skills.createEvaluationScale.success", {
          defaultValue: "L'échelle d'évaluation a bien été créée",
        }),
        {
          appearance: "success",
        }
      );
      history.push(
        "/responsable/configuration-du-module-competences/gestion-des-echelles-d-evaluation"
      );
    },
    onError: () => {
      toggleLoading(false);
      addToast(
        t("portal.config.skills.createEvaluationScale.error", {
          defaultValue: "L'échelle d'évaluation n'a pas pu être créée",
        }),
        {
          appearance: "error",
        }
      );
    },
  });

  const handleSubmit = async (values: FormEvaluationScale) => {
    toggleLoading(true);
    const payload = {
      isDefault: false,
      name: values.evaluationScaleName,
      levels: rewriteLevels(values.startAtZero, values.levels),
    };

    creationMutator.mutate(payload);
  };

  return (
    <>
      {isMobile ? (
        <NotAvailableInMobileView />
      ) : (
        <Formik
          initialValues={{
            evaluationScaleName: "",
            startAtZero: false,
            hasDescriptions: false,
            levels: getDefaultLevels(t),
          }}
          validationSchema={toFormikValidationSchema(SkillEvaluationScaleSchema)}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(formikProps) => {
            return (
              <DSLayout
                title={t("portal.config.skills.title.create.label", {
                  defaultValue: "Création d'une échelle d'évaluation",
                })}
                layouts={
                  [
                    {
                      primaryButton: <HandleSubmitButton t={t} loading={loading} />,
                    },
                  ] as const
                }
                parent={{
                  title: t("skills.title.configuration", {
                    defaultValue: "Configuration des échelles d'évaluation",
                  }),
                  titleUrl: generatePath(
                    "/responsable/configuration-du-module-competences/gestion-des-echelles-d-evaluation"
                  ),
                }}
              >
                <EvaluationScaleForm formikProps={formikProps} />
              </DSLayout>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default CreateEvaluationScale;
