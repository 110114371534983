import { MdAdd as Add } from "react-icons/md";
import { DSButton, DSButtonProps, useMediaQueries } from "@skillup/ui";

const DSNewHeaderButton = (props: DSButtonProps): JSX.Element => {
  const { icon, ...rest } = props;
  const { isMobile } = useMediaQueries();

  return <DSButton iconOnly={isMobile} icon={isMobile ? icon ?? <Add /> : undefined} {...rest} />;
};
export default DSNewHeaderButton;
