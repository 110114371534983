import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Flex, Text } from "@skillup/design-system";
import { Label, Select, DSModal } from "@skillup/ui";

import { useUsers } from "services/users";
import { changeManager } from "services/peopleReview/reviews/changeManager";

import { Campaign, type Review } from "../../types";

import styles from "./ChangeManagerModal.module.scss";

type ChangeManagerModalProps = {
  reviews: Review[];
  campaign: Campaign;
  onClose: () => void;
};

export function ChangeManagerModal({ campaign, onClose, reviews }: ChangeManagerModalProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const { data, error, isLoading } = useUsers({
    excludedUserUuids: reviews.map((r) => r.userID),
    query,
  });
  const [managerID, setManagerID] = useState<string>(null);
  const { isLoading: isMutationLoading, mutate } = useMutation(changeManager, {
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
      queryClient.invalidateQueries(["people-reviews-campaign-reviews", campaign.id]);
      onClose();
    },
  });

  function getNoOptionMessage() {
    if (isLoading) {
      return t("peopleReview.talentGrid.changeManagerModal.manager.placeholder.isLoading", {
        defaultValue: "Recherche en cours",
      });
    }

    if (error) {
      return t("peopleReview.talentGrid.changeManagerModal.manager.placeholder.error", {
        defaultValue: "Une erreur s'est produite",
      });
    }

    // Backend route seems to trigger only after 2/3 chars
    if (query?.length < 3) {
      return t("peopleReview.talentGrid.changeManagerModal.manager.placeholder.waitingUserInput", {
        defaultValue: "Saisissez au moins 3 caractères",
      });
    }

    return t("peopleReview.talentGrid.changeManagerModal.manager.placeholder.empty", {
      defaultValue: "Aucun résultat trouvé",
    });
  }

  return (
    <DSModal isOpen className={styles.ChangeManagerModal}>
      <DSModal.Header onClose={onClose}>
        {reviews.length === 1 ? (
          <DSModal.Header.Title
            title={t("peopleReview.talentGrid.changeManagerModal.title", {
              defaultValue: "Changer le manager de {{fullName}}",
              fullName: reviews[0].fullName,
            })}
          />
        ) : (
          <DSModal.Header.Title
            title={t("peopleReview.talentGrid.changeManagerModal.titleBulk", {
              defaultValue: "Changer le manager des collaborateurs sélectionnés",
            })}
          />
        )}
      </DSModal.Header>

      <DSModal.Content className={styles.content}>
        <Flex paddingBottom="s" flexDirection="column">
          <Flex paddingBottom="s" flexDirection="column">
            {reviews.length > 1 && (
              <Flex marginTop="s" flexDirection="column">
                <Text espaceFont="body2Regular" color="plainText-onLight-default">
                  Collaborateur(s) sélectionné(s):
                </Text>
                {reviews.map((review) => (
                  <Text key={review.id} espaceFont="body2Regular" color="plainText-onLight-default">
                    - {review.fullName}
                  </Text>
                ))}
              </Flex>
            )}
          </Flex>

          <Label
            required
            label={t("peopleReview.talentGrid.changeManagerModal.manager.label", {
              defaultValue: "Manager",
            })}
          />
          <Select
            autoFocus
            isSearchable
            noOptionsMessage={getNoOptionMessage()}
            onChange={(value) => setManagerID(value)}
            onInputChange={(query) => setQuery(query)}
            options={(data || []).map((employee) => ({
              label: employee.fullName,
              value: employee.uuid,
            }))}
            placeholder={t("peopleReview.talentGrid.changeManagerModal.manager.placeholder", {
              defaultValue: "Sélectionnez un manager",
            })}
          />
        </Flex>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("peopleReview.talentGrid.changeManagerModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          disabled={!managerID || isMutationLoading}
          onClick={() =>
            mutate({ campaignID: campaign.id, managerID, reviewIDs: reviews.map(({ id }) => id) })
          }
          label={t("peopleReview.talentGrid.changeManagerModal.confirm", {
            defaultValue: "Confirmer le changement",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
}
