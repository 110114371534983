import { MdDone as Done } from "react-icons/md";
import { MdContentCopy as ContentCopy } from "react-icons/md";
import { useState } from "react";
import { DSButton } from "@skillup/ui";

export function CopyText({ text }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
  }

  const handleCopyClick = (e) => {
    e.stopPropagation();
    copyTextToClipboard(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  return (
    <DSButton
      iconOnly
      buttonSize="S"
      tooltip={isCopied ? "ID copié" : "Copier l'ID de la session"}
      onClick={handleCopyClick}
      icon={isCopied ? <Done /> : <ContentCopy />}
    />
  );
}
