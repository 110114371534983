import styled from "styled-components";

import { SkuiSpacing } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

export const Container = styled(Flex)`
  width: 990px;
  flex-wrap: wrap;
  padding: ${SkuiSpacing.m};
`;

export const CardContainer = styled(Flex)`
  flex-wrap: wrap;
  flex: 1;
  gap: ${SkuiSpacing.l};
  margin-top: ${SkuiSpacing.m};
`;

export const ExpandDeclinedButton = styled(Flex)`
  width: 100%;
  align-items: center;
  cursor: pointer;
  margin-top: ${SkuiSpacing.m};
`;
