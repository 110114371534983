import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Flex, Title } from "@skillup/design-system";
import { NavigationTab, NavigationTabs } from "@skillup/ui";

import JobsOverview from "./pages/JobsOverview";
import SearchBar from "../../components/SearchBar";
import SkillsOverview from "./pages/SkillsOverview";

const Overview = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const tabMenu: NavigationTab[] = useMemo(() => {
    return [
      {
        id: "competences",
        label: t("skills.dashboard.skillsTab", {
          defaultValue: "Compétences",
        }),
        url: "/responsable/analyses/competences",
      },
      {
        id: "fiches-de-poste",
        label: t("skills.dashboard.jobsTab", {
          defaultValue: "Fiches de poste",
        }),
        url: "/responsable/analyses/fiches-de-poste",
      },
    ];
  }, [t]);

  const TAB = { jobs: "postes", skills: "competences" };

  const selectedTab = useMemo(() => {
    return tabMenu.find((e) => e.url === pathname)?.id;
  }, [tabMenu, pathname]);

  return (
    <Flex flex={1} width="100%" height="fit-content" flexDirection="column">
      <Flex width="100%" paddingTop="m" justifyContent="center" backgroundColor="surface-dark">
        <Title h2 marginTop="s" color="surface-light">
          {t("skills.dashboard.overviewTitle", {
            defaultValue: "Analyse des évaluations de compétences de vos collaborateurs",
          })}
        </Title>
      </Flex>
      <Flex
        top="0"
        flex={1}
        zIndex="2"
        paddingTop="s"
        position="sticky"
        flexDirection="column"
        backgroundColor="surface-dark"
      >
        <Flex width="100%" alignItems="center" flexDirection="column">
          <Flex width="40%" position="relative" flexDirection="column">
            <SearchBar />
          </Flex>
          <NavigationTabs tabs={tabMenu} spacingLeft={true} activeTab={selectedTab} />
        </Flex>
      </Flex>
      <Flex
        flex={1}
        alignItems="center"
        flexDirection="column"
        backgroundColor="surface-light-darker"
      >
        {selectedTab === TAB.skills ? <SkillsOverview /> : <JobsOverview />}
      </Flex>
    </Flex>
  );
};

export default Overview;
