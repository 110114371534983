const mock = [
  {
    id: "79e8e530-553f-4fe5-9ca9-5aab5ebfe01b",
    description:
      "Évalue la contribution globale d'un collaborateur à la réalisation des objectifs de l'équipe et de l'entreprise. Il s'agit de mesurer à quel point cette personne atteint ou dépasse les attentes fixées pour son poste, tant en termes de résultats que de comportement professionnel.",
    options: ["very nice"],
    title: "Performance",
  },
  {
    id: "c521951d-0c8d-46b6-b589-5f2bde36a4d0",
    description:
      "Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.Évalue la capacité d'un collaborateur à évoluer et à assumer des responsabilités plus importantes à l'avenir.  Il s'agit d'identifier son aptitude à développer de nouvelles compétences, à s'adapter à des environnements changeants, et à faire preuve de leadership ou d'innovation.",
    options: ["meh", "noice", "very nice"],
    title: "Potentiel",
  },
  {
    id: "d5ce9ede-36ec-43f3-8874-787aac62b7d5",
    description:
      "Évalue le degré d'implication, de motivation et d'adhésion d'un collaborateur envers l'entreprise, ses valeurs et ses objectifs. L'engagement se traduit par la volonté de contribuer activement au succès de l'organisation et de favoriser un environnement de travail positif.",
    options: ["meh", "noice", "very nice"],
    title: "Engagement",
  },
  {
    id: "d714c859-c29f-41d0-b396-8a8730fff7e5",
    description:
      "Évalue la probabilité qu'un collaborateur quitte l'entreprise à court ou moyen terme. Il prend en compte plusieurs facteurs tels que la satisfaction professionnelle, les perspectives de carrière, la motivation actuelle et les signaux comportementaux indiquant un possible désengagement.",
    options: ["meh", "noice", "very nice"],
    title: "Risque de départ",
  },
  {
    id: "aa2b4aaa-b7bc-4213-995f-4a1274ae0f03",
    description:
      "Évalue l'influence positive qu'un collaborateur exerce sur l'entreprise et son environnement. Il prend en compte sa capacité à inspirer ses collègues, à améliorer les processus ou la culture organisationnelle, et à contribuer au succès global de l'équipe et de l'entreprise.",
    options: ["meh", "noice", "very nice"],
    title: "Impact sur la société",
  },
];
export const getReviewCriteriaListCacheKey = () => ["core-hr", "review-criteria", "list"];
export const getReviewCriteriaList = () => mock;

