import styled from "styled-components";

import { Text } from "@skillup/design-system";
import {
  Label,
  DSAlert,
  DSModal,
  DSColor,
  DSCheckbox,
  SkuiSpacing,
  DSSkillsList,
} from "@skillup/ui";

export const SkillsList = styled(DSSkillsList)`
  border: 1px solid ${DSColor["border-onLight"]};
  border-radius: ${SkuiSpacing.xxs};
  padding: ${SkuiSpacing.xxs} ${SkuiSpacing.xs};
  margin-top: ${SkuiSpacing.xxs};
`;

export const StyledForm = styled.div`
  width: 100%;
`;

export const FormLabel = styled(Label)`
  word-wrap: break-word;
  margin-bottom: ${SkuiSpacing.xs};
`;

export const Modal = styled(DSModal)`
  width: 560px;
  max-height: 590px;
`;

export const Checkbox = styled(DSCheckbox)`
  width: 100%;

  div {
    width: 100%;

    label {
      width: 95%;
      overflow-wrap: break-word;
    }
  }
`;

export const Skillname = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const FormikInputTextAreaWrapper = styled.div`
  min-height: 200px;
  & > div {
    height: 100%;
  }
`;

export const SecondaryButton = styled.button`
  background: none;
  border: none;
  color: ${DSColor["text-onLight"]};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin: 0;
`;

export const Divider = styled.div`
  height: 1px;
  width: 150px;
  background-color: ${DSColor["plainText-onDark-darker"]};
  margin: 0 ${SkuiSpacing.m};
`;

export const Alert = styled(DSAlert)`
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: ${SkuiSpacing.m};
  margin-bottom: ${SkuiSpacing.m};
  z-index: 9;
`;
