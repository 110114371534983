import once from "lodash/once";
import User from "utils/User";

// Hotjar implementation documentation : https://www.notion.so/skillupco/Hotjar-integration-9faa9898a04942f98574513e9dd1d3fe#10a9f077698280a5b5d6ecba79ee872a
const HOTJAR_ID = 2720862;
const HOTJAR_SV = 6;

declare global {
  interface Window {
    _hjSettings?: {
      hjid?: typeof HOTJAR_ID;
      hjsv?: typeof HOTJAR_SV;
    };
    hj: any;
  }
}

const init = once(() =>
  new Promise((resolve) => {
    const url = "https://static.hotjar.com/c/hotjar-";

    window.hj =
      window.hj ||
      function () {
        (window.hj.q = window.hj.q || []).push(arguments);
      };

    window._hjSettings = { hjid: HOTJAR_ID, hjsv: HOTJAR_SV };
    const a = document.getElementsByTagName("head")[0];
    const r = document.createElement("script");
    r.async = true;
    r.src = `${url + window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
    a.appendChild(r);

    resolve(true);
  }).then(() => {
    const hotjarUserAttributes = {
      companyId: User.getUserCompanyUuid(),
      companyName: User.getUserCompanyName(),
      userRole: User.getUserLvl(),
      isDemoCompany: User.isDemoUser(),
      hasTrainingsEnabled: User.hasTrainingsEnabled(),
      hasInterviewsEnabled: User.hasInterviewsEnabled(),
      hasSkillModuleEnabled: User.hasSkillsEnabled(),
      hasPeopleReviewEnabled: User.hasPeopleReviewEnabled(),
    };
    window.hj("identify", User.getUserUuid(), hotjarUserAttributes);
  })
);

export default {
  init,
};
