import { useToasts } from "react-toast-notifications";

import { useMutation } from "@tanstack/react-query";

import useTranslation from "hooks/useTranslation";
import useAreas from "hooks/useAreas";
import type { ProjectsRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";
import { hasColdReviewsEnabled, hasHotReviewsEnabled } from "utils/User";

import { formatPayloadForCreation, sessionFormValidationSchema } from "./utils";
import { Session, State } from "./types";

import { Formik } from "formik";
import { SessionForm } from "./components/Form";
import { toFormikValidationSchema } from "zod-formik-adapter";

type Props = {
  onClose: () => void;
  isOpen: boolean;
  trainingUuid?: string;
  trainingReference?: string;
};

export const CreationModal = ({ onClose, isOpen, trainingUuid, trainingReference }: Props) => {
  const { t } = useTranslation();
  const { allAreas } = useAreas();
  const { addToast } = useToasts();

  const hasAccessToReviews = hasColdReviewsEnabled() || hasHotReviewsEnabled();

  const initialState: State = {
    areas: (allAreas ?? []).map((area) => area.uuid),
    price: { type: "total" },
    priceWithTax: undefined,
    dates: [{ allDay: true }],
    evalGroupsGenerated: hasAccessToReviews ? true : undefined,
    trainers: [],
    intra: undefined,
    min: undefined,
    max: undefined,
  };

  const { mutate, isLoading } = useMutation(async (state: Session) => {
    try {
      const payload = formatPayloadForCreation(state);

      await buildRequest<ProjectsRoutes.Create>({
        method: "POST",
        path: "/projects",
        payload,
      })();
      closeModal();
    } catch (err) {
      addToast(
        t("supervisor.sessions.creationModal.submitError", {
          defaultValue: "Une erreur est survenue lors de la création.",
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      console.error(err);
    }
  });

  const closeModal = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Formik<Session>
      initialValues={initialState}
      validationSchema={toFormikValidationSchema(sessionFormValidationSchema(t, "create"))}
      onSubmit={(values) => mutate(values)}
    >
      <SessionForm
        type="create"
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
        trainingUuid={trainingUuid}
        trainingReference={trainingReference}
      />
    </Formik>
  );
};
