import { MdArrowUpward as ArrowUp } from "react-icons/md";
import { MdArrowDownward as ArrowDown } from "react-icons/md";
import { MdDelete as Delete } from "react-icons/md";
import { useCallback } from "react";
import {
  Label as DSLabel,
  DSButton,
  DSFormGroupTextInput,
  Select,
  Flex,
  DSTooltip,
} from "@skillup/ui";

import { Divided } from "../../ChildWrappers";
import { TableColumn, TableColumnType, TableColumnDisallow } from "../types";

import { TableChildColumnPermissions } from "./TableChildColumnPermissions";
import { BuilderFormInput } from "../../../BuilderFormInput";

import styles from "../TableChild.module.scss";

const COLUMN_TYPES_LABELS: { [key in TableColumnType]: string } = {
  text: "À remplir par le participant - Texte libre",
  fixed: "Valeur fixe",
  dropdown: "À remplir par le participant - Choix multiple",
};

const COLUMN_TYPES_OPTIONS = Object.entries(COLUMN_TYPES_LABELS).map(([value, label]) => ({
  value,
  label,
}));

export const TableChildColumn = ({
  childId,
  rowsCount,
  column,
  index,
  onDeleteColumn,
  onUpdateColumn,
  onMoveColumnUpOrDown,
  disabledMoveUp,
  disabledMoveDown,
}: {
  childId: string;
  rowsCount: number;
  column: TableColumn;
  index: number;
  onDeleteColumn: (index: number) => void;
  onUpdateColumn: (index: number, column: TableColumn) => void;
  onMoveColumnUpOrDown: (index: number, direction: "up" | "down") => void;
  disabledMoveUp?: boolean;
  disabledMoveDown?: boolean;
}) => {
  const handleChangeTitle = useCallback(
    (value: string) => {
      onUpdateColumn(index, {
        ...column,
        title: value,
      });
    },
    [index, column, onUpdateColumn]
  );
  const handleChangeType = useCallback(
    (value: TableColumnType) => {
      switch (value) {
        case "text":
          onUpdateColumn(index, {
            uuid: column.uuid,
            title: column.title,
            disallow:
              column.type !== "fixed"
                ? column.disallow
                : { employee: { reply: false }, manager: { reply: true } },
            type: "text",
          });
          break;
        case "fixed":
          const values = new Array(rowsCount).fill("");
          onUpdateColumn(index, {
            uuid: column.uuid,
            title: column.title,
            disallow: { employee: { reply: true }, manager: { reply: true } },
            type: "fixed",
            values: values,
          });
          break;
        case "dropdown":
          onUpdateColumn(index, {
            uuid: column.uuid,
            title: column.title,
            disallow:
              column.type !== "fixed"
                ? column.disallow
                : { employee: { reply: false }, manager: { reply: true } },
            type: "dropdown",
            choices: [],
          });
          break;
      }
    },
    [index, column, rowsCount, onUpdateColumn]
  );

  // FIXED COLUMN
  const handleChangeColumnFixedValue = useCallback(
    (value: string, valueIndex: number) => {
      if (column.type !== "fixed") {
        return;
      }
      const newValues = [...column.values];
      newValues[valueIndex] = value;
      onUpdateColumn(index, {
        ...column,
        values: newValues,
      });
    },
    [index, column, onUpdateColumn]
  );

  // DROPDOWN COLUMN
  const handleChangeColumnDropdownChoice = useCallback(
    (value: string, choiceIndex: number) => {
      if (column.type !== "dropdown") {
        return;
      }
      const newChoices = [...column.choices];
      newChoices[choiceIndex] = value;
      onUpdateColumn(index, {
        ...column,
        choices: newChoices,
      });
    },
    [index, column, onUpdateColumn]
  );
  const handleRemoveColumnDropdownChoice = useCallback(
    (choiceIndex: number) => {
      if (column.type !== "dropdown") {
        return;
      }
      const newChoices = [...column.choices];
      newChoices.splice(choiceIndex, 1);
      onUpdateColumn(index, {
        ...column,
        choices: newChoices,
      });
    },
    [index, column, onUpdateColumn]
  );
  const handleAddColumnDropdownChoice = useCallback(
    (value: string) => {
      if (column.type !== "dropdown") {
        return;
      }
      const newChoices = column.choices.concat(value);
      onUpdateColumn(index, {
        ...column,
        choices: newChoices,
      });
    },
    [index, column, onUpdateColumn]
  );

  // PERMISSIONS
  const handleSetColumnPermissionReply = useCallback(
    (disallow: TableColumnDisallow) => {
      onUpdateColumn(index, {
        ...column,
        disallow: disallow,
      });
    },
    [index, column, onUpdateColumn]
  );

  const handleDeleteColumn = useCallback(() => {
    onDeleteColumn(index);
  }, [index, onDeleteColumn]);

  return (
    <Divided key={column.uuid}>
      <div className={styles.table__column__content}>
        <div className={styles.table__column__content__header}>
          <div>{`Colonne ${index + 1}`}</div>
          <div className={styles.table__column__content__header__buttons}>
            <DSTooltip
              /* [CSB] changes disabled */
              label="Cet élément ne peut pas être modifié sur une campagne en cours."
            >
              <DSButton
                label={"Supprimer une colonne"}
                buttonSize="S"
                emphasis="Mid"
                onClick={handleDeleteColumn}
                disabled // [CSB] changes disabled
              />
              {/* <DSTooltip label="Déplacer la colonne vers le haut" withPortal> [CSB] changes disabled */}
              <DSButton
                // disabled={disabledMoveUp} [CSB] changes disabled
                disabled // [CSB] changes disabled
                icon={<ArrowUp />}
                iconOnly
                buttonSize="S"
                emphasis="Mid"
                onClick={() => onMoveColumnUpOrDown(index, "up")}
              />
              {/* </DSTooltip> */}
              {/* <DSTooltip label="Déplacer la colonne vers le bas" withPortal> [CSB] changes disabled */}
              <DSButton
                // disabled={disabledMoveDown} [CSB] changes disabled
                disabled // [CSB] changes disabled
                icon={<ArrowDown />}
                iconOnly
                buttonSize="S"
                emphasis="Mid"
                onClick={() => onMoveColumnUpOrDown(index, "down")}
              />
              {/* </DSTooltip> */}
            </DSTooltip>
          </div>
        </div>
        <Flex column className={styles.table__column__content__form}>
          <DSTooltip
            /* [CSB] changes disabled */
            label="Cet élément ne peut pas être modifié sur une campagne en cours."
            direction="top"
          >
            <BuilderFormInput
              type="text"
              value={column.title}
              onChange={handleChangeTitle}
              label="Libellé de la colonne"
              name={`child-${childId}-column-${column.uuid}-label`}
              placeholder="Saisissez le libellé du tableau"
              disabled // [CSB] changes disabled
            />
          </DSTooltip>
          <DSFormGroupTextInput label="Type des cellules de la colonne" required>
            <DSTooltip
              /* [CSB] changes disabled */
              label="Cet élément ne peut pas être modifié sur une campagne en cours."
              direction="top"
              className={styles.table__column__content__form__select__tooltip}
            >
              <Select
                onChange={handleChangeType}
                options={COLUMN_TYPES_OPTIONS}
                value={column.type}
                defaultValue={{ value: column.type, label: COLUMN_TYPES_LABELS[column.type] }}
                disabled // [CSB] changes disabled
                className={styles.table__column__content__form__select} // [CSB] changes disabled
              />
            </DSTooltip>
          </DSFormGroupTextInput>
          {column.type === "fixed" &&
            column.values.map((value, valueIndex) => (
              <DSTooltip
                /* [CSB] changes disabled */
                label="Cet élément ne peut pas être modifié sur une campagne en cours."
                direction="top"
              >
                <div key={valueIndex}>
                  <BuilderFormInput
                    type="text"
                    value={value ? value.toString() : ""}
                    onChange={(newValue) => handleChangeColumnFixedValue(newValue, valueIndex)}
                    debounceValue={100}
                    label={`Contenu de la ligne ${valueIndex + 1}`}
                    name={`child-${childId}-column-${column.uuid}-value-${valueIndex}`}
                    placeholder={`Saisissez le contenu de la ligne ${valueIndex + 1}`}
                    disabled // [CSB] changes disabled
                  />
                </div>
              </DSTooltip>
            ))}
          {column.type === "dropdown" && (
            <Flex column className={styles["table__column--dropdown__choices"]}>
              <DSLabel label="Choix possibles" required />
              {column.choices.map((choice, choiceIndex, arr) => (
                <DSTooltip
                  /* [CSB] changes disabled */
                  label="Cet élément ne peut pas être modifié sur une campagne en cours."
                  direction="top"
                >
                  <Flex
                    row
                    className={styles["table__column--dropdown__choices__item"]}
                    key={`${choiceIndex}-${arr.length}`}
                  >
                    <BuilderFormInput
                      type="text"
                      value={choice}
                      onChange={(newChoice) =>
                        handleChangeColumnDropdownChoice(newChoice, choiceIndex)
                      }
                      debounceValue={300}
                      name={`child-${childId}-column-${column.uuid}-choice-${choiceIndex}-label`}
                      placeholder={`Libellé du choix ${choiceIndex + 1}`}
                      inputClassName={styles["table__column--dropdown__choices__item__input"]}
                      disabled // [CSB] changes disabled
                    />
                    <div
                      className={styles["table__column--dropdown__choices__item__deleteButton"]}
                      aria-label="Supprimer ce choix"
                    >
                      <DSButton
                        iconOnly
                        icon={<Delete />}
                        tooltip={"Supprimer le choix"}
                        tooltipDirection="left"
                        onClick={() => handleRemoveColumnDropdownChoice(choiceIndex)}
                        disabled // [CSB] changes disabled
                      />
                    </div>
                  </Flex>
                </DSTooltip>
              ))}
              <DSButton
                label={"Ajouter un choix possible"}
                onClick={() => handleAddColumnDropdownChoice("")}
                buttonSize="S"
                emphasis="Mid"
                disabled // [CSB] changes disabled
                tooltip="Cet élément ne peut pas être modifié sur une campagne en cours." // [CSB] changes disabled
              />
            </Flex>
          )}
        </Flex>
      </div>
      <TableChildColumnPermissions
        childId={childId}
        column={column}
        index={index}
        onUpdatePermissions={handleSetColumnPermissionReply}
      />
    </Divided>
  );
};
