import { ReactNode, RefObject } from "react";
import { MdCheck } from "react-icons/md";

import { utf8ToBase64 } from "helpers/functions";
import { HighchartsReactRefObject } from "highcharts-react-official";

import styles from "./menu.module.scss";

/**
 * Handles copying the chart as a PNG image to the clipboard and displays a success message in the dropdown.
 *
 * @param graphRef - Reference to the Highcharts graph.
 * @param setCopyText - State setter for updating the copy text.
 * @param successMessage - The message to display upon successful copy.
 * @param initialCopyText - The initial text to display for the copy action.
 */
export const handleCopy = async (
  graphRef: RefObject<HighchartsReactRefObject>,
  setCopyText: React.Dispatch<React.SetStateAction<ReactNode>>,
  successMessage: ReactNode,
  initialCopyText: ReactNode
) => {
  try {
    if (!graphRef.current || !graphRef.current.chart) {
      return;
    }

    const chart = graphRef.current.chart;
    const svg = chart.getSVG();
    const svgBase64 = utf8ToBase64(svg);
    const imgSrc = `data:image/svg+xml;base64,${svgBase64}`;

    const img = new Image();
    img.src = imgSrc;

    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    const canvas = document.createElement("canvas");
    canvas.width = chart.chartWidth;
    canvas.height = chart.chartHeight;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return;
    }
    ctx.drawImage(img, 0, 0);

    const pngBlob = await new Promise<Blob>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Canvas is empty."));
        }
      }, "image/png");
    });

    await navigator.clipboard.write([
      new ClipboardItem({
        "image/png": pngBlob,
      }),
    ]);

    setCopyText(
      <div className={styles.copyText}>
        <MdCheck color="green" className={styles.checkIcon} />
        {successMessage}
      </div>
    );
  } finally {
    setTimeout(() => setCopyText(initialCopyText), 3000);
  }
};
