import { MdEdit as Edit } from "react-icons/md";
import { AssistiveArea, DSButton, DSTextInput, DSFormGroupTextInput } from "@skillup/ui";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useReferenceValidation } from "services/sessions/useReferenceValidation";

import styles from "./ReferenceInput.module.scss";

interface Props {
  reference?: string;
  onChange: (reference: string) => void;
  programRef?: string;
  sessionDate?: string;
  sessionUuid?: string;
}

type EditionState =
  | { state: "inference" }
  | { state: "custom-display" }
  | { state: "custom-edit"; reference: string };

export function ReferenceInput(props: Props) {
  const { onChange, reference: parentRef } = props;
  const [edition, setEdition] = useState<EditionState>(
    props.reference ? { state: "custom-display" } : { state: "inference" }
  );

  const reference = useMemo(() => {
    if (edition.state === "inference") {
      return generateReference(props.programRef, props.sessionDate);
    }

    if (edition.state === "custom-display") {
      return props.reference;
    }

    return edition.reference;
  }, [edition, props.programRef, props.sessionDate, props.reference]);

  useEffect(() => {
    if (edition.state === "inference") {
      const refIdentical = compareRefsRoots(reference, parentRef);
      if (!refIdentical && reference != null) {
        onChange(reference);
      }
    }
  }, [edition, onChange, reference, parentRef]);

  const handleChange = useCallback(
    (newReference: string) => {
      props.onChange(newReference);
      setEdition({ state: "custom-display" });
    },
    [props]
  );

  if (edition.state === "inference") {
    return (
      <>
        <div className={styles.displayReference}>
          <p>{reference}</p>
          <DSButton
            iconOnly
            onClick={() => setEdition({ state: "custom-edit", reference })}
            icon={<Edit />}
            buttonSize="S"
          />
        </div>
        <p className={styles.assistive}>
          Cet identifiant unique est généré à la création de la session
        </p>
      </>
    );
  }

  if (edition.state === "custom-display") {
    return (
      <>
        <div className={styles.displayReference}>
          <p>{reference}</p>
          <DSButton
            iconOnly
            onClick={() => setEdition({ state: "custom-edit", reference })}
            icon={<Edit />}
            buttonSize="S"
          />
        </div>
        <p className={styles.assistive}>
          Cet identifiant unique est généré à la création de la session
        </p>
      </>
    );
  }

  return (
    <ReferenceEditor
      sessionUuid={props.sessionUuid}
      reference={reference}
      handleChange={handleChange}
    />
  );
}

function compareRefsRoots(ref1: string, ref2: string) {
  if (!ref1 || !ref2) {
    return false;
  }
  const ref1Root = ref1.split("-").slice(0, 2).join("-");
  const ref2Root = ref2.split("-").slice(0, 2).join("-");
  return ref1Root === ref2Root;
}

function ReferenceEditor({
  reference,
  handleChange,
  sessionUuid,
}: {
  reference: string;
  handleChange: (newReference: string) => void;
  sessionUuid?: string;
}) {
  const [editionInput, setEditionInput] = useState<string>(reference);
  const referenceValidation = useReferenceValidation(editionInput, sessionUuid);

  const assistiveArea = useMemo(() => {
    if (referenceValidation.isLoading) {
      return <AssistiveArea mode="default" text="Vérification en cours..." />;
    }

    if (referenceValidation.isError) {
      return <AssistiveArea mode="error" text="Une erreur est survenue" />;
    }

    if (referenceValidation.data.isValid === false) {
      return (
        <AssistiveArea mode="error" text={getAssistiveText(referenceValidation.data.because)} />
      );
    }

    return <AssistiveArea mode="default" text="La référence doit être unique" />;
  }, [referenceValidation.isError, referenceValidation.data, referenceValidation.isLoading]);

  return (
    <DSFormGroupTextInput label="ID de la session" required>
      <>
        <DSTextInput
          name="reference"
          value={editionInput}
          onChange={(newRef) => setEditionInput(newRef)}
          actionButton={
            <DSButton
              label="Enregistrer"
              disabled={
                referenceValidation.isLoading ||
                referenceValidation.isError ||
                !referenceValidation.data.isValid
              }
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleChange(editionInput);
              }}
            />
          }
        />
        {assistiveArea}
      </>
    </DSFormGroupTextInput>
  );
}

function generateReference(programRef?: string, sessionDate: string = new Date().toISOString()) {
  if (!programRef) {
    return undefined;
  }

  const year = new Date(sessionDate).getFullYear().toString().slice(2);
  const randomNumber = generateRandomNumberBetween0And999AsStringWith3Digits();

  let reference = programRef;
  if (programRef.length > 15) {
    reference = reference.slice(0, 15);
  }

  return `${year}-${reference}-${randomNumber}`;
}

export function generateRandomNumberBetween0And999AsStringWith3Digits() {
  return `${Math.floor(Math.random() * 1000)}`.padStart(3, "0");
}

function getAssistiveText(because: string) {
  switch (because) {
    case "EMPTY":
      return "Veuillez renseigner un ID de session";
    case "ALREADY_USED":
      return "Cet ID de session existe déjà. Veuillez saisir une nouvelle valeur.";
    case "TOO_SHORT":
      return "L’ID de session doit contenir au moins 4 caractères";
    case "TOO_LONG":
      return "L’ID de session est limité à 22 caractères";
    case "UNKNOWN":
    default:
      return "Une erreur est survenue";
  }
}
