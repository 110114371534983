import { useState } from "react";
import { useTranslation } from "react-i18next";

import { MdSearch as Search } from "react-icons/md";

import { DSModal, DSTextInput } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";

import { ProcessedSkill } from "types/skills";
import { sortString, toUpperCaseFirstChar } from "helpers/functions";

import { FormatSkillArray } from "../helper";
import { Modal, Checkbox, FormLabel } from "../JobForm.styled";

import styles from "./AddSkillModal.module.scss";

interface AddSkillModalProps {
  onClose: () => void;
  skills: ProcessedSkill[];
  skillAlreadyAdded: Array<string>;
  onConfirm: (e: ProcessedSkill[]) => void;
}

interface SelectedSkills {
  [skillUuid: string]: boolean;
}

const AddSkillModal = ({ onClose, onConfirm, skillAlreadyAdded, skills }: AddSkillModalProps) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedSkills, setSelectedSkills] = useState<SelectedSkills>({});

  const handleSkillChange = (skillUuid: string) => (isChecked: boolean) => {
    setSelectedSkills((prev) => ({ ...prev, [skillUuid]: isChecked }));
  };

  const handleSelection = () => {
    const skillsAdded = Object.keys(selectedSkills).filter(
      (skillUuid) => selectedSkills[skillUuid]
    );
    onConfirm(skills.filter((skill) => skillsAdded.includes(skill.uuid)));
  };

  const pendingSkills = skills
    .filter((skill) => !skillAlreadyAdded.includes(skill.uuid))
    .filter((skill) => skill.name.toLowerCase().includes(searchQuery?.toLowerCase()));

  const filteredSkills = FormatSkillArray(
    pendingSkills,
    t("jobs.addSkillsModal.noCategoryLabel", {
      defaultValue: "Compétences sans catégorie",
    })
  );

  const numberOfFilteredSkills = filteredSkills.reduce((acc, curr) => acc + curr.value.length, 0);

  const formatedSkills = filteredSkills
    .map((e) => ({
      name: e.key,
      skills: e.value
        .map((skill) => ({
          uuid: skill.uuid,
          name: skill.name,
        }))
        .sort(sortString),
    }))
    .sort(sortString);

  return (
    <>
      <Modal isOpen>
        <DSModal.Header onClose={onClose}>
          <DSModal.Header.Title
            title={t("jobs.addSkillsModal.title", {
              defaultValue: "Ajouter des compétences",
            })}
          />
        </DSModal.Header>
        <DSModal.Content>
          <Flex marginTop="xs" flexDirection="row" className={styles.modalSearch}>
            <DSTextInput
              name="searchSkill"
              value={searchQuery}
              actionButton={<Search />}
              className={styles.searchInput}
              onChange={setSearchQuery}
              placeholder={t("skills.archivedModal.searchPlaceholder", {
                defaultValue: "Rechercher une compétence",
              })}
            />
          </Flex>
          <Flex paddingTop="xxs" flexDirection="column">
            {searchQuery === "" || numberOfFilteredSkills > 0 ? (
              formatedSkills.map((category) => (
                <Flex key={category.name} flexDirection="column">
                  <FormLabel label={category.name} />
                  {category.skills.map((skill) => (
                    <Flex marginLeft="s" key={skill.uuid} marginBottom="s">
                      <Checkbox
                        checked={!!selectedSkills[skill.uuid]}
                        label={toUpperCaseFirstChar(skill.name)}
                        onChange={(isChecked) => handleSkillChange(skill.uuid)(isChecked)}
                      />
                    </Flex>
                  ))}
                </Flex>
              ))
            ) : (
              <Text marginBottom="s" espaceFont="body1Regular">
                {t("skills.jobSkillsModal.noResult", {
                  defaultValue: "Aucune compétence ne correspond à votre saisie",
                })}
              </Text>
            )}
          </Flex>
        </DSModal.Content>
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            onClick={onClose}
            label={t("skills.archivedModal.buttonCancel", {
              defaultValue: "Annuler",
            })}
          />
          <DSModal.Footer.PrimaryButton
            disabled={Object.keys(selectedSkills).every((skillUuid) => !selectedSkills[skillUuid])}
            onClick={handleSelection}
            label={t("jobs.addSkillsModal.buttonConfirm", {
              defaultValue: "Valider la sélection",
            })}
          />
        </DSModal.Footer>
      </Modal>
    </>
  );
};

export default AddSkillModal;
