import { Either } from "@skillup/monads";
import { ReviewRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export async function changeManager({
  campaignID,
  managerID,
  reviewIDs,
}: {
  managerID: string;
  campaignID: string;
  reviewIDs: string[];
}) {
  try {
    await buildRequest<ReviewRoutes.ChangeManager>({
      method: "POST",
      path: "/review/change-manager",
      payload: {
        campaignID,
        managerID,
        reviewIDs,
      },
      target: "PEOPLE_REVIEW",
    })();
    return Either.right(undefined);
  } catch (err) {
    return Either.left(err);
  }
}
