import { Suspense } from "react";

import { Flex, Loader } from "@skillup/design-system";

import { CollaboratorTable } from "./components/CollaboratorTable";

const SupervisorUserView = () => {
  return (
    <Flex width="100%" height="100%" flexDirection="column">
      <Suspense fallback={<Loader fillSpace />}>
        <CollaboratorTable />
      </Suspense>
    </Flex>
  );
};

export default SupervisorUserView;
