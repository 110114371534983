import { useCallback, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid-pro";
import { DSDataGrid, DSDropdownItem, DSFilters, FilterRef, useFilters } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { FormatDate, ListUtils, ParseDate } from "@skillup/shared-utils";
import { FieldRoutesType, TrainingRoutes } from "@skillup/espace-rh-bridge";

import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import DSLayout from "components/DSLayout";

import { useAccessCheckFor } from "hooks/useSettings";
import useTranslation from "hooks/useTranslation";

import { generateColumns, displayRows, initialState } from "./utils/table";
import DeleteIntraModal from "./components/DeleteIntraModal";
import { TrainingIntra } from "./types";

import styles from "./IntraListView.module.scss";
import { useTypedFetch } from "hooks";
import { getFilterConfig } from "./utils/filters";
import Loader from "components/Loader";

export type ModalState = {
  isOpen: boolean;
  selectedRow?: TrainingIntra;
};

const IntraListView = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const filterRef = useRef<FilterRef>();

  const [sortOptions, setSortOptions] = useState<ListUtils.SortingProperties>({
    property: "name",
    direction: "ASC",
  });
  const handleTableColumnFilterClick = (col: GridColDef) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field === "createdAt" ? "rawCreatedAt" : col.field);
    }
  };
  const handleTableColumnSortClick = (property: string, direction?: "ASC" | "DESC") => {
    setSortOptions({
      property: property ?? "name",
      direction: direction ?? "ASC",
    });
  };

  const [modalState, setModalState] = useState<ModalState>({ isOpen: false });

  const canUseIntra = useAccessCheckFor("trainings-intras-organization");
  const canUseCatalog = useAccessCheckFor("trainings-intras-skillup");
  const canUseFieldsInIntra = !useAccessCheckFor("custom-training-fields");

  const { data: rawRows, loading: trainingIsLoading } = useTypedFetch<TrainingRoutes.Get>({
    path: "/training",
    method: "GET",
    query: {},
  });

  const { data: fields, loading: fieldIsLoading } = useTypedFetch<FieldRoutesType.GetV2>({
    path: "/fields-v2",
    method: "GET",
    query: {},
  });

  const isLoading = trainingIsLoading || fieldIsLoading;

  const trainingFields = useMemo(() => {
    return fields?.filter((f) => Boolean(f.isTrainingField) && !f.isHidden);
  }, [fields]);

  const rows: TrainingIntra[] = useMemo(() => {
    if (!rawRows) {
      return [];
    }

    return rawRows.map((row) => {
      return {
        ...row,
        createdAt: new Date(row.createdAt),
        rawCreatedAt: FormatDate.ToStringFormat(
          ParseDate.FromParsableJS(row.createdAt),
          FormatDate.getDayMonthYearFormat()
        ),
        fieldValues: Object.entries(row.fields).reduce((acc, [binding, field]) => {
          if (field.type === "date") {
            acc[binding] = FormatDate.ToStringFormat(
              ParseDate.FromParsableJS(new Date(field.value)),
              FormatDate.getDayMonthYearFormat()
            );
          } else {
            acc[binding] = field.value;
          }

          return acc;
        }, {}),
        trainingNameOrReference: `${row.name} ${row.reference}`,
      };
    });
  }, [rawRows]);

  const [filterConfig, filterProps] = useMemo(() => {
    return getFilterConfig(trainingFields, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingFields]);

  const [filters, filterValues, setFilters] = useFilters(filterConfig, filterProps);

  const columns = useMemo(() => {
    return generateColumns({
      t,
      history,
      setModalState,
      fields: trainingFields ?? [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingFields]);

  const displayedRows = useMemo(() => {
    return displayRows(rows, trainingFields ?? [], filterConfig, filterValues, sortOptions);
  }, [rows, trainingFields, filterConfig, filterValues, sortOptions]);

  const layouts = useMemo(() => {
    const items = [];

    if (canUseIntra) {
      items.push({
        tab: {
          id: "gestion-intras",
          label: t("trainings.view.training_list.property.tab_intra_management", {
            defaultValue: "Catalogue entreprise",
          }),
          url: "/responsable/programmes/gestion-intras",
        },
        primaryButton: (
          <DSNewHeaderButton
            label={t("trainings.view.training_list.action.create_intra", {
              defaultValue: "Créer un intra",
            })}
            onClick={() => history.push("/responsable/programmes/gestion-intras/creation")}
          />
        ),
        dropdownContent: canUseFieldsInIntra
          ? [
              <DSDropdownItem
                key="export-trainings"
                onClick={() => {
                  window.open("/public-api/v1/training/export", "_blank");
                }}
                label={t("trainings.view.training_list.action.export_intra_list", {
                  defaultValue: "Exporter le catalogue entreprise",
                })}
              />,
            ]
          : undefined,
      });
    }

    if (canUseCatalog) {
      items.push({
        tab: {
          id: "catalogue",
          label: t("trainings.view.training_list.property.tab_skillup_catalog", {
            defaultValue: "Catalogue Skillup",
          }),
          url: "/responsable/programmes/catalogue",
        },
        primaryButton: null,
      });
    }

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUseIntra, canUseCatalog, canUseFieldsInIntra]);

  const handleOnClickRow = useCallback(
    ({ row }: { row: TrainingIntra }) => {
      history.push(`/responsable/programmes/gestion-intras/${row.uuid}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <DSLayout
      title={t("trainings.view.training_list.property.title", {
        defaultValue: "Programmes",
      })}
      activeLayout="gestion-intras"
      layouts={layouts}
      className={styles.layout}
    >
      <Flex gap="xs" flexDirection="column" className={styles.container}>
        {trainingFields ? (
          <DSFilters
            ref={filterRef}
            t={t}
            config={filterConfig}
            translationPrefix="supervisor.intra"
            filters={filters}
            onChange={setFilters}
          />
        ) : (
          <Loader />
        )}
        <div>
          <DSDataGrid
            pagination
            initialState={initialState}
            columns={columns}
            rows={displayedRows}
            loading={isLoading}
            getRowId={(row) => row.uuid}
            sorting={sortOptions}
            className={styles.dataGrid}
            onSort={handleTableColumnSortClick}
            onFilter={handleTableColumnFilterClick}
            onRowClick={handleOnClickRow}
            translationPrefix="supervisor.intra"
            entityName={t("supervisor.intra", {
              defaultValue: "programmes",
              count: displayedRows.length,
            })}
            getRowHeight={() => 58}
          />
        </div>
      </Flex>

      <DeleteIntraModal modalState={modalState} setModalState={setModalState} />
    </DSLayout>
  );
};

export default IntraListView;
