import { useQuery } from "@tanstack/react-query";

import { Modules } from "@skillup/espace-rh-bridge";
import type { UserRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import { OrganizationRoutesType } from "types/api";
import { useMemo } from "react";

export const ModulesLabel = {
  [Modules.TrainingDashboard]: "Dashboards - Formation",
  [Modules.InterviewDashboard]: "Dashboards - Entretiens",

  [Modules.TrainingsSchedulePlan]: "Formation - Recueil des besoins",
  [Modules.TrainingsScheduleCollection]: "Formation - Plan de formation",

  [Modules.TrainingsRegulatoryHabilitations]: "Formation - Règlementaire - Habilitations",
  [Modules.TrainingsRegulatoryTrackings]: "Formation - Règlementaire - Suivi par collaborateur",
  [Modules.TrainingsIntrasOrganization]: "Formation - Programmes - Entreprise",
  [Modules.TrainingsIntrasSkillup]: "Formation - Programmes - Skillup",
  [Modules.TrainingsSessions]: "Formation - Sessions",
  [Modules.TrainingHotEvaluations]: "Formation - Évaluations - à chaud",
  [Modules.TrainingColdEvaluations]: "Formation - Évaluations - à froid",

  [Modules.InterviewsTrackings]: "Entretiens - Suivi des entretiens",
  [Modules.InterviewsTemplates]: "Entretiens - Trames d'entretien",

  [Modules.SkillsJobs]: "Compétences - Fiches de poste",
  [Modules.SkillsRequired]: "Compétences - Compétences",
  [Modules.SkillsEmployees]: "Compétences - Collaborateurs",

  [Modules.Employees]: "Collaborateurs",

  [Modules.ConfigurationAdminPlatform]: "Configuration - Administration de la plateforme",
  [Modules.ConfigurationPlans]: "Configuration - Plans",
  [Modules.ConfigurationPortail]: "Configuration - Portail",
  [Modules.ConfigurationImports]: "Configuration - [Data] Imports",

  [Modules.CampaignCreation]: "Création de campagne",

  [Modules.PeopleReview]: "People Review",

  [Modules.Users]: "Utilisateurs",
  [Modules.MyAccount]: "Mon Compte",
};

export type ModuleType = `${Modules}`;

export type ISettings = OrganizationRoutesType.GetSettings["response"];
export type IModule = UserRoutes.GetModules["response"];
export type IUserSettings = {
  settings: ISettings;
  userAccessModules: IModule;
  loading: boolean;
};

const minute = 1000 * 60;
const useSettings = (): IUserSettings => {
  const settings = useQuery(
    ["settings&Modules"],
    async () => {
      const organizationRequest = buildRequest<OrganizationRoutesType.GetSettings>({
        method: "GET",
        path: "/organization/settings",
      });

      const userModulesRequest = buildRequest<UserRoutes.GetModules>({
        method: "GET",
        path: "/user/modules",
      });

      const requestResponse = await Promise.all([organizationRequest(), userModulesRequest()]);

      return {
        settings: requestResponse[0],
        userModules: requestResponse[1],
      };
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: minute,
    }
  );

  return {
    settings: settings?.data?.settings,
    userAccessModules: settings?.data?.userModules,
    loading: settings.isLoading,
  };
};

export default useSettings;

function getAccess(settings: ISettings, userAccessRights: ModuleType[]) {
  return {
    to: function (module: ModuleType): boolean {
      return settings?.features?.includes(module) && userAccessRights.includes(module);
    },
  };
}

/**
 * @deprecated Use `useAccessCheckFor` and `useAccessCheckForList` instead
 */
export function createUserAccessChecker(settings: ISettings, userAccessRights: ModuleType[]) {
  const hasAccess = getAccess(settings, userAccessRights);

  return {
    toTrainingDashboard: () => hasAccess.to(Modules.TrainingDashboard),
    toInterviewDashboard: () => hasAccess.to(Modules.InterviewDashboard),
    toTrainings: () => hasAccess.to(Modules.Trainings),
    toInterviews: () => hasAccess.to(Modules.Interviews),
    toSkills: () => hasAccess.to(Modules.Skills),
    toRegulatory: () => hasAccess.to(Modules.Regulatory),
    get toCampaignCreation() {
      return hasAccess.to(Modules.CampaignCreation);
    },
  };
}

/**
 *
 * @param module Any module you want to check access for
 * @returns a boolean indicating if the user has access to the module
 * @example
 * const canAccess = useAccessCheckFor("reglementaire");
 * if (!canAccess) return <Redirect to="/404" />;
 */
export function useAccessCheckFor(module: ModuleType) {
  const { settings, userAccessModules } = useSettings();
  const access = useMemo(
    () => getAccess(settings, userAccessModules).to(module),
    [settings, userAccessModules, module]
  );

  return access;
}

/**
 *
 * @param modules An array of modules you want to check access for
 * @return an object with the module as key and a boolean indicating if the user has access to the module
 * @example
 * const canAccess = useAccessCheckForList(["competences", "reglementaire", "interviews-dashboard"]);
 * if (!canAccess.competences) return <Redirect to="/404" />;
 * if (!canAccess.reglementaire) return <Redirect to="/404" />;
 * if (!canAccess["interviews-dashboard"]) return <Redirect to="/404" />;
 * // ... do something now that you know the user has access to those modules
 */
export function useAccessCheckForList<T extends ModuleType>(modules: T[]) {
  const { settings, userAccessModules } = useSettings();
  const accessibleModules = getAccess(settings, userAccessModules);

  const access = useMemo(
    () =>
      modules.reduce((acc, module) => {
        return {
          ...acc,
          [module]: accessibleModules.to(module),
        };
      }, {}) as {
        [key in T]: boolean;
      },
    [accessibleModules, modules]
  );

  return access;
}
