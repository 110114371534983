import { Route, Switch, Redirect } from "react-router-dom";
import { BringUpNeedsModal } from "./components";

export const TrainingsWorkflowModals = () => {
  return (
    <Switch>
      <Route
        exact
        component={BringUpNeedsModal}
        path={TrainingsWorkflowModalsRoutes.BringupNeeds}
      />
      <Redirect to={TrainingsWorkflowModalsRoutes.BringupNeeds} />
    </Switch>
  );
};

export const BaseUrl = "/responsable/modals" as const;
export const TrainingsWorkflowModalsRoutes = {
  BaseUrl,
  BringupNeeds: `${BaseUrl}/remontee-besoin-manager`,
} as const;
