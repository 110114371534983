import { useState } from "react";

import { useQuery, useMutation } from "@tanstack/react-query";

import { ListUtils } from "@skillup/shared-utils";
import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { Field } from "services/coreHR";
import { getCampaignMatrix } from "services/peopleReview/campaign/getCampaignMatrix";

import { Campaign, FiltersConfig } from "../../types";

type MatrixData = CampaignsRoutes.GetMatrix["response"];

type Options = {
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
};

const defaultOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

type UseMatrixDataParams = {
  campaign: Campaign;
  filters: ListUtils.FilterValues<FiltersConfig>;
  scalesMap: Record<string, NonNullable<Field["scale"]>>;
};

const useMatrixData = (
  { campaign, filters, scalesMap }: UseMatrixDataParams,
  options: Options = defaultOptions
) => {
  const [axis, setAxis] = useState<{
    x: NonNullable<Field["scale"]>;
    y: NonNullable<Field["scale"]>;
  }>(() => {
    const [first, second] = campaign.scales;
    return { x: scalesMap[first.id], y: scalesMap[second.id] };
  });

  const { data, isFetching, isLoading } = useQuery(
    ["matrix", campaign.id, filters, axis.x, axis.y],
    () => {
      return getCampaignMatrix({
        campaignID: campaign.id,
        filters,
        xAxisScaleID: axis.x.id,
        yAxisScaleID: axis.y.id,
      });
    },
    {
      ...options,
      /**
       * To avoid a flash when changing the query key (like if a filter change occurs)
       * We keep the previously loaded data and therefore we have a smoother transition
       */
      keepPreviousData: true,
    }
  );

  const swapMutations = useMutation({
    mutationFn: async ({
      newXAxisScaleID,
      newYAxisScaleID,
    }: {
      newXAxisScaleID: string;
      newYAxisScaleID: string;
    }) => {
      setAxis({
        x: scalesMap[newXAxisScaleID],
        y: scalesMap[newYAxisScaleID],
      });
    },
  });

  return {
    data,
    isLoading: isLoading || isFetching,
    mutations: {
      swap: swapMutations.mutate,
    },
    xAxis: axis.x,
    yAxis: axis.y,
  };
};

export { useMatrixData, type MatrixData };
