import React, { useEffect } from "react";
import { generatePath, match } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { CampaignTemplate } from "@skillup/types";
import CreatableSelect from "react-select/creatable";
import { useRecoilState } from "recoil";
import { trim } from "lodash";

import { Button, DSDropdownItem, TextDropdown, TextInput } from "@skillup/ui";

import { IWithRouter } from "utils/types";
import DataLayer from "utils/DataLayer";
import Acta from "utils/Acta";
import { useFetch } from "hooks";

import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import Section from "./Section";
import useDragAndDrop from "./DragnDrop";
import PreviewModal from "./PreviewModal";

import type { InterviewType, Section as SectionType } from "./types";
import { templateState, relatedCompanyState } from "./store";
import PageSettings from "./PageSettings";
import styles from "./CreateNewTemplate.module.scss";
import { useTranslation } from "react-i18next";

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : undefined,
});

const getItemStyle = (draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

interface IProps extends IWithRouter {
  match: match<{
    templateUuid: string;
  }>;
  test?: boolean;
}

const saveTemplate = async (template: CampaignTemplate, relatedCompany: string) => {
  const { uuid: templateUuid, ...rest } = template;
  const data = JSON.stringify(rest);

  try {
    await DataLayer.request({
      method: "POST",
      url: `/v1/templates/interviews/${templateUuid}`,
      body: JSON.stringify({ data, relatedCompanyUuid: relatedCompany }),
    });
    Acta.dispatchEvent("sendAppMessage", {
      message: "Modifications sauvegardées avec succès !",
      type: "success",
    });
  } catch (err) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Erreur lors de la sauvegarde des modifications !",
      type: "error",
    });
  }
};

const showPreviewModal = (templateUuid: string) => {
  Acta.setState("modalDisplayed", {
    content: <PreviewModal templateUuid={templateUuid} />,
    size: "medium",
    title: "Prévisualisation",
  });
};

const CreateNewTemplate = (props: IProps) => {
  const {
    match: {
      params: { templateUuid },
    },
  } = props;

  const { data = { templates: [] } } = useFetch<{ templates: CampaignTemplate[] }>({
    url: "/v1/templates/interviews",
    method: "GET",
  });

  const [templateData, setTemplateData] = useRecoilState(templateState);
  const [relatedCompany, setRelatedCompany] = useRecoilState(relatedCompanyState);
  const templateDataUuid = templateData?.uuid;

  useEffect(() => {
    if (templateUuid !== templateDataUuid) {
      if (data?.templates) {
        const selectedTemplate = data.templates.find((t) => t.uuid === templateUuid);
        if (selectedTemplate) {
          setTemplateData(selectedTemplate);
          setRelatedCompany(selectedTemplate.companyUuid);
        }
      }
    }
  }, [data, setTemplateData, templateUuid, templateDataUuid, setRelatedCompany]);

  const addSection = () => {
    setTemplateData({
      ...templateData,
      sections: [
        ...templateData.sections,
        {
          pages: [],
          title: "Nouvelle section",
          uuid: uuid(),
        },
      ],
    });
  };

  const updateSection = (sectionIndex: number) => (newSectionData: SectionType) => {
    const newSections = [...templateData.sections];
    newSections[sectionIndex] = newSectionData;
    setTemplateData({
      ...templateData,
      sections: newSections,
    });
  };

  const deleteSection = (sectionIndex: number) => () => {
    const newSections = templateData.sections
      .slice(0, sectionIndex)
      .concat(templateData.sections.slice(sectionIndex + 1));

    setTemplateData({ ...templateData, sections: newSections });
  };

  const DragnDropHandler = useDragAndDrop();
  const onDragEnd = (result) => {
    const { draggableId } = result;

    if (draggableId.includes("section")) {
      DragnDropHandler.handleSectionDrop(result);
    }

    if (draggableId.includes("child-template")) {
      DragnDropHandler.handleChildTemplateDropInBuilder(result);
    }

    if (draggableId.includes("child-form")) {
      DragnDropHandler.handleChildDropInPage(result);
    }
  };

  const types: InterviewType[] = [
    "Autre",
    "Bilan à 6 ans",
    "Entretien annuel",
    "Entretien professionnel",
  ];

  const { t } = useTranslation();

  return (
    <DSLayout
      title={templateData?.title}
      parent={{
        title: t("interviews.interviewFrames", {
          defaultValue: "Trames d'entretiens"
        }),
        titleUrl: generatePath("/responsable/templates"),
      }}
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              label="Enregistrer"
              onClick={() => saveTemplate(templateData, relatedCompany)}
            />
          ),
          dropdownContent: templateData?.uuid
            ? [
                <DSDropdownItem
                  key="preview"
                  label="Prévisualiser"
                  onClick={() => showPreviewModal(templateData.uuid)}
                />,
                <DSDropdownItem
                  key="json"
                  label="JSON"
                  onClick={() => {
                    console.log(JSON.stringify(templateData, null, 2));
                  }}
                />,
              ]
            : undefined,
        },
      ]}
    >
      <div className={styles.createNewTemplate} aria-label="Écran générateur de templates">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={styles.structure}>
            <h2>Titre template: </h2>
            <TextInput
              key={templateData?.uuid}
              aria-label="Titre du template"
              value={templateData?.title}
              onChange={(newTitle) => setTemplateData({ ...templateData, title: newTitle })}
            />
            <h2>Type d'entretien: </h2>
            <TextDropdown
              options={types.map((type) => ({ value: type, label: type }))}
              selectedIndex={types.indexOf(templateData?.type)}
              small
              onSelect={(item) =>
                setTemplateData({ ...templateData, type: item.value as InterviewType })
              }
            />
            <h2>Catégories d'objectifs (optionnel)</h2>
            <CreatableSelect
              isMulti
              name="Catégories"
              options={(templateData?.targetsCategories ?? []).map((value) => ({
                value,
                label: value,
                selected: true,
              }))}
              value={(templateData?.targetsCategories ?? []).map((value) => ({
                value,
                label: value,
                selected: true,
              }))}
              onChange={(options) => {
                setTemplateData({
                  ...templateData,
                  targetsCategories: options?.map((option) => trim(option.value)),
                });
              }}
              className={styles.targetCategories}
            />
            <h2>Choix pour les objectifs qualitatifs</h2>
            <CreatableSelect
              isMulti
              name="Choix pour les objectifs qualitatifs"
              options={(templateData?.choicesForOrdinalTargets ?? []).map((value) => ({
                value,
                label: value,
                selected: true,
              }))}
              value={(templateData?.choicesForOrdinalTargets ?? []).map((value) => ({
                value,
                label: value,
                selected: true,
              }))}
              onChange={(options) => {
                setTemplateData({
                  ...templateData,
                  choicesForOrdinalTargets: options?.map((option) => trim(option.value)),
                });
              }}
              className={styles.targetCategories}
            />

            <Droppable droppableId="sectionsList" index={1}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className={styles.sectionsList}
                >
                  <h2>Sections:</h2>

                  {templateData?.sections?.map?.((section, sectionIndex) => (
                    <Draggable
                      key={`${section.uuid}-${sectionIndex}`}
                      draggableId={`section-${section.uuid}`}
                      index={sectionIndex}
                    >
                      {(provided) => (
                        <div
                          className={styles.item}
                          key={`${section.uuid}-${sectionIndex}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(provided.draggableProps.style)}
                        >
                          <Section
                            sectionIndex={sectionIndex}
                            section={section}
                            updateSection={updateSection(sectionIndex)}
                            deleteSection={deleteSection(sectionIndex)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Button
              aria-label="Ajouter une section"
              kind="secondary"
              className={styles.addSection}
              label="Ajouter une section"
              onClick={addSection}
            />
          </div>

          <PageSettings />
        </DragDropContext>
      </div>
    </DSLayout>
  );
};

export default CreateNewTemplate;
