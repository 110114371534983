import { useState } from "react";

import { useAreas, useEmployeeFields } from "hooks";

import { ListUtils } from "@skillup/shared-utils";
import { UseFilterProp, useUrlSyncedShortenedFilters } from "@skillup/ui";

export const useAssignmentsGridFilters = () => {
  const { allAreas } = useAreas();
  const { getFilters } = useEmployeeFields();

  const [{ conf, initialValues }] = useState({
    conf: {
      branch: getFilters().branch,
      division: getFilters().division,
      email: getFilters().email,
      firstName: getFilters().firstName,
      fullName: getFilters().fullName,
      jobName: { type: ListUtils.FilterType.TEXT },
      lastName: getFilters().lastName,
      perimeters: getFilters().perimeters,
      registrationNumber: getFilters().registrationNumber,
      role: getFilters().role,
      service: getFilters().service,
      site: getFilters().site,
    },
    initialValues: {
      fullName: { visibilityMode: "always" },
      perimeters: {
        initialValue: allAreas.map(({ uuid }) => uuid),
        options: allAreas.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
        visibilityMode: allAreas.length > 1 ? "always" : "never",
      },
    } as UseFilterProp<typeof conf>,
  });

  const [filters, filterValues, setFilterValues] = useUrlSyncedShortenedFilters(
    conf,
    initialValues
  );

  return [conf, filters, filterValues, setFilterValues] as [
    typeof conf,
    typeof filters,
    typeof filterValues,
    typeof setFilterValues
  ];
};
