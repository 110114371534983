import styled from "styled-components";

import { DSColor } from "@skillup/design-system";

export const StyledDiv = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  background-color: ${({ color }) => `${DSColor[color]}`};
  margin-left: auto;
`;

export const StyledTitleContainer = styled.div`
  width: 190px;
  padding-top: 0.5rem;
`;

export const StyledTextContainer = styled.div`
  flex: 1;
`
