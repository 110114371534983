import { useTranslation } from "react-i18next";

import { MdTipsAndUpdates as TipsAndUpdates } from "react-icons/md";

import { DSAlert, DSAlertType, DSAlertDisplay } from "@skillup/ui";

interface Props {
  numberOfSkills: number;
}

export const SkillGeneratedInformationPanel = ({ numberOfSkills }: Props) => {
  const { t } = useTranslation();
  return (
    <DSAlert type={DSAlertType.INFO_INLINE} display={DSAlertDisplay.INLINE}>
      {numberOfSkills === 1 ? (
        <>
          <div>
            {t("jobs.form.skillGeneratedByAI.info.singular", {
              defaultValue: "La compétence marquée par l’icône ",
            })}
            <TipsAndUpdates />
            {t("jobs.form.skillGeneratedByAI.info.singular2", {
              defaultValue:
                " ne figure pas encore dans votre référentiel. Elle sera ajoutée à votre référentiel à l’issue de la création de cette fiche de poste.",
            })}
          </div>
          <br />
          {t("jobs.form.skillGeneratedByAI.info.singular3", {
            defaultValue:
              "Si vous ne souhaitez pas qu’elle soit ajoutée, vous pouvez la supprimer à cette étape.",
          })}
        </>
      ) : (
        <>
          <div>
            {t("jobs.form.skillGeneratedByAI.info.plural", {
              defaultValue: "Les {{numberOfSkills}} compétences marquées par l’icône ",
              numberOfSkills,
            })}
            <TipsAndUpdates />
            {t("jobs.form.skillGeneratedByAI.info.plural2", {
              defaultValue:
                "ne figurent pas encore dans votre référentiel. Elles seront ajoutées à votre référentiel à l’issue de la création de cette fiche de poste",
            })}
          </div>
          <br />
          {t("jobs.form.skillGeneratedByAI.info.plural3", {
            defaultValue:
              "Si vous ne souhaitez pas qu’elles soient ajoutées, vous pouvez les supprimer à cette étape.",
          })}
        </>
      )}
    </DSAlert>
  );
};
