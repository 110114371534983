import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useMemo, useState, useContext, useCallback, createContext } from "react";

import { trpc } from "utils/trpc";
import { hasEmployeeV3Enabled } from "utils/User";
import {
  Employee,
  JobFromAPI,
  EmployeeJob,
  JobAnalytics,
  DatatableJob,
  PerimeterFilters,
  CreateJobPayload,
  UpdateJobPayload,
} from "types/skills";

type JobsContextType = {
  isLoading: boolean;
  jobListLoading: boolean;
  employeeV3Enabled: boolean;
  jobList: undefined | DatatableJob[];
  employeeList: undefined | Employee[];
  createJob: (payload: CreateJobPayload) => void;
  updateJob: (payload: UpdateJobPayload) => void;
  jobListStatus: "error" | "success" | "loading";
  employees: undefined | Array<Employee> | Array<EmployeeJob>;
  removeJob: (uuid: string, version: number) => Promise<void>;
  jobListError: ReturnType<typeof trpc.jobs.getAll.useQuery>["error"];
  setJobPrivacy: (job: { uuid: string; version: number }, isPrivate: boolean) => Promise<void>;
  archiveJob: (
    jobs: Array<{
      uuid: string;
      version: number;
    }>,
    isArchived: boolean
  ) => Promise<void>;
  getJob: (uuid: string | undefined) => {
    data: JobFromAPI;
    status: "error" | "loading" | "success";
    error: ReturnType<typeof trpc.jobs.getById.useQuery>["error"];
  };
  getJobAnalytics: (
    uuid: string,
    filters?: PerimeterFilters
  ) => {
    data: JobAnalytics;
    status: "error" | "loading" | "success";
    error: ReturnType<typeof trpc.analytics.getJobAnalytics.useQuery>["error"];
  };
  reAssignEmployeeToJobAndArchiveJob: (
    job: { uuid: string; version: number },
    employees: Array<{ version: number; employeeUuid: string }>,
    targetJob: {
      uuid: string;
      name: string;
    }
  ) => Promise<void>;
};

const JobsContext = createContext<JobsContextType>(null);

const useJobsContext = () => {
  const context = useContext(JobsContext);
  if (!context) {
    throw new Error("useJobsContext must be used within an JobsContext");
  }
  return context;
};

interface JobsProviderProps {
  children: React.ReactNode;
}

const JobsProvider = ({ children }: JobsProviderProps) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const history = useHistory();
  const utils = trpc.useUtils();
  const employeeV3Enabled = hasEmployeeV3Enabled();
  const [isMultipleArchived, setIsMultipleArchived] = useState(false);
  const {
    data: jobsData,
    error: jobListError,
    isLoading: jobListLoading,
    status: jobListStatus,
  } = trpc.jobs.getAll.useQuery();
  const { data } = trpc.employees.getEmployeesJobs.useQuery();
  const employeeList = data as unknown as undefined | Employee[];
  const jobList: undefined | DatatableJob[] = jobsData as unknown as undefined | DatatableJob[];

  const assignEmployeePayload = useMemo(
    () => ({
      onError: () => {
        addToast(
          t("portal.config.employee.assignEmployeeToJob.error", {
            defaultValue: "La fiche de poste n'a pas pu être assignée",
          }),
          {
            appearance: "error",
          }
        );
      },
      onSuccess: () => {
        addToast(
          t("portal.config.employee.assignEmployeeToJob.success", {
            defaultValue: "La fiche de poste a bien été assignée",
          }),
          {
            appearance: "success",
          }
        );
      },
    }),
    [addToast, t]
  );

  const removeJobPayload = useMemo(
    () => ({
      onError: () => {
        addToast(
          t("jobs.list.label.archive.error", {
            defaultValue: `La fiche de poste n'a pas pu être supprimée`,
          }),
          {
            appearance: "error",
          }
        );
      },
      onSuccess: () => {
        addToast(
          t("jobs.list.label.remove.success", {
            defaultValue: `La fiche de poste a été supprimée`,
          }),
          {
            appearance: "success",
          }
        );
      },
    }),
    [addToast, t]
  );

  const archiveJobPayload = useMemo(
    () => ({
      onError: () => {
        addToast(
          t("jobs.list.label.archive.error", {
            defaultValue: `La fiche de poste n'a pas pu être archivée ou désarchivée`,
          }),
          {
            appearance: "error",
          }
        );
      },
      onSuccess: (res) => {
        const isArchive = res?.isArchived;
        addToast(
          isMultipleArchived
            ? t("jobs.list.label.archiveMultiple.success", {
                defaultValue: "Les fiches de poste ont été archivées",
              })
            : t("jobs.list.label.archive.success", {
                defaultValue: `La fiche de poste a été ${isArchive ? "archivée" : "désarchivée"}`,
              }),
          {
            appearance: "success",
          }
        );
      },
    }),
    [addToast, isMultipleArchived, t]
  );

  const createJobPayload = useMemo(
    () => ({
      onError: (e) => {
        if (e.message === "name-already-taken") {
          addToast(
            t("portal.config.skills.createJob.nameAlreadyTaken", {
              defaultValue: "Une fiche de poste avec cet intitulé existe déjà",
            }),
            {
              appearance: "error",
            }
          );
        } else {
          addToast(
            t("portal.config.skills.createJob.error", {
              defaultValue: "La fiche de poste n'a pas pu être créée",
            }),
            {
              appearance: "error",
            }
          );
        }
      },
      onSuccess: () => {
        addToast(
          t("portal.config.skills.createJob.success", {
            defaultValue: "La fiche de poste a bien été créée",
          }),
          {
            appearance: "success",
          }
        );
        history.goBack();
      },
    }),
    [addToast, history, t]
  );

  const setJobPrivacyPayload = useMemo(
    () => ({
      onError: () => {
        addToast(
          t("jobs.list.label.setPrivacy.error", {
            defaultValue: `La fiche de poste n'a pas pu masquée ou affichée`,
          }),
          {
            appearance: "error",
          }
        );
      },
      onSuccess: (res) => {
        const isPrivate = res?.isPrivate;
        addToast(
          t("jobs.list.label.archive.success", {
            defaultValue: `La fiche de poste a été ${isPrivate ? "masquée" : "affichée"}`,
          }),
          {
            appearance: "success",
          }
        );
      },
    }),
    [addToast, t]
  );

  const updateJobPayload = useMemo(
    () => ({
      onError: (e) => {
        if (e.message === "name-already-taken") {
          addToast(
            t("portal.config.skills.createJob.nameAlreadyTaken", {
              defaultValue: "Une fiche de poste avec cet intitulé existe déjà",
            }),
            {
              appearance: "error",
            }
          );
        } else {
          addToast(
            t("job.label.deleteSuccess", {
              defaultValue: "La fiche de poste n'a pas pu être modifiée",
            }),
            {
              appearance: "error",
            }
          );
        }
      },
      onSuccess: () => {
        addToast(
          t("job.label.editSuccess", {
            defaultValue: "La fiche de poste a bien été modifiée",
          }),
          {
            appearance: "success",
          }
        );
        history.goBack();
      },
    }),
    [addToast, history, t]
  );

  const v2EmployeeJobQuery = trpc.employees.getEmployeesJobs.useQuery(undefined, {
    enabled: !employeeV3Enabled,
  });
  const v3EmployeeJobQuery = trpc.jobs.getEmployeesJobs.useQuery(undefined, {
    enabled: employeeV3Enabled,
  });
  const castedV2Data = v2EmployeeJobQuery.data as undefined | Array<Employee>;
  const castedV3Data = v3EmployeeJobQuery.data as undefined | Array<EmployeeJob>;
  const v2EmployeeMutator = trpc.employees.assignEmployeeToJob.useMutation(assignEmployeePayload);
  const v3EmployeeMutator = trpc.jobs.assignEmployeeToJob.useMutation(assignEmployeePayload);
  const removeJobMutator = trpc.jobs.remove.useMutation(removeJobPayload);
  const archiveJobMutator = trpc.jobs.setArchiving.useMutation(archiveJobPayload);
  const createJobMutator = trpc.jobs.create.useMutation(createJobPayload);
  const updateJobMutator = trpc.jobs.update.useMutation(updateJobPayload);
  const setJobPrivacyMutator = trpc.jobs.setJobPrivacy.useMutation(setJobPrivacyPayload);

  const archiveJob = useCallback(
    async (
      jobs: Array<{
        uuid: string;
        version: number;
      }>,
      isArchived: boolean
    ) => {
      if (jobs.length > 1) {
        setIsMultipleArchived(true);
      }
      archiveJobMutator.mutate(
        { isArchived, jobs },
        {
          onSuccess: () => {
            utils.jobs.getAll.setData(undefined, (list) => {
              return list.map((job) => {
                const jobToArchive = jobs.find((j) => j.uuid === job.uuid);
                if (jobToArchive) {
                  return {
                    ...job,
                    isArchived,
                    version: jobToArchive.version,
                  };
                }
                return job;
              });
            });
          },
        }
      );
    },
    [archiveJobMutator, utils]
  );

  const removeJob = useCallback(
    async (uuid: string, version: number) => {
      removeJobMutator.mutate(
        { uuid, version: version + 1 },
        {
          onSuccess: () => {
            utils.jobs.getAll.setData(undefined, (list) => {
              return list.filter((job) => {
                return job.uuid !== uuid;
              });
            });
          },
        }
      );
    },
    [removeJobMutator, utils]
  );

  const getJob = useCallback((uuid: string | undefined) => {
    if (!uuid) {
      const undefinedResult: {
        data: JobFromAPI;
        status: "error" | "loading" | "success";
        error: ReturnType<typeof trpc.jobs.getById.useQuery>["error"];
      } = { data: undefined, error: undefined, status: "loading" };

      return undefinedResult;
    }
    const { data, error, status } = trpc.jobs.getById.useQuery({ uuid });
    const castData = data as unknown as JobFromAPI;

    return { data: castData, error, status };
  }, []);

  const setJobPrivacy = useCallback(
    async (
      job: {
        uuid: string;
        version: number;
      },
      isPrivate: boolean
    ) => {
      setJobPrivacyMutator.mutate(
        { uuid: job.uuid, isPrivate, version: job.version },
        {
          onSuccess: () => {
            utils.jobs.getAll.setData(undefined, (list) => {
              return list.map((jobRow) => {
                if (job.uuid === jobRow.uuid) {
                  return {
                    ...jobRow,
                    isPrivate,
                    version: job.version,
                  };
                }
                return jobRow;
              });
            });
          },
        }
      );
    },
    [setJobPrivacyMutator, utils]
  );

  const createJob = useCallback(
    (payload) => {
      createJobMutator.mutate(payload);
    },
    [createJobMutator]
  );

  const updateJob = useCallback(
    (payload) => {
      updateJobMutator.mutate(payload);
    },
    [updateJobMutator]
  );

  const getJobAnalytics = useCallback((uuid: string, filters?: PerimeterFilters) => {
    const { data, error, status } = trpc.analytics.getJobAnalytics.useQuery({
      filters: {
        employees: filters,
      },
      jobUuid: uuid,
    });
    let castData = data as unknown as JobAnalytics;

    if (castData && castData.skillEvaluations.length > 0) {
      castData = {
        ...castData,
        skillEvaluations: castData.skillEvaluations.sort((a, b) =>
          a.skill.name.localeCompare(b.skill.name)
        ),
      };
    }

    if (castData && castData.evaluationsBySkillCategory.length > 0) {
      castData = {
        ...castData,
        evaluationsBySkillCategory: castData.evaluationsBySkillCategory.map((evaluation) => ({
          ...evaluation,
          skills: evaluation.skills.sort((a, b) => a.name.localeCompare(b.name)),
        })),
      };
    }

    if (castData && castData.averageEvaluationBySkills.length > 0) {
      castData = {
        ...castData,
        averageEvaluationBySkills: castData.averageEvaluationBySkills.map((evaluation) => ({
          ...evaluation,
          skills: evaluation.skills.sort((a, b) => a.name.localeCompare(b.name)),
        })),
      };
    }

    return { data: castData, error, status };
  }, []);

  const reAssignEmployeeToJobAndArchiveJob = useCallback(
    async (
      jobToArchive: {
        uuid: string;
        version: number;
      },
      employees: Array<{ version: number; employeeUuid: string }>,
      targetJob: {
        uuid: string;
        name: string;
      }
    ) => {
      if (employeeV3Enabled) {
        v3EmployeeMutator.mutate(
          { employees: employees.map((e) => ({ ...e, jobUuid: targetJob.uuid })) },
          {
            onSuccess: () => {
              utils.employees.getEmployeesJobs.setData(undefined, (list) => {
                return list.map((employee) => {
                  const employeeToUpdate = employees.find((e) => e.employeeUuid === employee.uuid);
                  if (employeeToUpdate) {
                    return {
                      ...employee,
                      jobs: [
                        {
                          uuid: targetJob.uuid,
                          isArchived: false,
                          name: targetJob.name,
                        },
                      ],
                      version: employeeToUpdate.version,
                    };
                  }
                  return employee;
                });
              });
              archiveJob([jobToArchive], true);
            },
          }
        );
        return;
      } else {
        v2EmployeeMutator.mutate(
          { employees: employees.map((e) => ({ ...e, jobUuid: targetJob.uuid })) },
          {
            onSuccess: () => {
              utils.jobs.getAll.setData(undefined, (list: any[]) => {
                const employeesToMove = list.find((j) => j.uuid === jobToArchive.uuid)?.employees;

                return list.map((job) => {
                  if (job.uuid === jobToArchive.uuid) {
                    return {
                      ...job,
                      employees: [],
                    };
                  }

                  if (job.uuid === targetJob.uuid) {
                    return {
                      ...job,
                      employees: [...job.employees, ...employeesToMove],
                    };
                  }

                  return job;
                });
              });
              utils.employees.getEmployeesJobs.setData(undefined, (list) => {
                return list.map((employee) => {
                  const employeeToUpdate = employees.find((e) => e.employeeUuid === employee.uuid);
                  if (employeeToUpdate) {
                    return {
                      ...employee,
                      jobs: [
                        {
                          uuid: targetJob.uuid,
                          isArchived: false,
                          name: targetJob.name,
                        },
                      ],
                      version: employeeToUpdate.version,
                    };
                  }
                  return employee;
                });
              });
              archiveJob([jobToArchive], true);
            },
          }
        );
        return;
      }
    },
    [employeeV3Enabled, v3EmployeeMutator, v2EmployeeMutator, archiveJob, utils]
  );

  return (
    <JobsContext.Provider
      value={{
        archiveJob,
        createJob,
        employeeList,
        employees: castedV2Data || castedV3Data,
        employeeV3Enabled: employeeV3Enabled,
        getJob,
        getJobAnalytics,
        isLoading: employeeV3Enabled
          ? v3EmployeeJobQuery.isLoading || v3EmployeeMutator.isLoading
          : v2EmployeeJobQuery.isLoading || v2EmployeeMutator.isLoading,
        jobList,
        jobListError,
        jobListLoading,
        jobListStatus,
        reAssignEmployeeToJobAndArchiveJob,
        removeJob,
        setJobPrivacy,
        updateJob,
      }}
    >
      {children}
    </JobsContext.Provider>
  );
};

export { JobsContext, JobsProvider, useJobsContext };
