import { ListUtils } from "@skillup/shared-utils";
import { ReviewRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type CampaignEmployeesData = ReviewRoutes.GetReviewsForCampaign["response"];
export type CampaignReviewsQuery = ReviewRoutes.GetReviewsForCampaign["query"];

export type GetFieldsDataParams = {
  campaignID: string;
  sorting: ListUtils.SortingProperties;
  pagination: ListUtils.PaginationProperties;
  filters: ListUtils.FilterValues<ListUtils.FilterConfigurationMap>;
};

export async function getReviewsData({
  campaignID,
  filters,
  pagination,
  sorting,
}: GetFieldsDataParams) {
  try {
    const response = await buildRequest<ReviewRoutes.GetReviewsForCampaign>({
      method: "POST",
      params: {
        campaignID,
      },
      path: "/campaign/{campaignID}/reviews",
      payload: {
        filters,
        pagination,
        sorting,
      },
      target: "PEOPLE_REVIEW",
    })();

    return response;
  } catch (err) {
    return null;
  }
}
