import { CampaignCreationState } from "types/peopleReview";

import { Summary } from "./Steps/Summary";
import { Details } from "./Steps/Details";
import { Managers } from "./Steps/Managers";
import { Validate } from "./Steps/Validate";
import { Attendees } from "./Steps/Attendees";
import { AccessAndRoles } from "./Steps/AccessAndRoles";

export function ActiveStep({
  addManagersStep,
  setCreationState,
  step,
}: {
  step: CampaignCreationState;
  addManagersStep: (status: boolean) => void;
  setCreationState: (patch: Partial<CampaignCreationState>) => void;
}) {
  switch (step.currentStepId) {
    case "details":
      return (
        <Details
          templateID={step.templateID}
          titleForHR={step.titleForHR}
          toggleManagersStep={addManagersStep}
          hasManagers={step.hasManagers ?? false}
          titleForManagers={step.titleForManagers}
          nextStep={({ hasManagers, templateID, titleForHR, titleForManagers }) =>
            setCreationState({
              currentStepId: "collaborators",
              hasManagers,
              templateID,
              titleForHR,
              titleForManagers,
            })
          }
        />
      );
    case "collaborators":
      return (
        <Attendees
          file={step.uploadedFile}
          hasManagers={step.hasManagers}
          selectedCollaboratorManagerPairs={step.collaboratorManagerPairs}
          prevStep={({ collaboratorManagerPairs, uploadedFile }) =>
            setCreationState({
              collaboratorManagerPairs,
              currentStepId: "details",
              hasManagers: step.hasManagers,
              uploadedFile,
            })
          }
          nextStep={({ collaboratorManagerPairs, uploadedFile }) =>
            setCreationState({
              collaboratorManagerPairs,
              currentStepId: "access-and-roles",
              hasManagers: step.hasManagers,
              uploadedFile,
            })
          }
        />
      );
    case "access-and-roles":
      return (
        <AccessAndRoles
          selectedObservers={step.observers}
          selectedCoordinators={step.coordinators}
          prevStep={(data) =>
            setCreationState({
              currentStepId: "collaborators",
              hasManagers: step.hasManagers,
              ...data,
            })
          }
          nextStep={(data) => {
            if (step.hasManagers) {
              setCreationState({
                currentStepId: "managers",
                hasManagers: step.hasManagers,
                ...data,
              });
            } else {
              setCreationState({
                currentStepId: "summary",
                hasManagers: step.hasManagers,
                ...data,
              });
            }
          }}
        />
      );
    case "summary":
      return (
        <Summary
          summary={step}
          hasManagers={step.hasManagers}
          nextStep={() =>
            setCreationState({
              currentStepId: "validate",
              hasManagers: step.hasManagers,
            })
          }
          prevStep={() =>
            setCreationState({
              currentStepId: step.hasManagers ? "managers" : "access-and-roles",
              hasManagers: step.hasManagers,
            })
          }
        />
      );
    case "managers":
      return (
        <Managers
          managersHelpText={step.managersHelpText}
          managersPreparationEndDate={step.managersPreparationEndDate}
          prevStep={() =>
            setCreationState({
              currentStepId: "access-and-roles",
              hasManagers: step.hasManagers,
            })
          }
          nextStep={({ managersHelpText, managersPreparationEndDate }) =>
            setCreationState({
              currentStepId: "summary",
              hasManagers: step.hasManagers,
              managersHelpText,
              managersPreparationEndDate,
            })
          }
        />
      );
    case "validate":
      return <Validate payload={step} />;
    default:
      return null;
  }
}
