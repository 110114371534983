import { getCardData } from "../adapters/insights";
import { DashboardCardData } from "../types";
import { ListUtils } from "@skillup/shared-utils";
import { useQuery } from "@tanstack/react-query";

export const useDashboardCardData = (
  card: DashboardCardData,
  filters: ListUtils.FilterValues<any>
) => {
  const { data, isLoading } = useQuery(
    ["trainings-dashboard-card-data", card.question_meta.id, filters],

    () => getCardData(card, filters)
  );

  return { data, loading: isLoading };
};
