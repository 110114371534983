import { MdDelete as Delete } from "react-icons/md";
import { useMemo, useRef } from "react";

import { PanelConfigTypeEnum } from "@skillup/training-bridge";
import { DSButton, DSSortable, DSTooltip, Flex } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";

import style from "../../../views/Configuration.module.scss";
import { ConfigurationTypeTranslationMap } from "../../../utils/ConfigurationTypeTranslationMap";
import { type PanelConfigForm } from "../../../types";
import { DashboardTextInput } from "../DashboardTextInput";
import { Panel } from "./Panel";
import { AddPanelDropdown } from "./AddPanelDropdown";

export type SortablePanelsProps = {
  panels: PanelConfigForm[];
  onChange: (value: PanelConfigForm[]) => void;
};

export const SortablePanels = ({ panels, onChange }: SortablePanelsProps) => {
  const { t } = useTranslation();
  const dragContainerRef = useRef<HTMLDivElement>(null);

  const onDragItem = (items: any[], from: number, to: number): any[] => {
    const newItems = [...items];
    const [replaced] = newItems.splice(from, 1);
    newItems.splice(to, 0, replaced);

    onChange(newItems.map((item) => panels.find((panel) => panel.id === item.id)));

    return items;
  };

  const handleResetHeader = (panelType, panelIndex) => {
    const cp = [...panels];
    cp[panelIndex] = {
      ...cp[panelIndex],
      short_name: ConfigurationTypeTranslationMap(t)[panelType],
    };

    onChange(cp);
  };

  const draggableItems = useMemo(() => {
    return panels.map((panel: PanelConfigForm, panelIndex: number) => ({
      id: panel.id,
      title: (
        <Flex className={style.draggableHeader}>
          <Flex column className={style.headerType}>
            <DashboardTextInput
              name={`panels[${panelIndex}].short_name`}
              value={panel.short_name}
              // value={panel.short_name}
              label={t("training.dashboard.configuration.section.title.name.label", {
                defaultValue: "Libellé de la section",
              })}
              required
              assistiveText={
                <Flex center className={style.panelTypeWording}>
                  <span>
                    {t("training.dashboard.configuration.header.type.prefix", {
                      defaultValue: "Type de section",
                    })}
                  </span>
                  <span>:</span>
                  <span>{t("training.dashboard.configuration.header.type." + panel.type)}</span>
                  <span>—</span>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <span
                    className={style.resetButton}
                    onClick={() => {
                      handleResetHeader(panel.type, panelIndex);
                    }}
                  >
                    {t("training.dashboard.configuration.header.action.reset", {
                      defaultValue: "Rétablir le libellé",
                    })}
                  </span>
                </Flex>
              }
            />
          </Flex>
          <DSTooltip
            label={t("training.dashboard.configuration.header.delete.panel", {
              defaultValue: "Supprimer la section",
            })}
          >
            <DSButton
              icon={<Delete />}
              iconOnly
              emphasis="Mid"
              onClick={() => {
                onChange(panels.filter((_, i) => i !== panelIndex));
              }}
            />
          </DSTooltip>
        </Flex>
      ),
      content: [PanelConfigTypeEnum.DETAIL_INDICATORS, PanelConfigTypeEnum.KEY_INDICATORS].includes(
        panel.type as any
      ) && <Panel panel={panel} panelIndex={panelIndex} />,
      displayAfter: (
        <Flex center>
          <AddPanelDropdown offset={panelIndex + 1} onChange={onChange} panels={panels} />
        </Flex>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panels]);

  return (
    <div className={style.draggablePanels}>
      <div ref={dragContainerRef} className={style.dragContainer}></div>
      <DSSortable
        dragContainer={dragContainerRef.current}
        items={draggableItems}
        removeAlwaysVisible
        reorderItems={onDragItem}
        useDragOverlay={true}
      />
    </div>
  );
};
