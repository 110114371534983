import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { MdSearch as Search } from "react-icons/md";

import { format } from "@skillup/shared-utils";
import { Flex, Text } from "@skillup/design-system";
import { DSModal, DSTextInput, HorizontalDivider } from "@skillup/ui";

import { useTemplates } from "services/peopleReview";

import ArchiveTemplateButton from "./../../buttons/ArchiveTemplateButton";

import styles from "./ArchivedTemplates.module.scss";

interface ArchivedTemplatesModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const ArchivedTemplatesModal = ({ closeModal, isOpen }: ArchivedTemplatesModalProps) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { archivedTemplates } = useTemplates();

  const archivedTemplatesFiltered = archivedTemplates?.filter((template) =>
    template.title.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  useEffect(() => {
    if (archivedTemplates.length === 0) closeModal();
  }, [archivedTemplates.length, closeModal]);

  return (
    <DSModal isOpen={isOpen} portalTarget={document.body} className={styles.templateArchivedModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={
            archivedTemplates?.length > 1
              ? t("supervisor.people-review-management.templates.archivedTemplates", {
                  count: archivedTemplates?.length,
                  defaultValue: "{{count}} modèles archivés",
                })
              : t("supervisor.people-review-management.templates.archivedTemplate", {
                  defaultValue: "1 modèle archivé",
                })
          }
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex height="100%" flexDirection="column">
          <Flex height="100%" marginTop="xs" flexDirection="row" className={styles.modalSearch}>
            <DSTextInput
              value={searchQuery}
              name="searchTemplate"
              actionButton={<Search />}
              className={styles.searchInput}
              onChange={setSearchQuery}
              placeholder={t(
                "supervisor.people-review-management.templates.archivedModal.searchPlaceholder",
                {
                  defaultValue: "Rechercher une template",
                }
              )}
            />
          </Flex>

          {archivedTemplatesFiltered.length > 0 ? (
            <Flex height="100%" flexDirection="column">
              {archivedTemplatesFiltered?.map((template, index) => (
                <Flex width="100%" key={template.uuid} flexDirection="column">
                  <Flex gap="xxs" width="100%" alignItems="center">
                    <Flex width="100%" flexDirection="column">
                      <Text
                        fontSize="1rem"
                        espaceFont="body1Regular"
                        color="plainText-onLight-default"
                      >
                        {template.title}
                      </Text>

                      {template.archival && (
                        <Text
                          fontSize="0.75rem"
                          espaceFont="body1Regular"
                          color="plainText-onLight-lighter"
                        >
                          {t("templates.archivedModal.row.archivedAtBy", {
                            archivedDate: format(new Date(template.archival.date), "dd/MM/yyyy"),
                            archivedUser: template?.archival.author.name,
                            defaultValue:
                              "Template archivée le {{archivedDate}} par {{archivedUser}}",
                          })}
                        </Text>
                      )}
                    </Flex>
                    <ArchiveTemplateButton template={template} />
                  </Flex>
                  {archivedTemplatesFiltered?.length - 1 !== index && (
                    <HorizontalDivider top={"xs"} bottom={"xs"} />
                  )}
                </Flex>
              ))}
            </Flex>
          ) : (
            <Text marginBottom="s" espaceFont="body1Regular">
              {t("supervisor.people-review-management.templates.archivedModal.noResult", {
                defaultValue: "Aucune modèle ne correspond à votre saisie",
              })}
            </Text>
          )}
        </Flex>
      </DSModal.Content>
      <DSModal.FooterEmpty />
    </DSModal>
  );
};
