import { useToasts } from "react-toast-notifications";
import { useMutation } from "@tanstack/react-query";

import { Project } from "services/sessions";
import useTranslation from "hooks/useTranslation";
import { ProjectsRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";

import { AFTER_SUMMONED_STATES } from "../helper";
import { formatPayloadForUpdate, parsedDates, sessionFormValidationSchema } from "./utils";
import { Session, UpdateSession } from "./types";
import { SessionForm } from "./components/Form";
import { Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

type Props = {
  project: Project;
  onClose: () => void;
  isOpen: boolean;
  openSummonModal: (updatedProject?: Project) => void;
};

export const UpdateModal = ({ onClose, isOpen, project, openSummonModal }: Props) => {
  const hasAccessToReviews =
    project.reviewsState?.companyHasColdReviews === true ||
    project.reviewsState?.companyHasHotReviews === true;

  const { t } = useTranslation();

  const initialState: UpdateSession = {
    summon: false,
    trainers: project.trainers?.map(({ uuid, fullName }) => ({ uuid, fullName })) || [],
    areas: project.areas.map((area) => area.uuid) || [],
    price: {
      type: project.properties.priceType || "total",
      value:
        project.properties.priceType === "perTrainee"
          ? project.training.pricePerTrainee
          : project.training.bookingPrice,
    },
    priceWithTax:
      project.properties.priceType === "perTrainee"
        ? project.training.pricePerTraineeWithTax
        : project.training.bookingPriceWithTax,
    pricePerTrainee: project.training.pricePerTrainee,
    pricePerTraineeWithTax: project.training.pricePerTraineeWithTax,
    bookingPrice: project.training.bookingPrice,
    bookingPriceWithTax: project.training.bookingPriceWithTax,
    city: project.properties.city,
    room: project.properties.room,
    link: project.properties.link,
    min: project.properties.minStock,
    max: project.properties.stock,
    dates: parsedDates(project.properties.dates),
    reference: project.properties.reference,
    afterSummoned: AFTER_SUMMONED_STATES.includes(project.properties?.state),
    evalGroupsGenerated: !hasAccessToReviews
      ? undefined
      : project.reviewsState?.evalGroupsGenerated ?? true,
  };

  const { addToast } = useToasts();

  const { mutate, isLoading } = useMutation(async (state: Session) => {
    try {
      const payload = formatPayloadForUpdate(state);

      const updatedProject = await buildRequest<ProjectsRoutes.Update>({
        method: "POST",
        params: {
          uuid: project.properties.uuid,
        },
        path: "/projects/{uuid}",
        payload,
      })();
      addToast(
        t("supervisor.sessions.updateModal.submitSuccess", {
          defaultValue: "La session a été modifiée avec succès",
        }),
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      onClose();
      return updatedProject;
    } catch (err) {
      addToast(
        t("supervisor.sessions.updateModal.submitError", {
          defaultValue: "Une erreur est survenue lors de la modification de la session",
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      console.error(err);
      return null;
    }
  });

  if(!isOpen) return null;

  return (
    <Formik<UpdateSession>
      initialValues={initialState}
      validationSchema={toFormikValidationSchema(sessionFormValidationSchema(t, "update"))}
      onSubmit={(values) => mutate(values)}
    >
      <SessionForm
        type="update"
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
        session={project}
      />
    </Formik>
  );
};
