import { useHistory } from "react-router-dom";
import { useMemo, Suspense, useState, useEffect } from "react";

import { MdSearch as Search } from "react-icons/md";

import { DSTooltip } from "@skillup/ui";
import { Text, Loader } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import { JobFromAPI, ProcessedSkill } from "types/skills";

import { JobsProvider, useJobsContext } from "../../../Jobs/JobsContext";
import { SkillsProvider, useSkillsContext } from "../../../Skills/SkillsContext";
import { Link, SearchInput, SearchResult, DisabledLink } from "./SearchBar.styled";
import { AnalyticsProvider, useAnalyticsContext } from "../../pages/Overview/OverviewContext";

type SearchProps = {
  data?: JobFromAPI | ProcessedSkill;
};

const SearchBar = ({ data }: SearchProps) => {
  return (
    <SkillsProvider>
      <JobsProvider>
        <AnalyticsProvider>
          <Suspense fallback={<Loader fillSpace />}>
            <Layout data={data} />
          </Suspense>
        </AnalyticsProvider>
      </JobsProvider>
    </SkillsProvider>
  );
};

const Layout = ({ data }: SearchProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<string>();

  const { getSkills } = useSkillsContext();
  const { data: skillList, status: skillListStatus } = getSkills();
  const { jobList, jobListLoading } = useJobsContext();
  const { getJobAnalytics, getSkillAnalytics } = useAnalyticsContext();
  const { data: skillAnalytics } = getSkillAnalytics();
  const { data: jobAnalytics } = getJobAnalytics();

  const skillAnalyticsUuids = useMemo(() => {
    return new Set(skillAnalytics?.map((skill) => skill.uuid));
  }, [skillAnalytics]);

  const jobAnalyticsUuids = useMemo(() => {
    return new Set(jobAnalytics?.map((job) => job.uuid));
  }, [jobAnalytics]);

  const jobsToDisplay = useMemo(() => {
    if (jobListLoading) {
      return [];
    }

    const jobs =
      !searchQuery || searchQuery.trim() === ""
        ? jobList.filter((job) => !job.isArchived)
        : jobList.filter(
            (job) =>
              !job.isArchived && `${job.name}`.toLowerCase().includes(searchQuery.toLowerCase())
          );

    return jobs.map((job) => ({
      ...job,
      disabled: !jobAnalyticsUuids.has(job.uuid),
    }));
  }, [jobAnalyticsUuids, jobList, jobListLoading, searchQuery]);

  const skillsToDisplay = useMemo(() => {
    if (skillListStatus === "loading") {
      return [];
    }

    const skills =
      !searchQuery || searchQuery.trim() === ""
        ? skillList.filter((skill) => !skill.isArchived)
        : skillList.filter(
            (skill) =>
              !skill.isArchived && `${skill.name}`.toLowerCase().includes(searchQuery.toLowerCase())
          );

    return skills.map((skill) => ({
      ...skill,
      disabled: !skillAnalyticsUuids.has(skill.uuid),
    }));
  }, [skillListStatus, searchQuery, skillList, skillAnalyticsUuids]);

  useEffect(() => {
    if (data) {
      setSearchQuery(data.name);
    }
  }, [data]);

  const hasAtLeastOneResult =
    searchQuery &&
    searchQuery !== data?.name &&
    (jobsToDisplay.length > 0 || skillsToDisplay.length > 0);

  return (
    <>
      <SearchInput
        name="search"
        value={searchQuery}
        actionButton={<Search />}
        onChange={setSearchQuery}
        placeholder={t("skills.dashboard.searchJobOrSkill", {
          defaultValue: "Rechercher une fiche de poste ou une compétence",
        })}
      />
      {hasAtLeastOneResult && (
        <SearchResult width="100%">
          {jobsToDisplay.length !== 0 && (
            <Text espaceFont="body2Bold" color="plainText-onLight-lighter">
              {t("skills.dashboard.jobs", { defaultValue: "Fiches de poste" })}
            </Text>
          )}
          {jobsToDisplay.map((job) =>
            job.disabled ? (
              <DSTooltip
                key={job.uuid}
                label={t("skills.list.job.overviewJobNotEvaluated", {
                  defaultValue: "Cette fiche de poste n'a pas été évaluée",
                })}
              >
                <DisabledLink>{job.name}</DisabledLink>
              </DSTooltip>
            ) : (
              <Link
                key={job.uuid}
                onClick={() => history.push(`/responsable/analyses/fiche-de-poste/${job.uuid}`)}
              >
                {job.name}
              </Link>
            )
          )}
          {skillsToDisplay.length !== 0 && (
            <Text marginTop="xxs" espaceFont="body2Bold" color="plainText-onLight-lighter">
              {t("skills.dashboard.skills", { defaultValue: "Compétences" })}
            </Text>
          )}
          {skillsToDisplay.map((skill) =>
            skill.disabled ? (
              <DSTooltip
                key={skill.uuid}
                label={t("skills.list.job.overviewSkillNotEvaluated", {
                  defaultValue: "Cette compétence n'a pas été évaluée",
                })}
              >
                <DisabledLink>{skill.name}</DisabledLink>
              </DSTooltip>
            ) : (
              <Link
                key={skill.uuid}
                onClick={() => history.push(`/responsable/analyses/competence/${skill.uuid}`)}
              >
                {skill.name}
              </Link>
            )
          )}
        </SearchResult>
      )}
    </>
  );
};

export default SearchBar;
