import { Skeleton } from "@mui/material";
import { Flex } from "@skillup/design-system";

import style from "./Skeleton.module.scss";

export const SkeletonLoader = () => (
  <Flex alignItems="center" className={style.loader}>
    {Array.from({ length: 3 }).map(() => (
      <>
        <Skeleton variant="rectangular" width="146px" height="24px" sx={{ bgcolor: "#0C5D88" }} />
        <Skeleton variant="rectangular" width="100%" height="240px" sx={{ bgcolor: "#0C5D88" }} />
      </>
    ))}
    <Skeleton variant="rectangular" width="146px" height="24px" sx={{ bgcolor: "#0C5D88" }} />
  </Flex>
);
