import { useEffect, useMemo, useState } from "react";
import { MdExpandMore as ExpandMore, MdExpandLess as ExpandLess } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import cx from "classnames";

import { DSButton, DSDropdownItem, DSFilters, Flex } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import history from "utils/history";
import User from "utils/User";

import DSLayout from "components/DSLayout";
import Loader from "components/Loader";

import { fetchDashboardPanels, getFiltersConfig } from "../adapters/insights";

import { DashboardPanel } from "../components/DashboardPanel";
import { DataFreshness } from "../components/DataFreshness";

import styles from "./Dashboard.module.scss";

export const TrainingDashboardContainer = () => {
  const { t } = useTranslation();

  const activeSchedule = User.getActiveSchedule();

  const [isExpanded, setExpand] = useState(true);
  const [filterValues, setFilterValues] = useState<Record<string, any>>();

  const { data: dashboardPanels, isLoading: panelsLoading } = useQuery(
    ["trainings-dashboard-panels"],
    () => fetchDashboardPanels()
  );

  const { data: filters } = useQuery(["trainings-dashboard-filters"], () =>
    getFiltersConfig(activeSchedule.uuid, t)
  );

  useEffect(() => {
    if (!filters) {
      return;
    }

    const initialFilterValues = Object.keys(filters.config).reduce((acc, key) => {
      acc[key] = {
        value: filters.props[key].value ?? filters.props[key].defaultValue,
        operator: filters.props[key].operator,
        type: filters.config[key].type,
      };

      return acc;
    }, {});

    setFilterValues(initialFilterValues);
  }, [filters]);

  const layouts = useMemo(
    () => [
      {
        dropdownContent: [
          <DSDropdownItem
            key="configuration"
            onClick={() => {
              history.push("/responsable/dashboard/training/configuration");
            }}
            label={t("trainings.view.dashboard.redirect-configuration", {
              defaultValue: "Personnaliser le dashboard",
            })}
          />,
        ],
      },
    ],
    [t]
  );

  const isLoading = panelsLoading || !filters || !filterValues;

  const schedules = useMemo(() => {
    if (!filters?.props) {
      return [];
    }

    return filters.props.filter_plan_uuid_select?.options!.reduce(
      (acc, option) => {
        acc.push({ uuid: option.value, name: option.label });

        return acc;
      },
      [] as Array<{ name: string; uuid: string }>
    );
  }, [filters]);

  return (
    <DSLayout
      layouts={layouts}
      title={t("trainings.view.dashboard.title", {
        defaultValue: "Dashboard - Formation",
      })}
      header={
        !isLoading && (
          <>
            <div className={cx(styles.layoutHeader, { [styles.isExpanded]: isExpanded })}>
              <DSFilters
                t={t}
                darkMode
                filters={filters.props}
                config={filters.config}
                onChange={(newFilters) => {
                  setFilterValues(newFilters);
                  setLocalStorageFilters(newFilters);
                }}
                className={styles.filters}
              />
              <p>
                {t("trainings.view.dashboard.n_filters_applied", {
                  defaultValue: "{{ count }} filtre appliqué",
                  count: Object.keys(filterValues).filter((key) => filterValues[key].value).length,
                })}
              </p>

              <DSButton
                iconOnly
                className={styles.expandButton}
                darkMode
                icon={isExpanded ? <ExpandLess /> : <ExpandMore />}
                onClick={() => setExpand(!isExpanded)}
              />
            </div>
          </>
        )
      }
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Flex column style={{ width: "100%" }}>
            <Flex column className={styles.dashboard}>
              <Flex column className={styles.panels}>
                {dashboardPanels?.map((panel) => (
                  <DashboardPanel
                    key={panel.short_name}
                    panel={panel}
                    filters={filterValues}
                    schedules={schedules}
                  />
                ))}

                <DataFreshness />
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </DSLayout>
  );
};

const setLocalStorageFilters = (filters: Record<string, { value: string[] }>) => {
  localStorage.setItem("filters", JSON.stringify(filters));
};
