import { t } from "i18next";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Flex } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";

import { Review, Campaign } from "../../../types";
import { DecorativeTag } from "../../../components";

type AssignedActionsCellProps = {
  campaign: Campaign;
  params: GridRenderCellParams<Review>;
};

const AssignedActionsCell = ({ campaign, params }: AssignedActionsCellProps) => {
  const { t } = useTranslation();

  const assignedActions = params.row.assignedActions;

  return (
    <Flex alignItems="center" justifyContent="left">
      {assignedActions
        .filter((a) => a.state !== "DENIED")
        .map((assignedAction) => {
          const action = campaign.actions.find((action) => action.id === assignedAction.actionID);
          if (assignedAction.coordinatorComment || assignedAction.managerComment) {
            return (
              <DecorativeTag.FreeColor
                color={action?.color}
                label={action?.label ?? ""}
                key={assignedAction.actionID}
                tooltipLabel={`${
                  assignedAction.managerComment
                    ? `${t("peopleReview.manager", { defaultValue: "Manager" })} : ${
                        assignedAction.managerComment
                      } ${assignedAction.coordinatorComment ? " — " : ""}`
                    : ``
                } ${
                  assignedAction.coordinatorComment
                    ? `${t("peopleReview.coordinator", { defaultValue: "Coordinateur" })} : ${
                        assignedAction.coordinatorComment
                      }`
                    : ""
                }`}
              />
            );
          }
          return (
            <DecorativeTag.FreeColor
              color={action?.color}
              label={action?.label ?? ""}
              key={assignedAction.actionID}
            />
          );
        })}
    </Flex>
  );
};

export function getAssignedActionsColDef(campaign: Campaign): GridColDef<Review> {
  return {
    type: "string",
    cellClassName: "fake-editable--cell",
    field: "assignedActions" satisfies keyof Review,
    filterable: false,
    flex: 1,
    headerName: t("people-review-campaign.talentgrid.column.actions", {
      defaultValue: "Actions",
    }),
    minWidth: 250,
    renderCell: (params) => <AssignedActionsCell params={params} campaign={campaign} />,
    sortable: false,
  };
}
