import { useToasts } from "react-toast-notifications";

import { DSDropdown, DSDropdownItem } from "@skillup/ui";
import { ICompanyField, TrainingDomainRoutes } from "@skillup/training-bridge";

import { buildRequest } from "utils/buildRequest";
import useTranslation from "hooks/useTranslation";

import { useFieldRefetch } from "../context";

type Props = {
  field: ICompanyField;
};

const ActionsCell = ({ field }: Props) => {
  const refetch = useFieldRefetch();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const isDeleted = field.deletedAt !== null || field.deletedAt === undefined;

  const toggleHideField = async () => {
    try {
      await buildRequest<TrainingDomainRoutes.FIELD.ToggleFieldActivationRoute>({
        target: "TRAINING_API",
        method: "POST",
        path: "/field/toggle-activation",
        payload: {
          binding: field.binding,
        },
      })();

      refetch();
    } catch {
      addToast(
        t("field.config.error.query", {
          defaultValue: "Une erreur est survenue au chargement des champs.",
        }),
        {
          appearance: "error",
        }
      );
    }
  };

  const buttonLabel = isDeleted
    ? t("field.action.label.show", { defaultValue: "[OPS] Afficher" })
    : t("field.action.label.hide", { defaultValue: "[OPS] Masquer" });

  return (
    <DSDropdown buttonSize="S" overflow="initial">
      <DSDropdownItem label={buttonLabel} onClick={toggleHideField} />
    </DSDropdown>
  );
};

export default ActionsCell;
