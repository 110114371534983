import { RefObject, useRef, useState } from "react";

import { HighchartsReactRefObject } from "highcharts-react-official";

import { DSModal, DSRadio, DSRadioGroup, Flex, Label } from "@skillup/ui";

import { DashboardCardData, QuestionData } from "../types";

import styles from "./ExportModal.module.scss";

import { HistogramChart } from "./Charts/Histogram";
import useTranslation from "hooks/useTranslation";
import {
  histogramAllDataLabel,
  histogramPaginatedDataLabel,
  histogramYLabel,
  pieLabel,
} from "./Charts/utils/label";
import useSettings from "hooks/useSettings";

export type ExportModalProps = {
  open: boolean;
  canExportAllData: boolean;
  card: DashboardCardData;
  data: QuestionData;
  graphRef: RefObject<HighchartsReactRefObject>;
  onClose: () => void;
};

export const ExportModal = ({
  canExportAllData,
  open,
  card,
  data,
  graphRef,
  onClose,
}: ExportModalProps) => {
  const isPieChart =
    card.charts.includes("pie") &&
    card.question_meta.axes[1]?.fingerprint?.distinct_values &&
    card.question_meta.axes[1].fingerprint.distinct_values.length < 4;
  const isPaginated = !isPieChart && data?.data.length > 8;

  const { settings } = useSettings();
  const { t, i18n } = useTranslation();
  const [format, setFormat] = useState<"image/png" | "application/pdf">("image/png");
  const [type, setType] = useState<"all" | "page">("all");
  const allDataRef = useRef<HighchartsReactRefObject>(null);

  const { currency } = settings;

  const handleExportClick = () => {
    if (canExportAllData && type === "all") {
      allDataRef.current?.chart?.exportChart(
        {
          type: format,
          filename: card.short_name,
        },
        {
          chart: {
            spacing: [20, 20, 20, 20],
          },
          xAxis: {
            labels: histogramAllDataLabel(true),
          },
          yAxis: {
            labels: histogramYLabel(card, i18n.language, currency, true),
          },
          plotOptions: {
            pie: {
              dataLabels: pieLabel(card, i18n.language, currency),
            },
          },
        }
      );
      return;
    }

    graphRef.current?.chart?.exportChart(
      {
        type: format,
        filename: card.short_name,
      },
      {
        chart: {
          spacing: [20, 20, 20, 20],
        },
        xAxis: {
          labels: histogramPaginatedDataLabel(true) as any,
        },
        yAxis: {
          labels: histogramYLabel(card, i18n.language, currency, true),
        },
        plotOptions: {
          pie: {
            dataLabels: pieLabel(card, i18n.language, currency),
          },
        },
      }
    );
  };

  return (
    <DSModal isOpen={open}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.view.dashboard.export_modal_title", {
            defaultValue: "Télécharger le graphique « {{name}} »",
            name: card.short_name,
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <Flex className={styles.exportContainer}>
          <Flex column>
            <Label
              label={t("trainings.view.dashboard.export_type", {
                defaultValue: "Données",
              })}
            />
            <DSRadioGroup name={"type"}>
              <DSRadio
                label={t("trainings.view.dashboard.export_type_all", {
                  defaultValue: "Toutes",
                })}
                checked={type === "all"}
                onChange={() => setType("all")}
              />
              <DSRadio
                disabled={!canExportAllData}
                label={t("trainings.view.dashboard.export_type_page", {
                  defaultValue: "Vue actuelle",
                })}
                checked={type === "page"}
                onChange={() => setType("page")}
              />
            </DSRadioGroup>
          </Flex>

          <Flex column>
            <Label
              label={t("trainings.view.dashboard.export_format", {
                defaultValue: "Format",
              })}
            />
            <DSRadioGroup name={"format"}>
              <DSRadio
                label={t("trainings.view.dashboard.export_format_png", {
                  defaultValue: "PNG",
                })}
                checked={format === "image/png"}
                onChange={() => setFormat("image/png")}
              />
              <DSRadio
                label={t("trainings.view.dashboard.export_format_pdf", {
                  defaultValue: "PDF",
                })}
                checked={format === "application/pdf"}
                onChange={() => setFormat("application/pdf")}
              />
            </DSRadioGroup>
          </Flex>
        </Flex>

        <div style={{ display: "none" }}>
          {isPaginated && (
            <HistogramChart
              ref={allDataRef}
              allData
              isPaginated={false}
              data={data}
              card={card}
              loading={false}
            />
          )}
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("trainings.view.dashboard.action.cancel_export", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          onClick={handleExportClick}
          label={t("trainings.view.dashboard.action.export", {
            defaultValue: "Exporter",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
