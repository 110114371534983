import { MdInfoOutline as InfoOutline } from "react-icons/md";
import React from "react";

import { getIn, Field, type FieldProps, ErrorMessage } from "formik";

import { DSNumberInput } from "@skillup/ui";

import { Flex } from "../Flex";
import { Text } from "../Text";

interface Props {
  readonly name: string;
  readonly value?: number;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly defaultValue?: number;
}

export const FormikInputNumber = ({ defaultValue, disabled, name, placeholder, value }: Props) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <DSNumberInput
            value={value}
            name={field.name}
            disabled={disabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            error={getIn(form.errors, field.name) && getIn(form.touched, field.name)}
            onBlur={form.handleBlur}
            onChange={(_, e) => form.handleChange(e)}
          />
        )}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg) => (
          <Text fontSize="0.75rem" espaceFont="body1Regular" color="status-error-darker">
            <Flex gap="xs" alignItems="center">
              <InfoOutline />
              {msg}
            </Flex>
          </Text>
        )}
      />
    </>
  );
};
