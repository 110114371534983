// TODO: Move to UserV2 with new endpoint
import { Collaborator } from "../../../../User/api";

const parseCollaboratorIntoRow = (collaborator: Collaborator) => {
  return {
    id: collaborator.uuid,
    uuid: collaborator.uuid,
    areas: collaborator.areas[0]?.name,
    bookingManager: collaborator.bookingManager,
    email: collaborator.email,
    firstName: collaborator.firstName,
    groups: collaborator.groups[0]?.name,
    joinDate: collaborator.joinDate,
    lastName: collaborator.lastName,
    manager0Lvl0: collaborator.manager0Lvl0,
    manager0Lvl1: collaborator.manager0Lvl1,
    registrationNumber: collaborator.registrationNumber,
    role: collaborator.role,
    subCompanies: collaborator.subCompanies[0]?.name,
    ...collaborator.hierarchy?.observers
      .map((observer: { uuid: string; email: string; level: number; fullName: string }) => ({
        [`observerLvl${observer.level}`]: observer.fullName,
      }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
  };
};

export type CollaboratorRow = ReturnType<typeof parseCollaboratorIntoRow>;

export default parseCollaboratorIntoRow;
