import { t } from "i18next";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Text, Flex } from "@skillup/design-system";

import { Review } from "../../../types";

type EmailCellProps = {
  params: GridRenderCellParams<Review>;
};

export const EmailCell = ({ params }: EmailCellProps) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Text>{params.row.email}</Text>
    </Flex>
  );
};

export const emailColDef: GridColDef<Review> = {
  field: "email" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.email", {
    defaultValue: "Email",
  }),
  renderCell: (params) => <EmailCell params={params} />,
  width: 150,
};
