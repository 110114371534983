import isEmpty from "lodash/isEmpty";

import { MdSearch as Search } from "react-icons/md";
import { DSModal, DSTextInput, HorizontalDivider, Flex } from "@skillup/ui";
import { DateTime } from "@skillup/shared-utils";

import { plural } from "utils/locale";

import styles from "./ObsoleteTargets.module.scss";
import { useTargets } from "../../../hooks";
import { UserTarget } from "@skillup/espace-rh-bridge";

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  userUuid: string;
}

export const ObsoleteTargetsModal = ({ isOpen, closeModal, userUuid }: IProps) => {
  const { obsoleteTargets, searchOnObsolete, filteredObsoleteTargets } = useTargets({
    userUuid,
    queryOptions: {
      order: "ASC",
      filter: JSON.stringify({
        isObsolete: true,
      }),
    },
  });

  return (
    <DSModal isOpen={isOpen} className={styles.ObsoleteTargetsModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title={plural(obsoleteTargets.length, "%n objectif%s obsolète%s")} />
      </DSModal.Header>

      <DSModal.Content>
        <Flex column className={styles.list}>
          <div className={styles.search}>
            <DSTextInput
              placeholder="Recherche parmi les objectifs obsolètes"
              name="filter-targets"
              actionButton={<Search />}
              onChange={async (e) => await searchOnObsolete(e)}
            />
          </div>
          <ObsoleteTargetsList targets={obsoleteTargets} filteredTargets={filteredObsoleteTargets}>
            {filteredObsoleteTargets.map((target, index) => (
              <ObsoleteTargetItem
                isLastItem={index === filteredObsoleteTargets.length - 1}
                key={target.uuid}
                target={target}
              />
            ))}
          </ObsoleteTargetsList>
        </Flex>
      </DSModal.Content>
      <DSModal.FooterEmpty />
    </DSModal>
  );
};

const ObsoleteTargetsList = ({
  targets,
  filteredTargets,
  children,
}: {
  targets: UserTarget[];
  filteredTargets: UserTarget[];
  children: React.ReactNode;
}) => {
  if (isEmpty(targets)) {
    return <section className={styles["list__item--empty"]}>Aucun objectif obsolète</section>;
  }
  if (isEmpty(filteredTargets)) {
    return (
      <section className={styles["list__item--empty"]}>
        Aucun objectif obsolète ne correspond à votre recherche.
      </section>
    );
  }
  return <>{children}</>;
};

const ObsoleteTargetItem = ({
  target,
  isLastItem,
}: {
  target: UserTarget;
  isLastItem: boolean;
}) => {
  const obsoletors = target.activities
    ?.map((activity) => activity.logs.find((log) => log.type === "obsolete"))
    .filter(Boolean);
  return (
    <>
      <Flex row className={styles.list__item}>
        <div className={styles.list__item__content}>
          <div className={styles.list__item__content__title}>{target.name}</div>
          {obsoletors.length ? (
            <div className={styles.list__item__content__subtitle}>
              Objectif marqué comme obsolète le{" "}
              {DateTime.fromISO(obsoletors[0].createdAt).toFormat("dd/MM/yyyy")}
              {obsoletors[0].author && " par " + obsoletors[0].author.fullName}
            </div>
          ) : null}
        </div>
      </Flex>
      {isLastItem ? null : <HorizontalDivider top="none" bottom="none" />}
    </>
  );
};
