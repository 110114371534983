import { ListUtils } from "@skillup/shared-utils";
import { z } from "zod";

export type AnalyticsFilters = ListUtils.FilterValues<{
  name: {
    type: "text";
    label: string;
  };
  category: {
    type: "text";
    label: string;
  };
  perimeters: {
    type: "multiselect";
    label: string;
  };
}>;

export type PerimeterFilters = ListUtils.FilterValues<{
  perimeters: {
    type: "multiselect";
    label: string;
  };
}>;

export enum FieldEntity {
  SKILL = "SKILL",
  JOB = "JOB",
}

export enum FieldValueType {
  VALUE = "value",
  CONTENT = "content",
}
export interface SelectedSkills {
  [skillName: string]: {
    uuid?: string;
    name: string;
  };
}

export type TemplateField = {
  [key in FieldValueType]: string;
} & {
  uuid: string;
};

export type JobTemplate = {
  uuid: string;
  version: number;
  name: string;
  index: number;
  companyUuid: string;
  updatedAt: string;
  createdAt: string;
  deletedAt?: string;
};

export interface JobSkill {
  skillUuid: string;
  expectedLevelUuid?: string;
  skillName?: string;
  generated?: boolean;
  description?: string;
  categoryId?: string;
}

export interface JobSection {
  uuid: string;
  name: string;
  content: string;
  index: number;
}

interface JobSkillWithExpectedLevel {
  skillId: string;
  skill: Omit<Skill, "category"> & { category?: string };
  expectedLevelUuid?: string;
  deletedAt?: Date;
}

export interface Field {
  uuid: string;
  name: string;
  entity: FieldEntity;
  index: number;
  version?: number;
}

export interface JobFields {
  uuid: string;
  value?: string;
  content?: string;
  name: string;
  index: number;
}

export interface JobEmployee {
  uuid: string;
  firstName: string;
  lastName: string;
}

export interface FormJob {
  jobName: string;
  fields: JobFields[];
  sections: JobFields[];
  skills: JobSkill[];
}
export interface JobFromAPI {
  uuid: string;
  name: string;
  isArchived: boolean;
  isPrivate: boolean;
  generatedWithAI?: boolean;
  version: number;
  userUuid: string;
  companyUuid: string;
  createdAt: string;
  updatedAt: string;
  updatedBy?: {
    uuid: string;
    fullName: string;
  };
  deletedAt?: string;
  skillsLinked: number;
  skills: Array<{
    skill: {
      uuid: string;
      name: string;
      description?: string;
      companyUuid: string;
      isArchived: boolean;
      evaluationScale: {
        uuid: string;
        name: string;
        companyUuid: string;
        levels: Array<{
          uuid: string;
          name: string;
          level: number;
          description?: string;
          deletedAt?: string;
        }>;
      };
      category?: string;
    };
    expectedLevelUuid?: string;
    deletedAt?: string;
  }>;
  fields: Array<{
    uuid: string;
    name: string;
    value?: string;
    index: number;
  }>;
  sections: Array<{
    uuid: string;
    name: string;
    content: string;
    index: number;
  }>;
  employees: Array<{
    uuid: string;
    firstName: string;
    lastName: string;
    fullName: string;
    initials: string;
  }>;
}

export interface DatatableJob {
  uuid: string;
  name: string;
  isArchived: boolean;
  isPrivate: boolean;
  version: number;
  createdAt: Date;
  updatedAt: Date;
  updatedBy?: {
    uuid: string;
    fullName: string;
  };
  fields: {
    uuid: string;
    name: string;
    index: number;
    value?: string;
  }[];
  employees: Array<{
    uuid: string;
    firstName: string;
    lastName: string;
    fullName: string;
    initials: string;
  }>;
  skillsLinked: number;
}

export interface Job {
  uuid: string;
  name: string;
  version: number;
  updatedAt: Date;
  userUuid: string;
  isArchived: boolean;
  isPrivate: boolean;
  fields: JobFields[];
  sections: JobSection[];
  skills: Array<JobSkillWithExpectedLevel>;
}

export interface JobList {
  uuid: string;
  name: string;
  version: number;
  updatedAt?: Date;
  isArchived: boolean;
  isPrivate: boolean;
  skillsLinked: number;
  employees: Array<JobEmployee>;
  fields: Array<JobFields>;
}

export interface Skill {
  uuid: string;
  name: string;
  description?: string;
  companyUuid: string;
  archivedAt?: Date;
  isArchived: boolean;
  deletedAt?: Date;
  evaluationScale: EvaluationScale;
  category?: Category | CategoryLabel;
  generated?: boolean;
}

export interface FormikState {
  uuid?: string;
  jobName: string;
  fields: JobFields[];
  skills: JobSkill[];
  sections: JobFields[];
}

export interface Employee {
  uuid: string;
  version: number;
  companyUuid: string;
  jobs: Array<Job>;
}

export interface EmployeeJob {
  employeeId: string;
  companyUuid: string;
  jobId: string;
  version: number;
}

type Validator = {
  uuid: string;
  email: string;
  fullName: string;
  lastName: string;
  firstName: string;
};

export type Assignee = {
  uuid: string;
  site?: string;
  role?: string;
  email: string;
  branch?: string;
  areas?: string[];
  companyID?: string;
  service?: string;
  contract?: string;
  division?: string;
  createdAt: string;
  updatedAt: string;
  roleDate?: string;
  joinDate?: string;
  fullName?: string;
  lastName?: string;
  customField9?: any;
  customField8?: any;
  customField7?: any;
  customField6?: any;
  customField5?: any;
  customField4?: any;
  customField3?: any;
  customField2?: any;
  customField1?: any;
  customField0?: any;
  isImmune?: boolean;
  birthDate?: string;
  firstName?: string;
  validators?: Validator[];
  registrationNumber?: string;
  expectedDepartureDate?: string;
  jobID?: string;
  jobName?: string;
  version?: number;
};

export type GetAssignmentsResult = {
  assignments: Assignee[];
  pagination: {
    page: number;
    total: number;
    pageSize: number;
  };
};
export interface AssignCollabPayloadEmployee {
  employeeUuid: string;
  companyUuid: string;
  version: number;
}

export interface AssignCollabPayload {
  command: string;
  employees: Array<AssignCollabPayloadEmployee>;
  targetJob: { uuid: string; name: string };
}

export interface EvaluationScaleLevel {
  uuid: string;
  level: number;
  name: string;
  description?: string;
  customDescription?: string;
}

export interface Category {
  label: string;
  index: number;
  uuid: string;
  version: number;
  deletedAt?: Date;
  skillsLinked?: number;
}

interface CategoryLabel {
  label: string;
}

export interface SkillFields {
  uuid: string;
  version: number;
  value?: string;
  name: string;
  index: number;
}

export interface ProcessedSkill {
  uuid: string;
  version: number;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  isArchived: boolean;
  generatedWithAi?: boolean;
  updatedBy?: {
    uuid: string;
    fullName: string;
  };
  evaluationScale: {
    uuid: string;
    name: string;
    levels: {
      uuid: string;
      name: string;
      level: number;
      description?: string;
      customDescription?: string;
      deletedAt?: Date;
    }[];
    deletedAt?: Date;
  };
  fields: {
    uuid: string;
    version: number;
    name: string;
    index: number;
    value?: string;
  }[];
  jobs: {
    [jobUuid: string]: {
      uuid: string;
      name: string;
    };
  };
  employees: {
    [employeeUuid: string]: {
      uuid: string;
      firstName: string;
      lastName: string;
      initials: string;
      fullName: string;
    };
  };
  archivedAt?: Date;
  category?: {
    uuid: string;
    companyUuid: string;
    createdAt: Date;
    updatedAt: Date;
    index: number;
    label: string;
    version: number;
    deletedAt?: Date;
  };
  expectedLevelUuid?: string;
  generated?: boolean;
}

export interface ProcessedSkillWithLevelUuid extends ProcessedSkill {
  levelUuid: string;
}

export interface SkillWithLevelUuid extends Skill {
  levelUuid: string;
}

export interface CreateSkillFormProps {
  skillName: string;
  description?: string;
  fields: SkillFields[];
  categoryUuid?: string;
  evaluationScale: {
    uuid: string;
    levels: Array<{
      name: string;
      uuid: string;
      level: number;
      description?: string;
      customDescription?: string;
    }>;
  };
}

export interface UpdateSkillFormProps {
  uuid: string;
  version: number;
  skillName: string;
  categoryUuid?: string;
  evaluationScale: EvaluationScale;
  description?: string;
  fields: SkillFields[];
}

export interface GenerateJobDescriptionResult {
  job_title: string;
  sections: Array<{
    name: string;
    value: string;
    uuid: string;
    index: number;
  }>;
  cost: number;
}

export interface SkillWithExpectedLevel {
  skillUuid: string;
  skillName?: string;
  levelUuid: string;
  levelNumber?: number;
  totalLevel: number;
  evaluationScale: EvaluationScale;
  generated?: boolean;
  description?: string;
  categoryId?: string;
}

export interface CreateJobPayload {
  fields: Array<{ uuid: string; value?: string; content?: string }>;
  name: string;
  sections: Array<{ uuid: string; value?: string; content?: string }>;
  skills: Array<{ expectedLevelUuid: string; skillId: string }>;
  generatedWithAi?: boolean;
}

export interface UpdateJobPayload {
  uuid: string;
  fields: Array<{ uuid: string; value?: string; content?: string }>;
  name: string;
  sections: Array<{ uuid: string; value?: string; content?: string }>;
  skills: Array<{ expectedLevelUuid: string; skillId: string }>;
  version: number;
}

export interface EvaluationScale {
  uuid: string;
  name: string;
  version?: number;
  description?: string;
  isDefault?: boolean;
  levels: Array<EvaluationScaleLevel>;
  skills?: Array<{
    uuid: string;
    isArchived: boolean;
  }>;
}

export interface EvaluationScaleProps {
  items: EvaluationScale[];
}

export interface FormEvaluationLevel {
  level: number;
  name: string;
  description?: string;
  deletable?: boolean;
  placeholder?: string;
}

export interface UpdatedFormEvaluationLevel extends FormEvaluationLevel {
  uuid: string;
}

export interface FormEvaluationScale {
  startAtZero: boolean;
  evaluationScaleName: string;
  levels: Array<FormEvaluationLevel>;
}

export interface UpdatedFormEvaluationScale {
  startAtZero: boolean;
  evaluationScaleName: string;
  levels: Array<UpdatedFormEvaluationLevel>;
}

export const SkillEvaluationJsonDataSchema = z.object({
  skill: z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    version: z.number(),
    description: z.string().optional(),
    categoryLabel: z.string(),
    evaluationScale: z.object({
      uuid: z.string().uuid(),
      name: z.string(),
      levels: z.array(
        z.object({
          uuid: z.string().uuid(),
          name: z.string(),
          level: z.number().nonnegative(),
        })
      ),
    }),
  }),
  expectedLevel: z
    .object({
      uuid: z.string().uuid(),
      name: z.string(),
      level: z.number().nonnegative(),
      description: z.string().optional(),
    })
    .optional(),
  evaluatedLevel: z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    level: z.number().nonnegative(),
    description: z.string().optional(),
  }),
  job: z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    categoryLabel: z.string(),
    version: z.number(),
  }),
});

export type SkillEvaluationJsonData = z.infer<typeof SkillEvaluationJsonDataSchema>;

export const SkillEvaluationSchema = SkillEvaluationJsonDataSchema.extend({
  uuid: z.string().uuid(),
  userUuid: z.string().uuid(),
  version: z.number(),
  companyUuid: z.string().uuid(),
  state: z.string(),
  employeeUuid: z.string().uuid(),
  managerUuid: z.string().uuid(),
  managerComment: z.string().optional(),
  interviewUuid: z.string().uuid(),
  interviewType: z.string().optional(),
  interviewName: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  signedAt: z.date().optional(),
  evaluatedAt: z.date(),
  jobUuid: z.string().uuid(),
  skillUuid: z.string().uuid(),
});

export type SkillEvaluation = z.infer<typeof SkillEvaluationSchema>;

export type EmployeeAverageScore = {
  employeeUuid: string;
  fullName: string;
  role: string;
  averageEvaluationsScore: number;
};

export type EvaluationBySkillCategory = {
  name: string;
  skills: Array<{
    name: string;
    uuid: string;
    levelExpected?: number;
    evaluationScaleLevels: Array<{
      name: string;
      uuid: string;
      level: number;
      description?: undefined;
      employees: Array<{
        uuid: string;
        fullName: string;
        role: string;
      }>;
    }>;
  }>;
};

export type AverageSkillEvaluationByCategory = {
  categoryName: string;
  skills: Array<AverageSkillEvaluation>;
};

export type AverageSkillEvaluation = {
  uuid: string;
  name: string;
  level: number;
  levelName: string;
  expectedLevel?: number;
  levelDescription?: string;
  expectedLevelName?: string;
  expectedLevelDescription?: string;
  totalLevel: Array<{ level: number }>;
  levelRange: { max: number; min: number };
};

export type JobAnalytics = {
  skills: number;
  employeesLinked: number;
  employeesEvaluated: number;
  employeesAverageEvaluationsScore: Array<EmployeeAverageScore>;
  evaluationsBySkillCategory: Array<EvaluationBySkillCategory>;
  averageEvaluationBySkills: Array<AverageSkillEvaluationByCategory>;
  skillEvaluations: Array<SkillEvaluation>;
};

export type SkillAnalytics = {
  skillEvaluations: Array<SkillEvaluation>;
  overallAverage: number;
  employeesList: Array<{
    employeeUuid: string;
    level: number;
    fullName: string;
    role: string;
  }>;
  associatedJobs: number;
  associatedEmployees: number;
  evaluatedEmployees: number;
  latestScale?: {
    uuid: string;
    name: string;
    levels: Array<{
      uuid: string;
      name: string;
      level: number;
    }>;
  };
  jobData: Array<{
    jobUuid: string;
    average: number;
    expectedLevel: number;
    jobName: string;
    evaluations: number;
  }>;
};

export type JobAnalytic = {
  uuid: string;
  name: string;
  category?: { name: string; value: string };
  employeesEvaluated: number;
  averageEvaluationScore: string;
  averageExpectedLevelDifference: number | undefined;
  averageSkillsExpectedLevelDifference: { percentage: number; values: string } | undefined;
};

export type SkillAnalytic = {
  uuid: string;
  name: string;
  category: string;
  employeesEvaluated: number;
  averageEvaluationScore: string;
  averageExpectedLevelDifference: number | undefined;
  averageEmployeesExpectedLevelDifference: { percentage: number; values: string } | undefined;
};

export type Analytics = {
  jobAnalytics: Array<JobAnalytic>;
  skillAnalytics: Array<SkillAnalytic>;
};

export type SuggestionsJob = {
  role: string;
  mostRelevantJobId: string;
  mostRelevantJobLabel: string;
  employees: Array<{
    fullName: string;
    employeeID: string;
    employeeSuggestions: Array<{
      jobId: string;
      jobLabel: string;
      suggestionID: string;
    }>;
  }>;
};
