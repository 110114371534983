import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { DSDropdown, DSDropdownItem } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";
import downloadFileAsUser from "utils/downloadFileAsUser";

import { JobRow } from "../utils/parseJobIntoRow";

const ActionsCell = ({
  actions,
  row,
  t,
}: {
  row: JobRow;
  t: TranslationType;
  actions: { archiveRow: (row: JobRow) => void; setRowPrivacy: (row: JobRow) => void };
}) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const pathname = history.location.pathname;
  const { archiveRow, setRowPrivacy } = actions;

  return (
    <DSDropdown>
      <DSDropdownItem
        onClick={() => history.push(`${pathname}/edit/${row.id}`)}
        label={t("jobs.list.label.editJob", {
          defaultValue: "Modifier la fiche de poste",
        })}
      />
      <DSDropdownItem
        onClick={() => history.push(`${pathname}/new/${row.id}`)}
        label={t("jobs.list.label.duplicateJob", {
          defaultValue: "Dupliquer la fiche de poste",
        })}
      />
      <DSDropdownItem
        onClick={() => archiveRow(row)}
        label={t("jobs.list.label.deleteJob", {
          defaultValue: "Archiver la fiche de poste",
        })}
      />
      <DSDropdownItem
        onClick={() => history.push(`/responsable/analyses/fiche-de-poste/${row.id}`)}
        label={t("jobs.list.label.goToAnalytics", {
          defaultValue: "Accéder à la page d’analyse des évaluations",
        })}
      />
      <DSDropdownItem
        label={t("jobs.list.label.downloadJobPDF", {
          defaultValue: "Télécharger la fiche de poste en PDF",
        })}
        onClick={() => {
          const url = `/v1/pdf/job/${row.id}`;
          const filename = `fiche_de_poste_${row.name}.pdf`;
          try {
            downloadFileAsUser(url, filename, "API", "application/pdf");
          } catch (err) {
            addToast(
              t("jobs.list.label.downloadJobPDF.error", {
                defaultValue: "Erreur lors du téléchargement du PDF",
              }),
              {
                appearance: "error",
              }
            );
          }
        }}
      />
      {!row.isPrivate ? (
        <DSDropdownItem
          onClick={() => setRowPrivacy(row)}
          label={t("jobs.list.label.setJobPublic", {
            defaultValue: "Masquer cette fiche de poste pour les collaborateurs",
          })}
        />
      ) : (
        <DSDropdownItem
          onClick={() => setRowPrivacy(row)}
          label={t("jobs.list.label.setJobPrivate", {
            defaultValue: "Afficher cette fiche de poste pour les collaborateurs",
          })}
        />
      )}
    </DSDropdown>
  );
};

export default ActionsCell;
