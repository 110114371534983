import { MdFormatListBulleted as FormatListBulleted } from "react-icons/md";
import { MdChatBubble as ChatBubble } from "react-icons/md";
import { MdChecklist as Checklist } from "react-icons/md";
import { MdTrackChanges as TrackChanges } from "react-icons/md";
import { MdTableRows as TableRows } from "react-icons/md";
import { MdInfoOutline as InfoOutline } from "react-icons/md";
import { MdTextSnippet as TextSnippet } from "react-icons/md";
import { MdFormatSize as FormatSize } from "react-icons/md";
import { MdHistory as History } from "react-icons/md";
import { MdSchool as School } from "react-icons/md";
import { MdArticle as Article } from "react-icons/md";
import { MdAttachFile as AttachFile } from "react-icons/md";
import { MdPsychology as Psychology } from "react-icons/md";
import { MdSwapVert as SwapVert } from "react-icons/md";

import { memo } from "react";

type ChildIconProps = {
  kind: string;
  type?: string;
  size?: string;
};
export const ChildIcon = memo(function ChildIcon({ kind, type, size = "1rem" }: ChildIconProps) {
  switch (kind) {
    case "question":
      if (type === "radio") return <FormatListBulleted size={size} />;
      if (type === "text") return <ChatBubble size={size} />;
      if (type === "checkbox") return <Checklist size={size} />;
      return <ChatBubble size={size} />;
    case "targets":
      return <TrackChanges size={size} />;
    case "table":
      return <TableRows size={size} />;
    case "guidelines":
      return <InfoOutline size={size} />;
    case "personnalisableGuidelines":
      return <InfoOutline size={size} />; // todo verify with clotilde
    case "conclusion":
      return <InfoOutline size={size} />; // todo verify with clotilde
    case "html":
      return <TextSnippet size={size} />;
    case "title":
      return <FormatSize size={size} />;
    case "interviewsHistory":
      return <History size={size} />;
    case "trainingsHistory":
      return <History size={size} />;
    case "trainingsCollection":
      return <School size={size} />;
    case "job":
      return <Article size={size} />;
    case "upload":
      return <AttachFile size={size} />;
    case "globalTargetsCompletion":
      return <TableRows size={size} />;
    case "skills":
      return <Psychology size={size} />;
    case "userSpecificData":
      return <SwapVert size={size} />;
    default:
      return <></>;
  }
});
