import { MdClose as Close } from "react-icons/md";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import cx from "classnames";

import { DSButton } from "@skillup/ui";

import styles from "./SidePanel.module.scss";

export interface Props {
  readonly className?: string;
  readonly title: string;
  readonly subTitle?: string;
  readonly headerDetails?: string;
  readonly headerTopTools?: ReactNode;
  readonly headerBottomTools?: ReactNode;
  readonly children: ReactNode;
  readonly onClose?: () => void;
}

export default ({
  className,
  headerDetails,
  headerTopTools,
  headerBottomTools,
  title,
  subTitle,
  children,
  onClose,
}: Props) => {
  const [isSidePanel, setSidePanel] = useState(false);

  const handleCloseSidePanel = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const escFunction = useMemo(
    () => (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        handleCloseSidePanel();
        event.preventDefault();
      }
    },
    [handleCloseSidePanel]
  );

  useEffect(() => {
    window.addEventListener("keydown", escFunction);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  useEffect(() => {
    if (isSidePanel) return;
    if (!document.getElementById(styles.sidePanel)) {
      const $sidePanel = document.createElement("div");
      $sidePanel.id = styles.sidePanel;
      document.body.appendChild($sidePanel);
    }
    setSidePanel(true);
  }, [isSidePanel]);

  if (!isSidePanel) return <></>;

  return createPortal(
    <div className={cx(styles.sidePanelContent, className)}>
      <header className={styles.header}>
        <div className={styles.closeButtonWrapper}>
          <DSButton
            label="close"
            className={styles.closeBtn}
            darkMode
            onClick={handleCloseSidePanel}
            icon={<Close />}
            iconOnly
          />
        </div>
        <div className={styles.headerTopTools}>{headerTopTools}</div>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>{title}</h1>
          {subTitle && <h2 className={styles.subTitle}>{subTitle}</h2>}
          {headerDetails && <p className={styles.headerDetails}>{headerDetails}</p>}
        </div>
        <div>{headerBottomTools}</div>
      </header>
      <div className={styles.content}>{children}</div>
    </div>,
    document.getElementById(styles.sidePanel) as HTMLDivElement
  );
};
