import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useRef, useMemo, useState, useCallback } from "react";

import { MdPersonAdd as PersonAdd } from "react-icons/md";

import { GridColDef, useGridApiRef, GridInitialState } from "@mui/x-data-grid-pro";

import { Flex } from "@skillup/design-system";
import { UserRoutes } from "@skillup/espace-rh-bridge";
import {
  useModal,
  DSFilters,
  FilterRef,
  useModal2,
  DSDataGrid,
  DSDropdownItem,
  usePersistColumnSettings,
} from "@skillup/ui";

import User from "utils/User";
import { plural } from "utils/locale";
import DSLayout from "components/DSLayout";
import useTranslation from "hooks/useTranslation";
import useSettings, { useAccessCheckFor } from "hooks/useSettings";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
// TODO: Move to UserV2 ?
import AddUserModal from "containers/Supervisor/routes/User/components/AddUserModal";
import EditUserModal from "containers/Supervisor/routes/User/components/EditUserModal/EditUserModal";
import addUserApi, {
  CreateCollaboratorPayload,
} from "containers/Supervisor/routes/User/api/addUser";
import patchUser, {
  UpdateCollaboratorPayload,
} from "containers/Supervisor/routes/User/api/patchUser";

import useTableData from "./utils/useTableData";
import { CollaboratorRow } from "./utils/parseCollaboratorIntoRow";
import { CollaboratorsProvider, useCollaboratorsContext } from "./CollaboratorContext";

const CollaboratorTable = () => {
  return (
    <CollaboratorsProvider>
      <Layout />
    </CollaboratorsProvider>
  );
};

const Layout = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const hasAccessToTrainings = useAccessCheckFor("trainings");
  const { settings } = useSettings();
  const { addToast } = useToasts();
  const filterRef = useRef<FilterRef>();
  const {
    hide: hideCreationModal,
    isOpen: isCreationModalOpen,
    show: showCreationModal,
  } = useModal();
  const {
    hide: closeModal,
    isOpen: isEditCollabModalOpen,
    show: openEditCollabModal,
  } = useModal2();

  const {
    filtersHook,
    paginationHook: { total: totalRows },
    sortHook: { setSortModel, sortModel },
    users,
  } = useCollaboratorsContext();
  const { conf, filters, setFilterValues } = filtersHook;

  const [selectedCollaborator, setSelectedCollaborator] = useState<CollaboratorRow>();
  // const { getUsersData } = useContext();

  const apiRef = useGridApiRef();
  const { gridInitialState } = usePersistColumnSettings({
    apiRef,
    persistenceId: `grid-collaborators`,
  });

  const initialState: GridInitialState = useMemo(() => {
    return {
      pagination: { paginationModel: { page: 0, pageSize: 20 } },
      pinnedColumns: {
        left: ["lastName", "firstName"],
        right: ["actions"],
      },
      ...gridInitialState,
    };
  }, [gridInitialState]);

  const editRowCollaborator = (row: CollaboratorRow) => {
    setSelectedCollaborator(row);
    openEditCollabModal();
  };

  const { columns, rows } = useTableData({
    actions: { editRowCollaborator },
    collaborators: users.collabs,
    maxLevel: users.maxLevel,
    t,
  });

  const handleTableColumnFilterClick = (col: GridColDef) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  };

  const handleRowClick = (row) => {
    history.push(
      `/responsable/collaborateurs/${row.id}${hasAccessToTrainings ? "/formations" : ""}`
    );
  };

  const openExportFile = useCallback(async () => {
    return downloadTypedFileAsUser<UserRoutes.GetUserExportRouteData>(
      {
        method: "GET",
        params: { fileName: "Utilisateurs.xlsx" },
        path: "/user/export/{fileName}",
      },
      {
        filename: "Utilisateurs.xlsx",
        mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        target: "API",
      }
    );
  }, []);

  const openAllUsersExportFile = useCallback(async () => {
    return downloadTypedFileAsUser<UserRoutes.GetAllUsersExportRouteData>(
      {
        method: "GET",
        params: { fileName: "UtilisateursFull.xlsx" },
        path: "/user/export-all/{fileName}",
      },
      {
        filename: "UtilisateursFull.xlsx",
        mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        target: "API",
      }
    );
  }, []);

  const createUser = useCallback(
    async (payload: CreateCollaboratorPayload) => {
      try {
        await addUserApi(payload);
        hideCreationModal();
        // TODO: TO BE IMPLEMENTED WITH CONTEXT
        // if (collaborator) {
        //   dispatch({ type: "addCollab", newCollab: collaborator });
        // }
      } catch (err) {
        addToast(`Impossible de créer un collaborateur ${err?.message}`, {
          appearance: "error",
        });
      }
    },
    [addToast, hideCreationModal]
  );

  const updateUser = useCallback(
    async (uuid: string, payload: UpdateCollaboratorPayload) => {
      try {
        await patchUser(uuid, payload);
        // TODO: TO BE IMPLEMENTED WITH CONTEXT
        // dispatch({ type: "replaceCollab", collab: collaborator });
        addToast("Les données du collaborateur ont été mises à jour.", {
          appearance: "success",
        });
      } catch (err) {
        addToast("Erreur lors de la mise à jour du collaborateur", { appearance: "error" });
      }
    },
    [addToast]
  );

  return (
    <DSLayout
      title={totalRows > 0 ? `Collaborateurs V2 (${users?.collabs.length})` : "Collaborateurs"}
      layouts={[
        {
          dropdownContent: [
            settings?.portalHRUploadUserDBEnabled ? (
              <DSDropdownItem
                key="base-import"
                label="Importer une base"
                onClick={() => history.push("/responsable/collaborateurs/imports")}
              />
            ) : undefined,
            <DSDropdownItem
              key="base-export"
              label="Exporter la base utilisateur"
              onClick={openExportFile}
            />,
            User.isSkillupAdmin() ? (
              <DSDropdownItem
                key="export-all-users"
                label="[OPS] Exporter la base avec les utilisateurs inactifs"
                onClick={openAllUsersExportFile}
              />
            ) : undefined,
          ].filter(Boolean),
          primaryButton: (
            <DSNewHeaderButton
              icon={<PersonAdd />}
              label="Ajouter un collaborateur"
              onClick={showCreationModal}
            />
          ),
        },
      ]}
    >
      <Flex width="100%" paddingTop="m" paddingHorizontal="l" flexDirection="column">
        <DSFilters
          t={t}
          config={conf}
          ref={filterRef}
          filters={filters}
          translationPrefix="collaborators.dataGrid"
          onChange={setFilterValues}
        />

        <DSDataGrid
          pagination
          rows={rows ?? []}
          columns={columns}
          rowCount={totalRows}
          sortModel={sortModel}
          loading={users.isLoading}
          disableRowSelectionOnClick
          initialState={initialState}
          // paginationModel={actualPaginationModel}
          onRowClick={handleRowClick}
          onSortModelChange={setSortModel}
          onFilter={handleTableColumnFilterClick}
          // onPageChange={(page, pageSize) => {
          //   setPaginationModel({ page, pageSize });
          // }}
          emptyOverlay={{
            text: t("collaborators.list.emptyOverlayText", {
              defaultValue: "Aucun collaborateur ne correspond à votre recherche.",
            }),
          }}
          entityName={plural(
            totalRows || 0,
            `%n ${t("collaborators.list.collaborator.entityName", {
              defaultValue: "collaborateur",
            })}%s`
          )}
          errorOverlay={{
            text: [
              t("collaborators.list.errorOverlayText.firstSentence", {
                defaultValue: `Une erreur est survenue lors du chargement des collaborateurs.`,
              }),
              t("collaborators.list.errorOverlayText.secondSentence", {
                defaultValue: `Veuillez réessayer ultérieurement.`,
              }),
              t("collaborators.list.errorOverlayText.thirdSentence", {
                defaultValue: `Si l’erreur persiste, contactez votre interlocuteur Skillup.`,
              }),
            ],
          }}
        />

        {isCreationModalOpen && (
          <AddUserModal loading={false} submitRequest={createUser} onClose={hideCreationModal} />
        )}

        {selectedCollaborator && (
          <EditUserModal
            updateRequest={updateUser}
            isOpen={isEditCollabModalOpen}
            userUuid={selectedCollaborator.uuid}
            //FIXME: Fix this type
            userData={selectedCollaborator as any}
            closeModal={() => {
              closeModal();
              //TODO: TO BE IMPLEMENTED WITH CONTEXT
              // dispatch({ type: "resetSelection" });
            }}
          />
        )}
      </Flex>
    </DSLayout>
  );
};

export default CollaboratorTable;
