import { generatePath, useHistory, useParams } from "react-router-dom";
import { useToggle } from "react-use";
import { useToasts } from "react-toast-notifications";
import { Formik, useFormikContext } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { Loader, useMediaQueries } from "@skillup/ui";

import useTranslation, { TranslationType } from "hooks/useTranslation";
import { trpc } from "utils/trpc";

import DSLayout from "components/DSLayout";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import EvaluationScaleForm, { rewriteLevels } from "../../components/Form/EvaluationScaleForm";
import {
  EvaluationScale,
  UpdatedFormEvaluationLevel,
  UpdatedFormEvaluationScale,
} from "types/skills";
import { UpdateSkillEvaluationScaleSchema } from "../../SkillEvaluationScalesFormSchemas";
import { useMemo } from "react";

interface UpdateEvaluationScalePayload {
  uuid: string;
  version: number;
  name: string;
  command: string;
  levels: Array<UpdatedFormEvaluationLevel>;
}

interface ButtonProps {
  t: TranslationType;
  loading: boolean;
}

const getLevels = (
  levels: Array<Omit<UpdatedFormEvaluationLevel, "frontId"> | undefined>
): Array<UpdatedFormEvaluationLevel> => {
  return (levels || []).map((level, index) => {
    return {
      frontId: level.uuid,
      uuid: level.uuid,
      level: level.level,
      name: level.name,
      description: level.description || "",
      deletable: index > 1 ? true : false,
    };
  });
};

const HandleSubmitButton = ({ t, loading }: ButtonProps) => {
  const { submitForm, isValid, dirty } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const tooltipStr = t("portal.config.skills.tooltip", {
    defaultValue:
      "Vous devez remplir tous les champs obligatoires (indiqués par une astérisque rouge)",
  });
  const isDisabled = !isValid || !dirty;

  return (
    <DSNewHeaderButton
      label={t("portal.config.skills.button.update.label", {
        defaultValue: "Mettre à jour l'échelle d'évaluation",
      })}
      onClick={handleSubmit}
      loading={loading}
      type="submit"
      darkMode={true}
      disabled={isDisabled}
      tooltip={isDisabled ? tooltipStr : undefined}
      tooltipDarkMode={false}
    />
  );
};

const UpdateEvaluationScale = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, toggleLoading] = useToggle(false);
  const { isMobile } = useMediaQueries();
  const { addToast } = useToasts();
  const { uuid } = useParams<{ uuid: string }>();
  const { data, error } = trpc.skillEvaluationsScales.getById.useQuery({ uuid });
  const skillEvaluationsScale: EvaluationScale | undefined = data as EvaluationScale | undefined;
  const skillEvaluationsScalesUpdateMutator = trpc.skillEvaluationsScales.update.useMutation({
    onSuccess: () => {
      toggleLoading(false);
      addToast(
        t("portal.config.skills.updateEvaluationScale.success", {
          defaultValue: "L'échelle d'évaluation a bien été mise à jour !",
        }),
        {
          appearance: "success",
        }
      );
      history.push(
        "/responsable/configuration-du-module-competences/gestion-des-echelles-d-evaluation"
      );
    },
    onError: () => {
      toggleLoading(false);
      addToast(
        t("portal.config.skills.updateEvaluationScale.error", {
          defaultValue: "L'échelle d'évaluation n'a pas pu être mise à jour.",
        }),
        {
          appearance: "error",
        }
      );
    },
  });

  const initialValues = useMemo(() => {
    return {
      uuid: skillEvaluationsScale?.uuid,
      evaluationScaleName: skillEvaluationsScale?.name,
      startAtZero: skillEvaluationsScale?.levels.some((level) => level.level === 0),
      hasDescriptions: skillEvaluationsScale?.levels.some((level) => level.description),
      levels: getLevels(skillEvaluationsScale?.levels),
    };
  }, [skillEvaluationsScale]);

  if (error)
    history.push(
      "/responsable/configuration-du-module-competences/gestion-des-echelles-d-evaluation"
    );

  if (!skillEvaluationsScale) return <Loader />;

  const handleSubmit = async (values: UpdatedFormEvaluationScale) => {
    toggleLoading(true);
    const payload: UpdateEvaluationScalePayload = {
      uuid: skillEvaluationsScale.uuid,
      version: skillEvaluationsScale.version + 1,
      command: "update",
      name: values.evaluationScaleName,
      levels: rewriteLevels(values.startAtZero, values.levels),
    };
    skillEvaluationsScalesUpdateMutator.mutate(payload);
  };

  return (
    <>
      {isMobile ? (
        <NotAvailableInMobileView />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(UpdateSkillEvaluationScaleSchema)}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          enableReinitialize
        >
          {(formikProps) => {
            return (
              <DSLayout
                title={t("portal.config.skills.title.update.label", {
                  defaultValue: "Modification d'une échelle d'évaluation",
                })}
                layouts={
                  [
                    {
                      primaryButton: <HandleSubmitButton t={t} loading={loading} />,
                    },
                  ] as const
                }
                parent={{
                  title: t("portal.config.skills.title.configuration", {
                    defaultValue: "Configuration des échelles d'évaluation",
                  }),
                  titleUrl: generatePath(
                    "/responsable/configuration-du-module-competences/gestion-des-echelles-d-evaluation"
                  ),
                }}
              >
                <EvaluationScaleForm formikProps={formikProps} />
              </DSLayout>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default UpdateEvaluationScale;
