import { useTranslation } from "react-i18next";
import { Suspense, useState, useEffect } from "react";

import { MdSearch as Search } from "react-icons/md";

import { format } from "@skillup/shared-utils";
import { Text, Flex, Loader } from "@skillup/design-system";
import { DSModal, DSButton, HorizontalDivider } from "@skillup/ui";

import User from "utils/User";
import { DatatableJob } from "types/skills";

import { JobsProvider, useJobsContext } from "../../JobsContext";
import { SearchInput, StyledArchiveModal } from "../../pages/List/Jobs.styled";

interface ArchiveModalProps {
  open: boolean;
  close: () => void;
  archivedJobs: Array<DatatableJob>;
  handleSetArchive: (jobsUuid: Array<string>, isArchive: boolean) => void;
}

export const ArchiveModal = ({
  archivedJobs,
  close,
  handleSetArchive,
  open,
}: ArchiveModalProps) => {
  return (
    <JobsProvider>
      <Suspense fallback={<Loader fillSpace />}>
        <Layout
          open={open}
          close={close}
          archivedJobs={archivedJobs}
          handleSetArchive={handleSetArchive}
        />
      </Suspense>
    </JobsProvider>
  );
};
const Layout = ({ archivedJobs, close, handleSetArchive, open }: ArchiveModalProps) => {
  const [archivedSearchQuery, setArchivedSearchQuery] = useState<string>();
  const { t } = useTranslation();

  const { removeJob } = useJobsContext();

  const archivedJobsToDisplay =
    !archivedSearchQuery || archivedSearchQuery.trim() === ""
      ? archivedJobs
      : archivedJobs.filter((job) =>
          `${job.name}`.toLowerCase().includes(archivedSearchQuery.toLowerCase())
        );

  const handleDeleteJob = (jobsUuid: string) => {
    const job = archivedJobs.find((job) => jobsUuid.includes(job.uuid));

    close();
    removeJob(job.uuid, job.version);
  };

  useEffect(() => {
    if (archivedJobsToDisplay.length === 1) {
      close();
    }
  }, [archivedJobsToDisplay, close]);

  return (
    <StyledArchiveModal isOpen={open} portalTarget={document.body}>
      <DSModal.Header onClose={() => close()}>
        <DSModal.Header.Title
          title={
            archivedJobs?.length > 1
              ? t("jobs.label.list.archivedJobs", {
                  count: archivedJobs?.length,
                  defaultValue: "{{count}} fiches de poste archivées",
                })
              : t("jobs.button.list.archivedJob", {
                  defaultValue: "1 fiche de poste archivée",
                })
          }
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex height="100%" flexDirection="column">
          <Flex height="100%" marginTop="xs" flexDirection="row" className="modalSearch">
            <SearchInput
              name="searchJob"
              className="searchInput"
              actionButton={<Search />}
              value={archivedSearchQuery}
              onChange={setArchivedSearchQuery}
              placeholder={t("jobs.label.list.searchJob", {
                defaultValue: "Rechercher une fiche de poste",
              })}
            />
          </Flex>
          {archivedJobsToDisplay.length > 0 ? (
            <Flex height="100%" flexDirection="column">
              {archivedJobsToDisplay?.map((job, index) => (
                <Flex width="100%" key={job.uuid} flexDirection="column">
                  <Flex gap="xxs" width="100%" alignItems="center">
                    <Flex width="100%" overflow="hidden" flexDirection="column">
                      <Text
                        hasEllipsis
                        marginRight="s"
                        fontSize="1rem"
                        espaceFont="body1Regular"
                        color="plainText-onLight-default"
                      >
                        {job.name}
                      </Text>
                      <Text
                        fontSize="0.75rem"
                        espaceFont="body1Regular"
                        color="plainText-onLight-lighter"
                      >
                        {t("jobs.label.list.modal.rowArchived", {
                          archivedDate: format(job.updatedAt, "dd/MM/yyyy"),
                          archivedUser: job?.updatedBy ? job?.updatedBy.fullName : "Admin Skillup",
                          defaultValue:
                            "Fiche de poste archivée le {{archivedDate}} par {{archivedUser}}",
                        })}
                      </Text>
                    </Flex>
                    <DSButton
                      emphasis="Mid"
                      buttonSize="S"
                      className="modalArchiveBtn"
                      onClick={() => {
                        handleSetArchive([job.uuid], false);
                      }}
                      label={t("jobs.label.list.modal.rowButton", {
                        defaultValue: "Désarchiver",
                      })}
                    />
                    {User.isSkillupAdmin() && (
                      <DSButton
                        emphasis="Mid"
                        buttonSize="S"
                        className="modalArchiveBtn"
                        onClick={() => {
                          handleDeleteJob(job.uuid);
                        }}
                        label={t("jobs.label.list.modal.deleteRowButton", {
                          defaultValue: "[DEV] Supprimer",
                        })}
                      />
                    )}
                  </Flex>
                  {archivedJobsToDisplay?.length - 1 !== index && (
                    <HorizontalDivider top={"xs"} bottom={"xs"} />
                  )}
                </Flex>
              ))}
            </Flex>
          ) : (
            <Text marginBottom="s" espaceFont="body1Regular">
              {t("skills.archivedJobsModal.noResult", {
                defaultValue: "Aucune fiche de poste ne correspond à votre saisie",
              })}
            </Text>
          )}
        </Flex>
      </DSModal.Content>
      <DSModal.FooterEmpty />
    </StyledArchiveModal>
  );
};
