import { MdDragIndicator as DragIndicator } from "react-icons/md";
import { MdError as Error } from "react-icons/md";
import { memo, useMemo } from "react";
import cx from "classnames";

import { DSButton, DSTooltip, Flex } from "@skillup/ui";

import { ChildIcon } from "../../../ChildIcon";
import { ItemActions } from "../ItemActions";

import styles from "../../TemplateBuilderStructure.module.scss";

export const StructureChild = memo(function StructureChild({
  id,
  kind,
  type,
  label,
  errors,
  dragListeners,
  displayMenuOnHover = true,
  highlight = false,
  onClick,
  onFocus,
  onDuplication,
  onDeletion,
  showActionsButtons = false,
}: {
  id?: string;
  kind: string;
  type?: string;
  label?: string;
  errors?: string[];
  dragListeners?: any;
  displayMenuOnHover?: boolean;
  highlight?: boolean;
  onClick?: () => void;
  onFocus?: () => void;
  onDuplication?: () => void;
  onDeletion?: () => void;
  showActionsButtons?: boolean;
}) {
  const childKind = useMemo(() => {
    switch (kind) {
      case "question":
        if (type === "radio") {
          return "Choix multiple (une seule réponse possible)";
        }
        if (type === "checkbox") {
          return "Choix multiple (plusieurs réponses possibles)";
        }
        if (type === "text") {
          return "Question ouverte";
        }
        return "Unknown question type";
      case "targets":
        if (type === "current") {
          return "Revue des objectifs";
        }
        return "Fixation d'objectifs";
      case "table":
        return "Tableau";
      case "guidelines":
        return "Introduction";
      case "personnalisableGuidelines":
        return "En-tête de l'entretien";
      case "conclusion":
        return "Partage et signature";
      case "html":
        return "HTML";
      case "title":
        return "Titre";
      case "interviewsHistory":
        return "Historique d'entretiens";
      case "trainingsHistory":
        return "Historique de formation";
      case "trainingsCollection":
        return "Demande de formation";
      case "job":
        return "Fiche de poste";
      case "upload":
        return "Téléchargement de fichiers";
      case "globalTargetsCompletion":
        return "Taux d’atteinte global";
      case "skills":
        return "Compétences";
      case "userSpecificData":
        return "Données spécifiques à l'utilisateur";
      default:
        return "Unknown child kind";
    }
  }, [kind, type]);
  const emptyLabel = useMemo(() => label?.length === 0, [label]);
  return (
    <Flex
      row
      className={cx(
        styles.child,
        displayMenuOnHover ? styles["displayMenuOnHover"] : "",
        highlight ? styles.highlight : "",
        errors?.length ? styles["error"] : ""
      )}
      id={id}
      tabIndex={0}
      onClick={onClick}
      onFocus={onFocus}
    >
      {showActionsButtons ? (
        <DSButton
          iconOnly
          buttonSize="S"
          icon={<DragIndicator />}
          className={styles["drag-indicator"]}
          {...dragListeners}
        />
      ) : (
        <div className={styles["no-drag-indicator"]} />
      )}
      {errors?.length > 0 ? (
        <DSTooltip label={errors[0]}>
          <Error className={styles["error-icon"]} />
        </DSTooltip>
      ) : (
        <div className={styles["no-error-icon"]}></div>
      )}
      <div className={styles["child-icon"]}>
        <DSTooltip label={childKind}>
          <ChildIcon kind={kind} type={type} />
        </DSTooltip>
      </div>
      <div className={cx(styles["ellipsisBlock"], emptyLabel ? styles["emptyChildLabel"] : "")}>
        {emptyLabel ? "Sans libellé" : label}&nbsp;
      </div>
      <Flex grow />
      <ItemActions
        showActionsButtons={showActionsButtons}
        onDuplication={onDuplication}
        onDeletion={onDeletion}
      />
    </Flex>
  );
});
