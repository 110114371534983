import { MdRemoveRedEye as RemoveRedEye } from "react-icons/md";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import qs from "qs";

import { DSCheckbox, DSButton, DSCard, DSTextInput, Select } from "@skillup/ui";

import Loader from "components/Loader";
import { useTemplates } from "services/interviews";
import { TargetAlert } from "./TargetAlert";

import styles from "./Steps.module.scss";

type Template = {
  uuid: string;
  title: string;
};

interface IProps {
  readonly template?: Template;
  readonly setQuestionsTemplate: (template: Template) => void;
  readonly titleForHR: string;
  readonly titleForAttendees: string;
  readonly setCampaignTitle: (campaignName: string, scope: "HR" | "attendees") => void;
  readonly displayTargetAlert?: boolean;
}

function useQueryTemplateUuid() {
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  return query.template as string;
}
const QuestionsStep = (props: IProps) => {
  const templateUuid = useQueryTemplateUuid();
  const {
    template,
    setQuestionsTemplate,
    titleForHR,
    titleForAttendees,
    setCampaignTitle,
    displayTargetAlert,
  } = props;

  const { templates, isLoading } = useTemplates({ refetchOnWindowFocus: false });

  const selectTemplate = (selection) => {
    if (selection) {
      const selectedTemplate = templates.find((t) => t.uuid === selection);
      return setQuestionsTemplate({
        title: selectedTemplate.title,
        uuid: selectedTemplate.uuid,
      });
    }
  };

  useEffect(() => {
    if (!template && templateUuid && templates.length) {
      const templateFromURL = templates.find((t) => t.uuid === templateUuid);
      if (templateFromURL) {
        setQuestionsTemplate({ title: templateFromURL.title, uuid: templateFromURL.uuid });
      }
    }
  }, [template, templateUuid, templates, setQuestionsTemplate]);

  const [checkedbox, setCheckedbox] = useState(false);
  const [linkTemplate, setLinkTemplate] = useState("#");
  useEffect(() => {
    if (template) setLinkTemplate(`/responsable/template/${template.uuid}`);
  }, [template]);

  return (
    <div className={styles.CardWrapper}>
      <DSCard className={styles.QuestionsStep} showTitle={false}>
        {isLoading || !templates ? (
          <Loader />
        ) : (
          <div className={styles.Input}>
            <label className={styles.requiredField}>Choisir la trame de l'entretien</label>
            <div className={styles.Dropdown}>
              <Select
                options={templates.map(({ uuid, title }) => ({ label: title, value: uuid }))}
                onChange={selectTemplate}
                placeholder={template ? template.title : "Rechercher"}
                selectPlaceholder="Rechercher"
                value={template ? template.uuid : null}
                aria-label={"template-dropdown"}
              />
              {template ? (
                <Link to={linkTemplate} target="_blank">
                  <DSButton iconOnly icon={<RemoveRedEye />} buttonSize="M" />
                </Link>
              ) : (
                <DSButton iconOnly icon={<RemoveRedEye />} buttonSize="M" disabled={true} />
              )}
            </div>
            <p>Ce paramètre ne pourra pas être modifié une fois la campagne créée.</p>
            {displayTargetAlert && <TargetAlert />}
            <label className={styles.requiredField}>Nom de la campagne pour les RH</label>
            <DSTextInput
              placeholder="Ex: Entretiens trimestriels Q2"
              name="title-for-hr"
              value={titleForHR}
              onChange={(value) => {
                setCampaignTitle(value, "HR");
                if (!checkedbox) setCampaignTitle(value, "attendees");
              }}
              required
            />
            <DSCheckbox
              className={styles.Checkbox}
              label="Définir un nom différent pour les participants"
              checked={checkedbox}
              onChange={() => {
                setCheckedbox(!checkedbox);
                if (checkedbox) setCampaignTitle(titleForHR, "attendees");
              }}
            />
            {checkedbox && (
              <>
                <label className={styles.requiredField}>
                  Nom de la campagne pour les participants
                </label>
                <DSTextInput
                  placeholder="Ex: Entretiens trimestriels Q2"
                  name="title-for-attendees"
                  value={titleForAttendees}
                  onChange={(value) => setCampaignTitle(value, "attendees")}
                  required
                />
              </>
            )}
          </div>
        )}
      </DSCard>
    </div>
  );
};

export default QuestionsStep;
