import { useHistory } from "react-router-dom";

import { getInitials } from "@skillup/shared-utils";
import type { EvaluationScaleLevel } from "@skillup/gepp";
import { DSSidePanel, DSAvatarGroup, DSDropdownItem, DSFormGroupTextInput } from "@skillup/ui";

import { usersToAvatar } from "helpers/functions";
import useTranslation from "hooks/useTranslation";
import GetBaseURLFromLocation from "utils/GetBaseURLFromLocation";

import { SkillRow } from "../../utils/parseSkillIntoRow";

import styles from "../../ListSkills.module.scss";

interface SkillsSidePanelProps {
  skill: SkillRow;
  isOpen: boolean;
  close: () => void;
  openConfirmModal: () => void;
}

const sortByLevel = (a: EvaluationScaleLevel, b: EvaluationScaleLevel) => {
  return a.level - b.level;
};

const SkillsSidePanel = ({ close, isOpen, openConfirmModal, skill }: SkillsSidePanelProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = history.location;

  const baseURL = GetBaseURLFromLocation(pathname);

  const handleMainActionClicked = () => {
    history.push(`${pathname}/edit/${skill.uuid}`);
  };

  const goToJob = (uuid: string) => {
    const jobUrl = `${baseURL}/referentiels/fiches-de-poste/${uuid}`;
    history.push(jobUrl);
  };

  const sortedEvaluationScaleLevels = skill.evaluationScale?.levels.sort(sortByLevel);

  const sortedJob = Object.values(skill.jobs).sort((a, b) => a.name.localeCompare(b.name));

  return (
    <DSSidePanel isOpen={isOpen} portalTarget={document.body} className={styles.skillSidePanel}>
      <DSSidePanel.Header onClose={close}>
        <DSSidePanel.Header.Title title={skill.name} />
        <DSSidePanel.Header.Actions
          moreActions={[
            <DSDropdownItem
              key="edit"
              onClick={handleMainActionClicked}
              label={t("skills.list.sidepanel.editSkill", {
                defaultValue: "Modifier la compétence",
              })}
            />,
            <DSDropdownItem
              key="archive"
              onClick={() => {
                openConfirmModal();
              }}
              label={t("skills.list.sidepanel.archiveSkill", {
                defaultValue: "Archiver la compétence",
              })}
            />,
            <DSDropdownItem
              key="analytics"
              onClick={() => history.push(`/responsable/analyses/competence/${skill.uuid}`)}
              label={t("skills.list.label.goToAnalytics", {
                defaultValue: "Accéder à la page d’analyse des évaluations",
              })}
            />,
          ]}
        />
      </DSSidePanel.Header>
      <DSSidePanel.Content>
        <div className={styles.skillContainer}>
          <div className={styles.skillContent}>
            <DSFormGroupTextInput
              className={styles.skillContentRow}
              label={t("skills.list.sidepanel.category", {
                defaultValue: "Catégorie",
              })}
            >
              {skill.category ? (
                <p className={styles.skillContentValue}>{skill.category.label}</p>
              ) : (
                <p className={styles.skillContentPlaceholder}>
                  {t("skills.list.sidepanel.noCategories", {
                    defaultValue: "Non renseigné",
                  })}
                </p>
              )}
            </DSFormGroupTextInput>

            {skill.fields.length > 0 && (
              <section className={styles.skillContentList}>
                {skill.fields.map(
                  (field) =>
                    field.value && (
                      <DSFormGroupTextInput
                        key={field.uuid}
                        label={field.name}
                        className={styles.skillContentRow}
                      >
                        <p className={styles.skillContentValue}>{field.value}</p>
                      </DSFormGroupTextInput>
                    )
                )}
              </section>
            )}

            {skill.description && (
              <DSFormGroupTextInput label="Description" className={styles.skillContentRow}>
                <p
                  className={styles.skillContentValue}
                  dangerouslySetInnerHTML={{
                    __html: skill.description,
                  }}
                />
              </DSFormGroupTextInput>
            )}

            {skill.evaluationScale?.levels && (
              <DSFormGroupTextInput
                className={styles.skillContentRow}
                label={t("skills.list.sidepanel.evaluationScale", {
                  defaultValue: "Échelle d'évaluation",
                })}
              >
                <section className={styles.skillContentList}>
                  {sortedEvaluationScaleLevels.map(
                    ({ uuid, customDescription, description, level, name }) => (
                      <p key={uuid} className={styles.skillContentValue}>
                        {level}. {name}
                        {customDescription || description
                          ? `- ${customDescription ?? description}`
                          : ``}
                      </p>
                    )
                  )}
                </section>
              </DSFormGroupTextInput>
            )}

            <DSFormGroupTextInput
              className={styles.skillContentRow}
              label={t("skills.list.sidepanel.jobsLinked", {
                defaultValue: "Fiches de poste auxquelles cette compétence est liée",
              })}
            >
              <section className={styles.skillContentList}>
                {Object.keys(sortedJob).length > 0 ? (
                  Object.values(sortedJob).map(({ uuid, name }) => (
                    <p key={uuid} className={styles.skillContentLink} onClick={() => goToJob(uuid)}>
                      {name}
                    </p>
                  ))
                ) : (
                  <p className={styles.skillContentPlaceholder}>
                    {t("skills.list.sidepanel.noJobsLinked", {
                      defaultValue: "Aucune fiche de poste reliée à cette compétence",
                    })}
                  </p>
                )}
              </section>
            </DSFormGroupTextInput>

            {Object.keys(skill.employees).length > 0 && (
              <DSFormGroupTextInput
                className={styles.skillContentRow}
                label={t("skills.list.sidepanel.collaboratorsLinked", {
                  defaultValue:
                    "Collaborateurs associés à une fiche de poste liée à cette compétence",
                })}
              >
                <DSAvatarGroup
                  size="S"
                  maxItems={5}
                  totalItems={Object.keys(skill.employees).length}
                  users={Object.values(skill.employees).map(usersToAvatar)}
                />
              </DSFormGroupTextInput>
            )}
          </div>
        </div>
      </DSSidePanel.Content>
      {skill.updatedBy && (
        <DSSidePanel.Footer>
          <DSSidePanel.Footer.LastUpdateAvatar
            initials={getInitials({ fullName: skill.updatedBy.fullName })}
          />
          <DSSidePanel.Footer.LastUpdateText
            fullName={skill.updatedBy?.fullName}
            updatedAt={new Date(skill.updatedAt)}
          />
        </DSSidePanel.Footer>
      )}
    </DSSidePanel>
  );
};

export default SkillsSidePanel;
