import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExtractRouteParams } from "react-router";
import { Link, useParams, useHistory, generatePath, useRouteMatch } from "react-router-dom";

import cx from "classnames";

import { useQuery } from "@tanstack/react-query";

import { Flex, Text, Loader } from "@skillup/design-system";
import { DSColor, DSModal, DSAvatar, DSTextInput, DSFormGroupTextInput } from "@skillup/ui";

import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import {
  getReviewCriteria,
  getReviewCriteriaCacheKey,
} from "services/coreHR/fields/getReviewCriteria";
import {
  getReviewCriteriaHistory,
  getReviewCriteriaHistoryCacheKey,
} from "services/coreHR/fields/getReviewCriteriaHistory";

import { CoreHRRoutes } from "..";

import styles from "./ReviewCriteria.module.scss";

export function ReviewCriteria() {
  const { t } = useTranslation();
  const { id } = useParams<ExtractRouteParams<typeof CoreHRRoutes.reviewCriteria, string>>();
  const { data } = useQuery(getReviewCriteriaCacheKey(id), getReviewCriteria, {
    enabled: Boolean(id),
  });

  return (
    <DSLayout
      title={data?.title}
      parent={{
        title: t("core-hr.review-criteria.title", {
          defaultValue: "Configuration des critères d'évaluation",
        }),
        titleUrl: generatePath(CoreHRRoutes.reviewCriteriaList),
      }}
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              href={generatePath(CoreHRRoutes.reviewCriteriaEdit, { id })}
              label={t("core-hr.review-criteria.edit", { defaultValue: "Modifier le critère" })}
            />
          ),
        },
      ]}
    >
      {data ? (
        <>
          <Flex gap="l" padding="l">
            <Flex gap="l" width="50%" flexDirection="column">
              <Text espaceFont="h5" color="plainText-onLight-lighter">
                {t("core-hr.review-criteria.definition", { defaultValue: "Définition" })}
              </Text>

              {/* Using DSFormGroupTextInputs only to follow design */}
              <DSFormGroupTextInput
                label={t("core-hr.review-criteria.form.title.label", {
                  defaultValue: "Intitulé du critère",
                })}
                assistiveText={t("core-hr.review-criteria.form.title.assistive-text", {
                  defaultValue: "Clé : {{key}}",
                  key: data.key,
                })}
              >
                <DSTextInput readOnly name="title" value={data.title} />
              </DSFormGroupTextInput>

              <DSFormGroupTextInput
                label={t("core-hr.review-criteria.form.description.label", {
                  defaultValue: "Description",
                })}
              >
                <Text espaceFont="body1Regular" color="plainText-onLight-default">
                  {data.description}
                </Text>
              </DSFormGroupTextInput>

              <Flex gap="xxs" marginTop="auto" alignItems="baseline">
                <Text
                  display="inline"
                  espaceFont="captionRegular"
                  color="plainText-onLight-lighter"
                >
                  {t("core-hr.review-criteria.version.number", {
                    defaultValue: "Version {{version}} -",
                    version: data.version,
                  })}
                </Text>

                <Link
                  replace
                  className={styles.versionLink}
                  to={generatePath(CoreHRRoutes.reviewCriteriaHistory, { id })}
                >
                  <Text display="inline" espaceFont="captionRegular" color="action-onLight-darkest">
                    {t("core-hr.review-criteria.version.open-modal", {
                      defaultValue: "Voir l'historique des versions",
                    })}
                  </Text>
                </Link>
              </Flex>
            </Flex>

            <Flex gap="l" width="50%" flexDirection="column">
              <Text espaceFont="h5" color="plainText-onLight-lighter">
                {t("core-hr.review-criteria.scales", { defaultValue: "Échelle d'évaluation" })}
              </Text>

              {data.options.map(({ description, label, value }) => (
                <Flex key={value} flexDirection="column">
                  <Text espaceFont="body1Bold" color="plainText-onLight-lighter">
                    {label}
                  </Text>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {description}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>

          <Modal />
        </>
      ) : (
        <Loader />
      )}
    </DSLayout>
  );
}

function Modal() {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch({ path: CoreHRRoutes.reviewCriteriaHistory });
  const isOpen = Boolean(match);
  const { id, selectedVersion } =
    useParams<ExtractRouteParams<typeof CoreHRRoutes.reviewCriteriaHistory, string>>();
  const { data } = useQuery(getReviewCriteriaHistoryCacheKey(id), getReviewCriteriaHistory, {
    enabled: isOpen,
  });
  const selectedReviewCriteriaVersion = useMemo(
    () => data?.versions.find(({ version }) => Number(selectedVersion) === version),
    [data, selectedVersion]
  );

  if (!isOpen || !data) {
    return null;
  } else {
    if (!selectedReviewCriteriaVersion) {
      history.replace({
        pathname: generatePath(CoreHRRoutes.reviewCriteriaHistory, {
          id,
          selectedVersion: data.versions[0].version,
        }),
      });
      return null;
    }
  }

  return (
    <DSModal isOpen className={styles.historyModal}>
      <DSModal.Header
        onClose={() => {
          history.replace(generatePath(CoreHRRoutes.reviewCriteria, { id }));
        }}
      >
        <DSModal.Header.Title
          title={t("core-hr.review-criteria.history.title", {
            defaultValue: "Historique des modifications",
          })}
        />
        <DSModal.Header.SubHeader>
          <Text espaceFont="captionBold" color="plainText-onLight-lighter">
            {t("core-hr.review-criteria.history.subtitle", {
              defaultValue: "Critère d'évaluation : {{label}} (Clé : {{key}})",
              key: data.key,
              label: data.title,
            })}
          </Text>
        </DSModal.Header.SubHeader>
      </DSModal.Header>

      <DSModal.Content
        backgroundColor="transparent"
        className={styles.modalContent}
        borderColor={DSColor["border-onLight"]}
      >
        <Flex>
          <Flex flexDirection="column" className={styles.sidebar}>
            {data.versions.map(({ createdAt, createdBy, version }) => (
              <Link
                replace
                key={version}
                className={styles.listItemLink}
                to={{
                  pathname: generatePath(CoreHRRoutes.reviewCriteriaHistory, {
                    id,
                    selectedVersion: version,
                  }),
                }}
              >
                <Flex
                  gap="xxs"
                  padding="s"
                  flexDirection="column"
                  className={cx({
                    [styles.listItem]: true,
                    [styles.selectedListItem]: version === Number(selectedVersion),
                  })}
                >
                  <Text espaceFont="body1Bold" color="plainText-onLight-default">
                    {t("core-hr.review-criteria.history.list.version", {
                      defaultValue: "Version {{version}}",
                      version,
                    })}
                  </Text>

                  <Flex gap="xs">
                    <DSAvatar
                      darkMode
                      size="S"
                      type="Initials"
                      initials={createdBy.initials}
                      randomColorSeedString={createdBy.id}
                    />
                    <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                      {t("core-hr.review-criteria.history.list.last-update", {
                        createdAt: new Date(createdAt).toLocaleDateString(),
                        createdBy: createdBy.fullname,
                        defaultValue: "Créée le {{createdAt}} par {{createdBy}}",
                      })}
                    </Text>
                  </Flex>
                </Flex>
              </Link>
            ))}
          </Flex>

          <Flex gap="l" padding="s m" flexDirection="column" backgroundColor="surface-light-darker">
            <Flex gap="s" flexDirection="column">
              <Text espaceFont="h5" color="plainText-onLight-default">
                {t("core-hr.review-criteria.history.content.title", {
                  defaultValue: "{{title}} - Version {{version}}",
                  title: data.title,
                  version: selectedReviewCriteriaVersion.version,
                })}
              </Text>

              <Text espaceFont="h6" color="plainText-onLight-lighter">
                {t("core-hr.review-criteria.history.content.definition", {
                  defaultValue: "Définition",
                })}
              </Text>

              {/* Using DSFormGroupTextInputs only to follow design */}
              <DSFormGroupTextInput
                label={t("core-hr.review-criteria.history.content.definition.title", {
                  defaultValue: "Intitulé du critère",
                })}
              >
                <DSTextInput readOnly name="title" value={selectedReviewCriteriaVersion.title} />
              </DSFormGroupTextInput>

              <DSFormGroupTextInput
                label={t("core-hr.review-criteria.history.content.definition.description", {
                  defaultValue: "Description",
                })}
              >
                <Text espaceFont="body1Regular" color="plainText-onLight-default">
                  {selectedReviewCriteriaVersion.description}
                </Text>
              </DSFormGroupTextInput>
            </Flex>

            <Flex gap="s" flexDirection="column">
              <Text espaceFont="h6" color="plainText-onLight-lighter">
                {t("core-hr.review-criteria.history.content.scales", {
                  defaultValue: "Échelle d'évaluation",
                })}
              </Text>

              {selectedReviewCriteriaVersion.options.map(({ description, label, value }) => (
                <Flex key={value} flexDirection="column">
                  <Text espaceFont="body1Bold" color="plainText-onLight-lighter">
                    {label}
                  </Text>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {description}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </DSModal.Content>

      <DSModal.FooterEmpty />
    </DSModal>
  );
}
