import { MdMoreVert as MoreVert } from "react-icons/md";
import { useMemo } from "react";

import SidePanel from "components/SidePanel";
import TrackingStatus from "../../Trackings/components/TrackingStatus";
import { translateState } from "../../Trackings/components/TrackingState";
import TrackingDuration from "../TrackingDuration/TrackingDuration";
import DocumentList from "../../Trackings/components/DocumentList/DocumentList";
import {
  HorizontalDivider,
  DSButton,
  DSFormGroupTextInput,
  Label,
  DSDropdown,
  DSDropdownItem,
  DSAvatar,
} from "@skillup/ui";

import styles from "./TrackingSidePanel.module.scss";
import { getInitials } from "@skillup/shared-utils";

import { Tracking } from "../../types";
import { TrackingAction } from "../../Trackings/useTrackingActions";

import { format } from "@skillup/shared-utils";
import { useHabilitationByUuid } from "../../state/habilitations";

function formatDate(d: string) {
  if (!d) return "";
  return new Date(d).toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
}
interface Props {
  readonly tracking: Tracking;
  readonly onClose: () => void;
  readonly refetchTrackings: () => void;
  readonly openActionModal: (trackingUuid: string, action: TrackingAction) => void;
  readonly getActionLabel: (action: TrackingAction) => string;
}

export default ({
  tracking,
  onClose,
  refetchTrackings,
  openActionModal,
  getActionLabel,
}: Props) => {
  const habilitation = useHabilitationByUuid(tracking.habilitation.uuid);

  const isLimited = useMemo(
    () => habilitation.isRight() && habilitation.right().validity === "limited",
    [habilitation]
  );

  const validityValue = useMemo(() => {
    if (!isLimited) {
      return <p className={styles.sectionValue}>{"Validité illimitée"}</p>;
    } else if (tracking.validUntil) {
      return (
        <div className={styles.sectionValue}>
          <TrackingDuration validUntil={tracking.validUntil} />
        </div>
      );
    }
    return <p className={styles.sectionValue}>{"Aucune date de limite"}</p>;
  }, [isLimited, tracking]);

  return (
    <div>
      {tracking && (
        <SidePanel
          className={styles.trackingSidePanel}
          headerTopTools={
            <div className={styles.headerStatus}>
              <TrackingStatus status={tracking.status} darkMode />
            </div>
          }
          title={tracking.user.fullName}
          subTitle={tracking.habilitation.name}
          headerBottomTools={
            <div className={styles.headerDropDown}>
              <DSDropdown
                button={<DSButton label="dropdown" darkMode icon={<MoreVert />} iconOnly />}
              >
                {tracking.actions.length > 0 &&
                  tracking.actions.map((action) => (
                    <DSDropdownItem
                      label={getActionLabel(action)}
                      disabled={action.disabled}
                      onClick={() => {
                        openActionModal(tracking.uuid, action);
                      }}
                      tooltipLabel={action.tooltip}
                      tooltipDirection="top"
                    />
                  ))}
              </DSDropdown>
            </div>
          }
          onClose={onClose}
        >
          <div>
            {tracking.associatedRowState && (
              <div>
                <div className={styles.trackingContainer}>
                  <section className={styles.trackingRowSection}>
                    <Label label="État" />
                    <p className={styles.sectionValue}>
                      {translateState(tracking.associatedRowState)}
                    </p>
                  </section>
                </div>
                <div className={styles.trackingSidePaneldivider}>
                  <HorizontalDivider top="xs" bottom="xs" />
                </div>
              </div>
            )}
            <div className={styles.trackingContainer}>
              {tracking.status !== "archived" && (
                <section className={styles.trackingSection}>
                  <DSFormGroupTextInput label="Durée restante de validité">
                    {validityValue}
                  </DSFormGroupTextInput>
                </section>
              )}
              <div className={styles.trackingRow}>
                <section className={styles.trackingRowSection}>
                  <Label label="Date de début de validité" />
                </section>
                {(isLimited || tracking.status === "archived") && (
                  <section className={styles.trackingRowSection}>
                    <Label label="Date de fin de validité" />
                  </section>
                )}
              </div>
              <div className={styles.trackingRow}>
                <section className={styles.trackingRowSection}>
                  <p className={styles.sectionValue}>{displayDate(tracking.validSince)}</p>
                </section>
                {(isLimited || tracking.status === "archived") && (
                  <section className={styles.trackingRowSection}>
                    <p className={styles.sectionValue}>{displayDate(tracking.validUntil)}</p>
                  </section>
                )}
              </div>
            </div>
            <div className={styles.trackingSidePaneldivider}>
              <HorizontalDivider top="xs" bottom="xs" />
            </div>
            <div className={styles.trackingContainer}>
              <DocumentList tracking={tracking} refetchTrackings={refetchTrackings} />
            </div>
            <div className={styles.trackingContainer}>
              <Label label="Note" />
              {tracking.note ? (
                <div className={styles.trackingNote}>
                  <DSAvatar initials={getInitials({ fullName: tracking.note.createdBy })} />
                  <div>
                    <p>{tracking.note.noteText}</p>
                    <small>
                      Dernière modification le {formatDate(tracking.note.updatedAt)} par{" "}
                      {tracking.note.createdBy}.
                    </small>
                  </div>
                </div>
              ) : (
                <span className={styles.noTrackingNote}>Aucune note pour cette ligne de suivi</span>
              )}
            </div>
          </div>
        </SidePanel>
      )}
    </div>
  );
};

const displayDate = (date: string): string =>
  date ? format(new Date(date), "dd/MM/yyyy") : "Aucune date renseignée";
