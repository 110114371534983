import { useMemo, useState } from "react";
import { DSDropdown, DSDropdownItem, Icon, DSButton, DSTooltip } from "@skillup/ui";
import {
  type PanelConfigType,
  PanelConfigTypeEnum,
  type ScheduleRowTypes,
} from "@skillup/training-bridge";

import { v4 } from "uuid";
import useTranslation from "hooks/useTranslation";
import cx from "classnames";

import style from "../../../views/Configuration.module.scss";
import { CardConfigForm } from "../../../types";
import { useTrainingDashboardContext } from "../../../context";

export type Props = {
  offset: number;
  cards: CardConfigForm[];
  onChange: (value: CardConfigForm[]) => void;
  panelType: PanelConfigType;
  className?: string;
  simplePlus?: boolean;
};

export const AddCardDropdown = ({
  offset,
  onChange,
  cards,
  panelType,
  className,
  simplePlus,
}: Props) => {
  const [opened, setOpened] = useState(false);

  const { t } = useTranslation();
  const {
    keyIndicators,
    detailedIndicators,
    detailedIndicatorsUtilities: { indicatorDimensionMap },
  } = useTrainingDashboardContext();

  const indicatorOptions = useMemo(() => {
    return Object.keys(indicatorDimensionMap).map((key) => {
      return {
        label: key,
        value: key,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorDimensionMap]);

  const questions = useMemo(
    () => ({
      [PanelConfigTypeEnum.DETAIL_INDICATORS]: detailedIndicators,
      [PanelConfigTypeEnum.KEY_INDICATORS]: keyIndicators,
    }),
    //eslint-disable-next-line
    [detailedIndicators, keyIndicators]
  );

  return (
    <DSDropdown
      button={
        simplePlus ? (
          <DSTooltip
            label={t("training.dashboard.configuration.card.add.tooltip", {
              defaultValue: "Ajouter un indicateur",
            })}
          >
            <DSButton
              onClick={() => setOpened(!opened)}
              darkMode={true}
              icon={<Icon.PlusSlim />}
              iconOnly
            />
          </DSTooltip>
        ) : (
          <DSButton
            onClick={() => setOpened(!opened)}
            emphasis="Mid"
            darkMode={true}
            label={t("training.dashboard.configuration.card.add." + panelType, {
              defaultValue:
                panelType === PanelConfigTypeEnum.KEY_INDICATORS
                  ? "Ajouter un indicateur"
                  : "Ajouter un graphique",
            })}
            buttonSize="S"
          />
        )
      }
      opened={opened}
      className={cx(`${style.addCardButton} ${className}`, {
        [style.showOnHoverButton]: simplePlus,
        [style.addGraph]: panelType === PanelConfigTypeEnum.DETAIL_INDICATORS && !simplePlus,
      })}
    >
      {panelType === PanelConfigTypeEnum.KEY_INDICATORS &&
        questions[panelType].map((question: ScheduleRowTypes.Question, index) => (
          <>
            <DSDropdownItem
              key={question.name + index}
              label={question.recommended_short_name}
              onClick={() => {
                const newCards = [...cards];
                newCards.splice(offset, 0, {
                  id: v4(),
                  short_name: question.recommended_short_name,
                  question_name: question.name,
                  indicator: question.fields[0],
                  dimension: question.fields[1],
                });

                onChange(newCards);
              }}
            />
          </>
        ))}
      {panelType === PanelConfigTypeEnum.DETAIL_INDICATORS &&
        indicatorOptions.map((option: { label: string; value: string }, index) => (
          <>
            <DSDropdownItem
              label={option.label}
              onClick={() => {
                const newCards = [...cards];
                newCards.splice(offset, 0, {
                  id: v4(),
                  short_name: "",
                  question_name: "",
                  indicator: option.value,
                  dimension: "",
                });

                onChange(newCards);
              }}
              key={index}
            />
          </>
        ))}
    </DSDropdown>
  );
};
