import { useMemo } from "react";
import { ListUtils } from "@skillup/shared-utils";
import { type PanelConfigType } from "@skillup/training-bridge";

import { type DashboardCardData, type QuestionData } from "../../types";

import { useDashboardCardData } from "../../utils/card";

import { DetailedIndicator } from "./DetailedIndicator";
import { KeyIndicator } from "./KeyIndicator";
import { Monthly } from "./Monthly";
import { Summary } from "./Summary";

export type Props = {
  card: DashboardCardData;
  filters: ListUtils.FilterValues<any>;
  schedules: Array<{ name: string; uuid: string }>;
  type: PanelConfigType;
};

export type CardProps = {
  data: QuestionData;
  loading: boolean;
  card: DashboardCardData;
  scheduleName: string;
  rowStatus?: string[];
};

const ComponentPanelTypeMap: { [key in PanelConfigType]: (props: CardProps) => any } = {
  "detail-indicators": DetailedIndicator,
  "key-indicators": KeyIndicator,
  monthly: Monthly,
  summary: Summary,
  "monthly-with-tax": Monthly,
  "summary-with-tax": Summary,
};

export const DashboardCard = ({ card, type, filters, schedules }: Props) => {
  const { data, loading } = useDashboardCardData(card, filters);

  const scheduleName = useMemo(() => {
    const uuid = filters.filter_plan_uuid_select?.value;

    return schedules.find((option) => option.uuid === uuid)?.name;
  }, [filters, schedules]);

  const rowStatus = filters?.filter_plan_line_completion_phase_multi_select?.value || [];

  const Component = ComponentPanelTypeMap[type];

  return (
    <Component
      data={data}
      loading={loading}
      card={card}
      scheduleName={scheduleName}
      rowStatus={rowStatus}
    />
  );
};
