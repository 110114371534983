import { useState } from "react";

import { DSDropdown, DSDropdownItem, Icon, DSButton } from "@skillup/ui";
import { type PanelConfigType, PanelConfigTypeEnum } from "@skillup/training-bridge";

import { v4 } from "uuid";
import useTranslation from "hooks/useTranslation";

import style from "../../../views/Configuration.module.scss";
import { ConfigurationTypeTranslationMap } from "../../../utils/ConfigurationTypeTranslationMap";
import { type PanelConfigForm } from "../../../types";
import { useTrainingDashboardContext } from "../../../context";
import User from "utils/User";

export type SortablePanelsProps = {
  offset: number;
  panels: PanelConfigForm[];
  onChange: (value: PanelConfigForm[]) => void;
};

export const AddPanelDropdown = ({ offset, onChange, panels }) => {
  const [opened, openedDD] = useState(false);

  const { t } = useTranslation();
  const { summaryQuestion, monthlyQuestion, monthlyQuestionWithTax, summaryQuestionWithTax } =
    useTrainingDashboardContext();

  const typeLabelMap = ConfigurationTypeTranslationMap(t);

  const handleAddPanel = (type: PanelConfigType) => {
    const newPanels = [...panels];

    let defaultCardValue = [];

    if (type === PanelConfigTypeEnum.SUMMARY) {
      defaultCardValue = [
        {
          short_name: summaryQuestion.recommended_short_name,
          question_name: summaryQuestion.name,
        },
      ];
    }

    if (type === PanelConfigTypeEnum.SUMMARY_WITH_TAX) {
      defaultCardValue = [
        {
          short_name: summaryQuestionWithTax.recommended_short_name,
          question_name: summaryQuestionWithTax.name,
        },
      ];
    }

    if (type === PanelConfigTypeEnum.MONTHLY) {
      defaultCardValue = [
        {
          short_name: monthlyQuestion.recommended_short_name,
          question_name: monthlyQuestion.name,
        },
      ];
    }

    if (type === PanelConfigTypeEnum.MONTHLY_WITH_TAX) {
      defaultCardValue = [
        {
          short_name: monthlyQuestionWithTax.recommended_short_name,
          question_name: monthlyQuestionWithTax.name,
        },
      ];
    }

    newPanels.splice(offset, 0, {
      id: v4(),
      short_name: typeLabelMap[type],
      cards: defaultCardValue,
      type,
    });

    onChange(newPanels);
  };

  return (
    <DSDropdown
      className={style.dropdown}
      button={
        <DSButton
          label={t("training.dashboard.configuration.panel.add", {
            defaultValue: "Ajouter une section",
          })}
          onClick={() => openedDD(!opened)}
          darkMode={true}
          actionIcon={<Icon.ChevronDown />}
          style={{ margin: 10 }}
          buttonSize="S"
        />
      }
      opened={opened}
    >
      {Object.keys(typeLabelMap)
        .filter((type: PanelConfigType) => {
          if (!User.isCompanyWithTax()) {
            return (
              type !== PanelConfigTypeEnum.SUMMARY_WITH_TAX &&
              type !== PanelConfigTypeEnum.MONTHLY_WITH_TAX
            );
          }

          return true;
        })
        .map((type: PanelConfigType) => (
          <DSDropdownItem
            key={type}
            label={typeLabelMap[type]}
            onClick={() => handleAddPanel(type)}
          />
        ))}
    </DSDropdown>
  );
};
