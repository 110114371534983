import { MdOpenInNew as OpenInNew } from "react-icons/md";
import { useMemo } from "react";

import { isNil } from "lodash";
import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";
import { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";
import { TrainingUtils } from "@skillup/training-bridge";
import { DSCard, DSDropdownItem } from "@skillup/ui";
import { useBaseUrl } from "@skillup/hooks";

import AggregationsState from "./AggregationsState";
import styles from "./TrainingCard.module.scss";

interface Props {
  readonly training: CollaboratorsRoutes.GetTrainings["response"][0];
}

export default ({ training }: Props) => {
  const { t, i18n } = useTranslation();
  const { settings } = useSettings();
  const { baseUrl } = useBaseUrl();

  const session = useMemo(() => {
    const formatParams = {
      t,
      createdAt: training.collectionData[0].value.toString(),
      startDate: training.formattedTranslatableDates?.[0],
      endDate: training.formattedTranslatableDates?.[1],
      state: training.state,
    };
    const dateSentence = TrainingUtils.ScheduleRow.getScheduleRowDateSentence(formatParams);
    return dateSentence;
  }, [training, t]);

  const redirUrl = useMemo(() => {
    const bodyPath =
      training.state === "pending_for_HR" ||
      training.state === "pending_for_additionnal_validation" ||
      training.state === "denied"
        ? "recueil-des-besoins/collection-all"
        : "plan-de-formation/plan-all";

    return `${baseUrl}/${training.schedule?.uuid}/${bodyPath}/${training.rowUuid}`;
  }, [training, baseUrl]);

  return (
    <DSCard
      className={styles.TrainingCard}
      title={training.name}
      labels={[<AggregationsState state={training.state} />]}
      actionItems={[
        <DSDropdownItem
          key="download-pdf"
          label={
            <>
              Accéder au programme{" "}
              <OpenInNew style={{ fontSize: "1rem", verticalAlign: "middle" }} />
            </>
          }
          disabled={isNil(training.seoSlug)}
          tooltipLabel={
            isNil(training.seoSlug)
              ? "Aucun programme n’est lié à cette formation dans Skillup"
              : ""
          }
          onClick={() => {
            const win = window.open(
              `${baseUrl}/programmes/${training.seoSlug ? "catalogue" : "gestion-intras"}/${
                training.seoSlug
              }`,
              "_blank"
            );
            win.focus();
          }}
        />,
        <DSDropdownItem
          key="show-interview"
          label={
            <>
              Consulter la ligne associée dans "{training.schedule?.name}"{" "}
              <OpenInNew style={{ fontSize: "1rem", verticalAlign: "middle" }} />
            </>
          }
          onClick={() => {
            const win = window.open(redirUrl, "_blank");
            win.focus();
          }}
        />,
      ]}
    >
      <section className={styles.content}>
        <p>Par {training.organization.name}</p>
        <p>
          {t("training.summary.price.label", { defaultValue: "Prix" })}
          {" : "}
          {TrainingUtils.ScheduleRow.formatScheduleRowPrice({
            price: training.price,
            options: {
              currency: settings?.currency,
              locale: i18n.language,
            },
          })}
        </p>
      </section>
      <section className={styles.footer}>
        {session && (
          <div className={styles.section}>
            <span className={styles.sessionLine}>{session}</span>
          </div>
        )}
      </section>
    </DSCard>
  );
};
