import { DSDropdownItem } from "@skillup/ui";
import { useCallback } from "react";
import { PlanType, exportNewXLSX } from "./exportCSV";

export interface ExportSchedulesProps {
  readonly scheduleUuid: string;
  readonly areas: string[];
  readonly type: PlanType;
}

const ExportSchedules = (props: ExportSchedulesProps) => {
  const handleExport = useCallback(
    (pType?: PlanType) => async () => {
      await exportNewXLSX({
        type: pType,
        scheduleUuid: props.scheduleUuid,
        activeAreas: props.areas,
      });
    },
    [props]
  );

  return (
    <>
      <DSDropdownItem
        label={props.type === "plan" ? "Exporter le plan" : "Exporter le recueil"}
        onClick={handleExport(props.type)}
      />
      <DSDropdownItem label="Exporter le recueil et le plan" onClick={handleExport()} />
    </>
  );
};

export default ExportSchedules;
