import styled from "styled-components";

import { Flex } from "@skillup/design-system";
import { DSColor, EspaceFont, SkuiSpacing, DSTextInput } from "@skillup/ui";

export const SearchInput = styled(DSTextInput)`
  ${EspaceFont.rh.body2Regular};

  width: 100%;

  input {
    width: 100%;
    border-color: ${DSColor["border-onLight"]};

    &::placeholder {
      font-style: italic;
    }
  }
  svg {
    fill: ${DSColor["plainText-onLight-lighter"]};
    height: ${SkuiSpacing.m};
    width: ${SkuiSpacing.m};
  }
`;

export const SearchResult = styled(Flex)`
  position: absolute;
  z-index: 99999;
  top: 46px;
  max-height: 400px;
  overflow: auto;
  padding: ${SkuiSpacing.xs};
  border: 1px solid ${DSColor["border-onLight"]};
  border-radius: 0 0 ${SkuiSpacing.xxs} ${SkuiSpacing.xxs};
  background-color: ${DSColor["surface-light"]};
  flex-direction: column;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);

  > * {
    margin-bottom: ${SkuiSpacing.xs};
  }

  > span {
    width: fit-content;
  }
`;

export const Link = styled.a`
  cursor: pointer;
  display: flex;
  color: ${DSColor["plainText-onLight-default"]};
`;

export const DisabledLink = styled.span`
  color: ${DSColor["greyscale-medium"]};
`;
