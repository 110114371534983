import { MdError as Error } from "react-icons/md";
import { Flex } from "@skillup/ui";

import styles from "../Modal.module.scss";

const AssistiveErrorText = ({ errorText }: { errorText: string }): any => {
  return (
    <Flex className={styles.assistiveErrorText}>
      <Error size="1rem" />
      {errorText}
    </Flex>
  );
};

export default AssistiveErrorText;
