import { useEffect, useMemo, useRef } from "react";

import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { AssistiveArea, Flex } from "@skillup/ui";
import { formatMonetary } from "@skillup/shared-utils";

import useTranslation from "hooks/useTranslation";

import { MonthlyData } from "../../types";
import { CHARTS_PALETTE } from "../../utils/graph";

import CardsPlaceholder from "../Cards/Placeholder";
import { Dot, Column, Line } from "./Icons/Tooltip";
import styles from "./Monthly.module.scss";

export type MonthlyChartProps = {
  data: MonthlyData;
  scheduleName: string;
  rowStatus: string[];
};

export const MonthlyChart = ({ data, scheduleName, rowStatus }: MonthlyChartProps) => {
  const { t } = useTranslation();
  const chartRef = useRef(null);

  const isPlanProcessing = rowStatus.includes("plan-processing"); // Inscrit
  const isPlanAttended = rowStatus.includes("plan-attended"); // Réalisé

  const displayPlaceholder = !isPlanProcessing && !isPlanAttended;

  let alertText: string | undefined;
  if (!isPlanProcessing) {
    alertText = t("dashboard-summary-monthly-plan-approved-alert", {
      defaultValue:
        "Les filtres appliqués excluent les lignes au statut « Inscrit », le graphique ci-dessous ne montre donc que les dépenses correspondant au statut « Réalisées ».",
    });
  } else if (!isPlanAttended) {
    alertText = t("dashboard-summary-monthly-plan-attended-alert", {
      defaultValue:
        "Les filtres appliqués excluent les lignes au statut « Réalisé », le graphique ci-dessous ne montre donc que les dépenses correspondant au statut « Inscrit ».",
    });
  }

  const options = useMemo(() => {
    return {
      chart: {
        backgroundColor: "transparent",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: data?.data.map((d) => moment(d[3])?.format("MMM YYYY")),
        crosshair: {
          className: styles.crosshair,
        },
        lineColor: "#E5E8EB",
        labels: {
          style: {
            fontSize: ".75rem",
            fontFamily: "Noto Sans Display, sans-serif",
            fontWeight: "bold",
            color: "#69798C",
          },
        },
      },
      yAxis: [
        {
          title: {
            text: null,
          },
          gridLineDashStyle: "Dash",
          gridLineColor: "#E5E8EB",
          labels: {
            style: {
              fontSize: ".75rem",
              fontFamily: "Noto Sans Display, sans-serif",
              fontWeight: "bold",
              color: "#69798C",
            },
          },
        },
      ],
      series: [
        {
          name: t("dashboard-summary-monthly-registered", {
            defaultValue: "Inscrit",
          }),
          type: "column",
          data: data?.data.map((d, index) => ({
            y: d[0] ?? 0,
            color: CHARTS_PALETTE[1],
          })),
        },
        {
          name: t("dashboard-summary-monthly-accumulated", {
            defaultValue: "Cumul depuis démarrage du plan",
          }),
          type: "line",
          data: data?.data.map((d) => d[1]),
          yAxis: 0,
          color: CHARTS_PALETTE[1],
          marker: {
            enabled: true,
            radius: 5,
            symbol: "circle",
          },
        },
        {
          name: t("dashboard-summary-monthly-budget", {
            defaultValue: "Budget",
          }),
          type: "line",
          data: data?.data.map((d) => d[2]),
          yAxis: 0,
          color: CHARTS_PALETTE[0],
          marker: {
            enabled: false,
          },
        },
      ],
      tooltip: {
        useHTML: true,
        shadox: false,
        shared: true,
        padding: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
        formatter: function () {
          const points = this.points;

          return generateTooltip(points, t);
        },
      },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (chartRef.current && data) {
      const chart = chartRef.current.chart;

      if (!chart.customElements) {
        chart.customElements = [];
      }

      chart.customElements.forEach((el) => el.destroy());
      chart.customElements = [];

      const rectangle = chart.renderer
        .rect(100, 18, 172, 78, 5)
        .attr({
          fill: "rgba(255, 255, 255, 255)",
          zIndex: 3,
          stroke: "rgba(105, 121, 140, 0.2)",
          "stroke-width": 1,
        })
        .add();
      chart.customElements.push(rectangle);

      const circle = chart.renderer
        .circle(113, 33, 5)
        .attr({
          fill: CHARTS_PALETTE[1],
          zIndex: 3,
        })
        .add();
      chart.customElements.push(circle);

      const titleText = chart.renderer
        .text(scheduleName, 125, 38)
        .attr({
          class: styles.title,
          zIndex: 3,
        })
        .css({ color: "#69798C" })
        .add();
      chart.customElements.push(titleText);

      const budgetText = chart.renderer
        .text(
          t("dashboard-summary-monthly-budget", {
            defaultValue: "Budget",
          }),
          125,
          61
        )
        .attr({ class: styles.text, zIndex: 3 })
        .css({ color: "#69798C" })
        .add();
      chart.customElements.push(budgetText);

      const budgetTextWidth = budgetText.getBBox().width;

      const budgetValueText = chart.renderer
        .text(
          `${formatMonetary(Number(data?.data[data?.data.length - 1]?.[2]))}`,
          132 + budgetTextWidth,
          61
        )
        .attr({ class: styles.text, zIndex: 3 })
        .add();
      chart.customElements.push(budgetValueText);

      const registeredText = chart.renderer
        .text(
          t("dashboard-summary-monthly-accumulated", { defaultValue: "Réalisé cumulé" }),
          125,
          84
        )
        .attr({ class: styles.text, zIndex: 3 })
        .css({ color: "#69798C" })
        .add();
      chart.customElements.push(registeredText);

      const registeredTextWidth = registeredText.getBBox().width;

      const registeredValueText = chart.renderer
        .text(
          `${formatMonetary(Number(data?.data[data?.data.length - 1]?.[1]))}`,
          132 + registeredTextWidth,
          84
        )
        .attr({ class: styles.text, zIndex: 3 })
        .add();
      chart.customElements.push(registeredValueText);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Flex column className={styles.chart}>
      {alertText && <AssistiveArea text={alertText} mode="warning" className={styles.alert} />}
      {displayPlaceholder ? (
        <CardsPlaceholder />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
      )}
    </Flex>
  );
};

const generateTooltip = (points, t) => {
  const columnDate = moment(points[0].key, "MMM YYYY");
  const today = moment().format("MMM YYYY");

  return `
    <div class=${styles.tooltip}>
      <div class=${styles.tooltip_line}>
        <div class=${styles.tooltip_line_label}>
          ${Column}
          <div>${
            columnDate.isBefore(today)
              ? t("dashboard-summary-monthly-attended", {
                  defaultValue: "Réalisé en",
                })
              : columnDate.isSame(today)
                ? t("dashboard-summary-monthly-processing-or-attended", {
                    defaultValue: "Réalisé et inscrit en",
                  })
                : t("dashboard-summary-monthly-processing", {
                    defaultValue: "Inscrit en",
                  })
          } ${points[0].key} :</div>
        </div>
        <div class=${styles.tooltip_line_value}>${formatMonetary(points[0]?.y)}</div>
      </div>
      <div class=${styles.tooltip_divider}></div>
      <div class=${styles.tooltip_line}>
        <div class=${styles.tooltip_line_label}>
          ${Dot}
          <div>${t("dashboard-summary-monthly-accumulated", {
            defaultValue: "Cumul depuis démarrage du plan",
          })} :</div>
        </div>
        <div class=${styles.tooltip_line_value}>${formatMonetary(points[1]?.y)}</div>
      </div>
      <div class=${styles.tooltip_line}>
        <div class=${styles.tooltip_line_label}>
          ${Line}
          <div>${t("dashboard-summary-monthly-budget", {
            defaultValue: "Budget",
          })} :</div>
        </div>
        <div class=${styles.tooltip_line_value}>${formatMonetary(points[2]?.y)}</div>
      </div>
    </div>
  `;
};
