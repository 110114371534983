import { Flex } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { UnavailableDashboardIcon } from "./UnavailableDashboardIcon";

import styles from "./UnavailableDashboard.module.scss";

export type Props = {
  isRefreshing: boolean;
};

export const UnavailableDashboard = ({ isRefreshing }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex column className={styles.unavailableDashboard}>
      <UnavailableDashboardIcon />

      <pre>
        {isRefreshing
          ? t("trainings.view.dashboard.toast.data_refreshing", {
              defaultValue:
                "La récupération des données à jour est encore en cours. Elle peut avoir été initiée par un autre utilisateur.\nCela peut prendre plusieurs minutes, n’hésitez pas à revenir plus tard.",
            })
          : t("trainings.view.dashboard.need_data_refresh", {
              defaultValue:
                "Votre dashboard est en cours de préparation.\nRevenez le consulter dans quelques jours.",
            })}
      </pre>
    </Flex>
  );
};
