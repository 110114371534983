import { CSVLink } from "react-csv";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useMemo, useState, useEffect } from "react";

import cx from "classnames";

import { useMutation } from "@tanstack/react-query";
import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid-pro";

import { Flex, Text } from "@skillup/design-system";
import {
  Link,
  DSAlert,
  FileDrop,
  DSButton,
  DSDataGrid,
  DSAlertType,
  DSAlertDisplay,
} from "@skillup/ui";

import { CampaignCreationState } from "types/peopleReview";
import { uploadReviewedEmployees } from "services/peopleReview/uploadReviewedEmployees";

import { CollaboratorOwnManagerWarningAlert } from "../../../components/CollaboratorOwnManagerWarningAlert";

import styles from "./../steps.module.scss";

type TableRow = Awaited<ReturnType<typeof uploadReviewedEmployees>>[number];

function isManagerAndCollaboratorTheSame(row: TableRow) {
  return row.collaborator?.uuid === row.manager?.uuid;
}

function getSelectedCollaboratorManagerPairs(
  rows: TableRow[],
  rowSelectionModel: GridRowSelectionModel
) {
  return rows
    .filter(({ collaborator }) => rowSelectionModel.includes(collaborator.uuid))
    .map(({ collaborator, manager }) => ({
      collaborator: {
        id: collaborator.uuid,
        fullName: collaborator.fullName,
      },
      ...(manager && {
        manager: {
          id: manager.uuid,
          fullName: manager.fullName,
        },
      }),
    }));
}

type AttendeesProps = {
  file: File | null;
  hasManagers: boolean;
  prevStep: (data: Partial<CampaignCreationState>) => void;
  nextStep: (data: Partial<CampaignCreationState>) => void;
  selectedCollaboratorManagerPairs: CampaignCreationState["collaboratorManagerPairs"];
};
export function Attendees({
  file,
  hasManagers,
  nextStep,
  prevStep,
  selectedCollaboratorManagerPairs,
}: AttendeesProps) {
  const { t } = useTranslation();

  const [uploadedFile, setUploadedFile] = useState<File | null>(file);

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>(
    selectedCollaboratorManagerPairs.map(({ collaborator }) => collaborator.id)
  );

  const { data, isError, isLoading, mutate, reset } = useMutation(uploadReviewedEmployees, {
    onSuccess: (data) => {
      if (selectedCollaboratorManagerPairs.length > 0) {
        setRowSelectionModel(
          selectedCollaboratorManagerPairs.map(({ collaborator }) => collaborator.id)
        );
      } else {
        setRowSelectionModel(
          data.filter((row) => !isManagerAndCollaboratorTheSame(row)).map(({ id }) => id)
        );
      }
    },
  });

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    disabled: isLoading,
    // ~50Mb
    maxSize: 52428800,
    multiple: false,
    onDrop: (files) => setUploadedFile(files[0]),
  });

  useEffect(() => {
    if (uploadedFile) {
      mutate(uploadedFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile]);

  const hasSomeManagersInFile = useMemo(
    () => data?.some(({ manager }) => Boolean(manager)),
    [data]
  );
  const collaboratorsThatAreTheirOwnManagerMails = useMemo(
    () =>
      data
        ?.filter((row) => isManagerAndCollaboratorTheSame(row))
        .map(({ collaborator }) => collaborator.email) ?? [],
    [data]
  );

  const collaboratorColDef: GridColDef<TableRow> = {
    field: "collaboratorFullName",
    flex: 1,
    headerName: "Collaborateur",
    renderCell: (params) => {
      return (
        <p
          className={cx({
            [styles.invalidCollabManagerPair]: isManagerAndCollaboratorTheSame(params.row),
          })}
        >
          {params.value}
        </p>
      );
    },
    resizable: false,
    valueGetter: (params) => params.row.collaborator?.fullName,
  };

  const managerColDef: GridColDef<TableRow> = {
    field: "managerFullName",
    flex: 1,
    headerName: "Manager",
    renderCell: (params) => {
      return (
        <p
          className={cx({
            [styles.invalidCollabManagerPair]: isManagerAndCollaboratorTheSame(params.row),
          })}
        >
          {params.value}
        </p>
      );
    },
    resizable: false,
    valueGetter: (params) => params.row.manager?.fullName,
  };

  const columns: GridColDef[] = [collaboratorColDef, ...(hasManagers ? [managerColDef] : [])];

  return (
    <Flex
      gap="l"
      width="100%"
      maxWidth="50rem"
      flexDirection="column"
      marginHorizontal="auto"
      className={styles.attendees}
    >
      {!data ? (
        <Flex gap="l" flexDirection="column">
          <Flex gap="s" flexDirection="column">
            <FileDrop
              rootProps={getRootProps()}
              isDragActive={isDragActive}
              className={styles.fileDrop}
              inputProps={getInputProps()}
              btnLabel={t("peoplereview.upload.drop.button", {
                defaultValue: "Choisir un fichier",
              })}
              dropLabel={t("peoplereview.upload.drop.message", {
                defaultValue: "ou glisser déposer un fichier ici",
              })}
            />
          </Flex>
          <Flex flexDirection="column">
            {hasManagers ? (
              <Text espaceFont="body1Regular" color="plainText-onLight-default">
                {t("peoplereview.upload.template.explanationText", {
                  defaultValue:
                    "Votre fichier doit comporter 1 ligne par collaborateur et 2 colonnes",
                })}
              </Text>
            ) : (
              <Text espaceFont="body1Regular" color="plainText-onLight-default">
                {t("peoplereview.upload.template.explanationText", {
                  defaultValue:
                    "Votre fichier doit comporter 1 ligne par collaborateur et 1 colonne",
                })}
              </Text>
            )}

            <ul className={styles.columnsText}>
              <li>
                <Text espaceFont="body1Regular" color="plainText-onLight-default">
                  {t("peoplereview.upload.template.explanationText.columnOne", {
                    defaultValue: "Colonne 1 : email du collaborateur",
                  })}
                </Text>
              </li>
              {hasManagers && (
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peoplereview.upload.template.explanationText.columnTwo", {
                      defaultValue:
                        "Colonne 2 : email du manager en charge de la People Review du collaborateur",
                    })}
                  </Text>
                </li>
              )}
            </ul>
          </Flex>
          <CSVLink
            filename="template.csv"
            className={styles.getTemplateLink}
            data={[["collab_email", hasManagers && "manager_email"].filter(Boolean)]}
          >
            {t("peoplereview.upload.template.download.text", {
              defaultValue: "Télécharger un template de fichier",
            })}
          </CSVLink>
        </Flex>
      ) : (
        <>
          <Flex
            gap="m"
            alignItems="center"
            justifyContent="center"
            className={styles.removeFileButton}
          >
            <Text espaceFont="body1Regular" color="plainText-onLight-default">
              {uploadedFile.name}
            </Text>
            <DSButton
              emphasis="Mid"
              buttonSize="S"
              onClick={() => {
                reset();
                setUploadedFile(null);
              }}
              label={t("peoplereview.upload.removeFile", {
                defaultValue: "Supprimer le fichier",
              })}
            />
          </Flex>

          {!hasManagers && hasSomeManagersInFile && (
            <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
              {t("peoplereview.upload.template.download.hasError", {
                defaultValue:
                  'Votre fichier contient une colonne "Manager". Vous pouvez activer l\'option de préparation manager à ',
              })}
              <Link
                label="l'étape précédente."
                className={styles.goToPreviousStepLink}
                onClick={() => {
                  prevStep({
                    collaboratorManagerPairs: getSelectedCollaboratorManagerPairs(
                      data,
                      rowSelectionModel
                    ),
                    uploadedFile,
                  });
                }}
              />
            </DSAlert>
          )}

          {hasManagers && collaboratorsThatAreTheirOwnManagerMails.length > 0 && (
            <CollaboratorOwnManagerWarningAlert emails={collaboratorsThatAreTheirOwnManagerMails} />
          )}

          <DSDataGrid
            rows={data}
            hideFooter={true}
            checkboxSelection
            columns={columns}
            isError={isError}
            loading={isLoading}
            rowSelectionModel={rowSelectionModel}
            isRowSelectable={(params: GridRowParams<TableRow>) =>
              !isManagerAndCollaboratorTheSame(params.row)
            }
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            entityName={t("peoplereview.creationStep.attendees.datagrid.entityName", {
              count: data?.length ?? 0,
              defaultValue: "{{count}} collaborateurs",
            })}
            emptyOverlay={{
              text: [
                t("peopleReview.creationStep.attendees.emptyOverlayText.firstSentence", {
                  defaultValue: "Aucun collaborateur chargé pour le moment.",
                }),
                t("peopleReview.creationStep.attendees.emptyOverlayText.secondSentence", {
                  defaultValue: "Déposez un fichier correspondant au template pour commencer.",
                }),
              ],
            }}
            errorOverlay={{
              text: [
                t("peopleReview.creationStep.attendees.errorOverlayText.firstSentence", {
                  defaultValue: `Une erreur est survenue lors de l'import du fichier.`,
                }),
                t("peopleReview.creationStep.attendees.errorOverlayText.secondSentence", {
                  defaultValue: `Merci de vérifier que votre fichier correspond au template`,
                }),
              ],
            }}
          />
        </>
      )}

      <Flex justifyContent="space-between">
        <DSButton
          emphasis="Low"
          label={t("previousStep", {
            defaultValue: "Précédent",
          })}
          onClick={() => {
            prevStep({
              collaboratorManagerPairs: getSelectedCollaboratorManagerPairs(
                data,
                rowSelectionModel
              ),
              uploadedFile,
            });
          }}
        />
        <DSButton
          disabled={isError || rowSelectionModel.length === 0 || isLoading}
          label={t("nextStep", {
            defaultValue: "Suivant",
          })}
          onClick={() =>
            nextStep({
              collaboratorManagerPairs: getSelectedCollaboratorManagerPairs(
                data,
                rowSelectionModel
              ),
              uploadedFile,
            })
          }
        />
      </Flex>
    </Flex>
  );
}
