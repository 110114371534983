import React from "react";

import {
  DateRangePicker as MUIDateRangePicker,
  type DateRangePickerProps as MUIDateRangePickerProps,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { CalendarIcon, type PickerValidDate } from "@mui/x-date-pickers-pro";
import ArrowForward from "@mui/icons-material/ArrowForward";

import { useTheme } from "context/themes";

import Popper from "../components/Popper";
import { SkuiSpacing } from "commons/spacing";

export type DateRangePickerProps = Omit<
  MUIDateRangePickerProps<PickerValidDate>,
  "theme" | "disableHighlightToday" | "slots"
>;

const DateRangePicker = (props: DateRangePickerProps) => {
  const theme = useTheme();

  const iconColor = props.disabled ? "rgba(0, 0, 0, 0.26)" : "rgba(0, 0, 0, 0.54)";

  return (
    <MUIDateRangePicker
      showDaysOutsideCurrentMonth
      {...props}
      disableHighlightToday
      slots={{
        popper: Popper,
      }}
      slotProps={{
        ...props.slotProps,
        fieldRoot: {
          ...props.slotProps?.fieldRoot,
          useFlexGap: true,
          gap: SkuiSpacing.xxs,
          width: "fit-content",
        },
        textField: {
          ...props.slotProps?.textField,
          label: undefined,
          InputProps: {
            endAdornment: <CalendarIcon style={{ color: iconColor, fill: iconColor }} />,
          },
        },
        field: {
          ...props.slotProps?.field,
          slots: {
            separator: ArrowForward,
          },
        },
        popper: {
          ...props.slotProps?.popper,
          theme: theme,
        } as any,
      }}
    />
  );
};

export default DateRangePicker;
