import { ReactElement } from "react";

import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";

import { DSAvatarGroup } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { getInitials } from "@skillup/shared-utils";

import { SkillFields } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import { SkillRow } from "./parseSkillIntoRow";
import { DatatableSkillFields } from "./getFields";
import { EllipsisText } from "../ListSkills.styled";
import ActionsCell from "../components/Table/ActionsCell";

const renderFlexCell = (value: string | number | ReactElement) => {
  return (
    <Flex width="100%" alignItems="center">
      {typeof value === "string" ? <EllipsisText>{value}</EllipsisText> : value}
    </Flex>
  );
};

const generateColumns = ({
  actions,
  customFields,
  fields,
  t,
}: {
  t: TranslationType;
  customFields: SkillFields[];
  actions: (row: SkillRow) => void;
  fields: typeof DatatableSkillFields;
}): GridColDef[] => {
  const skillFields = [fields.name.key, fields.categoryLabel.key];

  const columns: GridColDef[] = skillFields.map((key) => {
    const fieldData = Object.keys(skillFields).includes(key) ? skillFields[key] : fields[key];
    return {
      field: key,
      filterable: key === "name" ? false : true,
      headerName: t(fieldData.traductionKey, {
        defaultValue: fieldData.traductionDefaultValue,
      }),
      minWidth: 200,
      renderCell: ({ row }: GridValueGetterParams<SkillRow>) => {
        return renderFlexCell(row[key]);
      },
      sortable: true,
    } satisfies GridColDef;
  });

  const jobsCountColumn: GridColDef = {
    description: t(fields.jobsCount.tooltipTraductionKey, {
      defaultValue: fields.jobsCount.tooltipTraductionDefaultValue,
    }),
    field: "jobsCount",
    headerName: t(fields.jobsCount.traductionKey, {
      defaultValue: fields.jobsCount.traductionDefaultValue,
    }),
    minWidth: 150,
    renderCell: ({ row }: GridValueGetterParams<SkillRow>) => {
      return renderFlexCell(row.jobsCount);
    },
    sortable: true,
  };

  const avatarGroupColumn: GridColDef = {
    description: t(fields.employeesWithSkill.tooltipTraductionKey, {
      defaultValue: fields.employeesWithSkill.tooltipTraductionDefaultValue,
    }),
    field: "employees",
    filterable: false,
    headerName: t(fields.employeesWithSkill.traductionKey, {
      defaultValue: fields.employeesWithSkill.traductionDefaultValue,
    }),
    minWidth: 150,
    renderCell: ({ row }: GridValueGetterParams<SkillRow>) => {
      return (
        <DSAvatarGroup
          size="S"
          maxItems={5}
          totalItems={Object.keys(row.employeesWithSkill).length}
          users={Object.values(row.employeesWithSkill).map((employee) => ({
            uuid: employee.uuid,
            fullName: employee.firstName + " " + employee.lastName,
            initials: getInitials({
              firstName: employee.firstName,
              lastName: employee.lastName,
            }),
          }))}
        />
      );
    },

    sortable: true,
    sortComparator: (v1: Array<SkillRow>, v2: Array<SkillRow>) => v1.length - v2.length,
  };

  const fieldsColumns = customFields
    .sort((a, b) => a.index - b.index)
    .map((field) => ({
      field: field.name,
      filterable: true,
      headerName: field.name,
      minWidth: 200,
      renderCell: ({ row }: GridValueGetterParams<SkillRow>) => {
        return renderFlexCell(row[field.name]);
      },
      sortable: true,
    }));

  columns.push(jobsCountColumn, avatarGroupColumn, ...fieldsColumns, {
    type: "actions",
    field: "actions",
    filterable: false,
    getActions: ({ row }) => [<ActionsCell t={t} row={row} actions={actions} />],
    sortable: false,
    width: 50,
  });

  return columns;
};

export default generateColumns;
