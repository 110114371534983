import { MdTrackChanges as TrackChanges } from "react-icons/md";
import { useMemo } from "react";
import { v4 as uuid } from "uuid";

import { ChipNumber } from "@skillup/ui";
import type { TargetCategoryLegacy } from "@skillup/espace-rh-bridge";

export type LegacyCategoryRow = ReturnType<typeof parseTableRows>;

const useTableData = (
  categories: TargetCategoryLegacy[] | undefined
): {
  data: LegacyCategoryRow[];
  columns: Array<{
    key: string;
    title: string;
    filterable: boolean;
    width: number;
  }>;
} => {
  const tableData = useMemo(
    () => ({
      columns: generateTableColumns(),
      data: generateTableRows(categories ?? []),
    }),
    [categories]
  );

  return tableData;
};

export default useTableData;

function generateTableRows(categories: Array<TargetCategoryLegacy>) {
  return categories.map(parseTableRows);
}

function parseTableRows(category: TargetCategoryLegacy) {
  return {
    id: uuid(),
    data: {
      title: category.label,
      targets: category.targetCount,
      structures: category.structures.length,
    },
  };
}

function generateTableColumns() {
  const columns = [
    {
      key: "title",
      title: "Intitulé de la catégorie",
      filterable: true,
      width: 10,
      sortFn: (a: LegacyCategoryRow, b: LegacyCategoryRow) =>
        a.data.title.localeCompare(b.data.title),
    },
    {
      key: "targets",
      title: "Nombre d'objectifs créés dans cette catégorie",
      icon: <TrackChanges size="1rem" />,
      filterable: true,
      width: 2,
      sortFn: (a: LegacyCategoryRow, b: LegacyCategoryRow) => a.data.targets - b.data.targets,
      renderCell: ({ targets }) => <ChipNumber value={targets} />,
    },
    {
      key: "structures",
      title: "Nombre de campagnes utilisant cette catégorie",
      filterable: true,
      width: 2,
      sortFn: (a: LegacyCategoryRow, b: LegacyCategoryRow) => a.data.structures - b.data.structures,
      renderCell: ({ structures }) => <ChipNumber value={structures} />,
    },
  ];
  return columns;
}
