import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useMemo, useContext, useCallback, createContext } from "react";

import { trpc, RouterInputs } from "utils/trpc";
import {
  Field,
  Category,
  FieldEntity,
  SkillAnalytics,
  ProcessedSkill,
  EvaluationScale,
  PerimeterFilters,
} from "types/skills";

type CreateSkillInput = RouterInputs["skills"]["createSkill"];
type UpdateSkillInput = RouterInputs["skills"]["updateSkill"];

type FieldType = Exclude<RouterInputs["fields"]["getAllFields"], void>["entity"];

type SkillsContextType = {
  removeSkill: (skill: ProcessedSkill) => Promise<void>;
  updateSkill: (skill: UpdateSkillInput) => Promise<void>;
  createSkill: (skill: CreateSkillInput) => Promise<void>;
  getFields: () => {
    isLoading: boolean;
    data: Field[] | undefined;
  };
  getCategories: () => {
    isLoading: boolean;
    data: undefined | Category[];
  };
  updateArchiveStatus: (isArchive: boolean, skills: Array<ProcessedSkill>) => Promise<void>;
  getSkillEvaluationsScales: () => {
    isLoading: boolean;
    data: undefined | EvaluationScale[];
  };
  getSkills: (query?: { uuids: Array<string> }) => {
    data: undefined | ProcessedSkill[];
    status: "error" | "loading" | "success";
    error: ReturnType<typeof trpc.skills.getSkills.useQuery>["error"];
  };
  getSkillAnalytics: (
    uuid: string,
    filters?: PerimeterFilters
  ) => {
    data: SkillAnalytics;
    status: "error" | "loading" | "success";
    error: ReturnType<typeof trpc.analytics.getSkillAnalytics.useQuery>["error"];
  };
};

const SkillsContext = createContext<SkillsContextType>(null);

const useSkillsContext = () => {
  const context = useContext(SkillsContext);
  if (!context) {
    throw new Error("useSkillsContext must be used within a SkillsContext");
  }
  return context;
};

interface SkillsProviderProps {
  children: React.ReactNode;
}

const SkillsProvider = ({ children }: SkillsProviderProps) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const history = useHistory();
  const utils = trpc.useUtils();

  const getSkillAnalytics = useCallback((uuid: string, filters?: PerimeterFilters) => {
    const { data, error, status } = trpc.analytics.getSkillAnalytics.useQuery({
      filters: {
        employees: filters,
      },
      skillUuid: uuid,
    });

    const castData = data as unknown as SkillAnalytics;

    return { data: castData, error, status };
  }, []);

  const getSkills = useCallback((query?: { uuids: Array<string> }) => {
    const {
      data: skillListData,
      error: skillListError,
      status: skillListStatus,
    } = query ? trpc.skills.getSkills.useQuery(query) : trpc.skills.getAllSkills.useQuery();

    const skills = {
      data: skillListData as unknown as undefined | ProcessedSkill[],
      error: skillListError,
      status: skillListStatus,
    };
    return skills;
  }, []);

  const getSkillEvaluationsScales = useCallback(() => {
    const { data: skillEvaluationsScalesData, isLoading: skillEvaluationsScalesLoading } =
      trpc.skillEvaluationsScales.getAll.useQuery();

    const skillEvaluationsScales = {
      data: skillEvaluationsScalesData as unknown as undefined | EvaluationScale[],
      isLoading: skillEvaluationsScalesLoading,
    };
    return skillEvaluationsScales;
  }, []);

  const getCategories = useCallback(() => {
    const { data: categoriesData, isLoading: categoriesLoading } =
      trpc.categories.getAllCategories.useQuery();

    const categories = {
      data: categoriesData as unknown as undefined | Category[],
      isLoading: categoriesLoading,
    };
    return categories;
  }, []);

  const getFields = useCallback(() => {
    const { data: fieldsData, isLoading: fieldsLoading } = trpc.fields.getAllFields.useQuery({
      entity: FieldEntity.SKILL as unknown as FieldType,
    });

    const fields = {
      data: fieldsData as unknown as Field[] | undefined,
      isLoading: fieldsLoading,
    };
    return fields;
  }, []);

  const archivePayload = useMemo(
    () => ({
      onError: (_, req) => {
        const { isArchive } = req as {
          isArchive?: boolean;
          skills?: { uuid?: string; version?: number }[];
        };
        addToast(
          t(`skills.list.label.${isArchive ? "" : "un"}archive.error`, {
            defaultValue: `La compétence n'a pas pu être ${isArchive ? "" : "dés"}archivée`,
          }),
          {
            appearance: "error",
          }
        );
      },
      onSuccess: (_, req) => {
        const { isArchive, skills } = req as {
          isArchive?: boolean;
          skills?: { uuid?: string; version?: number }[];
        };
        addToast(
          skills?.length > 1
            ? t("skills.list.label.archiveMultiple.success", {
                defaultValue: `Les compétences ont été archivées`,
              })
            : t(`skills.list.label.${isArchive ? "" : "un"}archive.success`, {
                defaultValue: `La compétence a été ${isArchive ? "" : "dés"}archivée`,
              }),
          {
            appearance: "success",
          }
        );

        utils.skills.getAllSkills.refetch();
      },
    }),
    [addToast, t, utils]
  );

  const archiveMutation = trpc.skills.archiveSkill.useMutation(archivePayload);

  const updateArchiveStatus = useCallback(
    async (isArchive: boolean, skills: Array<ProcessedSkill>) => {
      const skillsWithVersion = skills.map((skill) => {
        return {
          uuid: skill.uuid,
          version: skill.version + 1,
        };
      });
      archiveMutation.mutate({
        isArchive,
        skills: skillsWithVersion,
      });
    },
    [archiveMutation]
  );

  const removalPayload = useMemo(
    () => ({
      onError: () => {
        addToast(
          t("skills.list.label.remove.error", {
            defaultValue: `La compétence n'a pas pu être supprimée`,
          }),
          {
            appearance: "error",
          }
        );
      },
      onSuccess: () => {
        addToast(
          t("skills.list.label.remove.success", {
            defaultValue: `La compétence a été supprimée`,
          }),
          {
            appearance: "success",
          }
        );

        utils.skills.getAllSkills.refetch();
      },
    }),
    [addToast, t, utils]
  );

  const removeMutation = trpc.skills.removeSkill.useMutation(removalPayload);

  const removeSkill = useCallback(
    async ({ uuid, version }: ProcessedSkill) => {
      removeMutation.mutate({
        uuid,
        version: version + 1,
      });
    },
    [removeMutation]
  );

  const creationPayload = useMemo(
    () => ({
      onError: (e) => {
        if (e.message === "name-already-taken") {
          addToast(
            t("portal.config.skills.createSkill.nameAlreadyTaken", {
              defaultValue: "Une compétence avec ce nom existe déjà",
            }),
            {
              appearance: "error",
            }
          );
        } else {
          addToast(
            t("portal.config.skills.createSkill.error", {
              defaultValue: "La compétence n'a pas pu être créée",
            }),
            {
              appearance: "error",
            }
          );
        }
      },
      onSuccess: () => {
        addToast(
          t("portal.config.skills.createSkill.success", {
            defaultValue: "La compétence a bien été créée",
          }),
          {
            appearance: "success",
          }
        );
        history.goBack();
      },
    }),
    [addToast, history, t]
  );

  const creationMutator = trpc.skills.createSkill.useMutation(creationPayload);

  const createSkill = useCallback(
    async (payload: CreateSkillInput) => {
      creationMutator.mutate(payload);
    },
    [creationMutator]
  );

  const updatePayload = useMemo(
    () => ({
      onError: (e) => {
        if (e.message === "name-already-taken") {
          addToast(
            t("portal.config.skills.createSkill.nameAlreadyTaken", {
              defaultValue: "Une compétence avec ce nom existe déjà",
            }),
            {
              appearance: "error",
            }
          );
        } else {
          addToast(
            t("portal.config.skills.updateSkill.error", {
              defaultValue: "La compétence n'a pas pu être mise à jour",
            }),
            {
              appearance: "error",
            }
          );
        }
      },
      onSuccess: () => {
        addToast(
          t("portal.config.skills.updateSkill.success", {
            defaultValue: "La compétence a bien été mise à jour",
          }),
          {
            appearance: "success",
          }
        );
        history.goBack();
      },
    }),
    [addToast, history, t]
  );

  const updateMutator = trpc.skills.updateSkill.useMutation(updatePayload);

  const updateSkill = useCallback(
    async (payload: UpdateSkillInput) => {
      updateMutator.mutate(payload);
    },
    [updateMutator]
  );

  return (
    <SkillsContext.Provider
      value={{
        createSkill,
        getCategories,
        getFields,
        getSkillAnalytics,
        getSkillEvaluationsScales,
        getSkills,
        removeSkill,
        updateArchiveStatus,
        updateSkill,
      }}
    >
      {children}
    </SkillsContext.Provider>
  );
};

export { SkillsContext, SkillsProvider, useSkillsContext };
