import { useHistory, useLocation } from "react-router-dom";
import { Dispatch, useState, useEffect, useCallback, SetStateAction } from "react";

import { FormikProps } from "formik";

import { useMachine } from "@xstate/react";

import type { EvaluationScale } from "@skillup/gepp";
import { Grid, Flex, FormikInputText, FormikInputTextArea } from "@skillup/design-system";
import {
  DSAlert,
  useModal,
  DSButton,
  DSAlertType,
  DSAlertDisplay,
  Label as DSLabel,
} from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { FormikState, SelectedSkills, ProcessedSkill, SkillWithExpectedLevel } from "types/skills";

import { JobModal, EditSkillModal } from "./Modals";
import { skillMachine } from "./Modals/stateMachine";
import { CustomField } from "./components/CustomField";
import { CreateJobState } from "../../pages/Create/CreateJob";
import { UpdateJobState } from "../../pages/Update/UpdateJob";
import { LoadingTextArea } from "./components/LoadingTextArea";
import { GenerateJobDataIA } from "../../pages/Create/components/Modals";
import { SkillGeneratedInformationPanel } from "./components/SkillGeneratedInformationPanel";
import { Alert, StyledForm, SkillsList, FormikInputTextAreaWrapper } from "./JobForm.styled";
import {
  getSkillsPerEvaluationScale,
  formatPayloadGenerateSkillsLevel,
} from "./helpers/generateSkillsLevel";
import {
  FormatSkillList,
  formatGeneratedSkillList,
  formatSkillListWithLevelUuid,
  formatAndFilterSkillListWithLevelUuid,
} from "./helper";
import {
  getSkills,
  getAllSkills,
  generateSkillsLevel,
  getSkillsCategories,
  generateJobDescription,
  generateSkillsDescription,
  getDefaultEvaluationScale,
} from "../../../queries";

interface JobFormProps {
  isCreationPage?: boolean;
  onTitleChange: () => void;
  formikProps: FormikProps<FormikState>;
  state: CreateJobState | UpdateJobState;
  setState?: Dispatch<SetStateAction<CreateJobState | UpdateJobState>>;
}

export const JobForm = ({
  formikProps,
  isCreationPage,
  onTitleChange,
  setState,
  state,
}: JobFormProps) => {
  const { t } = useTranslation();
  const send = useMachine(skillMachine)[1];

  const location = useLocation();
  const history = useHistory();
  const [anchor, setAnchor] = useState("");

  useEffect(() => {
    if (location.hash) {
      setAnchor(location.hash.substring(1));
    }
  }, [location]);

  const { values } = formikProps;
  const { skills: currentSkills } = values;

  const { data } = getAllSkills();
  const skillData = data as unknown as undefined | ProcessedSkill[];

  const { data: categories } = getSkillsCategories();

  const { data: defaultEvaluationScaleData } = getDefaultEvaluationScale();
  const defaultEvaluationScale = defaultEvaluationScaleData as unknown as EvaluationScale;
  const [skillsFromIA, setSkillsFromIA] = useState<Array<ProcessedSkill>>([]);
  const [loadingAIData, setLoadingAIData] = useState<boolean>(false);
  const [loadingAISkillsDescription, setLoadingAISkillsDescription] = useState<boolean>(false);
  const [displayReloadAlert, setDisplayReloadAlert] = useState<boolean>(false);
  const [selectedSkillsData, setSelectedSkills] = useState<SelectedSkills>({});
  const [selectedAISkillsData, setSelectedAISkills] = useState<SelectedSkills>({});

  const traductionLabel = t("jobs.addSkillsModal.noCategoryLabel", {
    defaultValue: "Compétences sans catégorie",
  });

  const generatedByAiLabel = t("jobs.addSkillsModal.generatedByAi", {
    defaultValue: "Compétences générées par IA",
  });

  const createJobAiTooltip = t("jobs.button.createAIJob.tooltip", {
    defaultValue: "Saisissez un titre pour poursuivre la création via IA",
  });

  const createJobTooltip = t("jobs.button.createJob.tooltip", {
    defaultValue: "Saisissez un titre pour poursuivre la création manuellement",
  });

  const pendingSkills = formatAndFilterSkillListWithLevelUuid(skillData, currentSkills);
  const pendingAISkills = formatGeneratedSkillList(
    currentSkills.filter((skill) => skill.generated),
    defaultEvaluationScale
  );
  const skillsToDisplay = FormatSkillList(
    [...pendingSkills, ...pendingAISkills],
    traductionLabel,
    generatedByAiLabel
  );

  const allSkillsFormated = FormatSkillList(
    formatSkillListWithLevelUuid(skillData),
    traductionLabel,
    generatedByAiLabel
  );

  const numberOfGeneratedSkills = currentSkills.filter((skill) => skill.generated).length;
  const haveAtLeastOneGeneratedSkill = skillsToDisplay.some((category) =>
    category.skills.some((skill) => skill.generated)
  );

  const {
    hide: hideAddSkillModal,
    isOpen: isAddSkillModalOpen,
    show: showAddSkillModal,
  } = useModal();

  const {
    hide: hideEditSkillModal,
    isOpen: isEditSkillModalOpen,
    show: showEditSkillModal,
  } = useModal();

  const {
    hide: hideGenerateJobDataIaModal,
    isOpen: isGenerateJobDataIaModalOpen,
    show: showGenerateJobDataIaModal,
  } = useModal();

  const [skillToEdit, setSkillToEdit] = useState<undefined | ProcessedSkill>(undefined);

  const [isTitleSet, setTitle] = useState<boolean>(false);
  const [isContentShown, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    if (!isCreationPage) {
      setShowContent(true);
    }

    if (values.jobName.length) {
      setTitle(true);
      onTitleChange();
    } else {
      setTitle(false);
    }

    if (isCreationPage && !isContentShown && state.step === "duplicate-job") {
      setShowContent(true);
    }
  }, [isCreationPage, values.jobName, state.step, isContentShown, onTitleChange]);

  const handleSkillUpdate = (skills: SkillWithExpectedLevel[]) => {
    const removed = currentSkills.filter(
      (e) => !skills.some((skill) => skill.skillUuid === e.skillUuid)
    );
    formikProps.setFieldValue("skills", [
      ...removed,
      ...skills.map((skill) => ({
        categoryId: skill.categoryId,
        description: skill.description ?? undefined,
        expectedLevelUuid: skill.levelUuid ?? null,
        generated: skill.generated ?? false,
        skillName: skill.skillName ?? undefined,
        skillUuid: skill.skillUuid,
      })),
    ]);

    history.replace(`${location.pathname}#${skills[0].skillUuid}`);
  };

  const handleEdit = useCallback(
    (skillUuid: string) => {
      const skillToEditFromData = skillData?.find((e) => e.uuid === skillUuid);
      const skillToEditFromDisplay = skillsFromIA.find((e) => e.uuid === skillUuid);

      setSkillToEdit(skillToEditFromData || skillToEditFromDisplay);
      showEditSkillModal();
    },
    [skillData, skillsFromIA, setSkillToEdit, showEditSkillModal]
  );

  const handleRemove = (skillUuid: string) => {
    formikProps.setFieldValue(
      "skills",
      currentSkills.filter((e) => e.skillUuid !== skillUuid)
    );
  };

  const handleGenerateJobDescription = async (
    selectedAISkills: SelectedSkills,
    selectedSkills: SelectedSkills
  ) => {
    hideGenerateJobDataIaModal();
    setLoadingAIData(true);
    setShowContent(true);
    setDisplayReloadAlert(false);
    setSelectedSkills(selectedSkills);
    setSelectedAISkills(selectedAISkills);
    Object.keys(selectedAISkills).length > 0 && setLoadingAISkillsDescription(true);

    let selectedSkillsDataToUpdate: SkillWithExpectedLevel[] = [];
    let selectedGeneratedSkillsData: SkillWithExpectedLevel[] = [];
    generateJobDescription(values.jobName, [
      ...Object.keys(selectedAISkills),
      ...Object.keys(selectedSkills),
    ]).then(
      async (jobDescriptions) => {
        const levelsRecords = {};
        defaultEvaluationScale.levels.forEach((level) => {
          levelsRecords[level.uuid] = level.name;
        });

        const selectedSkillsUuids = Object.values(selectedSkills).map((e) => e.uuid);
        const skillsData = (await getSkills(selectedSkillsUuids)) as unknown as ProcessedSkill[];

        if (skillsData) {
          send({ type: "SET_SKILLS", payload: { skills: skillsData } });

          const skillsPerEvaluationScale = getSkillsPerEvaluationScale(skillsData);

          const results = await Promise.all(
            Object.values(skillsPerEvaluationScale).map((evaluationScale) =>
              generateSkillsLevel(formatPayloadGenerateSkillsLevel(values.jobName, evaluationScale))
            )
          );

          const generatedLevels = results.flat();

          selectedSkillsDataToUpdate = skillsData.map((skill) => {
            const skillLevel = generatedLevels.find((res) => res.skill === skill.name);
            const levelNumber = skill.evaluationScale.levels.find(
              (lvl) => lvl.uuid === skillLevel.level
            )?.level;

            return {
              evaluationScale: skill.evaluationScale,
              levelNumber: levelNumber,
              levelUuid: skillLevel.level,
              skillName: skill.name,
              skillUuid: skill.uuid,
              totalLevel: skill.evaluationScale.levels.length,
            };
          });
        }

        if (Object.keys(selectedAISkills).length > 0) {
          const skillsDescription = await generateSkillsDescription({
            categories: categories?.reduce<Record<string, string>>(
              (acc, category) => ({ ...acc, [category.uuid]: category.label }),
              {}
            ),
            evaluationScale: levelsRecords,
            jobName: values.jobName,
            selectedSkills: Object.keys(selectedAISkills),
          });

          const formatedGeneratedSkillList = formatGeneratedSkillList(
            Object.values(selectedAISkills).map((e) => {
              return { skillName: e.name, skillUuid: e.uuid };
            }),
            defaultEvaluationScale
          );

          const skillsWithDescription = formatedGeneratedSkillList.map((skill) => {
            const skillDescription = skillsDescription.find(
              (skillDescription) => skillDescription.skill === skill.name
            );

            let category: undefined | ProcessedSkill["category"];

            if (skillDescription.category !== null) {
              category = categories.find((cat) => cat.uuid === skillDescription.category);
            }

            return {
              ...skill,
              category,
              description: skillDescription?.description ?? "",
              expectedLevelUuid: skillDescription?.level ?? undefined,
            };
          });

          send({ type: "SET_SKILLS", payload: { skills: skillsWithDescription } });
          setSkillsFromIA(skillsWithDescription);

          selectedGeneratedSkillsData = skillsWithDescription.map((skill) => ({
            categoryId: skill.category?.uuid,
            description: skill.description,
            evaluationScale: skill.evaluationScale,
            generated: true,
            levelNumber: skill.evaluationScale.levels.find(
              (level) => level.uuid === skill.levelUuid
            )?.level,
            levelUuid: skill.expectedLevelUuid,
            skillName: skill.name,
            skillUuid: skill.uuid,
            totalLevel: skill.evaluationScale.levels.length,
          }));
          setLoadingAISkillsDescription(false);
          setState({ step: "ai-generation-finished" });
        }

        if (jobDescriptions && !loadingAISkillsDescription) {
          setLoadingAIData(false);
          formikProps.setFieldValue("sections", jobDescriptions.sections);
          handleSkillUpdate([...selectedSkillsDataToUpdate, ...selectedGeneratedSkillsData]);
          setState({ step: "ai-generation-finished" });
        }
      },
      (error) => {
        setLoadingAIData(false);
        setLoadingAISkillsDescription(false);
        setDisplayReloadAlert(true);
      }
    );
  };

  return (
    <>
      {loadingAIData && (
        <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.FULL_BLEED}>
          {t("jobs.form.aiData.loading", {
            defaultValue:
              "La génération de votre fiche de poste peux prendre une dizaine de secondes...",
          })}
        </DSAlert>
      )}
      <Flex width="100%" height="100%" overflow="auto" backgroundColor="surface-light-darker">
        <StyledForm>
          <Flex marginTop="s" overflow="hidden" paddingBottom="jumbo" justifyContent="center">
            <Flex gap="s" width="650px" flexDirection="column">
              <Flex flexDirection="column">
                <DSLabel
                  required
                  label={t("jobs.form.label.name", {
                    defaultValue: "Intitulé de la fiche de poste",
                  })}
                />
                <FormikInputText
                  name="jobName"
                  value={values.jobName ?? ""}
                  placeholder={t("jobs.form.label.name", {
                    defaultValue: "Intitulé de la fiche de poste",
                  })}
                />
              </Flex>
              {isCreationPage && !isContentShown && (
                <Flex gap="xs" flexDirection="row" justifyContent="end">
                  <DSButton
                    type="button"
                    emphasis="Low"
                    buttonSize="M"
                    key="create-job"
                    disabled={!isTitleSet}
                    tooltip={!isTitleSet ? createJobTooltip : undefined}
                    label={t("jobs.button.createAIJob", {
                      defaultValue: "Créer ma fiche de poste manuellement",
                    })}
                    onClick={() => {
                      setState({ step: "manual-creation" });
                      setShowContent(true);
                    }}
                  />
                  <DSButton
                    type="button"
                    buttonSize="M"
                    key="create-ai-job"
                    disabled={!isTitleSet}
                    tooltip={!isTitleSet ? createJobAiTooltip : undefined}
                    label={t("jobs.button.createAIJob", {
                      defaultValue: "Créer ma fiche de poste à l'aide de l'IA",
                    })}
                    onClick={() => {
                      setState({ step: "ai-job-generation-loading" });
                      showGenerateJobDataIaModal();
                    }}
                  />
                </Flex>
              )}
              {isContentShown && (
                <>
                  <Grid
                    gridRowGap="s"
                    gridColumnGap="s"
                    gridTemplateRows={"auto"}
                    gridTemplateColumns={{
                      columnNumbers: 3,
                      columnWidth: "1fr",
                    }}
                  >
                    {values.fields.map((field, index) => {
                      return <CustomField key={index} index={index} field={field} />;
                    })}
                  </Grid>
                  {values.sections.map((templateField, index) => (
                    <Flex key={index} flexDirection="column">
                      <DSLabel label={templateField.name} />
                      {loadingAIData ? (
                        <LoadingTextArea />
                      ) : (
                        <FormikInputTextAreaWrapper>
                          <FormikInputTextArea
                            toolbarButtons={toolbarButtons}
                            name={`sections.${index}.value`}
                            value={templateField.value ?? ""}
                            placeholder={t("jobs.form.label.placeholder", {
                              defaultValue:
                                "Saisissez ici le contenu de cette section de fiche de poste",
                            })}
                          />
                        </FormikInputTextAreaWrapper>
                      )}
                    </Flex>
                  ))}
                  {currentSkills.length !== 0 && (
                    <Flex flexDirection="column">
                      <DSLabel
                        label={t("jobs.form.label.skillsLinked", {
                          defaultValue: "Compétences associées",
                        })}
                      />
                      {haveAtLeastOneGeneratedSkill && (
                        <SkillGeneratedInformationPanel numberOfSkills={numberOfGeneratedSkills} />
                      )}
                      <SkillsList
                        anchor={anchor}
                        allCategories={allSkillsFormated}
                        categoriesDisplayed={skillsToDisplay}
                        onEdit={handleEdit}
                        onRemove={handleRemove}
                      />
                    </Flex>
                  )}
                  <Flex marginBottom="s">
                    <DSButton
                      type="button"
                      buttonSize="S"
                      emphasis="Mid"
                      aria-label="Ajouter des compétences"
                      onClick={showAddSkillModal}
                      label={t("jobs.form.button.addSkill", {
                        defaultValue: "Ajouter des compétences",
                      })}
                    />
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
          {isGenerateJobDataIaModalOpen && (
            <GenerateJobDataIA
              jobName={values.jobName}
              close={hideGenerateJobDataIaModal}
              open={isGenerateJobDataIaModalOpen}
              onConfirm={handleGenerateJobDescription}
            />
          )}
          {isAddSkillModalOpen && skillData && (
            <JobModal
              skills={skillData}
              skillAlreadyAdded={currentSkills.map((skill) => skill.skillUuid)}
              onAbort={hideAddSkillModal}
              onConfirm={(skills) => {
                handleSkillUpdate(skills);
                hideAddSkillModal();
              }}
            />
          )}
          {isEditSkillModalOpen && skillToEdit && (
            <EditSkillModal
              skill={skillToEdit}
              onAbort={() => {
                hideEditSkillModal();
                setSkillToEdit(undefined);
              }}
              onConfirm={(skills) => {
                handleSkillUpdate(skills);
                hideEditSkillModal();
                setSkillToEdit(undefined);
              }}
            />
          )}
          {displayReloadAlert && (
            <Alert
              closeButton
              type={DSAlertType.ERROR}
              display={DSAlertDisplay.FLOATING}
              onClose={() => setDisplayReloadAlert(false)}
              onSecondaryAction={() => setDisplayReloadAlert(false)}
              onAction={() =>
                handleGenerateJobDescription(selectedAISkillsData, selectedSkillsData)
              }
              buttonLabel={t("jobs.form.aiData.alert.reload", {
                defaultValue: "Relancer l'opération",
              })}
              secondaryButtonLabel={t("jobs.form.aiData.alert.cancel", {
                defaultValue: "Reprendre manuellement",
              })}
            >
              {t("jobs.form.aiData.error", {
                defaultValue:
                  "Une erreur est survenue lors de la génération de votre fiche de poste. Veuillez réessayer.",
              })}
            </Alert>
          )}
        </StyledForm>
      </Flex>
    </>
  );
};

const toolbarButtons = [
  "bold",
  "italic",
  "underline",
  "paragraphFormat",
  "insertTable",
  "|",
  "insertLink",
  "insertVideo",
  "|",
  "formatUL",
  "formatOL",
  "|",
  "outdent",
  "indent",
  "|",
  "selectAll",
  "clearFormatting",
  "|",
  "help",
  "html",
  "|",
  "undo",
  "redo",
];
