import { Flex } from "@skillup/ui";

import { PlaceholderIcon } from "./Icon";
import styles from "./Cards.module.scss";

const CardsPlaceholder = ({ label }: {label?: string}) => {
  return (<Flex center column className={styles.placeholder}>
    <PlaceholderIcon />
    {label && <p className={styles.placeholderText}>{ label }</p>}
  </Flex>)
}

export default CardsPlaceholder;
export { PlaceholderIcon } from "./Icon";
