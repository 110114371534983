import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import DSLayout from "components/DSLayout";

import { ReviewCriteria } from "./ReviewCriteria/ReviewCriteria";
import { ImportsExports } from "./ImportsExports/ImportsExports";
import { ReviewCriteriaList } from "./ReviewCriteriaList/ReviewCriteriaList";
import { ReviewCriteriaEdit } from "./ReviewCriteriaEdit/ReviewCriteriaEdit";

export const CoreHRManagement = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentTab = history.location.pathname.split("/").pop() || "";

  return (
    <Switch>
      <Route exact component={ReviewCriteria} path={CoreHRRoutes.reviewCriteria} />
      <Route exact component={ReviewCriteria} path={CoreHRRoutes.reviewCriteriaHistory} />
      <Route exact component={ReviewCriteriaEdit} path={CoreHRRoutes.reviewCriteriaEdit} />

      <Route>
        <DSLayout
          activeLayout={currentTab}
          title={t("core-hr.layout.title", { defaultValue: "Configuration du Core RH" })}
          layouts={[
            {
              primaryButton: null,
              tab: {
                id: CoreHRRoutes.reviewCriteriaList.split("/").pop(),
                label: t("core-hr.layout.tab.review-criteria-list.label", {
                  defaultValue: "Critères d'évaluation",
                }),
                url: CoreHRRoutes.reviewCriteriaList,
              },
            },
            {
              primaryButton: null,
              tab: {
                id: CoreHRRoutes.importExport.split("/").pop(),
                label: t("core-hr.layout.tab.importExport.label", {
                  defaultValue: "Imports & Exports",
                }),
                url: CoreHRRoutes.importExport,
              },
            },
          ]}
        >
          <Switch>
            <Route exact component={ReviewCriteriaList} path={CoreHRRoutes.reviewCriteriaList} />
            <Route component={ImportsExports} path={CoreHRRoutes.importExport} />
            <Redirect to={CoreHRRoutes.reviewCriteriaList} />
          </Switch>
        </DSLayout>
      </Route>
    </Switch>
  );
};

const BaseUrl = "/responsable/configuration-du-core-hr" as const;
export const CoreHRRoutes = {
  BaseUrl,
  importExport: `${BaseUrl}/imports-exports` as const,
  reviewCriteria: `${BaseUrl}/review-criteria/:id` as const,
  reviewCriteriaEdit: `${BaseUrl}/review-criteria/edit/:id` as const,
  reviewCriteriaHistory: `${BaseUrl}/review-criteria/:id/history/:selectedVersion?` as const,
  reviewCriteriaList: `${BaseUrl}/review-criteria` as const,
};
