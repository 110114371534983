import { ModulesAccessList } from "@skillup/espace-rh-bridge";

import { ModuleType } from "hooks/useSettings";
import User from "utils/User";

const Dashboards = (
  hasAccess: { to: (module: ModuleType) => boolean },
  toTrainings: boolean,
  toInterviews: boolean
) => {
  const toTrainingDashboard = () =>
    User.isSkillupDeveloper() ||
    (toTrainings &&
      User.hasTrainingDashboardEnabled() &&
      hasAccess.to(ModulesAccessList.TrainingDashboard));

  const toInterviewDashboard = () =>
    User.isSkillupDeveloper() ||
    (toInterviews &&
      User.hasInterviewDashboardEnabled() &&
      hasAccess.to(ModulesAccessList.InterviewDashboard));

  const toDashboards = () => toTrainingDashboard() || toInterviewDashboard();
  return {
    toTrainingDashboard,
    toInterviewDashboard,
    toDashboards,
  };
};

export default Dashboards;
