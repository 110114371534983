export const Line = `
    <svg width="32" height="2" viewBox="0 0 32 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1H16H32" stroke="#4490A7" stroke-width="2" />
    </svg>
`;

export const Dot = `
    <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10H16H32" stroke="#FFBB00" stroke-width="3" />
      <path
        opacity="0.25"
        d="M16 20C13.3483 20 10.8051 18.9468 8.92981 17.0719C7.05452 15.1971 6.00066 12.6542 6 10.0025C5.99934 7.35076 7.05192 4.80734 8.92627 2.93158C10.8006 1.05582 13.3433 0.00132712 15.995 1.25181e-06C18.6467 -0.00132461 21.1904 1.05062 23.0667 2.92451C24.9429 4.79839 25.998 7.34076 26 9.99249C26.002 12.6442 24.9507 15.1882 23.0773 17.0649C21.2039 18.9416 18.6617 19.9973 16.01 20L16 20Z"
        fill="#FFBB00"
      />
      <path
        d="M16 16C14.409 16 12.883 15.3681 11.7579 14.2432C10.6327 13.1183 10.0004 11.5925 10 10.0015C9.9996 8.41046 10.6312 6.88441 11.7558 5.75895C12.8804 4.63349 14.406 4.0008 15.997 4C17.588 3.99921 19.1143 4.63038 20.24 5.75471C21.3657 6.87904 21.9988 8.40446 22 9.9955C22.0012 11.5865 21.3704 13.1129 20.2463 14.2389C19.1223 15.3649 17.597 15.9984 16.006 16L16 16Z"
        fill="#FFBB00"
        stroke="white"
      />
    </svg>
`;

export const Column = `
    <svg width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_4829_10942" fill="white">
        <path d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V12H0V2Z" />
      </mask>
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V12H0V2Z"
        fill="#FFBB00"
      />
      <path
        d="M-1 2C-1 0.343146 0.343146 -1 2 -1H30C31.6569 -1 33 0.343146 33 2H31C31 1.44772 30.5523 1 30 1H2C1.44772 1 1 1.44772 1 2H-1ZM32 12H0H32ZM-1 12V2C-1 0.343146 0.343146 -1 2 -1V1C1.44772 1 1 1.44772 1 2V12H-1ZM30 -1C31.6569 -1 33 0.343146 33 2V12H31V2C31 1.44772 30.5523 1 30 1V-1Z"
        fill="#B28600"
        mask="url(#path-1-inside-1_4829_10942)"
      />
    </svg>
`;
