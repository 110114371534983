import { useMemo } from "react";

import cx from "classnames";
import { MdChatBubble } from "react-icons/md";

import { DSColor, DSTooltip } from "@skillup/ui";

import styles from "./DecorativeTag.module.scss";

const DecorativeTagThemes = {
  blue: styles.blueTag,
  green: styles.greenTag,
  grey: styles.greyTag,
  orange: styles.orangeTag,
  pink: styles.pinkTag,
  red: styles.redTag,
  yellow: styles.yellowTag,
} as const;

const ColorsMap = {
  [DSColor["decoration-blue-base"]]: {
    background: DSColor["decoration-blue-lightest"],
    icon: DSColor["decoration-blue-base"],
    label: DSColor["decoration-blue-darker"],
  },
  [DSColor["decoration-green-base"]]: {
    background: DSColor["decoration-green-lightest"],
    icon: DSColor["decoration-green-base"],
    label: DSColor["decoration-green-darker"],
  },
  [DSColor["decoration-orange-base"]]: {
    background: DSColor["decoration-orange-lightest"],
    icon: DSColor["decoration-orange-base"],
    label: DSColor["decoration-orange-darker"],
  },
  [DSColor["decoration-pink-base"]]: {
    background: DSColor["decoration-pink-lightest"],
    icon: DSColor["decoration-pink-base"],
    label: DSColor["decoration-pink-darker"],
  },
  [DSColor["decoration-red-base"]]: {
    background: DSColor["decoration-red-lightest"],
    icon: DSColor["decoration-red-base"],
    label: DSColor["decoration-red-darker"],
  },
  [DSColor["decoration-yellow-base"]]: {
    background: DSColor["decoration-yellow-lightest"],
    icon: DSColor["decoration-yellow-base"],
    label: DSColor["decoration-yellow-darker"],
  },
  [DSColor["greyscale-dark"]]: {
    background: DSColor["greyscale-lighter"],
    icon: DSColor["greyscale-dark"],
    label: DSColor["greyscale-darker"],
  },
};

/**
 * This is temporary, in the future actions will provide their own theme value.
 */
function randomColor() {
  const keys = Object.keys(DecorativeTagThemes);
  const randomIndex = Math.floor(Math.random() * keys.length);
  return keys[randomIndex] as keyof typeof DecorativeTagThemes;
}

export function DecorativeTag(props: {
  label: string;
  className?: string;
  tooltipLabel?: string;
  theme?: keyof typeof DecorativeTagThemes;
}) {
  const theme = useMemo(() => props.theme ?? randomColor(), [props.theme]);
  return (
    <div className={cx(styles.decorativeTag, DecorativeTagThemes[theme], props.className)}>
      <span className={styles.label}>{props.label}</span>
      {props.tooltipLabel && (
        <DSTooltip withPortal className={styles.tooltip} label={props.tooltipLabel}>
          <MdChatBubble className={styles.icon} />
        </DSTooltip>
      )}
    </div>
  );
}

DecorativeTag.FreeColor = (props: {
  color: string;
  label: string;
  className?: string;
  tooltipLabel?: string;
}) => {
  const color = ColorsMap[props.color] ?? ColorsMap[DSColor["greyscale-dark"]];
  const iconColor = color.icon;
  const labelColor = color.label;
  const backgroundColor = color.background;
  return (
    <div
      style={{ backgroundColor }}
      className={cx(styles.decorativeTag, props.className)} // oh oh ! no theme here ?
    >
      <span
        className={styles.label}
        style={{
          color: labelColor,
        }}
      >
        {props.label}
      </span>
      {props.tooltipLabel && (
        <DSTooltip withPortal className={styles.tooltip} label={props.tooltipLabel}>
          <MdChatBubble className={styles.icon} style={{ color: iconColor }} />
        </DSTooltip>
      )}
    </div>
  );
};
