import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { getFields } from "./getFields";

import { type Field } from "./getFields";

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};

const defaultOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
};

type FieldsMap = Record<string, Field>;

export const useCoreHRFields = (options: Options = defaultOptions) => {
  const query = useQuery(["core-hr-fields"], getFields, {
    ...defaultOptions,
    ...options,
  });

  const fields = useMemo(() => query.data ?? [], [query.data]);

  const fieldsMap = useMemo(() => {
    if (!query.data) {
      return {} satisfies FieldsMap;
    }
    return query.data?.reduce<Record<string, Field>>((acc, field) => {
      acc[field.id] = field;
      return acc;
    }, {} satisfies FieldsMap);
  }, [query.data]);

  return {
    fields,
    fieldsMap,
    isError: query.isError,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
  };
};

export { Field };
