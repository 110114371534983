import { SuggestionsJob } from "types/skills";

export const associateJob = (employees: SuggestionsJob["employees"], selectedJobId: string) => {
  const accepted = employees
    .map(
      (employee) =>
        employee.employeeSuggestions.find((suggestion) => suggestion.jobId === selectedJobId)
          ?.suggestionID
    )
    .filter((suggestion) => suggestion !== undefined);

  const dismissed = employees.flatMap((employee) =>
    employee.employeeSuggestions
      .filter((suggestion) => suggestion.jobId !== selectedJobId)
      .map((suggestion) => suggestion.suggestionID)
  );

  const manualAssignments = accepted.length
    ? undefined
    : employees.reduce<Array<{ jobId: string; employeeId: string }>>(
        (acc, employee) => acc.concat([{ employeeId: employee.employeeID, jobId: selectedJobId }]),
        []
      );

  return { accepted, dismissed, manualAssignments };
};

export const associateMultipleJobs = (
  employees: SuggestionsJob["employees"],
  selectedJobIdPerEmployee: { [employeeId: string]: string }
) => {
  const payload = {
    accepted: [],
    dismissed: [],
    manualAssignments: [],
  };

  Object.keys(selectedJobIdPerEmployee).forEach((employeeId) => {
    const employee = employees.find((e) => e.employeeID === employeeId);

    if (selectedJobIdPerEmployee[employeeId] === undefined) return;

    const accepted = employee.employeeSuggestions.find(
      (suggestion) => suggestion.jobId === selectedJobIdPerEmployee[employeeId]
    )?.suggestionID;

    if (accepted) payload.accepted.push(accepted);

    const dismissed = employee.employeeSuggestions
      .filter((suggestion) => suggestion.suggestionID !== accepted)
      .map((suggestion) => suggestion.suggestionID);

    payload.dismissed.push(...dismissed);

    if (accepted) return;

    payload.manualAssignments.push({ employeeId, jobId: selectedJobIdPerEmployee[employeeId] });
  });

  return payload;
};
