import { buildRequest } from "utils/buildRequest";
import { PromisePool } from "@skillup/promise-pool";

import { ScheduleRowTypes, type TrainingDomainRoutes } from "@skillup/training-bridge";

import type {
  GetQuestionsRouteType,
  GetQuestionRouteType,
} from "../types";

export const fetchAnalyticsConfig = async () => {
  try {
    const config = await buildRequest<TrainingDomainRoutes.SCHEDULE_ROWS.DashboardGetConfigRoute>({
      method: "GET",
      path: "/schedule-rows/dashboard/config",
      target: "TRAINING_API",
    })();

    if (!config) {
      return null;
    }

    return config;
  } catch (error) {
    return null;
  }
};

export const saveAnalyticsConfig = async (config: ScheduleRowTypes.DashboardConfig) => {
  try {
    return await buildRequest<TrainingDomainRoutes.SCHEDULE_ROWS.DashboardSaveConfigRoute>({
      method: "POST",
      path: "/schedule-rows/dashboard/save",
      target: "TRAINING_API",
      payload: config,
    })();
  } catch (error) {
    throw new Error("Failed to save config");
  }
};

export const saveDefaultDashboardConfiguration = async () => {
  try {
    return await buildRequest<TrainingDomainRoutes.SCHEDULE_ROWS.DashboardSaveDefaultConfigRoute>({
      method: "POST",
      path: "/schedule-rows/dashboard/default-config/save",
      target: "TRAINING_API",
    })();
  } catch (error) {
    throw new Error("Failed to check for default configuration");
  }
};

export const getQuestions = async () => {
  const questionIds = await buildRequest<GetQuestionsRouteType>({
    method: "GET",
    path: `/refine/questions`,
    target: "ANALYTICS_CONFIG_SERVICE",
  })();

  const cardsData = questionIds.data.attributes.question_ids.map((id) => () => {
    return getQuestion(id);
  });

  const cards = await PromisePool.All(cardsData);

  return cards.map((card) => card.data.attributes);
};

export const getQuestion = (questionId: string) => {
  return buildRequest<GetQuestionRouteType>({
    method: "GET",
    path: `/refine/question/{questionId}`,
    params: { questionId },
    target: "ANALYTICS_CONFIG_SERVICE",
  })();
};
