import { GridColDef } from "@mui/x-data-grid-pro";

import { Review, Campaign } from "../../../types";

export function getCustomFieldColDefs(campaign: Campaign): GridColDef<Review>[] {
  return campaign.customFields
    .filter(({ binding }) => binding !== null)
    .map(({ binding, label }) => {
      return {
        type: "string",
        editable: false,
        field: binding,
        flex: 1,
        headerName: label,
        minWidth: 100,
      };
    });
}
