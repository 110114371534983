import type { Targets, TemplatesRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

export interface Props {
  readonly templateUuid: string;
}

export const getTemplateTargetsChildren = async ({ templateUuid }: Props): Promise<Targets[]> => {
  try {
    const { children } = await buildRequest<TemplatesRoutes.GetChildren>({
      method: "GET",
      path: "/templates/{templateUuid}/children/{kind}",
      params: {
        templateUuid,
        kind: "targets",
      },
    })();

    return children as Targets[];
  } catch (error) {
    throw new Error("No campaign Id in response");
  }
};
