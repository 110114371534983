import { FieldsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

type Payload = FieldsRoutes.UpdateFieldForEmployee["payload"];

export async function updateFieldsDataForEmployee(payload: Payload) {
  const response = await buildRequest<FieldsRoutes.UpdateFieldForEmployee>({
    method: "PUT",
    path: "/fields",
    payload,
    target: "PEOPLE_REVIEW",
  })();

  return response;
}
