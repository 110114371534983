export const PlaceholderIcon = () => {
  return (
    <svg
      width="375"
      height="180"
      viewBox="0 0 375 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity=".5">
        <g filter="url(#m)">
          <g clip-path="url(#o)">
            <path
              d="m180 68c0-13.255 10.745-24 24-24h134c13.255 0 24 10.745 24 24v63c0 13.255-10.745 24-24 24h-134c-13.255 0-24-10.745-24-24v-63z"
              fill="#fff"
            />
            <rect x="196" y="64.75" width="48" height="48" rx="3" fill="url(#j)" />
            <rect x="261" y="82.25" width="85" height="13" rx="3" fill="url(#i)" />
            <rect x="196" y="133.5" width="150" height="25.5" rx="3" fill="url(#h)" />
          </g>
        </g>
        <g filter="url(#l)">
          <g clip-path="url(#n)">
            <path
              d="m12 68c0-13.255 10.745-24 24-24h134c13.255 0 24 10.745 24 24v63c0 13.255-10.745 24-24 24h-134c-13.255 0-24-10.745-24-24v-63z"
              fill="#fff"
            />
            <rect x="28" y="64.75" width="48" height="48" rx="3" fill="url(#g)" />
            <rect x="93" y="82.25" width="85" height="13" rx="3" fill="url(#f)" />
            <rect x="28" y="133.5" width="150" height="25.5" rx="3" fill="url(#e)" />
          </g>
        </g>
        <rect x="12" y="65" width="350" height="115" fill="url(#d)" />
        <g filter="url(#k)">
          <path
            d="m91 26c0-9.9411 8.0589-18 18-18h146c9.941 0 18 8.0589 18 18v75c0 9.941-8.059 18-18 18h-146c-9.9411 0-18-8.059-18-18v-75z"
            fill="#fff"
          />
          <rect x="103" y="20.75" width="48" height="48" rx="3" fill="url(#c)" />
          <rect x="176" y="38.25" width="85" height="13" rx="3" fill="url(#b)" />
          <rect x="103" y="81.5" width="158" height="25.5" rx="3" fill="url(#a)" />
        </g>
      </g>
      <defs>
        <filter
          id="m"
          x="168"
          y="35"
          width="206"
          height="135"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4645_6568" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_4645_6568" result="shape" />
        </filter>
        <filter
          id="l"
          x="0"
          y="35"
          width="206"
          height="135"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4645_6568" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_4645_6568" result="shape" />
        </filter>
        <filter
          id="k"
          x="79"
          y="-1"
          width="206"
          height="135"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4645_6568" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_4645_6568" result="shape" />
        </filter>
        <linearGradient
          id="j"
          x1="196"
          x2="253.77"
          y1="64.75"
          y2="85.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E5E8EB" offset="0" />
          <stop stop-color="#F6F7F8" offset=".46064" />
          <stop stop-color="#E5E8EB" offset=".65856" />
          <stop stop-color="#F6F7F8" offset="1" />
        </linearGradient>
        <linearGradient
          id="i"
          x1="261"
          x2="278.87"
          y1="82.25"
          y2="123.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E5E8EB" offset="0" />
          <stop stop-color="#F6F7F8" offset=".46064" />
          <stop stop-color="#E5E8EB" offset=".65856" />
          <stop stop-color="#F6F7F8" offset="1" />
        </linearGradient>
        <linearGradient
          id="h"
          x1="196"
          x2="233.59"
          y1="133.5"
          y2="212.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E5E8EB" offset="0" />
          <stop stop-color="#F6F7F8" offset=".46064" />
          <stop stop-color="#E5E8EB" offset=".65856" />
          <stop stop-color="#F6F7F8" offset="1" />
        </linearGradient>
        <linearGradient
          id="g"
          x1="28"
          x2="85.774"
          y1="64.75"
          y2="85.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E5E8EB" offset="0" />
          <stop stop-color="#F6F7F8" offset=".46064" />
          <stop stop-color="#E5E8EB" offset=".65856" />
          <stop stop-color="#F6F7F8" offset="1" />
        </linearGradient>
        <linearGradient
          id="f"
          x1="93"
          x2="110.87"
          y1="82.25"
          y2="123.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E5E8EB" offset="0" />
          <stop stop-color="#F6F7F8" offset=".46064" />
          <stop stop-color="#E5E8EB" offset=".65856" />
          <stop stop-color="#F6F7F8" offset="1" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="28"
          x2="65.589"
          y1="133.5"
          y2="212.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E5E8EB" offset="0" />
          <stop stop-color="#F6F7F8" offset=".46064" />
          <stop stop-color="#E5E8EB" offset=".65856" />
          <stop stop-color="#F6F7F8" offset="1" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="187"
          x2="187"
          y1="-31.148"
          y2="144.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAFAFA" stop-opacity="0" offset="0" />
          <stop stop-color="#FAFAFA" offset="1" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="103"
          x2="160.77"
          y1="20.75"
          y2="41.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E5E8EB" offset="0" />
          <stop stop-color="#F6F7F8" offset=".46064" />
          <stop stop-color="#E5E8EB" offset=".65856" />
          <stop stop-color="#F6F7F8" offset="1" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="176"
          x2="193.87"
          y1="38.25"
          y2="79.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E5E8EB" offset="0" />
          <stop stop-color="#F6F7F8" offset=".46064" />
          <stop stop-color="#E5E8EB" offset=".65856" />
          <stop stop-color="#F6F7F8" offset="1" />
        </linearGradient>
        <linearGradient
          id="a"
          x1="103"
          x2="139.35"
          y1="81.5"
          y2="161.96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E5E8EB" offset="0" />
          <stop stop-color="#F6F7F8" offset=".46064" />
          <stop stop-color="#E5E8EB" offset=".65856" />
          <stop stop-color="#F6F7F8" offset="1" />
        </linearGradient>
        <clipPath id="o">
          <path
            d="m180 68c0-13.255 10.745-24 24-24h134c13.255 0 24 10.745 24 24v63c0 13.255-10.745 24-24 24h-134c-13.255 0-24-10.745-24-24v-63z"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="n">
          <path
            d="m12 68c0-13.255 10.745-24 24-24h134c13.255 0 24 10.745 24 24v63c0 13.255-10.745 24-24 24h-134c-13.255 0-24-10.745-24-24v-63z"
            fill="#fff"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
