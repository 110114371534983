import { type CSSProperties } from "react";

import styled from "styled-components";

import { DSColor, EspaceFont } from "@skillup/ui";

import { type SpacingTokenValues, translateDSSpacingToken } from "../utils";

export type TitleInterface = Pick<CSSProperties, "color" | "margin" | "padding"> & {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  width?: string;
  collab?: boolean;
  margin?: SpacingTokenValues;
  padding?: SpacingTokenValues;
  marginTop?: SpacingTokenValues;
  paddingTop?: SpacingTokenValues;
  marginLeft?: SpacingTokenValues;
  paddingLeft?: SpacingTokenValues;
  marginRight?: SpacingTokenValues;
  paddingRight?: SpacingTokenValues;
  marginBottom?: SpacingTokenValues;
  paddingBottom?: SpacingTokenValues;
};

const TitleWrapper = styled.div<TitleInterface>`
  ${({ color }) =>
    color ? `color: ${DSColor[color]}` : `color: ${DSColor["plainText-onLight-default"]}`};
  & {
    ${({ h5 }) => h5 && EspaceFont.rh.h5}
    ${({ collab, h5 }) => h5 && collab && EspaceFont.collab.h5}
    ${({ h4 }) => h4 && EspaceFont.rh.h4}
    ${({ collab, h4 }) => h4 && collab && EspaceFont.collab.h4}
    ${({ h3 }) => h3 && EspaceFont.rh.h3}
    ${({ collab, h3 }) => h3 && collab && EspaceFont.collab.h3}
    ${({ h2 }) => h2 && EspaceFont.rh.h2}
    ${({ collab, h2 }) => h2 && collab && EspaceFont.collab.h2}
    ${({ h1 }) => h1 && EspaceFont.rh.h1}
    ${({ collab, h1 }) => h1 && collab && EspaceFont.collab.h1}

    ${({ marginTop }) => marginTop && `margin-top: ${translateDSSpacingToken(marginTop)}`};

    ${({ marginBottom }) =>
      marginBottom && `margin-bottom: ${translateDSSpacingToken(marginBottom)}`};

    ${({ marginLeft }) => marginLeft && `margin-left: ${translateDSSpacingToken(marginLeft)}`};

    ${({ marginRight }) => marginRight && `margin-right: ${translateDSSpacingToken(marginRight)}`};

    ${({ margin }) => margin && `margin: ${translateDSSpacingToken(margin)}`};

    ${({ paddingTop }) => paddingTop && `padding-top: ${translateDSSpacingToken(paddingTop)}`};

    ${({ paddingBottom }) =>
      paddingBottom && `padding-bottom: ${translateDSSpacingToken(paddingBottom)}`};

    ${({ paddingLeft }) => paddingLeft && `padding-left: ${translateDSSpacingToken(paddingLeft)}`};

    ${({ paddingRight }) =>
      paddingRight && `padding-right: ${translateDSSpacingToken(paddingRight)}`};

    ${({ padding }) => padding && `padding: ${translateDSSpacingToken(padding)}`};

    ${({ width }) => width && `width: ${width}`};
  }
`;

export default TitleWrapper;
