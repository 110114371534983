import { useMemo } from "react";
import cx from "classnames";

import { Flex } from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";
import { type PanelConfigType } from "@skillup/training-bridge";

import { type DashboardPanelData } from "../types";
import { DashboardCard } from "../components/Cards/Card";

import styles from "./DashboardPanel.module.scss";

export type DashboardPanelProps = {
  panel: DashboardPanelData;
  filters: ListUtils.FilterValues<any>;
  schedules: Array<{ name: string; uuid: string }>;
};

export const DashboardPanel = ({ panel, filters, schedules }: DashboardPanelProps) => {
  const type = useMemo((): PanelConfigType => {
    const card = panel.cards[0];

    if (card.question_meta.axes.length === 1) {
      return "key-indicators";
    }

    if (card.question_meta.axes.length === 2) {
      return "detail-indicators";
    }

    if (card.question_meta.axes.length === 4) {
      return "monthly";
    }

    return "summary";
  }, [panel]);

  return (
    <Flex column className={styles.dashboardPanel}>
      <span className={styles.title}>{panel.short_name}</span>
      <Flex
        className={cx(styles.cards, {
          [styles.detailedIndicators]: type === "detail-indicators",
          [styles.keyIndicators]: type === "key-indicators",
          [styles.summary]: type === "summary",
        })}
      >
      {panel.cards.map((card) => (
        <DashboardCard
          type={type}
          key={card.short_name}
          card={card}
          filters={filters}
          schedules={schedules}
        />
      ))}
      </Flex>
    </Flex>
  );
};
