import storage from "services/storage";
import i18n, { InitOptions } from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { isNil } from "lodash";

const { NODE_ENV, REACT_APP_LOCALES_API_ROOT } = process.env;

const userData = localStorage.getItem("_skillup_userData");
let language = "fr";

try {
  if (userData) {
    language = JSON.parse(userData).currentLanguage;
  }
} catch (e) {
  console.log("Error while parsing localStorage trying to get _skillup_userData: ", e);
}

// for all options read: https://www.i18next.com/overview/configuration-options
const options: InitOptions = {
  lng: language,
  fallbackLng: "fr",
  ns: ["default"],
  defaultNS: "default",
  fallbackNS: "default",
  react: {
    transKeepBasicHtmlNodesFor: ["strong", "p", "em", "span", "h1", "h2", "h3", "h4", "h5", "br"],
  },
  interpolation: {
    escapeValue: false, // not needed for React as it escapes by default
  },
  keySeparator: false,
  ...(NODE_ENV === "development" && {
    // saveMissing: true,
    debug: true,
    missingKeyHandler: ([language], namespace, key, fallbackValue) => {
      if (language === "fr" && namespace === "default") {
        throw new ReferenceError(`Missing default value for key "${key}"`);
      }

      return fallbackValue;
    },
  }),
  backend: {
    loadPath: `${REACT_APP_LOCALES_API_ROOT}/locales?language={{lng}}&namespace={{ns}}&i18next=true`,
    customHeaders: {
      ...(!isNil(storage.get("X-Auth-Token")) && {
        "X-Auth-Token": storage.get("X-Auth-Token") as string,
      }),
    },
  },
  returnNull: true,
};

if (process.env.NODE_ENV !== "test") {
  i18n.use(HttpApi); // load translations from /public/locales
}

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init(options); // init i18next

export default i18n;
