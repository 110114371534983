import { useEffect, useMemo, useRef, useState } from "react";
import { useFormikContext, getIn } from "formik";
import { MdDelete as Delete, MdInfoOutline as InfoOutline } from "react-icons/md";

import {
  DropDownCheckbox,
  DSFormGroupTextInput,
  NumberInput,
  AssistiveArea,
  Select as DSSelect,
  Flex,
  Checkbox,
  DSButton,
  DSRadioGroup,
  DSRadio,
  DSAlertType,
  DSAlert,
  DSAlertDisplay,
  DSTooltip,
  DSCheckbox,
  DSModal,
} from "@skillup/ui";
import { DateRangePicker, FormikInputText, Text, TimeRangePicker } from "@skillup/design-system";
import { USER_FILE_VALIDATION_TYPE } from "@skillup/types";

import { searchUsersByQuery } from "fetchers/users-fetcher";
import useTranslation from "hooks/useTranslation";
import useAreas from "hooks/useAreas";

import {
  disableReviewsCheckbox,
  formatToDate,
  generateReference,
  onlyColdReviewsWouldBeDisabled,
} from "../utils";
import {
  PriceInputs,
  SelectIntraAutocomplete,
  AssistiveErrorText,
  ReferenceInput,
} from "../components";

import { hasColdReviewsEnabled, hasHotReviewsEnabled } from "utils/User";
import { Project } from "services/sessions";

import styles from "../Modal.module.scss";
import { Session } from "../types";
import { parseDateRange } from "utils/dates/dates";

type Props = {
  type: "create" | "update";
  session?: Project;
  trainingUuid?: string;
  trainingReference?: string;
  onClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
};

const FieldError = ({ name }: { name: string }) => {
  const { errors } = useFormikContext<Session>();

  const error = getIn(errors, name);

  if (typeof error !== "string") {
    return null;
  }

  return (
    <Text fontSize="0.75rem" espaceFont="body1Regular" color="status-error-darker">
      <Flex style={{ gap: "0.125rem", alignItems: "center" }}>
        <InfoOutline />
        {error}
      </Flex>
    </Text>
  );
};

export const SessionForm = ({
  isOpen,
  onClose,
  type,
  session,
  trainingUuid,
  trainingReference,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const { allAreas } = useAreas();
  const [showAlert, setShowAlert] = useState({ value: false, fields: [] });

  const { values, setFieldValue, isValid, handleSubmit, initialValues } =
    useFormikContext<Session>();

  const hasAccessToReviews = hasColdReviewsEnabled() || hasHotReviewsEnabled();
  const firstDate = values.dates?.[0]?.dateRange?.[0];

  useEffect(() => {
    if (values.intra?.reference) {
      setFieldValue("reference", generateReference(values.intra.reference));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.intra?.reference]);

  const reviewsGenerationAlert = useMemo(() => {
    if (!session || type === "create") {
      return undefined;
    }

    if (
      session.reviewsState?.evalGroupsGenerated === true &&
      values.evalGroupsGenerated === false
    ) {
      if (onlyColdReviewsWouldBeDisabled(session.reviewsState)) {
        return {
          value: t("supervisor.sessions.updateModal.reviews.warningText.coldOnly", {
            defaultValue: "Cette action n'affectera que les évaluations à froid.",
          }),
        };
      }
    }

    if (
      session.reviewsState?.evalGroupsGenerated === false &&
      values.evalGroupsGenerated === true
    ) {
      const intervalInfos = parseDateRange([session.properties.endDate], {
        dateFormat: "DD/MM/YYYY",
        hourFormat: "H:mm",
      });
      const { days } = intervalInfos;

      const endDay = days[0];

      const endDate = formatToDate({ date: endDay });
      const now = new Date();

      const date10DaysAgo = new Date(now);
      date10DaysAgo.setDate(now.getDate() - 10);
      const date100DaysAgo = new Date(now);
      date100DaysAgo.setDate(now.getDate() - 100);

      const completedMoreThan10DaysAgo = endDate < date10DaysAgo;
      const completedLessThan100DaysAgo = endDate > date100DaysAgo;
      const completedMoreThan100DaysAgo = endDate < date100DaysAgo;

      if (completedMoreThan10DaysAgo && session.reviewsState?.companyHasColdReviews === false) {
        return {
          value: t("supervisor.sessions.updateModal.reviews.warningText.hotOnly", {
            defaultValue: `Les modifications apportées ne sont pas rétroactives : les évaluations à chaud ne seront pas envoyées aux collaborateurs mais seront disponibles dans l'onglet "Évaluations".`,
          }),
        };
      }

      if (
        completedMoreThan10DaysAgo &&
        completedLessThan100DaysAgo &&
        session.reviewsState?.companyHasColdReviews === true
      ) {
        return {
          value: t("supervisor.sessions.updateModal.reviews.warningText.hotAndColdRecent", {
            defaultValue: `Les modifications apportées ne sont pas rétroactives : les évaluations à chaud ne seront pas envoyées aux collaborateurs mais seront disponibles dans l'onglet "Évaluations".
                Les évaluations à froid seront bien générées et envoyées.`,
          }),
        };
      }

      if (completedMoreThan100DaysAgo && session.reviewsState?.companyHasColdReviews === true) {
        return {
          value: t("supervisor.sessions.updateModal.reviews.warningText.hotAndColdOld", {
            defaultValue: `Les modifications apportées ne sont pas rétroactives : les évaluations à chaud et à froid ne seront pas envoyées aux collaborateurs mais seront disponibles dans l'onglet "Évaluations".`,
          }),
        };
      }
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, values.evalGroupsGenerated]);

  useEffect(() => {
    if (type === "create") return;
    if (!values.afterSummoned) return;
    const alertFields = [];

    const isDatesChanged = () => {
      const parsedProjectDates = initialValues.dates;
      if (parsedProjectDates.length !== values.dates.length) return true;

      return parsedProjectDates.some((projectDate, index) => {
        const stateDate = values.dates[index];
        return (
          projectDate.allDay !== stateDate.allDay ||
          projectDate.dateRange[0]?.getTime() !== stateDate.dateRange[0]?.getTime() ||
          projectDate.dateRange[1]?.getTime() !== stateDate.dateRange[1]?.getTime() ||
          projectDate.hourRange?.[0]?.getTime() !== stateDate.hourRange?.[0]?.getTime() ||
          projectDate.hourRange?.[1]?.getTime() !== stateDate.hourRange?.[1]?.getTime()
        );
      });
    };

    const isFieldChanged = (field: string) =>
      values[field] !== initialValues[field] && (values[field] !== "" || session[field] !== null);

    if (isDatesChanged()) alertFields.push("dates");
    if (isFieldChanged("room")) alertFields.push("room");
    if (isFieldChanged("link")) alertFields.push("link");
    if (isFieldChanged("city")) alertFields.push("city");

    setShowAlert({ value: alertFields.length > 0, fields: alertFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.dates, values.room, values.link, values.city, session]);

  const reviewsAlertRef = useRef(null);

  useEffect(() => {
    if (reviewsGenerationAlert) {
      reviewsAlertRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [reviewsGenerationAlert]);

  useEffect(() => {
    setFieldValue("isPostponed", showAlert.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlert]);

  if (!isOpen) {
    return null;
  }

  return (
    <DSModal isOpen className={styles.DSModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t(`trainings.sessions.modal.${type}.title`, {
            defaultValue: type === "create" ? "Créer une session" : "Modifier la session",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <div className={styles.modal}>
          {showAlert.value && (
            <DSAlert
              type={DSAlertType.WARNING}
              display={DSAlertDisplay.FULL_BLEED}
              className={styles.alert}
            >
              {t("supervisor.sessions.updateModal.alert", {
                defaultValue:
                  "La modification de « {{fields}} » rend obsolètes les convocations déjà envoyées. Si vous ne relancez pas de convocations, la session sera enregistrée comme « Provisoire ».",
                fields: showAlert.fields
                  .map((field) => {
                    return t(`supervisor.sessions.UpdateModal.${field}`, {
                      defaultValue: field,
                    });
                  })
                  .join(", "),
              })}
            </DSAlert>
          )}

          {type === "create" && (
            <>
              <SelectIntraAutocomplete
                intra={values.intra}
                intraUuid={trainingUuid}
                intraReference={trainingReference}
                onChange={(newIntra) => setFieldValue("intra", newIntra)}
                placeholder={t("supervisor.sessions.creationModal.program.placeholder", {
                  defaultValue: "Sélectionnez un lieu",
                })}
              />
              <FieldError name="intra" />
            </>
          )}

          <section className={styles.infoSection}>
            <Text espaceFont="body2Bold" color="plainText-onLight-default">
              {t("supervisor.sessions.creationModal.sessionInfo", {
                defaultValue: "Infos de session",
              })}
            </Text>

            <DSFormGroupTextInput className={styles.dateForm} label="Dates" required>
              <>
                {values.dates.map((date, index) => (
                  <DSFormGroupTextInput key={index} className={styles.dateFormGroup}>
                    <Flex column>
                      <Flex wrap className={styles.dateContainer} style={{ gap: "0.25rem" }}>
                        <DateRangePicker
                          className={styles.dateRangePicker}
                          value={date.dateRange as any}
                          onChange={(value) => setFieldValue(`dates[${index}].dateRange`, value)}
                          slotProps={{
                            popper: {
                              style: {
                                zIndex: 1000,
                              },
                            },
                          }}
                        />
                        {!date.allDay && (
                          <div className={styles.timeRangeContainer}>
                            <TimeRangePicker
                              onChange={(value) =>
                                setFieldValue(`dates[${index}].hourRange`, value)
                              }
                              value={date.hourRange as any}
                              slotProps={{
                                popper: {
                                  style: {
                                    zIndex: 1000,
                                  },
                                },
                              }}
                            />
                          </div>
                        )}
                        <Checkbox
                          key="toggle-time"
                          className={styles.toggle}
                          label={t("supervisor.sessions.creationModal.allDay", {
                            defaultValue: "Toute la journée",
                          })}
                          value="all"
                          checked={date.allDay}
                          onClick={() => setFieldValue(`dates[${index}].allDay`, !date.allDay)}
                        />
                        <DSButton
                          onClick={() => {
                            setFieldValue(
                              "dates",
                              values.dates.filter((_, i) => i !== index)
                            );
                          }}
                          iconOnly
                          icon={<Delete />}
                          buttonSize={"S"}
                          emphasis={"Low"}
                        />
                      </Flex>

                      <FieldError name={`dates[${index}]`} />
                    </Flex>
                  </DSFormGroupTextInput>
                ))}

                <DSButton
                  label={t("supervisor.sessions.creationModal.addDate", {
                    defaultValue: "Ajouter des dates à la session",
                  })}
                  buttonSize={"S"}
                  emphasis={"Low"}
                  onClick={() => setFieldValue("dates", values.dates.concat({ allDay: true }))}
                  type="button"
                />
              </>
            </DSFormGroupTextInput>
            <DSFormGroupTextInput
              label={t("supervisor.sessions.creationModal.city", {
                defaultValue: "Ville",
              })}
              required
            >
              <FormikInputText
                name="city"
                value={values.city}
                placeholder={t("supervisor.sessions.creationModal.cityPlaceholder", {
                  defaultValue: "Ex: Paris",
                })}
              />
            </DSFormGroupTextInput>

            <Flex>
              <DSFormGroupTextInput
                label={t("supervisor.sessions.creationModal.room", {
                  defaultValue: "Salle",
                })}
              >
                <FormikInputText
                  name="room"
                  value={values.room}
                  placeholder={t("supervisor.sessions.creationModal.roomPlaceholder", {
                    defaultValue: "Ex: 439",
                  })}
                />
              </DSFormGroupTextInput>

              <DSFormGroupTextInput
                label={t("supervisor.sessions.creationModal.link", {
                  defaultValue: "Lien de connexion",
                })}
              >
                <FormikInputText
                  name="link"
                  value={values.link}
                  placeholder={t("supervisor.sessions.creationModal.linkPlaceholder", {
                    defaultValue: "Ex : https://www.skillup.co/",
                  })}
                />
              </DSFormGroupTextInput>
            </Flex>

            <DSFormGroupTextInput
              className={styles.priceForm}
              label={t("supervisor.sessions.creationModal.sessionPrice", {
                defaultValue: "Prix de la session",
              })}
              required
            >
              <>
                <AssistiveArea
                  mode="default"
                  text={t("supervisor.sessions.creationModal.sessionPrice.assistiveText", {
                    defaultValue:
                      "Si vous renseignez un prix de session, le prix prévisionnel par participant sera ignoré.",
                  })}
                />

                <DSRadioGroup name="sessionPrice">
                  <DSRadio
                    label={t("supervisor.sessions.creationModal.sessionPrice", {
                      defaultValue: "Prix de la session",
                    })}
                    checked={values.price.type === "total"}
                    onChange={() => {
                      setFieldValue("price.type", "total");
                      setFieldValue("priceWithTax", null);
                      setFieldValue("price.value", null);
                    }}
                  />
                  {values.price.type === "total" && (
                    <PriceInputs
                      state={values}
                      setPriceHT={(value) => setFieldValue("price.value", value)}
                      setPriceTTC={(value) => setFieldValue("priceWithTax", value)}
                    />
                  )}
                  <DSRadio
                    label={t("supervisor.sessions.creationModal.sessionPrice.provisional", {
                      defaultValue: "Prix prévisionnel par participant",
                    })}
                    checked={values.price.type === "perTrainee"}
                    onChange={() => {
                      setFieldValue("price.type", "perTrainee");
                      setFieldValue("price.value", values.intra?.price);
                      setFieldValue("priceWithTax", values.intra?.priceWithTax);
                    }}
                  />
                  {values.price.type === "perTrainee" && (
                    <PriceInputs
                      state={values}
                      setPriceHT={(value) => setFieldValue("price.value", value)}
                      setPriceTTC={(value) => setFieldValue("priceWithTax", value)}
                    />
                  )}
                </DSRadioGroup>
              </>
            </DSFormGroupTextInput>
            <FieldError name={`price`} />
            <FieldError name={`priceWithTax`} />

            <Flex className={styles.attendeeForm} row wrap>
              <DSFormGroupTextInput
                label={t("supervisor.sessions.creationModal.minimum", {
                  defaultValue: "Nombre de participants minimum",
                })}
                className={styles.input}
                assistiveArea={
                  values.min > values.max
                    ? ((
                        <AssistiveErrorText
                          errorText={t("supervisor.sessions.creationModal.minimum.inferior", {
                            defaultValue:
                              "La valeur minimale doit être inférieure à la valeur maximale",
                          })}
                        />
                      ) as any)
                    : values.min < 1
                      ? ((
                          <AssistiveErrorText
                            errorText={t("supervisor.sessions.creationModal.minimum.superior", {
                              defaultValue: "La valeur minimale doit être supérieure à 0",
                            })}
                          />
                        ) as any)
                      : undefined
                }
              >
                <NumberInput
                  min={0}
                  step={1}
                  max={values.max}
                  value={values.min}
                  className={styles.participantsNumber}
                  errored={values.min > values.max || values.min < 1}
                  onChange={(value) => setFieldValue("min", value)}
                  placeholder={t("supervisor.sessions.creationModal.minimum.placeholder", {
                    defaultValue: "Ex : 1",
                  })}
                />
              </DSFormGroupTextInput>

              <DSFormGroupTextInput
                label={t("supervisor.sessions.creationModal.minimum", {
                  defaultValue: "Nombre de participants maximum",
                })}
                className={styles.input}
                assistiveArea={
                  values.max && values.min > values.max
                    ? ((
                        <AssistiveErrorText
                          errorText={t("supervisor.sessions.creationModal.minimum.inferior", {
                            defaultValue:
                              "La valeur minimale doit être inférieure à la valeur maximale",
                          })}
                        />
                      ) as any)
                    : values.max === 0
                      ? ((
                          <AssistiveErrorText
                            errorText={t("supervisor.sessions.creationModal.maximum.superior", {
                              defaultValue: "La valeur maximal doit être supérieure à 0",
                            })}
                          />
                        ) as any)
                      : undefined
                }
              >
                <NumberInput
                  min={values.min ?? 0}
                  step={1}
                  value={values.max}
                  errored={values.min > values.max || values.max < 1}
                  onChange={(value) => setFieldValue("max", value)}
                  className={styles.participantsNumber}
                  placeholder={t("supervisor.sessions.creationModal.maximum.placeholder", {
                    defaultValue: "Ex : 10",
                  })}
                />
              </DSFormGroupTextInput>
            </Flex>
          </section>
          <section className={styles.perimeterSection}>
            <Text espaceFont="body2Bold" color="plainText-onLight-default">
              {t("supervisor.sessions.creationModal.parameterSession", {
                defaultValue: "Paramètres de la session",
              })}
            </Text>

            <DSFormGroupTextInput
              label={t("supervisor.sessions.creationModal.perimeter.label", {
                defaultValue: "Périmètres",
              })}
              className={styles.perimeterForm}
              required
            >
              <DSSelect
                multi
                value={values.areas}
                canSelectAll
                allValuesLabel={t("supervisor.sessions.creationModal.perimeter.optionAll", {
                  defaultValue: "Tous sélectionnés",
                })}
                options={(allAreas ?? []).map((area) => ({
                  value: area.uuid,
                  label: area.name,
                }))}
                onChange={(option) => setFieldValue("areas", option ?? [])}
              />
            </DSFormGroupTextInput>

            <DSFormGroupTextInput
              label={t("supervisor.sessions.creationModal.internalTrainer.label", {
                defaultValue: "Formateur(s) interne(s)",
              })}
              className={styles.trainerForm}
            >
              <DropDownCheckbox
                aria-label="trainers"
                labels={{
                  itemSingular: t("supervisor.sessions.creationModal.internalTrainer.optionLabel", {
                    defaultValue: "formateur",
                    count: 1,
                  }),
                  itemPlural: t("supervisor.sessions.creationModal.internalTrainer.optionLabel", {
                    defaultValue: "formateurs",
                    count: 2,
                  }),
                  noItemSelected: t(
                    "supervisor.sessions.creationModal.internalTrainer.noItemSelected",
                    {
                      defaultValue: "Choisissez le(s) formateur(s) parmi vos collaborateurs",
                    }
                  ),
                  searchPlaceholder: t(
                    "supervisor.sessions.creationModal.internalTrainer.searchPlaceholder",
                    {
                      defaultValue: "Rechercher un collaborateur...",
                    }
                  ),
                  noItemFound: t("supervisor.sessions.creationModal.internalTrainer.noItemFound", {
                    defaultValue: "Aucun collaborateur trouvé, veuillez modifier votre recherche.",
                  }),
                }}
                items={values.trainers?.map((user) => ({
                  label: user.fullName,
                  value: user.uuid,
                  isSelected: values.trainers?.some(({ uuid }) => uuid === user.uuid),
                }))}
                onChange={(selection) => {
                  setFieldValue(
                    "trainers",
                    selection
                      .filter((user) => user.isSelected)
                      .map((user) => ({ uuid: user.value, fullName: user.label }))
                  );
                }}
                canSelectAll={false}
                searchAutoFocus
                onSearch={async (query) => {
                  const users = await searchUsersByQuery(query, USER_FILE_VALIDATION_TYPE.NONE);
                  return users.map((user) => ({
                    label: user.fullName,
                    value: user.uuid,
                    isSelected: !!values.trainers?.some(({ uuid }) => uuid === user.uuid),
                  }));
                }}
              />
            </DSFormGroupTextInput>

            {values.intra?.reference && (
              <ReferenceInput
                onChange={(reference) => setFieldValue("reference", reference)}
                programRef={values.intra?.reference}
                sessionDate={!isNaN(firstDate?.getTime()) ? firstDate?.toISOString() : undefined}
                reference={values.reference}
              />
            )}

            {type === "create" && hasAccessToReviews && (
              <DSFormGroupTextInput
                className={styles.reviewsForm}
                label={t("supervisor.sessions.creationModal.reviewSession", {
                  defaultValue: "Paramètre des évaluations dans la session",
                })}
              >
                <>
                  <Checkbox
                    className={styles.toggle}
                    label={t("supervisor.sessions.creationModal.generateReviews", {
                      defaultValue: "Générer les évaluations",
                    })}
                    value="toggle-reviews"
                    checked={values.evalGroupsGenerated}
                    onClick={() =>
                      setFieldValue("evalGroupsGenerated", !values.evalGroupsGenerated)
                    }
                  />
                  <AssistiveArea
                    mode="default"
                    text={t("supervisor.sessions.creationModal.reviews.assistiveText", {
                      defaultValue:
                        "La génération des évaluations entraînera un envoi d’une invitation à évaluer la formation aux collaborateurs",
                    })}
                  />
                </>
              </DSFormGroupTextInput>
            )}
            {type === "update" && hasAccessToReviews && (
              <>
                <DSFormGroupTextInput
                  className={styles.reviewsForm}
                  label={t("supervisor.sessions.updateModal.reviewSession", {
                    defaultValue: "Paramètre des évaluations dans la session",
                  })}
                >
                  <DSTooltip
                    label={t("supervisor.sessions.updateModal.reviews.tooltip", {
                      defaultValue: "Les évaluations sont déjà générées",
                    })}
                    disabled={!disableReviewsCheckbox(session.reviewsState)}
                  >
                    <DSCheckbox
                      key="toggle-reviews"
                      className={styles.toggle}
                      label={t("supervisor.sessions.updateModal.generateReviews", {
                        defaultValue: "Générer les évaluations",
                      })}
                      value="all"
                      checked={values.evalGroupsGenerated}
                      helpText={t("supervisor.sessions.updateModal.reviews.assistiveText", {
                        defaultValue:
                          "La génération des évaluations entraînera un envoi d’une invitation à évaluer la formation aux collaborateurs",
                      })}
                      disabled={disableReviewsCheckbox(session.reviewsState)}
                      onChange={() =>
                        !disableReviewsCheckbox(session.reviewsState) &&
                        setFieldValue("evalGroupsGenerated", !values.evalGroupsGenerated)
                      }
                    />
                  </DSTooltip>
                </DSFormGroupTextInput>
                {reviewsGenerationAlert && (
                  <div className={styles.reviewsAlert} ref={reviewsAlertRef}>
                    <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
                      {reviewsGenerationAlert.value}
                    </DSAlert>
                  </div>
                )}
              </>
            )}
          </section>
        </div>
      </DSModal.Content>
      {type === "create" ? (
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            label={t("common.cancel", { defaultValue: "Annuler" })}
            onClick={onClose}
          />
          <DSModal.Footer.PrimaryButton
            label={t("supervisor.sessions.creationModal.submit", {
              defaultValue: "Créer la session",
            })}
            disabled={!isValid}
            tooltip={
              !isValid
                ? t("supervisor.sessions.creationModal.submitError", {
                    defaultValue:
                      "Vous devez remplir tous les champs obligatoires (indiqués par une astérisque rouge)",
                  })
                : ""
            }
            loading={isLoading}
            onClick={() => handleSubmit()}
          />
        </DSModal.Footer>
      ) : (
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            label={t("common.cancel", { defaultValue: "Annuler" })}
            onClick={onClose}
          />
          {showAlert.value ? (
            <>
              <DSModal.Footer.PrimaryButton
                label={t("supervisor.sessions.updateModal.submit", {
                  defaultValue: "Enregister",
                })}
                emphasis="Mid"
                disabled={!isValid}
                tooltip={
                  !isValid
                    ? t("supervisor.sessions.updateModal.submitError", {
                        defaultValue:
                          "Tous les champs obligatoires (signalés par une astérisque rouge) doivent être renseignés",
                      })
                    : ""
                }
                loading={isLoading}
                onClick={() => handleSubmit()}
              />
              <DSModal.Footer.PrimaryButton
                label={t("supervisor.sessions.updateModal.submitAndSummon", {
                  defaultValue: "Enregister et reconvoquer",
                })}
                disabled={!isValid}
                tooltip={
                  !isValid
                    ? t("supervisor.sessions.updateModal.submitError", {
                        defaultValue:
                          "Tous les champs obligatoires (signalés par une astérisque rouge) doivent être renseignés",
                      })
                    : ""
                }
                loading={isLoading}
                onClick={async () => {
                  await setFieldValue("summon", true);
                  handleSubmit();
                }}
              />
            </>
          ) : (
            <DSModal.Footer.PrimaryButton
              label={t("supervisor.sessions.updateModal.submit", {
                defaultValue: "Modifier la session",
              })}
              disabled={!isValid}
              tooltip={
                !isValid
                  ? t("supervisor.sessions.updateModal.submitError", {
                      defaultValue:
                        "Tous les champs obligatoires (signalés par une astérisque rouge) doivent être renseignés",
                    })
                  : ""
              }
              loading={isLoading}
              onClick={async () => {
                await setFieldValue("summon", true);
                handleSubmit();
              }}
            />
          )}
        </DSModal.Footer>
      )}
    </DSModal>
  );
};
