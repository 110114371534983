import { useMemo, useRef } from "react";
import { HighchartsReactRefObject } from "highcharts-react-official";
import useTranslation from "hooks/useTranslation";

import { Flex } from "@skillup/ui";

import { CardMenu } from "../CardMenu";
import { PieChart } from "../Charts/Pie";
import { HistogramChart } from "../Charts/Histogram";

import CardsPlaceholder from "./Placeholder";
import { CardProps } from "./Card";
import styles from "./DetailedIndicator.module.scss";

export const DetailedIndicator = ({ data: rawData, loading, card }: CardProps) => {
  const { t } = useTranslation();
  const graphRef = useRef<HighchartsReactRefObject>(null);

  const hasData = rawData && rawData.data.length > 0;
  const isPieChart =
    card.charts.includes("pie") &&
    card.question_meta.axes[1]?.fingerprint?.distinct_values &&
    card.question_meta.axes[1].fingerprint.distinct_values.length < 4;
  const isPaginated = !isPieChart && rawData?.data.length > 8;

  const data: typeof rawData = useMemo(() => {
    if (card.question_meta.axes[1]?.id === "training_plan_plan_completion_phase") {
      if (rawData && rawData.data) {
        return {
          ...rawData,
          data: rawData.data.map((row) => {
            return [row[0], t(row[1])];
          }),
        };
      }
    }
    return rawData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  return (
    <Flex column className={styles.detailedIndicator}>
      <Flex row className={styles.header}>
        <div className={styles.title}>{card.short_name}</div>
        {hasData && <CardMenu data={data} card={card} graphRef={graphRef} />}
      </Flex>
      <Flex className={styles.cardContainer}>
        {!hasData && (
          <CardsPlaceholder
            label={t("training.dashboard.indicator.placeholder.noData", {
              defaultValue: "Aucune donnée n’est disponible pour cet indicateur",
            })}
          />
        )}
        {hasData && isPieChart && (
          <PieChart ref={graphRef} loading={loading} data={data} card={card} />
        )}
        {hasData && !isPieChart && (
          <HistogramChart
            ref={graphRef}
            loading={loading}
            data={data}
            card={card}
            isPaginated={isPaginated}
          />
        )}
      </Flex>
    </Flex>
  );
};
