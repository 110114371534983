import { useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { IScheduleColumn, IScheduleRow, IScheduleDataCall } from "@skillup/types";
import { useMediaQueries, Select, Flex, DSButton } from "@skillup/ui";

import { IWithRouter } from "utils/types";
import User from "utils/User";

import type { FiltersWithSort, Row } from "components/DataTable/types";

import { PlanType } from "./utils/exportCSV";

import styles from "./SupervisorTrainingScheduleStyles.module.scss";

import type { GetScheduleDataRoute } from "types/api";
import { isNil } from "lodash";
import DSLayout from "components/DSLayout";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";
import { useSchedule } from "hooks";

import { ReturnedColumn } from "./utils/loadPartialData";
import SupervisorTrainingHeader from "./components/Header";
import SupervisorTrainingList from "./components/List";
import SupervisorTrainingDashboard from "./SupervisorDashboard";
import { buildRequest } from "utils/buildRequest";
import { TrainingDomainRoutes } from "@skillup/training-bridge";

interface IParams {
  readonly tab?: GetScheduleDataRoute["query"]["tab"] | "dashboard";
  readonly type?: "plan-de-formation" | "recueil-des-besoins";
  readonly uuid?: string;
  readonly selectedUUID?: string;
}

export interface IProps extends IWithRouter<IParams> {
  readonly areas: any;
}

export interface IState {
  activeTab: IParams["tab"] | "dashboard";
  columns?: Array<IScheduleColumn>;
  isLoading: boolean;
  rows?: Array<Row>;
  schedule?: IScheduleDataCall;
  selectedRows: Array<IScheduleRow["uuid"]>;
  reloadingRows: Array<string>;
  title: string;
  type: PlanType;
  filters?: FiltersWithSort["rawFilters"];
  sort?: FiltersWithSort["rawSort"];
  returnedColumns?: ReturnedColumn[];
}

const types = {
  "plan-de-formation": "plan",
  "recueil-des-besoins": "collection",
} as const;

// see here https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/

const SupervisorTrainingSchedule = (props: IProps) => {
  const { match, history } = props;
  const { params } = match;
  const planType = types[params.type];
  const { activeSchedule, unarchivedSchedules } = useSchedule();

  const scheduleView = useMemo(() => {
    const retrievedSchedule = sessionStorage.getItem("selectedScheduleUuid");
    if (!isNil(retrievedSchedule)) {
      const savedSchedule = unarchivedSchedules.find((s) => s.uuid === retrievedSchedule);
      if (!isNil(savedSchedule?.uuid)) return savedSchedule;
    }
    return (
      (params.uuid && unarchivedSchedules.find((e) => e.uuid === params.uuid)) || activeSchedule
    );
  }, [activeSchedule, unarchivedSchedules, params.uuid]);

  const containerRef = useRef(null);

  const { isMobile } = useMediaQueries();

  const [layouts, setLayouts] = useState<{
    primaryButton: JSX.Element;
    dropdownContent: JSX.Element[];
  }>({
    primaryButton: null,
    dropdownContent: [],
  });

  const [settingsVersion, setSettingsVersion] = useState<number>(0);

  return (
    <>
      {isMobile ? (
        <NotAvailableInMobileView />
      ) : (
        <DSLayout
          title={planType === "plan" ? "Plan de formation" : "Recueil des besoins"}
          layouts={[layouts]}
          leftItem={
            scheduleView ? (
              <Flex style={{ gap: "1rem" }}>
                <Select
                  isSearchable={false}
                  value={scheduleView.uuid}
                  options={unarchivedSchedules.map((e) => ({
                    label: e.active ? `${e.name} (plan actif)` : e.name,
                    value: e.uuid,
                  }))}
                  onChange={(value) => {
                    sessionStorage.setItem("selectedScheduleUuid", value);
                    User.setCurrentSchedule(value);
                    history.push(`/responsable${`/${value}`}/${params.type}/${params.tab}`);
                  }}
                  darkMode
                  className={styles.selectPlan}
                />
                {User.isSkillupDeveloper() && (
                  <DSButton
                    buttonSize="S"
                    emphasis="Low"
                    onClick={() => {
                      buildRequest<TrainingDomainRoutes.SCHEDULE.RefreshScheduleRows>({
                        method: "GET",
                        path: "/schedule/{scheduleUuid}/refresh",
                        target: "TRAINING_API",
                        params: {
                          scheduleUuid: scheduleView.uuid,
                        },
                      })();
                    }}
                    style={{ color: "white" }}
                    label="Refresh"
                  />
                )}
              </Flex>
            ) : undefined
          }
        >
          <>
            <main ref={containerRef} className={styles.AccountTrainingScheduleView}>
              <SupervisorTrainingHeader onChangeColumns={() => setSettingsVersion((v) => v + 1)} />

              {params.tab === "dashboard" ? (
                <SupervisorTrainingDashboard type={planType} scheduleUuid={params.uuid} />
              ) : (
                <SupervisorTrainingList
                  key={[params.tab, params.uuid, params.type].join(":")}
                  settingsVersion={settingsVersion}
                  containerRef={containerRef}
                  onSetLayouts={setLayouts}
                />
              )}
            </main>
          </>
        </DSLayout>
      )}
    </>
  );
};

export default withRouter(SupervisorTrainingSchedule);
