import useTranslation from "hooks/useTranslation";
import styles from "./NotAvailableInMobileView.module.scss";
import mobileViewImg from "assets/mobile_view.svg";

const NotAvailableInMobileView = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.NotAvailableInMobileView}>
      <img src={mobileViewImg} alt="mobile version is not supported" />
      <span>
        {t("common.mobile_view_not_supported", {
          defaultValue:
            "Cette vue n'est pas optimisée pour une utilisation sur mobile, utilisez plutôt un ordinateur.",
        })}
      </span>
    </div>
  );
};

export default NotAvailableInMobileView;
