import { useToggle } from "react-use";
import { useMemo, Suspense, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useParams, useHistory, generatePath } from "react-router-dom";

import { Formik, useFormikContext } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { useMediaQueries } from "@skillup/ui";
import { Flex, Loader, Footer } from "@skillup/design-system";

import DSLayout from "components/DSLayout";
import { ProcessedSkill, UpdateSkillFormProps } from "types/skills";
import useTranslation, { TranslationType } from "hooks/useTranslation";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";

import { SkillForm } from "../../components/Form";
import { UpdateSkillSchema } from "../../components/Form/Schemas";
import { SkillsProvider, useSkillsContext } from "../../SkillsContext";

interface ButtonProps {
  loading: boolean;
  t: TranslationType;
}

const HandleSubmitButton = ({ loading, t }: ButtonProps) => {
  const { dirty, isValid, submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const isDisabled = !isValid || !dirty;

  const tooltipStr = t("portal.config.skills.tooltip", {
    defaultValue:
      "Vous devez remplir tous les champs obligatoires (indiqués par une astérisque rouge)",
  });

  return (
    <DSNewHeaderButton
      type="submit"
      loading={loading}
      disabled={isDisabled}
      tooltipDarkMode={false}
      tooltip={isDisabled ? tooltipStr : undefined}
      onClick={handleSubmit}
      label={t("portal.config.skills.button.updateSkill.label", {
        defaultValue: "Enregistrer les modifications",
      })}
    />
  );
};

const UpdateSkill = () => {
  return (
    <SkillsProvider>
      <Flex height="100%" flexDirection="column">
        <Suspense fallback={<Loader fillSpace />}>
          <Layout />
        </Suspense>
      </Flex>
    </SkillsProvider>
  );
};

const Layout = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const history = useHistory();
  const [loading, toggleLoading] = useToggle(false);
  const { isMobile } = useMediaQueries();

  const { skillUuid } = useParams<{ skillUuid: string }>();

  const { getCategories, getSkillEvaluationsScales, getSkills, updateSkill } = useSkillsContext();
  const skillEvaluationsScales = getSkillEvaluationsScales();
  const categories = getCategories();

  const { data, error, status } = getSkills({ uuids: [skillUuid] });
  const isLoading = status === "loading";
  const skill: undefined | ProcessedSkill = data?.[0];

  const handleSubmit = async (values: UpdateSkillFormProps) => {
    toggleLoading(true);

    const customDescriptionLevels = values.evaluationScale.levels.some(
      (e) => e.customDescription && e.customDescription !== e.description
    );

    const payload = {
      uuid: values.uuid,
      description: values.description,
      evaluationScale: {
        uuid: values.evaluationScale.uuid,
        levels: values.evaluationScale.levels.map((level) => ({
          uuid: level.uuid,
          customDescription: level.customDescription,
          description: level.description,
          level: level.level,
          name: level.name,
        })),
        name: values.evaluationScale.name,
      },
      name: values.skillName,
      version: values.version + 1,
      ...(customDescriptionLevels
        ? {
            customEvaluationScale: {
              uuid: values.evaluationScale.uuid,
              levels: values.evaluationScale.levels.map((level) => ({
                uuid: level.uuid,
                description: level.customDescription,
                level: level.level,
                name: level.name,
              })),
            },
          }
        : {
            evaluationScaleUuid: values.evaluationScale.uuid,
          }),
      categoryId: values.categoryUuid,
      fields: values.fields.map((field) => ({
        uuid: field.uuid,
        index: field.index,
        value: field.value ?? "",
        version: field.version,
      })),
    };

    updateSkill(payload);
    toggleLoading(false);
  };

  if (error) history.goBack();

  const initialValues = useMemo(() => {
    return {
      uuid: skill?.uuid,
      categoryUuid: skill?.category?.uuid,
      description: skill?.description,
      evaluationScale: skill?.evaluationScale,
      fields: skill?.fields,
      skillName: skill?.name,
      version: skill?.version,
    };
  }, [skill]);

  useEffect(() => {
    if (skill?.isArchived) {
      addToast(
        t("skills.label.skillIsArchived.error", {
          defaultValue: "Cette compétence est archivée, vous ne pouvez plus y accéder.",
        }),
        {
          appearance: "error",
        }
      );
      history.push("/responsable/referentiels/competences");
    }
  }, [skill, addToast, history, t]);

  if (isLoading || skillEvaluationsScales.isLoading || categories.isLoading)
    return (
      <DSLayout
        title={t("skills.title.skill", { defaultValue: "Compétence" })}
        parent={{
          title: t("skills.title.skills", {
            defaultValue: "Compétences",
          }),
          titleUrl: generatePath("/responsable/referentiels/competences"),
        }}
      >
        <Loader />
      </DSLayout>
    );

  if (isMobile) return <NotAvailableInMobileView />;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={toFormikValidationSchema(UpdateSkillSchema)}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formikProps) => {
        return (
          <DSLayout
            isRelative={true}
            title={skill?.name}
            parent={{
              title: t("skills.title.skills", {
                defaultValue: "Compétences",
              }),
              titleUrl: generatePath("/responsable/referentiels/competences"),
            }}
          >
            <SkillForm
              formikProps={formikProps}
              categories={categories.data}
              evaluationScales={skillEvaluationsScales.data}
            />
            <Footer
              containerWidth={"650px"}
              firstColumnChildren={<HandleSubmitButton t={t} loading={loading} />}
            />
          </DSLayout>
        );
      }}
    </Formik>
  );
};

export default UpdateSkill;
