export function updateReviewCriteria(id: string) {
  return async ({
    description,
    options,
    title,
  }: {
    description: string;
    options: {
      description: string;
      value: number;
      label: string;
    }[];
    title: string;
    forceNewVersion: boolean;
  }) => {
    console.log(id, { description, options, title });
  };
}
