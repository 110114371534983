import { Flex, Text } from "@skillup/design-system";

type EmptyStateProps = {
  label: string;
};

export const EmptyState = ({ label }: EmptyStateProps) => {
  return (
    <Flex alignItems="center" flexDirection={"column"}>
      <svg width={"207.34px"}>
        <g mask="url(#mask0_2297_8255)">
          <g filter="url(#filter0_d_2297_8255)">
            <rect rx="8" height="68" y="48.1627" x="0.36586" fill="white" width="102.5" />
            <path
              fill="#E5E8EB"
              d="M24.3659 58.8293C17.0059 58.8293 11.0325 64.8027 11.0325 72.1627C11.0325 79.5227 17.0059 85.496 24.3659 85.496C31.7259 85.496 37.6992 79.5227 37.6992 72.1627C37.6992 64.8027 31.7259 58.8293 24.3659 58.8293ZM24.3659 62.8293C26.5792 62.8293 28.3659 64.616 28.3659 66.8293C28.3659 69.0427 26.5792 70.8293 24.3659 70.8293C22.1525 70.8293 20.3659 69.0427 20.3659 66.8293C20.3659 64.616 22.1525 62.8293 24.3659 62.8293ZM24.3659 81.7627C21.0325 81.7627 18.0859 80.056 16.3659 77.4693C16.4059 74.816 21.6992 73.3627 24.3659 73.3627C27.0192 73.3627 32.3259 74.816 32.3659 77.4693C30.6459 80.056 27.6992 81.7627 24.3659 81.7627Z"
            />
            <rect rx="2" height="4" width="48" y="62.1627" x="46.8659" fill="#C9CED4" />
            <rect rx="2" height="4" width="48" y="70.1627" x="46.8659" fill="#C9CED4" />
            <rect rx="2" height="4" width="48" y="78.1627" x="46.8659" fill="#C9CED4" />
            <path
              fill="#7EC2E2"
              d="M86.8659 93.4961C83.1859 93.4961 80.1992 96.4828 80.1992 100.163C80.1992 103.843 83.1859 106.829 86.8659 106.829C90.5459 106.829 93.5325 103.843 93.5325 100.163C93.5325 96.4828 90.5459 93.4961 86.8659 93.4961ZM85.5325 103.496L82.1992 100.163L83.1392 99.2228L85.5325 101.609L90.5925 96.5494L91.5325 97.4961L85.5325 103.496Z"
            />
          </g>
          <g filter="url(#filter1_d_2297_8255)">
            <g clipPath="url(#clip0_2297_8255)">
              <rect
                rx="8"
                height="68"
                y="18.7588"
                x="67.5976"
                fill="white"
                width="102.5"
                transform="rotate(-5 67.5976 18.7588)"
              />
              <path
                fill="#E5E8EB"
                d="M92.4359 27.2932C85.1039 27.9347 79.6739 34.4059 80.3154 41.7379C80.9569 49.0699 87.4281 54.4999 94.7601 53.8584C102.092 53.217 107.522 46.7458 106.881 39.4138C106.239 32.0818 99.7679 26.6518 92.4359 27.2932ZM92.7845 31.278C94.9895 31.0851 96.925 32.7093 97.1179 34.9142C97.3108 37.1191 95.6867 39.0547 93.4818 39.2476C91.2769 39.4405 89.3413 37.8163 89.1484 35.6114C88.9555 33.4065 90.5796 31.4709 92.7845 31.278ZM94.4347 50.1393C91.114 50.4298 88.0298 48.9865 86.0909 46.5596C85.8995 43.9128 91.0461 42.0037 93.7026 41.7713C96.3458 41.54 101.759 42.5253 102.03 45.1651C100.542 47.8918 97.7553 49.8488 94.4347 50.1393Z"
              />
              <rect
                rx="2"
                height="4"
                width="48"
                y="28.6528"
                x="115.141"
                fill="#C9CED4"
                transform="rotate(-5 115.141 28.6528)"
              />
              <rect
                rx="2"
                height="4"
                width="48"
                y="36.6223"
                x="115.838"
                fill="#C9CED4"
                transform="rotate(-5 115.838 36.6223)"
              />
              <rect
                rx="2"
                height="4"
                width="48"
                y="44.5919"
                x="116.535"
                fill="#C9CED4"
                transform="rotate(-5 116.535 44.5919)"
              />
              <path
                fill="#7EC2E2"
                d="M157.72 56.3806C154.054 56.7014 151.339 59.937 151.659 63.603C151.98 67.269 155.216 69.984 158.882 69.6632C162.548 69.3425 165.263 66.1069 164.942 62.4409C164.621 58.7749 161.386 56.0599 157.72 56.3806ZM159.255 66.2845L157.927 66.4007L157.811 65.0724L159.139 64.9562L159.255 66.2845ZM159.023 63.628L157.695 63.7442L157.346 59.7594L158.674 59.6432L159.023 63.628Z"
              />
            </g>
          </g>
          <g filter="url(#filter2_d_2297_8255)">
            <g clipPath="url(#clip1_2297_8255)">
              <rect
                rx="8"
                height="68"
                y="63.8253"
                x="105.598"
                fill="white"
                width="102.5"
                transform="rotate(5 105.598 63.8253)"
              />
              <path
                fill="#E5E8EB"
                d="M128.577 76.5432C121.245 75.9017 114.773 81.3317 114.132 88.6637C113.49 95.9957 118.92 102.467 126.252 103.108C133.584 103.75 140.056 98.3198 140.697 90.9879C141.339 83.6559 135.909 77.1846 128.577 76.5432ZM128.228 80.528C130.433 80.7209 132.057 82.6565 131.864 84.8614C131.671 87.0663 129.736 88.6904 127.531 88.4975C125.326 88.3046 123.702 86.369 123.895 84.1641C124.087 81.9592 126.023 80.3351 128.228 80.528ZM126.578 99.3892C123.257 99.0987 120.47 97.1417 118.982 94.415C119.254 91.7753 124.653 90.7888 127.31 91.0212C129.953 91.2525 135.113 93.1628 134.922 95.8095C132.983 98.2364 129.898 99.6798 126.578 99.3892Z"
              />
              <rect
                rx="2"
                x="150.7"
                height="4"
                width="48"
                y="81.8247"
                fill="#C9CED4"
                transform="rotate(5 150.7 81.8247)"
              />
              <rect
                rx="2"
                height="4"
                width="48"
                y="89.7943"
                x="150.003"
                fill="#C9CED4"
                transform="rotate(5 150.003 89.7943)"
              />
              <rect
                rx="2"
                height="4"
                width="48"
                y="97.7639"
                x="149.306"
                fill="#C9CED4"
                transform="rotate(5 149.306 97.7639)"
              />
              <path
                fill="#7EC2E2"
                d="M187.817 116.525C184.151 116.204 180.916 118.919 180.595 122.585C180.274 126.251 182.989 129.487 186.655 129.808C190.321 130.128 193.557 127.413 193.878 123.747C194.198 120.081 191.483 116.846 187.817 116.525ZM185.618 126.371L182.587 122.76L183.606 121.905L185.782 124.491L191.264 119.892L192.118 120.917L185.618 126.371Z"
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            height="84"
            y="42.1627"
            x="-7.63414"
            width="118.5"
            id="filter0_d_2297_8255"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              type="matrix"
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite operator="out" in2="hardAlpha" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2297_8255" />
            <feBlend
              mode="normal"
              result="shape"
              in="SourceGraphic"
              in2="effect1_dropShadow_2297_8255"
            />
          </filter>
          <filter
            y="3.82532"
            x="59.5976"
            width="124.037"
            height="92.6747"
            id="filter1_d_2297_8255"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              type="matrix"
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite operator="out" in2="hardAlpha" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2297_8255" />
            <feBlend
              mode="normal"
              result="shape"
              in="SourceGraphic"
              in2="effect1_dropShadow_2297_8255"
            />
          </filter>
          <filter
            x="91.671"
            y="57.8253"
            width="124.037"
            height="92.6747"
            id="filter2_d_2297_8255"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              type="matrix"
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite operator="out" in2="hardAlpha" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2297_8255" />
            <feBlend
              mode="normal"
              result="shape"
              in="SourceGraphic"
              in2="effect1_dropShadow_2297_8255"
            />
          </filter>
          <linearGradient
            y1="0"
            y2="138"
            x2="107"
            x1="107"
            id="paint0_linear_2297_8255"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D9D9" />
            <stop offset="1" stopOpacity="0" stopColor="#D9D9D9" />
          </linearGradient>
          <clipPath id="clip0_2297_8255">
            <rect
              rx="8"
              height="68"
              y="18.7588"
              x="67.5976"
              fill="white"
              width="102.5"
              transform="rotate(-5 67.5976 18.7588)"
            />
          </clipPath>
          <clipPath id="clip1_2297_8255">
            <rect
              rx="8"
              height="68"
              y="63.8253"
              x="105.598"
              fill="white"
              width="102.5"
              transform="rotate(5 105.598 63.8253)"
            />
          </clipPath>
        </defs>
      </svg>

      <Flex marginTop="m" marginBottom="m">
        <Text espaceFont="body2Regular">{label}</Text>
      </Flex>
    </Flex>
  );
};
