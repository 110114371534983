import { PeopleReviewRoutes } from "@skillup/espace-rh-bridge";

import { buildFileRequest } from "utils/buildRequest";

export async function uploadReviewedEmployees(file: File) {
  const result = await buildFileRequest<PeopleReviewRoutes.UploadReviewedEmployees>({
    file,
    method: "POST",
    path: "/upload-reviewed-employees",
  })();

  return result.map((item) => ({ ...item, id: item.collaborator?.uuid }));
}
