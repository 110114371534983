import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { NewCategoryParams, TargetCategoriesRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

async function getTargetCategories() {
  try {
    return buildRequest<TargetCategoriesRoutes.GetCategories>({
      method: "GET",
      path: "/targetCategories",
    })();
  } catch (err) {
    //add banner or error page
  }
  return [];
}

async function createCategory(params: NewCategoryParams) {
  try {
    const { uuid } = await buildRequest<TargetCategoriesRoutes.CreateCategory>({
      method: "POST",
      path: "/targetCategories/create",
      payload: params,
    })();
    if (uuid === undefined) throw new Error("Category creation failed");
    return uuid;
  } catch (err) {
    return undefined;
  }
}

export const useTargetCategories = () => {
  const queryClient = useQueryClient();
  const { data: targetCategories } = useQuery(["targetCategories"], getTargetCategories, {
    refetchOnWindowFocus: false,
  });

  const createNewCategory = useCallback(
    async (params) => {
      const result = await createCategory(params);
      await queryClient.invalidateQueries(["targetCategories"]);
      return result;
    },
    [queryClient]
  );

  return {
    targetCategories,
    createNewCategory,
  };
};
