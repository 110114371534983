import { useToggle } from "react-use";
import { useMemo, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useParams, useHistory, generatePath } from "react-router-dom";

import { Formik, useFormikContext } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { Flex, Footer } from "@skillup/design-system";
import { Loader, useMediaQueries } from "@skillup/ui";

import DSLayout from "components/DSLayout";
import { Job, FormJob, JobFields, JobSection } from "types/skills";
import useTranslation, { TranslationType } from "hooks/useTranslation";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";

import { JobSchema } from "../../JobFormSchema";
import { JobForm } from "../../components/Form/JobForm";
import { parseFormFields } from "../../components/Form/helper";
import { JobsProvider, useJobsContext } from "../../JobsContext";

interface ButtonProps {
  loading: boolean;
  t: TranslationType;
  state: UpdateJobState;
}

const HandleSubmitButton = ({ loading, state, t }: ButtonProps) => {
  const { dirty, isValid, submitForm } = useFormikContext();
  const { isButtonDisabled, tooltip } = useButtonStatus(state, { dirty, isValid });

  const handleSubmit = () => {
    submitForm();
  };

  const isDisabled = !isValid || !dirty;
  return (
    <DSNewHeaderButton
      type="submit"
      loading={loading}
      disabled={isDisabled}
      tooltipDarkMode={false}
      tooltip={isButtonDisabled ? tooltip : undefined}
      onClick={handleSubmit}
      label={t("jobs.update.save", {
        defaultValue: "Enregistrer les modifications",
      })}
    />
  );
};

const parseFormtemplateFields = (fields: undefined | JobSection[]): JobFields[] => {
  const formFieldValues = (fields || []).map((field) => ({
    uuid: field.uuid,
    index: field.index,
    name: field.name,
    value: field.content ?? "",
  }));

  return formFieldValues;
};

export type UpdateJobState =
  | { step: "manual-creation" }
  | { step: "ai-generation-finished" }
  | { step: "ai-job-generation-loading" }
  | { step: "ai-skills-generation-loading" };

const UpdateJob = () => {
  return (
    <JobsProvider>
      <Layout />
    </JobsProvider>
  );
};

const Layout = () => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, toggleLoading] = useToggle(false);
  const { isMobile } = useMediaQueries();
  const { getJob, updateJob } = useJobsContext();
  const { jobUuid } = useParams<{ jobUuid: string }>();
  const { data, error, status } = getJob(jobUuid);
  const job = useMemo(() => {
    if (!data) return undefined;
    return { ...data, updatedAt: new Date(data?.updatedAt) } as unknown as Job;
  }, [data]);
  const [state, setState] = useState<UpdateJobState>({
    step: "manual-creation",
  }) as [UpdateJobState, (newState: UpdateJobState) => void];

  const handleSubmit = async (values: FormJob) => {
    toggleLoading(true);

    const fieldValues = values.fields.map((field) => ({
      uuid: field.uuid,
      value: field.value ?? "",
    }));

    const skillValues = values.skills.map((s) => ({
      expectedLevelUuid: s.expectedLevelUuid ?? null,
      skillId: s.skillUuid,
    }));

    const sectionValues = values.sections.map((section) => ({
      uuid: section.uuid,
      content: section.value ?? "",
    }));

    const payload = {
      uuid: job.uuid,
      fields: fieldValues,
      name: values.jobName,
      sections: sectionValues,
      skills: skillValues,
      version: job.version + 1,
    };

    updateJob(payload);
  };

  const initialValues = useMemo(() => {
    return {
      uuid: job?.uuid,
      fields: parseFormFields(job?.fields),
      jobName: job?.name,
      sections: parseFormtemplateFields(job?.sections),
      skills: job?.skills.map(({ expectedLevelUuid, skill }) => ({
        expectedLevelUuid,
        skillUuid: skill.uuid,
      })),
    };
  }, [job]);

  useEffect(() => {
    if (job?.isArchived) {
      addToast(
        t("jobs.label.jobIsArchived.error", {
          defaultValue: "Cette fiche de poste est archivée, vous ne pouvez plus y accéder.",
        }),
        {
          appearance: "error",
        }
      );
      history.push("/responsable/referentiels/fiches-de-poste");
    }
  }, [job, addToast, history, t]);

  if (error) history.goBack();

  if (status !== "success") return <Loader />;

  return (
    <>
      {isMobile ? (
        <NotAvailableInMobileView />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(JobSchema)}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(formikProps) => {
            return (
              <DSLayout
                title={job.name}
                isRelative={true}
                parent={{
                  title: t("jobs.title.jobSheets", {
                    defaultValue: "Fiches de poste",
                  }),
                  titleUrl: generatePath("/responsable/referentiels/fiches-de-poste"),
                }}
              >
                <Flex flex="1" flexDirection="column">
                  <JobForm
                    state={state}
                    setState={setState}
                    formikProps={formikProps}
                    onTitleChange={() => toggleLoading(false)}
                  />
                </Flex>
                <Footer
                  containerWidth={"650px"}
                  firstColumnChildren={<HandleSubmitButton t={t} state={state} loading={loading} />}
                />
              </DSLayout>
            );
          }}
        </Formik>
      )}
    </>
  );
};

function useButtonStatus(state: UpdateJobState, formikProps: { dirty: boolean; isValid: boolean }) {
  const { t } = useTranslation();
  const isInputEmpty = !formikProps.isValid || !formikProps.dirty;

  const mandatoryFieldTranslation = t("portal.config.skills.tooltip-manual-creation", {
    defaultValue:
      "Vous devez remplir tous les champs obligatoires (indiqués par une astérisque rouge)",
  });

  const aiGenerationLoadingTranslation = t("portal.config.skills.tooltip-ai-loading", {
    defaultValue: "La génération de votre fiche de poste peut prendre une dizaine de secondes",
  });

  const cannotCreateJobTranslation = t("portal.config.skills.tooltip-set-title", {
    defaultValue: "Vous ne pouvez pas créer la fiche de poste à ce stade",
  });

  switch (state.step) {
    case "manual-creation":
      return {
        isButtonDisabled: isInputEmpty,
        tooltip: mandatoryFieldTranslation,
      };
    case "ai-job-generation-loading":
    case "ai-skills-generation-loading":
      return {
        isButtonDisabled: true,
        tooltip: aiGenerationLoadingTranslation,
      };
    case "ai-generation-finished":
      return {
        isButtonDisabled: false,
        tooltip: undefined,
      };
    default:
      return {
        isButtonDisabled: true,
        tooltip: isInputEmpty ? mandatoryFieldTranslation : cannotCreateJobTranslation,
      };
  }
}

export default UpdateJob;
