import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export async function getCampaignManagers(campaignID: string) {
  const response = await buildRequest<CampaignsRoutes.GetManagers>({
    method: "GET",
    params: { campaignID },
    path: "/campaigns/{campaignID}/managers",
    target: "PEOPLE_REVIEW",
  })();

  return response;
}
