export const SortTopToBottom = () => {
  return (
    <svg width="14" height="20" viewBox="0 0 14 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 0H0V8H2V0Z" />
      <path d="M6 2H4V8H6V2Z" />
      <path d="M10 4H8V8H10V4Z" />
      <path d="M14 6H12V8H14V6Z" />
      <path d="M10 14H0V16H10L7.5 18.5L9 20L14 15L9 10L7.5 11.5L10 14Z" />
    </svg>
  );
};
