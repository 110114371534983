import React, { useEffect } from "react";

import { useTheme } from "context/themes";
import Highcharts from "highcharts/highcharts";
import PieChart from "highcharts-react-official";
import { Themes, DSColor, EspaceFont, FontFamily, SkuiSpacing } from "commons";

import { colors } from "@skillup/ui";

interface PieProps {
  small?: boolean;
  chartSize?: string;
  valueTitle?: string;
  showLegend?: boolean;
  labelDistance?: number;
  legend: Array<{
    y: number;
    name: string;
    color?: string;
  }>;
}

interface PlotSeriesDataLabelsOptionsExtended extends Highcharts.PlotSeriesDataLabelsOptions {
  distance: number;
}

const Pie = ({
  chartSize = "100%",
  labelDistance = -50,
  legend,
  showLegend = true,
  valueTitle,
}: PieProps) => {
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-highcharts-tooltip {
        background: ${DSColor["surface-light"]};
        padding: ${SkuiSpacing.s};
        font-family: "Noto Sans Display", sans-serif;
        border-radius: 0.25rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        p {
          text-align: center;
        }

        .label {
          font-size: 0.75rem;
          color: ${DSColor["plainText-onLight-lighter"]};
          font-weight: bold;
        }
        .valueContainer {
          display: flex;
          align-items: center;

          .round {
            width: 8px;
            height: 8px;
            color: #6dc6d9;
            border-radius: 50%;
            margin-right: ${SkuiSpacing.xxs};
          }

          .value {
            color: ${DSColor["plainText-onLight-default"]};
            font-size: 0.75rem;
          }
        }
      }
    `;
    document.head.appendChild(style);
  }, []);

  const theme = useTheme();
  const isRHTheme = theme === Themes.ESPACE_RH;

  const fontFamily = isRHTheme ? FontFamily.rh : FontFamily.collab;
  const espaceFont = isRHTheme ? EspaceFont.rh : EspaceFont.collab;

  const options: Highcharts.Options = {
    chart: {
      type: "pie",
    },
    colors: legend.map((e) => e.color ?? colors[e.name]),
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    legend: {
      align: "center",
      itemStyle: {
        color: DSColor["plainText-onLight-default"],
        fontFamily,
        fontSize: espaceFont.captionRegular.fontSize,
        padding: "0 0.5rem",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            legendItemClick: () => false,
          },
        },
        showInLegend: showLegend,
        size: chartSize,
      },
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: false,
          },
          {
            distance: labelDistance,
            enabled: true,
            filter: {
              operator: ">",
              property: "percentage",
              value: 9,
            },
            format: "{point.percentage:.0f} %",
            style: {
              color: "white",
              fontSize: "1rem",
              opacity: 0.7,
              textOutline: "none",
            },
          } as PlotSeriesDataLabelsOptionsExtended,
        ],
      },
    },
    series: [
      {
        type: "pie",
        data: legend.map((e) => {
          const { color, name, y } = e;
          return {
            color,
            name,
            y: Math.round(y),
          };
        }),
        dataLabels: {
          style: {
            color: DSColor["plainText-onLight-default"],
            fontFamily,
            fontSize: espaceFont.captionRegular.fontSize,
          },
        },
        name: valueTitle,
      },
    ],
    title: {
      text: "",
    },
    tooltip: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      formatter: function () {
        return `
          <div class="custom-highcharts-tooltip">
            <p class="label">${this.key}</p>
            <div class="valueContainer">
              <div class="round" style="background-color: ${this.color}"></div>
              <p class="value">Poucentage d'évaluation : <b>${this.y} %</b></p>
            </div>
          </div>
        `;
      },
      useHTML: true,
    },
  };

  return <PieChart options={options} highcharts={Highcharts} />;
};

export default Pie;
