import styled from "styled-components";

import { SkuiSpacing, DSSkillsListDetail } from "@skillup/ui";

const espaceRhFont = `font-family: "Noto Sans Display", "Helvetica Neue", sans-serif`;

export const TextAreaWrapper = styled.div`
  overflow-wrap: break-word;

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  h1 {
    ${espaceRhFont};
    font-weight: 600;
    line-height: 1.25;
    font-size: ${SkuiSpacing.l};
  }

  h2 {
    ${espaceRhFont};
    font-weight: 600;
    line-height: 1.25;
    font-size: 1.75rem;
  }

  h3 {
    ${espaceRhFont};
    font-weight: 600;
    line-height: 1.25;
    font-size: ${SkuiSpacing.m};
  }

  h4 {
    ${espaceRhFont};
    font-weight: 600;
    line-height: 1.25;
    font-size: 1.25rem;
  }

  h5 {
    ${espaceRhFont};
    font-weight: 600;
    line-height: 1.6875rem;
    font-size: 1.125rem;
  }

  li {
    margin-left: ${SkuiSpacing.m};
  }
`;

export const SkillsListDetail = styled(DSSkillsListDetail)`
  ${espaceRhFont};
  border-radius: ${SkuiSpacing.xxs};
  margin-top: ${SkuiSpacing.s};
  padding-bottom: ${SkuiSpacing.l};
`;
