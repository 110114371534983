import { AssistiveArea, Flex } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { MonthlyChart } from "../Charts/Monthly";
import CardsPlaceholder, { PlaceholderIcon } from "./Placeholder";
import { CardProps } from "./Card";
import styles from "./Monthly.module.scss";
import { MonthlyData } from "../../types";

export const Monthly = ({ data, scheduleName, rowStatus }: CardProps) => {
  const { t } = useTranslation();

  const isPlanProcessing = rowStatus.includes("plan-processing"); // Inscrit
  const isPlanAttended = rowStatus.includes("plan-attended"); // Réalisé

  let alertText: string | undefined;

  if (!isPlanProcessing && !isPlanAttended) {
    alertText = t("dashboard-summary-monthly-plan-approved-attended-alert", {
      defaultValue:
        "Les filtres appliqués excluent les lignes au statut « Réalisé » et « Inscrit », il n’est donc pas possible d’afficher le graphique des dépenses mensuelles. ",
    });
  }

  if (!data) return null;

  if (data?.data?.length === 0) {
    return (
      <Flex center column className={styles.placeholder}>
        {alertText && <AssistiveArea text={alertText} mode="warning" className={styles.alert} />}
        <PlaceholderIcon />
        {!alertText && (
          <p className={styles.placeholderText}>
            {t("training.dashboard.monthly.placeholder.noData", {
              defaultValue: `Le suivi des dépenses mensuelles n'est pas disponible car aucune donnée n'est disponible pour le plan sélectionné.`,
            })}
          </p>
        )}
      </Flex>
    );
  }

  if (data?.data?.length > 24) {
    return (
      <CardsPlaceholder
        label={t("training.dashboard.monthly.placeholder", {
          defaultValue: `Le suivi des dépenses mensuelles n’est pas disponible car le plan sélectionné s’étale sur plus de 24 mois.`,
        })} 
      />
    );
  }

  return (
    <Flex className={styles.monthly}>
      <MonthlyChart
        data={data as unknown as MonthlyData}
        scheduleName={scheduleName}
        rowStatus={rowStatus}
      />
    </Flex>
  );
};
