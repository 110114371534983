import { RouterInputs, trpc, trpcClient } from "utils/trpc";
import { GeneratedSkillsDescription, Category } from "@skillup/gepp";

interface CreateSkillByAi {
  name: string;
  description: string;
  evaluationScaleUuid: string;
  fields: Array<{
    uuid: string;
    version: number;
    index: number;
    value: string;
  }>;
  generatedWithAi: boolean;
}

interface GenerateSkillsDescription {
  jobName: string;
  selectedSkills: Array<string>;
  evaluationScale: Record<string, string>;
  categories: Record<string, string>;
}

interface GenerateSkillsLevel {
  jobName: string;
  skills: Array<string>;
  evaluationScale: Record<string, string>;
}

type FieldType = Exclude<RouterInputs["fields"]["getAllFields"], void>["entity"];

export const getAllFieldsForEntity = (entity: FieldType) => {
  return trpc.fields.getAllFields.useQuery({ entity });
};

export const getAllJobTemplates = () => {
  return trpc.jobTemplates.getAll.useQuery();
};

export const getDefaultEvaluationScale = () => {
  return trpc.skillEvaluationsScales.getDefaultEvaluationScale.useQuery();
};

export const getSkillsCategories = () => {
  return trpc.categories.getAllCategories.useQuery() as { data: Category[] };
};

export const getAllSkills = () => {
  return trpc.skills.getAllSkills.useQuery();
};

export const getSkills = (uuids: string[]) => {
  return trpcClient.skills.getSkills.query({ uuids });
};

export const generateJobDescription = (name: string, selectedSkills: string[]) => {
  return trpcClient.jobWithAi.generateJobDescription.query({ name, selectedSkills });
};

export const generateSkillsDescription = (payload: GenerateSkillsDescription) => {
  return trpcClient.jobWithAi.generateSkillsDescription.query(payload) as Promise<
    GeneratedSkillsDescription[]
  >;
};

export const generateSkillsLevel = (payload: GenerateSkillsLevel) => {
  return trpcClient.jobWithAi.generateSkillsLevel.query(payload);
};

export const createSkills = (skills: Array<CreateSkillByAi>) => {
  return trpcClient.skills.createSkills.mutate(skills);
};
