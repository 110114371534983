import { ReactElement } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";

import { DSAvatarGroup } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { ParseDate, getInitials } from "@skillup/shared-utils";

import { JobFields } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import { JobRow } from "./parseJobIntoRow";
import { EllipsisText } from "../Jobs.styled";
import { DatatableJobFields } from "./getFields";
import ActionsCell from "../components/ActionsCell";

const renderFlexCell = (value: string | number | ReactElement) => {
  return (
    <Flex width="100%" alignItems="center">
      {typeof value === "string" ? <EllipsisText>{value}</EllipsisText> : value}
    </Flex>
  );
};

const generateColumns = ({
  actions,
  customFields,
  fields,
  t,
}: {
  t: TranslationType;
  customFields: JobFields[];
  fields: typeof DatatableJobFields;
  actions: { archiveRow: (row: JobRow) => void; setRowPrivacy: (row: JobRow) => void };
}): GridColDef[] => {
  const jobFields = [fields.name.key, fields.skillsCount.key];

  const columns: GridColDef[] = jobFields.map((key) => {
    const fieldData = Object.keys(jobFields).includes(key) ? jobFields[key] : fields[key];
    return {
      field: key,
      filterable: key === "name" ? false : true,
      headerName: t(fieldData.traductionKey, {
        defaultValue: fieldData.traductionDefaultValue,
      }),
      minWidth: 200,
      renderCell: ({ row }: GridValueGetterParams<JobRow>) => {
        return renderFlexCell(row[key]);
      },
      sortable: true,
    } satisfies GridColDef;
  });

  const lastEditColumn = {
    field: "lastEdit",
    filterable: false,
    headerName: t(fields.lastEdit.traductionKey, {
      defaultValue: fields.lastEdit.traductionDefaultValue,
    }),
    minWidth: 200,
    renderCell: ({ row }: GridValueGetterParams<JobRow>) => {
      return row.lastEdit;
    },
    sortable: true,
    sortComparator: (a: string, b: string) => {
      const dateA = ParseDate.FromStringFormat(a, "dd/MM/yyyy");
      const dateB = ParseDate.FromStringFormat(b, "dd/MM/yyyy");

      return dateA.valueOf() - dateB.valueOf();
    },
  };

  const avatarGroupColumn = {
    field: "employees",
    filterable: false,
    headerName: t(fields.employees.traductionKey, {
      defaultValue: fields.employees.traductionDefaultValue,
    }),
    minWidth: 200,
    renderCell: ({ row }: GridValueGetterParams<JobRow>) => {
      return (
        <DSAvatarGroup
          size="S"
          maxItems={5}
          totalItems={row.employees.length}
          users={row.employees.map((employee) => ({
            uuid: employee.uuid,
            fullName: employee.firstName + " " + employee.lastName,
            initials: getInitials({
              firstName: employee.firstName,
              lastName: employee.lastName,
            }),
          }))}
        />
      );
    },
    sortable: true,
    sortComparator: (v1: Array<JobRow>, v2: Array<JobRow>) => v1.length - v2.length,
  };

  const fieldsColumns = customFields
    .sort((a, b) => a.index - b.index)
    .map((field) => ({
      field: field.name,
      filterable: true,
      headerName: field.name,
      minWidth: 200,
      renderCell: ({ row }: GridValueGetterParams<JobRow>) => {
        return renderFlexCell(row[field.name]);
      },
      sortable: true,
    }));

  const jobPrivacyColumn = {
    type: "boolean",
    field: "isPrivate",
    filterable: false,
    headerName: t(fields.isPrivate.traductionKey, {
      defaultValue: fields.isPrivate.traductionDefaultValue,
    }),
    minWidth: 150,
    renderCell: ({ row }: GridValueGetterParams<JobRow>) => {
      return row.isPrivate ? (
        <VisibilityOff fontSize="small" color="disabled" />
      ) : (
        <Visibility fontSize="small" color="disabled" />
      );
    },
    sortable: true,
  };
  const nameColumn = columns.splice(jobFields.indexOf(fields.name.key), 1);
  columns.unshift(
    ...nameColumn,
    ...fieldsColumns,
    lastEditColumn,
    avatarGroupColumn,
    jobPrivacyColumn
  );

  columns.push({
    type: "actions",
    field: "actions",
    filterable: false,
    getActions: ({ row }) => [<ActionsCell t={t} row={row} actions={actions} />],
    sortable: false,
    width: 50,
  });

  return columns;
};

export default generateColumns;
