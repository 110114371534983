import { useMemo } from "react";
import { useSetState } from "react-use";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";

import { Stepper } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

import DSLayout from "components/DSLayout";
import { StepIds, AvailableStep, CampaignCreationState } from "types/peopleReview";

import { ActiveStep } from "./ActiveStep";
import { PeopleReviewRoutes } from "./../router";

import styles from "./steps.module.scss";
import peopleReviewStyles from "../../people-review.module.scss";

export function CampaignCreation() {
  const { t } = useTranslation();
  const steps = useMemo<AvailableStep[]>(
    () => [
      {
        id: "details",
        label: t("peopleReview.creationSteps.details", {
          defaultValue: "Intitulé, modèle et type de campagne",
        }),
      },
      {
        id: "collaborators",
        label: t("peopleReview.creationSteps.collaborators", {
          defaultValue: "Choix de la population",
        }),
      },
      {
        id: "access-and-roles",
        label: t("peopleReview.creationSteps.access-and-roles", {
          defaultValue: "Choix des responsables",
        }),
      },
      {
        id: "managers",
        label: t("peopleReview.creationSteps.manager", {
          defaultValue: "Préparation Managers",
        }),
      },
      {
        id: "summary",
        label: t("peopleReview.creationSteps.summary", {
          defaultValue: "Synthèse",
        }),
      },
    ],
    [t]
  );
  const [creationState, setCreationState] = useSetState<CampaignCreationState>({
    availableSteps: steps.filter(({ id }) => id !== "managers"),
    collaboratorManagerPairs: [],
    coordinators: [],
    currentStepId: "details" as StepIds,
    hasManagers: false,
    managersHelpText: null,
    managersPreparationEndDate: null,
    observers: [],
    templateID: null,
    titleForHR: null,
    titleForManagers: null,
    uploadedFile: null,
  });
  const currentStepIndex = useMemo(
    () =>
      creationState.availableSteps.findIndex(({ id }) => creationState.currentStepId === id) + 1,
    [creationState.availableSteps, creationState.currentStepId]
  );

  const handleChangeStep = (stepIndex: number) => {
    setCreationState({
      currentStepId: creationState.availableSteps[stepIndex - 1].id,
    });
  };

  return (
    <DSLayout
      className={peopleReviewStyles.layout}
      title={t("peopleReview.campaignCreation", {
        defaultValue: "Création d'une campagne de People Review",
      })}
      parent={{
        title: t("peopleReview.title", { defaultValue: "People Review" }),
        titleUrl: generatePath(PeopleReviewRoutes.PPRCampaignsList),
      }}
    >
      <Flex gap="s" padding="l" width="100%" flexDirection="column">
        <Stepper
          disableNextSteps={true}
          className={styles.stepper}
          activeStep={currentStepIndex}
          doneStep={currentStepIndex - 1}
          steps={creationState.availableSteps.map(({ label }) => ({ label }))}
          onChangeStep={handleChangeStep}
        />

        <Flex width="100%" paddingBottom="l">
          <ActiveStep
            step={creationState}
            setCreationState={setCreationState}
            addManagersStep={(status) => {
              setCreationState({
                availableSteps: status ? steps : steps.filter(({ id }) => id !== "managers"),
                // If manager toggle is changed, reset some of the form fields
                collaboratorManagerPairs: [],
                hasManagers: false,
                managersHelpText: null,
                uploadedFile: null,
              });
            }}
          />
        </Flex>
      </Flex>
    </DSLayout>
  );
}
