import { MdEditNote as EditNote } from "react-icons/md";
import { MdAttachFile as AttachFile } from "react-icons/md";
import { useMemo } from "react";
import { DataTableProps, ColumnType, DSTooltip, DSCheckbox } from "@skillup/ui";

import { plural } from "utils/locale";
import { uniqBy } from "lodash";

import type { Tracking } from "../state/tracking";
import TrackingStatus from "./components/TrackingStatus";
import TrackingState from "./components/TrackingState";
import TrackingDuration from "../components/TrackingDuration/TrackingDuration";

import styles from "./Trackings.module.scss";
import { TrackingAction, Actions } from "./useTrackingActions";

function useTrackingsTableData(
  trackings: Array<Tracking>,
  openActionModal: (trackingUuid: string, action: TrackingAction) => void
) {
  const tableData = useMemo(
    () => ({
      rows: generateTableData(trackings),
      columns: generateColumns(trackings, openActionModal),
    }),
    [trackings, openActionModal]
  );

  return tableData;
}

export default useTrackingsTableData;

const generateColumns = (
  trackings: Array<Tracking>,
  openActionModal: (trackingUuid: string, action: TrackingAction) => void
): DataTableProps<TrackingRow>["columns"] => {
  const hasSite = trackings.some((tracking) => tracking.user.site);
  const hasCompany = trackings.some((tracking) => tracking.user.company);

  const statusOptions = [
    {
      label: "Expirée",
      value: "expired",
    },
    {
      label: "Échéance proche",
      value: "expiring",
    },
    {
      label: "En attente",
      value: "pending",
    },
    {
      label: "À jour",
      value: "valid",
    },
    {
      label: "Archivée",
      value: "archived",
    },
  ];

  const stateOptions = [
    {
      label: "À Inscrire",
      value: "pending",
    },
    {
      label: "Inscrit",
      value: "won",
    },
    {
      label: "Réalisé",
      value: "realized",
    },
  ];

  const siteOptions = uniqBy(trackings, (t) => {
    return t.user.site;
  })
    .map((e) => ({ value: e.user?.site ?? "", label: e.user?.site ?? "" }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const companyOptions = uniqBy(trackings, (t) => {
    return t.user.company;
  })
    .map((e) => ({ value: e.user?.company ?? "", label: e.user?.company ?? "" }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const collaboratorOptions = uniqBy(trackings, (t) => {
    return t.user.fullName;
  })
    .map((e) => ({ value: e.user?.fullName ?? "", label: e.user?.fullName ?? "" }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const habilitationOptions = uniqBy(trackings, (t) => {
    return t.habilitation.name;
  })
    .map((e) => ({ value: e.habilitation.name, label: e.habilitation.name }))
    .sort((a, b) => a.label.localeCompare(b.label));

  enum DocFilterValue {
    WITH = 1,
    WITHOUT = 0,
    NONE = -1,
  }

  const columns: DataTableProps<TrackingRow>["columns"] = [
    {
      key: "status",
      title: "Statut",
      minWidth: 8,
      sticky: true,
      type: ColumnType.SELECT,
      options: statusOptions,
      renderCell: ({ status }) => <TrackingStatus status={status} />,
    },
    {
      key: "user",
      title: "Collaborateur",
      type: ColumnType.SEARCH_SELECT,
      options: collaboratorOptions,
      largeFilterForm: true,
      minWidth: 12,
      sticky: true,
      filterFn: (row, values: Set<string>) => {
        return [...values].some((value) => row.data.user === value);
      },
    },
    ...(hasSite
      ? [
          {
            key: "site",
            title: "Site",
            type: ColumnType.SEARCH_SELECT,
            options: siteOptions,
            width: 12,
          },
        ]
      : []),
    ...(hasCompany
      ? [
          {
            key: "company",
            title: "Société",
            width: 10,
            type: ColumnType.SEARCH_SELECT,
            options: companyOptions,
          },
        ]
      : []),
    {
      key: "habilitation",
      title: "Habilitation",
      type: ColumnType.SEARCH_SELECT,
      options: habilitationOptions,
      largeFilterForm: true,
      minWidth: 12,
      filterFn: (row, values: Set<string>) => {
        return [...values].some((value) => row.data.habilitation === value);
      },
    },
    {
      key: "validUntil",
      title: "Durée restante",
      width: 10,
      renderCell: ({ validUntil }) => <TrackingDuration validUntil={validUntil} />,
      filterable: false,
    },
    {
      key: "associatedRowState",
      title: "État",
      width: 8,
      type: ColumnType.SELECT,
      options: stateOptions,
      hasActionOnClickCell: (t) => {
        const action = t.data.actions.find((e) => e.type === Actions.ModifyState && !e.disabled);
        if (action) {
          return ({ row }: { row: TrackingRow }) => {
            openActionModal(row.id, action);
          };
        }

        return undefined;
      },
      renderCell: ({ associatedRowState }) =>
        associatedRowState && <TrackingState state={associatedRowState} />,
    },
    {
      key: "note",
      title: "Note",
      type: ColumnType.BOOL,
      icon: <EditNote />,
      className: styles.attachmentCol,
      width: 6,
      hasActionOnClickCell: (t) => {
        const action = t.data.actions.find(
          (e) => (e.type === Actions.AddNote || e.type === Actions.ManageNote) && !e.disabled
        );
        if (action) {
          return ({ row }: { row: TrackingRow }) => {
            openActionModal(row.id, action);
          };
        }

        return undefined;
      },
      renderCell: ({ note }) => {
        return (
          note && (
            <DSTooltip label={note}>
              <EditNote />
            </DSTooltip>
          )
        );
      },
      sortFn(row) {
        return row.data.note ? -1 : 1;
      },
      filterFn: ({ data }, value) => {
        switch (value) {
          case "without notes only":
            return !data.note;
          case "with notes only":
            return !!data.note;
          case "all":
            return true;
          default:
            return false;
        }
      },
      renderFilter: ({ onChange, value }) => {
        const withNoteFilter = value === "all" || value === "with notes only";
        const withoutNoteFilter = value === "all" || value === "without notes only";

        function handleChange() {
          if (withNoteFilter && withoutNoteFilter) return onChange("all");
          if (withNoteFilter && !withoutNoteFilter) return onChange("with notes only");
          if (!withNoteFilter && withoutNoteFilter) return onChange("without notes only");
          else return onChange("empty");
        }
        return (
          <div className={styles.customFilter}>
            <DSCheckbox
              name="withNote"
              label="Avec note"
              checked={withNoteFilter}
              onChange={() => {
                handleChange();
              }}
            />
            <DSCheckbox
              name="withoutNote"
              label="Sans note"
              checked={withoutNoteFilter}
              onChange={() => {
                handleChange();
              }}
            />
          </div>
        );
      },
    },
    {
      key: "documentsCount",
      title: "Document",
      type: ColumnType.BOOL,
      icon: <AttachFile />,
      className: styles.attachmentCol,
      width: 6,
      hasActionOnClickCell: (t) => {
        const action = t.data.actions.find(
          (e) =>
            (e.type === Actions.AddDocument || e.type === Actions.ManageDocuments) && !e.disabled
        );
        if (action) {
          return ({ row }: { row: TrackingRow }) => {
            openActionModal(row.id, action);
          };
        }

        return undefined;
      },
      renderCell: ({ documentsCount }) =>
        documentsCount > 0 && (
          <DSTooltip label={plural(documentsCount, "%n document%s associé%s")}>
            <AttachFile />
          </DSTooltip>
        ),
      filterFn: ({ data }, value: DocFilterValue) => {
        switch (value) {
          case DocFilterValue.NONE:
            return false;
          case DocFilterValue.WITHOUT:
            return !data.documentsCount;
          case DocFilterValue.WITH:
            return !!data.documentsCount;
          default:
            return true;
        }
      },
      renderFilter: ({ value, onChange }) => {
        const withDoc = (value ?? DocFilterValue.WITH) === DocFilterValue.WITH;
        const withoutDoc = (value ?? DocFilterValue.WITHOUT) === DocFilterValue.WITHOUT;
        function handleChange(withDoc, withoutDoc) {
          if (withDoc && withoutDoc) return onChange(undefined);
          if (withDoc) return onChange(DocFilterValue.WITH);
          if (withoutDoc) return onChange(DocFilterValue.WITHOUT);
          return onChange(DocFilterValue.NONE);
        }
        return (
          <div className={styles.customFilter}>
            <DSCheckbox
              name="withDoc"
              label="Avec document"
              checked={withDoc}
              onChange={(v) => handleChange(v, withoutDoc)}
            />
            <DSCheckbox
              name="withoutDoc"
              label="Sans document"
              onChange={(v) => handleChange(withDoc, v)}
              checked={withoutDoc}
            />
          </div>
        );
      },
    },
  ];

  return columns;
};

function generateTableData(trackings: Tracking[]) {
  const tableRows = trackings?.map(parseTrackingIntoRow);
  return tableRows;
}

export interface TrackingRow {
  id: string;
  data: {
    status: Tracking["status"];
    user: string;
    habilitation: string;
    actions: TrackingAction[];
    documentsCount: number;
    validUntil?: string;
    validSince?: string;
    associatedRowState?: Tracking["associatedRowState"];
    site?: string;
    company?: string;
    note?: string;
  };
}

function parseTrackingIntoRow(tracking: Tracking): TrackingRow {
  return {
    id: tracking.uuid,
    data: {
      status: tracking.status,
      user: tracking.user.fullName,
      habilitation: tracking.habilitation.name,
      validUntil: tracking.validUntil,
      validSince: tracking.validSince,
      associatedRowState: tracking.associatedRowState,
      documentsCount: tracking.documents.length,
      note: tracking.note?.noteText,
      actions: tracking.actions,
      company: tracking.user.company,
      site: tracking.user.site,
    },
  };
}
