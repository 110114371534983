import { useToasts } from "react-toast-notifications";

import { UserRoutes } from "@skillup/espace-rh-bridge";
import { Select } from "@skillup/ui";
import { Languages } from "@skillup/types";

import useTranslation from "hooks/useTranslation";
import User from "utils/User";
import { buildRequest } from "utils/buildRequest";

import styles from "./LanguageSelector.module.scss";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const { addToast } = useToasts();

  const options: Array<{ label: string; value: Languages }> = [
    {
      label: t("app.spaces.francais", { defaultValue: "Français" }),
      value: "fr",
    },
    {
      label: t("app.spaces.english", { defaultValue: "English" }),
      value: "en",
    },
    {
      label: t("app.spaces.deutsch", { defaultValue: "Deutsch" }),
      value: "de",
    },
    {
      label: t("app.spaces.espanol", { defaultValue: "Español" }),
      value: "es",
    },
    {
      label: t("app.spaces.italiano", { defaultValue: "Italiano" }),
      value: "it",
    },
    ...(User.isSkillupAdmin()
      ? ([
          {
            label: t("app.spaces.cimode", { defaultValue: "Trad Label" }),
            value: "cimode",
          },
        ] as const)
      : []),
  ];

  const syncInLocalStorage = (lang: Languages) => {
    const userData = JSON.parse(localStorage.getItem("_skillup_userData") ?? "");
    if (userData) {
      localStorage.setItem(
        "_skillup_userData",
        JSON.stringify({ ...userData, currentLanguage: lang })
      );
    }
  };

  const updateLanguage = async (lang: Languages) => {
    try {
      const userData = User.getUserData();
      await buildRequest<UserRoutes.PatchUserLanguageRouteData>({
        target: "API",
        method: "POST",
        path: `/user/{userUuid}`,
        params: { userUuid: userData.uuid },
        payload: { currentLanguage: lang },
      })();
      syncInLocalStorage(lang);
      i18n.changeLanguage(lang, undefined);
    } catch (error) {
      addToast(t("app.errors.message"), { appearance: "error" });
    }
  };

  return (
    <Select
      className={styles.LanguageSelector}
      options={options}
      value={options.find((e) => e.value === i18n.language).value}
      onChange={updateLanguage}
    />
  );
};

export default LanguageSelector;
