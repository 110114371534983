import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { MdLink, MdGroups, MdArticle, MdPsychology, MdThumbDownAlt } from "react-icons/md";

import { KPI, Flex, Text, Title, Loader, EvaluationBoard } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import { JobFromAPI, JobAnalytics } from "types/skills";

import RadarList from "../../../components/RadarList";
import LevelGauge from "../../../components/LevelGauge";
import { Link, KPIContainer, StyledTooltip, RadarContainer } from "./JobAnalytic.styled";

export default ({
  error,
  jobAnalytics,
  jobData,
  loading,
}: {
  error: boolean;
  loading: boolean;
  jobData: JobFromAPI;
  jobAnalytics: JobAnalytics;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const [anchor, setAnchor] = useState("");

  useEffect(() => {
    if (location.hash) {
      setAnchor(location.hash.substring(1));
    }
  }, [location]);

  if (loading) return <Loader fillSpace />;

  if (error || !jobData || !jobAnalytics) {
    return <Text>{t("skills.dashboard.error", { defaultValue: "Une erreur est survenue" })}</Text>;
  }

  const {
    averageEvaluationBySkills,
    employeesAverageEvaluationsScore,
    employeesEvaluated,
    employeesLinked,
    evaluationsBySkillCategory,
    skillEvaluations,
    skills,
  } = jobAnalytics;

  const unmasteredSkills = averageEvaluationBySkills.reduce((acc, category) => {
    return (
      acc +
      category.skills.reduce((acc, skill) => {
        return skill.expectedLevel && skill.level < skill.expectedLevel ? acc + 1 : acc;
      }, 0)
    );
  }, 0);

  const hasAtLeastOneExpectedValue = skillEvaluations.some((skill) => skill.expectedLevel);

  return (
    <Flex width="100%" flexDirection="column">
      <StyledTooltip
        direction="top"
        label={t("skills.dashboard.jobTooltipLink", {
          defaultValue: "Accéder au détail de la fiche de poste",
        })}
      >
        <Title h3 marginTop="xs" color="plainText-onLight-lighter">
          <Link
            onClick={() =>
              history.push(`/responsable/referentiels/fiches-de-poste/${jobData.uuid}`)
            }
          >
            {jobData.name} <MdLink />
          </Link>
        </Title>
      </StyledTooltip>
      <Text espaceFont="captionRegular" color="plainText-onLight-default">
        {t("skills.dashboard.jobMainDesc", {
          defaultValue:
            "Les évaluations présentées ici sont celles des collaborateurs actifs qui sont actuellement assignés à cette fiche de poste.",
        })}
      </Text>
      <KPIContainer>
        <KPI
          Icon={MdGroups}
          value={employeesLinked.toString()}
          label={t("skills.dashboard.linkedEmployeesTitle", {
            defaultValue: "Collaborateurs associés",
          })}
          tooltip={t("skills.dashboard.linkedEmployeesDesc", {
            defaultValue: "Collaborateurs auxquels cette fiche de poste est associée",
          })}
        />
        <KPI
          Icon={MdArticle}
          value={employeesEvaluated.toString()}
          label={t("skills.dashboard.evaluatedEmployeesTitle", {
            defaultValue: "Collaborateurs évalués",
          })}
          tooltip={t("skills.dashboard.evaluatedEmployeesDesc", {
            defaultValue: "Nombre de collaborateurs évalués sur cette fiche de poste",
          })}
        />
        <KPI
          Icon={MdPsychology}
          value={skills.toString()}
          label={t("skills.dashboard.linkedSkillsTitle", {
            defaultValue: "Compétences",
          })}
          tooltip={t("skills.dashboard.linkedSkillsDesc", {
            defaultValue: "Nombre de compétences liées à cette fiche de poste ",
          })}
        />

        {hasAtLeastOneExpectedValue && (
          <KPI
            Icon={MdThumbDownAlt}
            value={unmasteredSkills.toString()}
            label={t("skills.dashboard.unmasteredSkills", {
              defaultValue: "Compétences non maîtrisées",
            })}
            tooltip={t("skills.dashboard.unmasteredSkillsDesc", {
              defaultValue:
                "Compétences dont la moyenne des évaluations est inférieure au niveau attendu",
            })}
          />
        )}
      </KPIContainer>

      {evaluationsBySkillCategory.length > 0 && (
        <Flex marginVertical="l" flexDirection="column">
          <Title h4 color="plainText-onLight-lighter">
            {t("skills.dashboard.gaugeTitle", {
              defaultValue:
                "Niveau moyen des collaborateurs sur l’ensemble des compétences de la fiche de poste (%)",
            })}
          </Title>
          <LevelGauge name={jobData.name} employees={employeesAverageEvaluationsScore} />
        </Flex>
      )}

      {averageEvaluationBySkills.length > 0 && (
        <RadarContainer>
          <RadarList totalSkills={skills} categories={averageEvaluationBySkills} />
        </RadarContainer>
      )}

      {evaluationsBySkillCategory.length > 0 && (
        <Flex marginTop="l" alignItems="center" justifyContent="space-between">
          <EvaluationBoard
            t={t}
            anchor={anchor}
            jobName={jobData.name}
            categories={evaluationsBySkillCategory}
          />
        </Flex>
      )}
    </Flex>
  );
};
