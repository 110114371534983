import { DSAlert, DSAlertType, DSAlertDisplay } from "@skillup/ui";
import styles from "./Steps.module.scss";

export const TargetAlert = () => (
  <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE} className={styles.alert}>
    <div>
      La création d'objectifs n'est effective qu'à la signature d'un entretien ou à la clôture de la
      campagne dans laquelle il apparaît.
      <br />
      <strong>
        Pensez donc à clôturer vos précédentes campagnes pour vous assurer que les objectifs sont
        bien créés et peuvent être évalués.
      </strong>
    </div>
  </DSAlert>
);
