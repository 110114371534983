import { ISO8601Date } from "./ISO08601Date";
import { TargetGroups } from "./target";
import { InterviewState } from "./interviewState";

export enum Confidentiality {
  PUBLIC = "public",
  PRIVATE = "private",
}

interface CampaignCreationData {
  template: string;
  titleForHR: string;
  titleForAttendees: string;
  managerOnly?: boolean;
  hideFromEmployeeUntil?: {
    managerState: InterviewState;
  };
  hideFromManagerUntil?: {
    employeeState: InterviewState;
  };
  confidentiality: Confidentiality;
}

export interface OngoingCampaignCreationData extends CampaignCreationData {
  duration: number;
  preparationTime: number;
  frequency: number;
  type: "ongoing";
}

export interface LegacyCampaignCreationData extends CampaignCreationData {
  duration: number;
  preparationTime: number;
  startDate: number;
  type: "legacy";
  mailingSettings: CampaignMailingSettings;
}

export interface AdHocCampaignCreationData extends CampaignCreationData {
  type: "adHoc";
}

type HTMLString = string;

export enum InterviewRoles {
  EMPLOYEE = "employee",
  MANAGER = "manager",
  OBSERVER = "observer",
}

export type InterviewActiveAttendeeRoles = InterviewRoles.EMPLOYEE | InterviewRoles.MANAGER;

export type TemplatePreviewRoles = InterviewRoles.EMPLOYEE | InterviewRoles.MANAGER | "hr";

export type Disallow = {
  [role in InterviewActiveAttendeeRoles]?: {
    reply?: boolean;
    comment?: boolean;
  };
};

export interface Question {
  uuid: string;
  kind: "question";
  label: string;
  description?: string;
  replyPlaceholder?: string;
  disallow?: Disallow;
  required?: boolean;
}

export interface TextQuestion extends Question {
  type: "text";
}

export interface QuestionChoice {
  label: string;
  help?: string;
}

export interface RadioQuestion extends Question {
  type: "radio";
  horizontal?: boolean;
  choices: QuestionChoice[];
}

export interface CheckboxQuestion extends Question {
  type: "checkbox";
  choices: QuestionChoice[];
}

export type InterviewType =
  | "Entretien annuel"
  | "Entretien professionnel"
  | "Bilan à 6 ans"
  | "Autre";

export type PersonalisableFields =
  | {
      type: "field";
      label: string;
      fieldName: string;
      fieldType: "string" | "date" | "number" | "monetary" | "boolean";
    }
  | {
      type: "const";
      label: string;
      value: string;
    };

export type PersonalisableFieldsWithData =
  | {
      readonly label: string;
      readonly value?: number;
      readonly fieldType: "number" | "monetary";
    }
  | {
      readonly label: string;
      readonly value?: string;
      readonly fieldType: "string" | "date";
    }
  | {
      readonly label: string;
      readonly value?: boolean;
      readonly fieldType: "boolean";
    };

export interface InterviewGuidelines {
  uuid: string;
  kind: "guidelines";
  description?: HTMLString;
  title?: string;
}

export interface InterviewPersonalisableGuidelines {
  uuid: string;
  kind: "personnalisableGuidelines";
  description?: HTMLString;
  richDescription?: HTMLString;
  employee: {
    title: string;
    fields: Array<PersonalisableFields>;
  };
  manager: {
    title: string;
    fields: Array<PersonalisableFields>;
  };
  title?: string;
}

export interface InterviewPageTitle {
  uuid: string;
  kind: "title";
  variant: "H1" | "H2" | "H3" | "H4";
  title: string;
}

export interface InterviewPageHTML {
  uuid: string;
  kind: "html";
  variant?: "info" | "warning" | "error";
  content: HTMLString;
}

export interface InterviewUserSpecificData {
  uuid: string;
  kind: "userSpecificData";
  userSpecificDataKey: string;
}

export interface ChildrenRestrictions extends Record<InterviewPageChild["kind"], any> {
  interviewsHistory: InterviewsHistory["disallow"];
  trainingsHistory: TrainingsHistory["disallow"];
  targets: TargetDisallow;
  globalTargetsCompletion: GlobalTargetsCompletion["disallow"];
  question: Question["disallow"];
  table: InterviewTable["disallow"];
  skills: InterviewSkills["disallow"];
  job: InterviewJob["disallow"];
  upload: InterviewFileUpload["disallow"];
  trainingsCollection: InterviewTrainingsCollection["disallow"];
}

export interface InterviewsHistory {
  uuid: string;
  kind: "interviewsHistory";
  maxAge?: number;
  ageUnit?: "years" | "months";
  disallow?: {
    employee?: {
      comment?: boolean;
      downloadPDF?: boolean;
    };
    manager?: {
      comment?: boolean;
      downloadPDF?: boolean;
    };
  };
  description?: HTMLString;
  types: InterviewType[];
}

export type InterviewQuestion = TextQuestion | RadioQuestion | CheckboxQuestion;

export type TargetDisallow = {
  [role in InterviewActiveAttendeeRoles]?: Disallow[InterviewRoles.EMPLOYEE] & {
    // same for both roles
    creation?: boolean;
    deletion?: boolean;
    evaluation?: boolean;
    edition?: boolean;
    commentTarget?: boolean;
  };
};

export type ReviewType = "evaluation" | "comment";
export type TargetType = "current" | "next";
export interface Targets {
  uuid: string;
  kind: "targets";
  type: TargetType;
  isCreateOptionsVisible?: boolean;
  description?: string;
  disallow?: TargetDisallow;
  choices?: string[];
  creationCategories?: string[]; // [TCY-1] legacy doc
  creationTargetCategories?: TemplateTargetCategory[];
  displayCategories?: string[]; // [TCY-1] legacy doc
  displayTargetCategories?: TemplateTargetCategory[];
  restrictScaleTo?: "ordinal" | "numeric";
  disableGoalSelection?: boolean;
  hidePeriod?: boolean;
  hideWeight?: boolean;
  targetNamePlaceholder?: string;
  targetDescriptionPlaceholder?: string;
  reviewType?: ReviewType;
  obsoletedAt?: ISO8601Date;
}

export interface GlobalTargetsCompletion {
  uuid: string;
  kind: "globalTargetsCompletion";
  forcePercentWeight?: boolean;
  required?: boolean;
  disallow?: Disallow;
}
export interface QuestionReply<T = string> {
  value?: T;
  updatedAt?: ISO8601Date;
}

export type GlobalTargetsCompletionWithItems = GlobalTargetsCompletion & {
  data: {
    [role in InterviewActiveAttendeeRoles]?: {
      comment?: QuestionReply<string>;
    };
  };
  completionTable: TargetGroups;
};

export interface InterviewConclusion {
  kind: "conclusion";
  title?: string;
  uuid: string;
}

export interface TrainingsHistory {
  uuid: string;
  kind: "trainingsHistory";
  maxAge: number;
  ageUnit: "years" | "months";
  disallow?: Disallow;
  description?: HTMLString;
}

export type TableCellValue = string | number | ISO8601Date | null;

// Fix values, allow fixing values of table's rows
type TableColumnFixed = {
  type: "fixed";
  values: TableCellValue[];
};

// Cells are dropdown
export type TableColumnDropdown = {
  type: "dropdown";
  choices: string[];
  defaultValue?: string; // Must be one of the choices
  id?: string;
};

// Classic excel-like cells
type TableColumnText = {
  type: "text";
  placeholder?: string;
  defaultValue?: string;
};

export type TableColumn = {
  title: string;
  width?: number; // pixels;
  uuid: string;
} & (TableColumnFixed | TableColumnDropdown | TableColumnText) & {
    disallow?: {
      [role in InterviewActiveAttendeeRoles]?: {
        reply?: boolean;
      };
    };
  };

export interface InterviewTable {
  uuid: string;
  kind: "table";
  columns: TableColumn[];
  rowsCount: number;
  disallow?: {
    [role in InterviewActiveAttendeeRoles]: {
      comment?: boolean;
    };
  };
  label?: string;
  description?: HTMLString;
}

export interface InterviewTrainingsCollection {
  uuid: string;
  kind: "trainingsCollection";
  disallow: {
    employee: {
      comment?: boolean;
      requestTraining?: boolean;
    };
    manager: {
      comment?: boolean;
      requestTraining?: boolean;
    };
  };
}

export interface InterviewFileUpload {
  uuid: string;
  kind: "upload";
  disallow?: {
    [role in InterviewActiveAttendeeRoles]?: {
      upload?: boolean;
      comment?: boolean;
    };
  };
}

export interface InterviewSkills {
  uuid: string;
  kind: "skills";
  description?: HTMLString;
  groupByCategoryUuid?: string;
  categoryValuesOrder?: string[];
  disallow?: {
    [role in InterviewActiveAttendeeRoles]?: {
      evaluation?: boolean;
      comment?: boolean;
      commentEvaluation?: boolean;
    };
  };
}

type InterviewPageChildCommonKeys = {
  commentPlaceholder?: string;
};

export type InterviewJob = {
  uuid: string;
  kind: "job";
  title?: string;
  disallow?: {
    employee?: {
      comment?: boolean;
    };
    manager?: {
      comment?: boolean;
    };
  };
};

export type InterviewPageChild = (
  | InterviewGuidelines
  | InterviewPersonalisableGuidelines
  | InterviewPageTitle
  | InterviewPageHTML
  | InterviewUserSpecificData
  | InterviewsHistory
  | InterviewQuestion
  | Targets
  | GlobalTargetsCompletion
  | InterviewConclusion
  | TrainingsHistory
  | InterviewTable
  | InterviewTrainingsCollection
  | InterviewFileUpload
  | InterviewSkills
  | InterviewJob
) &
  InterviewPageChildCommonKeys;

export interface InterviewSectionPage {
  uuid?: string;
  title?: string;
  hideInBuilder?: true;
  children: InterviewPageChild[];
}
export interface InterviewSection {
  uuid?: string;
  title: string;
  pages: InterviewSectionPage[];
}

export type TemplateTargetCategory = {
  uuid: string;
  label: string;
};
export interface CampaignTemplate {
  uuid: string;
  title: string;
  sections: InterviewSection[];
  choicesForOrdinalTargets?: string[];
  targetsCategories?: string[];
  targetCategories?: TemplateTargetCategory[];
  type: InterviewType;
}

export type CampaignMailingSettingsEntity = {
  uuid: string;
  notificationStart: boolean;
  notificationCreated: boolean;
  notificationManagerChange: boolean;
  notificationShared: boolean;
  notificationSigned: boolean;
  notificationReopen: boolean;
  reminderMinus14: boolean;
  reminderMinus7: boolean;
  reminderMinus1: boolean;
  reminderPlus1: boolean;
};

export type CampaignMailingSettings = Omit<CampaignMailingSettingsEntity, "uuid">;

export enum CampaignIsArchivable {
  YES = "yes",
  NO = "no",
  NOT_VISIBLE_INTERVIEW_ARE_NOT_ARCHIVABLE = "not_visible_interview_are_not_archivable",
}

export interface CampaignListItem {
  uuid: string;
  titleForHR: string;
  titleForAttendees: string;
  managerOnly?: boolean;
  type: "legacy" | "ongoing" | "adHoc";
  createdAt: string;
  deletedAt: string;
  isPreview: boolean;
  template: CampaignTemplate;
  templateSource: {
    uuid?: string;
    title?: string;
  };
  signedInterviews: number;
  totalInterviews: number;
  preparationTime?: number;
  duration?: number;
  frequency?: number;
  startDate?: string;
  endDate?: string;
  preparationEndDate?: ISO8601Date;
  archivedAt?: ISO8601Date;
  confidential: boolean;
  mailingSettings?: CampaignMailingSettings;
  hideFromEmployeeUntil?: {
    managerState: InterviewState;
  };
  hideFromManagerUntil?: {
    employeeState: InterviewState;
  };
  isArchivable?: CampaignIsArchivable;
  closedBy?: Array<{
    fullName: string;
    archivedAt?: ISO8601Date;
  }>;
}

export enum PossibleCodes {
  NoOrdinalChoices = "interview.targets.noOrdinalChoices",
  NoCategories = "interview.targets.noCategories",
  UserHasNoJobAssignement = "interview.skills.noJob",
  CampaignIsArchived = "interview.campaign.archived",
}

interface InterviewCreationRuleErrorBase {
  code: PossibleCodes;
  message: string;
}

export interface InterviewCreationRuleNoOrdinalChoices extends InterviewCreationRuleErrorBase {
  code: PossibleCodes.NoOrdinalChoices;
  payload: CampaignTemplate;
}

export interface InterviewCreationRuleNoCategories extends InterviewCreationRuleErrorBase {
  code: PossibleCodes.NoCategories;
  payload: CampaignTemplate;
}

export interface InterviewCreationRuleUserHasNoJobAssignement
  extends InterviewCreationRuleErrorBase {
  code: PossibleCodes.UserHasNoJobAssignement;
  payload: Array<{ uuid: string; email: string; fullName: string }>;
}

export interface InterviewCreationRuleCampaignIsArchived extends InterviewCreationRuleErrorBase {
  code: PossibleCodes.CampaignIsArchived;
  payload: { archivedAt?: ISO8601Date };
}

export type InterviewCreationRuleError =
  | InterviewCreationRuleNoOrdinalChoices
  | InterviewCreationRuleNoCategories
  | InterviewCreationRuleUserHasNoJobAssignement
  | InterviewCreationRuleCampaignIsArchived;

export type ModifyCampaignPayload = {
  data: Omit<
    AdHocCampaignCreationData | LegacyCampaignCreationData | OngoingCampaignCreationData,
    "template" | "confidentiality"
  > & {
    confidential: boolean;
  };
};

type CampaignDataCore = {
  uuid: string;
  createdAt: ISO8601Date;
  archivedAt?: ISO8601Date;
  closedBy?: Array<{
    fullName: string;
    archivedAt?: ISO8601Date;
  }>;
  titleForHR: string;
  titleForAttendees: string;
  managerOnly?: boolean;
  hideFromEmployeeUntil?: {
    managerState: InterviewState;
  };
  hideFromManagerUntil?: {
    employeeState: InterviewState;
  };
  confidentiality: Confidentiality;
  template: CampaignTemplate;
};

interface LegacyCampaignData extends CampaignDataCore {
  duration: number;
  preparationTime: number;
  startDate: ISO8601Date;
  type: "legacy";
  preparationEndDate: ISO8601Date;
  endDate: ISO8601Date;
}

interface OngoingCampaignData extends CampaignDataCore {
  duration: number;
  preparationTime: number;
  frequency: number;
  type: "ongoing";
}

interface AdHocCampaignData extends CampaignDataCore {
  type: "adHoc";
}

export type CampaignData = LegacyCampaignData | OngoingCampaignData | AdHocCampaignData;

export type GetCampaignsTemplatesResponse = Array<{
  title: string;
  uuid: string;
}>;

type withoutConfidentiality<T> = Omit<T, "confidentiality">;

export type CreateCampaignPayload = {
  data:
    | withoutConfidentiality<OngoingCampaignCreationData>
    | withoutConfidentiality<LegacyCampaignCreationData>
    | withoutConfidentiality<AdHocCampaignCreationData>;

  confidential: boolean;
  pairs: {
    employeeUuid: string;
    employeeJoinDate: number;
    managerUuid: string;
  }[];
};
