import { t } from "i18next";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Text, Flex } from "@skillup/design-system";

import { Review } from "../../../types";

type RoleCellProps = {
  params: GridRenderCellParams<Review>;
};

export const RoleCell = ({ params }: RoleCellProps) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Text>{params.row.role}</Text>
    </Flex>
  );
};

export const roleColDef: GridColDef<Review> = {
  field: "role" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.role", {
    defaultValue: "Rôle",
  }),
  renderCell: (params) => <RoleCell params={params} />,
  width: 150,
};
