export const UnavailableDashboardIcon = () => {
  return (
    <svg
      width="470"
      height="296"
      viewBox="0 0 470 296"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mask0_6669_56139" maskUnits="userSpaceOnUse" x="0" y="0" width="470" height="296">
        <rect width="470" height="296" fill="url(#paint0_linear_6669_56139)" />
      </mask>
      <g mask="url(#mask0_6669_56139)">
        <g filter="url(#filter0_d_6669_56139)">
          <rect x="32.2317" y="96.3257" width="192" height="216" rx="16" fill="white" />
          <rect x="48.2317" y="112.326" width="96" height="8" rx="4" fill="#C9CED4" />
          <path
            d="M48.2317 204.326C48.2317 199.907 51.8134 196.326 56.2317 196.326H72.2317C76.65 196.326 80.2317 199.907 80.2317 204.326V296.326H48.2317V204.326Z"
            fill="#7EC2E2"
          />
          <path
            d="M92.2317 229.326C92.2317 224.907 95.8134 221.326 100.232 221.326H116.232C120.65 221.326 124.232 224.907 124.232 229.326V296.326H92.2317V229.326Z"
            fill="#C0E2F1"
          />
          <path
            d="M136.232 254.326C136.232 249.907 139.813 246.326 144.232 246.326H160.232C164.65 246.326 168.232 249.907 168.232 254.326V296.326H136.232V254.326Z"
            fill="#C0E2F1"
          />
          <path
            d="M136.232 254.326C136.232 249.907 139.813 246.326 144.232 246.326H160.232C164.65 246.326 168.232 249.907 168.232 254.326V296.326H136.232V254.326Z"
            fill="white"
            fill-opacity="0.2"
          />
          <path
            d="M180.232 279.326C180.232 274.907 183.813 271.326 188.232 271.326H204.232C208.65 271.326 212.232 274.907 212.232 279.326V296.326H180.232V279.326Z"
            fill="#C0E2F1"
          />
          <path
            d="M180.232 279.326C180.232 274.907 183.813 271.326 188.232 271.326H204.232C208.65 271.326 212.232 274.907 212.232 279.326V296.326H180.232V279.326Z"
            fill="white"
            fill-opacity="0.4"
          />
        </g>
        <g filter="url(#filter1_d_6669_56139)">
          <g clip-path="url(#clip0_6669_56139)">
            <rect
              x="166.695"
              y="37.5186"
              width="192"
              height="216"
              rx="16"
              transform="rotate(-5 166.695 37.5186)"
              fill="white"
            />
            <rect
              x="184.029"
              y="52.0632"
              width="96"
              height="8"
              rx="4"
              transform="rotate(-5 184.029 52.0632)"
              fill="#C9CED4"
            />
            <path
              d="M352.46 141.447C356.297 185.309 323.851 223.978 279.988 227.815C236.126 231.653 197.458 199.206 193.62 155.344C189.783 111.481 222.229 72.8129 266.092 68.9754C309.954 65.138 348.622 97.5845 352.46 141.447ZM225.388 152.564C227.691 178.882 250.892 198.35 277.209 196.047C303.527 193.745 322.994 170.544 320.692 144.226C318.39 117.909 295.189 98.4409 268.871 100.743C242.554 103.046 223.086 126.247 225.388 152.564Z"
              fill="#F6F7F8"
            />
            <path
              d="M266.092 68.9754C282.864 67.5081 299.669 71.388 314.101 80.0591C328.532 88.7302 339.847 101.747 346.426 117.245C353.004 132.742 354.507 149.925 350.72 166.329C346.933 182.733 338.049 197.518 325.343 208.563L304.422 184.496C312.046 177.869 317.376 168.998 319.648 159.156C321.92 149.313 321.018 139.004 317.071 129.705C313.124 120.407 306.335 112.596 297.676 107.394C289.018 102.191 278.934 99.8629 268.871 100.743L266.092 68.9754Z"
              fill="#CEEDF3"
            />
            <path
              d="M325.216 208.967C316.04 216.944 305.162 222.716 293.412 225.842C281.663 228.968 269.355 229.366 257.428 227.004C245.502 224.643 234.273 219.585 224.602 212.217C214.93 204.85 207.072 195.367 201.628 184.496C196.184 173.625 193.299 161.653 193.193 149.495C193.087 137.338 195.763 125.317 201.016 114.352C206.269 103.388 213.961 93.7699 223.502 86.2346C233.044 78.6993 244.182 73.4461 256.066 70.8768L262.805 102.046C255.674 103.587 248.991 106.739 243.267 111.261C237.542 115.782 232.927 121.552 229.775 128.131C226.623 134.71 225.017 141.922 225.081 149.217C225.145 156.511 226.876 163.695 230.142 170.218C233.408 176.74 238.123 182.429 243.926 186.85C249.729 191.271 256.466 194.305 263.622 195.722C270.778 197.139 278.163 196.901 285.213 195.025C292.262 193.149 298.789 189.686 304.295 184.9L325.216 208.967Z"
              fill="#F3FBFC"
            />
          </g>
        </g>
        <g filter="url(#filter2_d_6669_56139)">
          <g clip-path="url(#clip1_6669_56139)">
            <rect
              x="254.548"
              y="127.651"
              width="192"
              height="216"
              rx="16"
              transform="rotate(5 254.548 127.651)"
              fill="white"
            />
            <rect
              x="269.093"
              y="144.985"
              width="96"
              height="8"
              rx="4"
              transform="rotate(5 269.093 144.985)"
              fill="#C9CED4"
            />
            <path
              opacity="0.6"
              d="M261.074 236.635C261.459 232.233 265.34 228.978 269.741 229.363L285.68 230.757C290.082 231.142 293.338 235.022 292.953 239.424L284.934 331.074L253.056 328.285L261.074 236.635Z"
              fill="#FFC21A"
            />
            <path
              opacity="0.6"
              d="M302.728 265.375C303.113 260.973 306.993 257.717 311.395 258.102L327.334 259.497C331.735 259.882 334.991 263.762 334.606 268.164L328.767 334.909L296.889 332.12L302.728 265.375Z"
              fill="#FFDB80"
            />
            <g opacity="0.6">
              <path
                d="M344.382 294.114C344.767 289.713 348.647 286.457 353.049 286.842L368.988 288.237C373.389 288.622 376.645 292.502 376.26 296.903L372.599 338.744L340.721 335.955L344.382 294.114Z"
                fill="#FFDB80"
              />
              <path
                d="M344.382 294.114C344.767 289.713 348.647 286.457 353.049 286.842L368.988 288.237C373.389 288.622 376.645 292.502 376.26 296.903L372.599 338.744L340.721 335.955L344.382 294.114Z"
                fill="white"
                fill-opacity="0.2"
              />
            </g>
            <path
              d="M257.588 276.483L309.931 239.905L333.079 263.011L395.635 219.297L422.993 221.69"
              stroke="#FFBB00"
              stroke-width="8"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_6669_56139"
          x="16.2317"
          y="84.3257"
          width="224"
          height="248"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6669_56139" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6669_56139"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_6669_56139"
          x="150.695"
          y="8.78464"
          width="242.095"
          height="263.912"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6669_56139" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6669_56139"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_6669_56139"
          x="219.722"
          y="115.651"
          width="242.095"
          height="263.912"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6669_56139" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6669_56139"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6669_56139"
          x1="235"
          y1="0"
          x2="235"
          y2="296"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D9D9D9" />
          <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_6669_56139">
          <rect
            x="166.695"
            y="37.5186"
            width="192"
            height="216"
            rx="16"
            transform="rotate(-5 166.695 37.5186)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_6669_56139">
          <rect
            x="254.548"
            y="127.651"
            width="192"
            height="216"
            rx="16"
            transform="rotate(5 254.548 127.651)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
