import { useMemo } from "react";
import { i18n } from "i18next";
import {
  useTranslation as useI18NextTranslationHook,
  UseTranslationResponse,
  Namespace,
} from "react-i18next";
import { LocaleDate } from "@skillup/shared-utils";
import User from "utils/User";

export interface CustomI18n extends i18n {
  language: LocaleDate.Locales;
}

interface UseTranslationReturnType<K extends Namespace<string>, TPrefix>
  extends Omit<UseTranslationResponse<K, TPrefix>, "t" | "i18n"> {
  t: (
    key: string,
    options?: {
      [key: string]: any;
      defaultValue?: string | undefined;
    }
  ) => string;
  i18n: CustomI18n;
}

const useTranslation = (): UseTranslationReturnType<Namespace<string>, undefined> => {
  const user = User.getUserData();
  const localesNs: string = useMemo(() => user?.activeCompany?.slug ?? "default", [user]);

  const { t, i18n, ready } = useI18NextTranslationHook(localesNs, { useSuspense: false });

  const tOut = useMemo(
    () =>
      (
        key: string,
        options: {
          [key: string]: any;
          defaultValue?: string;
        } = {}
      ) => {
        const { defaultValue, ...rest } = options;
        const value = t(`${localesNs}:${key}`, { defaultValue, ...rest });

        return value ?? defaultValue;
      },
    [t, localesNs]
  );

  return {
    t: tOut,
    i18n,
    ready,
  } as UseTranslationReturnType<Namespace<string>, undefined>;
};

export type TranslationType = (
  key: string,
  options?: { [key: string]: any; defaultValue?: string }
) => string;

export default useTranslation;
