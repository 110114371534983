import { useCallback, useEffect, useMemo, useState } from "react";
import Fuse from "fuse.js";
import { useQuery } from "@tanstack/react-query";
import { TargetsRoutes, UserTarget } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

export interface QueryAction {
  type: "sort" | "pagination" | "filter";
  sort?: string;
  order?: "ASC" | "DESC";
  page?: number;
  pageSize?: number;
  filter?: {
    isEvaluated?: boolean;
    isObsolete?: boolean;
    name?: string;
    targetCategoryLabel?: string;
    campaigns?: string[];
    "evaluationCampaign.titleForHR"?: string;
    "creationCampaign.titleForHR"?: string;
    manager?: string[];
  };
}

export interface QueryOptions {
  sort?: string;
  order?: "ASC" | "DESC";
  limit?: number;
  offset?: number;
  filter?: string;
}

async function getUserTargets({
  userUuid,
  queryOptions,
}: {
  userUuid: string;
  queryOptions: QueryOptions;
}) {
  try {
    const result = await buildRequest<TargetsRoutes.GetUserTargets>({
      method: "GET",
      path: "/user/{userUuid}/targets",
      params: { userUuid },
      query: queryOptions,
    })();
    return result;
  } catch (err) {
    return { targets: [], totalRows: 0, globalFilters: { campaigns: [], evaluators: [] } };
  }
}

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};
const defaultOptions: Options = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

export function useTargets({
  userUuid,
  queryOptions,
}: {
  userUuid: string;
  queryOptions: QueryOptions;
}) {
  const { data: targetsWithTotal, isLoading } = useQuery(
    ["targets", userUuid, queryOptions],
    async () => getUserTargets({ userUuid, queryOptions }),
    {
      ...defaultOptions,
      keepPreviousData: true,
    }
  );

  const [obsoleteTargets, activeTargets]: [UserTarget[], UserTarget[]] = useMemo(
    () => splitTargetsIntoActiveAndObsolete(targetsWithTotal?.targets ?? []),
    [targetsWithTotal]
  );

  const [search, setSearch] = useState("");
  const [filteredObsoleteTargets, setfilteredObsoleteTargets] =
    useState<UserTarget[]>(obsoleteTargets);
  useEffect(() => {
    if (filteredObsoleteTargets.length === 0 && search.length === 0) {
      setfilteredObsoleteTargets(obsoleteTargets);
    }
  }, [filteredObsoleteTargets, setfilteredObsoleteTargets, obsoleteTargets, search]);

  const searchOnObsolete = useCallback(
    async (searchInput: string) => {
      setSearch(searchInput);
      const fuse = new Fuse(obsoleteTargets, {
        keys: ["name"],
        threshold: 0.333,
        shouldSort: true,
        includeScore: true,
      });
      setfilteredObsoleteTargets(fuse.search(searchInput).map((result) => result.item));
    },
    [obsoleteTargets]
  );

  return {
    targetsList: activeTargets,
    obsoleteTargets,
    searchOnObsolete,
    filteredObsoleteTargets,
    targetsTotal: targetsWithTotal?.totalRows ?? 0,
    campaigns: targetsWithTotal?.globalFilters.campaigns ?? [],
    evaluators: targetsWithTotal?.globalFilters.evaluators ?? [],
    isLoading,
  };
}

function splitTargetsIntoActiveAndObsolete(targets: UserTarget[]): [UserTarget[], UserTarget[]] {
  if (targets.length === 0) return [[], []];
  return targets.reduce(
    ([obsoleteTargets, activeTargets], target) => {
      return target.obsoletedAt
        ? [[...obsoleteTargets, target], activeTargets]
        : [obsoleteTargets, [...activeTargets, target]];
    },
    [[], []]
  );
}
