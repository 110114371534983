import { useContext, useCallback, createContext } from "react";

import type { StateProperty } from "@skillup/core-hr-bridge";

import { trpc } from "utils/trpc";
import { DatatableJob, SuggestionsJob } from "types/skills";

type AcceptSuggestions = {
  accepted: string[];
  dismissed: string[];
  manualAssignments: {
    jobId: string;
    employeeId: string;
  }[];
};

type SuggestionsContextType = {
  acceptSuggestions: (params: AcceptSuggestions) => Promise<string[]>;
  cancelJobSuggestions: (suggestionIds: Array<string>) => Promise<void>;
  getJobs: () => {
    data: Array<DatatableJob>;
    status: "error" | "loading" | "success";
    error: ReturnType<typeof trpc.jobs.getAll.useQuery>["error"];
  };
  getJobSuggestions: (state?: StateProperty) => {
    refetch: () => void;
    data: Array<SuggestionsJob>;
    status: "error" | "loading" | "success";
    error: ReturnType<typeof trpc.suggestions.get.useQuery>["error"];
  };
};

const SuggestionsContext = createContext<SuggestionsContextType>(null);

const useSuggestionsContext = () => {
  const context = useContext(SuggestionsContext);
  if (!context) {
    throw new Error("useSuggestionsContext must be used within a SuggestionsContext");
  }
  return context;
};

interface SuggestionsProviderProps {
  children: React.ReactNode;
}

const SuggestionsProvider = ({ children }: SuggestionsProviderProps) => {
  const getJobSuggestions = useCallback((state: StateProperty = undefined) => {
    const { data, error, refetch, status } = trpc.suggestions.get.useQuery({ state });

    const castData = data as unknown as Array<SuggestionsJob>;

    return { data: castData, error, refetch, status };
  }, []);

  const getJobs = useCallback(() => {
    const { data, error, status } = trpc.jobs.getAll.useQuery();

    const castData = data as unknown as Array<DatatableJob>;

    return { data: castData, error, status };
  }, []);

  const cancelSuggestionsMutation = trpc.suggestions.cancel.useMutation();

  const cancelJobSuggestions = useCallback(
    async (suggestionIDs: Array<string>) => {
      await cancelSuggestionsMutation.mutateAsync({ suggestionIDs });
    },
    [cancelSuggestionsMutation]
  );

  const acceptSuggestionsMutation = trpc.suggestions.accept.useMutation();
  const acceptSuggestions = useCallback(
    async (params: AcceptSuggestions) => acceptSuggestionsMutation.mutateAsync(params),
    [acceptSuggestionsMutation]
  );

  return (
    <SuggestionsContext.Provider
      value={{
        acceptSuggestions,
        cancelJobSuggestions,
        getJobs,
        getJobSuggestions,
      }}
    >
      {children}
    </SuggestionsContext.Provider>
  );
};

export { SuggestionsContext, SuggestionsProvider, useSuggestionsContext };
