import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import DSNewHeader from "components/DSNewHeader";
import { toFormikValidationSchema } from "zod-formik-adapter";
import useTranslation from "hooks/useTranslation";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import { DSAlert, DSAlertDisplay, DSAlertType, DSTooltip, Flex } from "@skillup/ui";

import style from "../../views/Configuration.module.scss";
import { DashboardConfigForm } from "../../types";
import { configurationSchema } from "../../utils/validationSchemas";
import { AddPanelDropdown } from "./Panels/AddPanelDropdown";
import { SortablePanels } from "./Panels/SortablePanel";
import { CancelModal } from "./CancelModal";

export type ConfigurationFormProps = {
  initialValue?: DashboardConfigForm;
  onSubmit: (values: DashboardConfigForm) => void;
};

const compareDashboardConfig = (a: DashboardConfigForm, b: DashboardConfigForm) => {
  // On very big config and weak perf, this can be heavy
  return JSON.stringify(a) === JSON.stringify(b);
};

export const ConfigurationForm = ({ initialValue, onSubmit }: ConfigurationFormProps) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Flex column className={style.configFormWrapper}>
      <Formik<DashboardConfigForm>
        validateOnMount={false}
        enableReinitialize
        validationSchema={toFormikValidationSchema(configurationSchema(t))}
        onSubmit={onSubmit}
        initialValues={initialValue}
      >
        {({ values, handleSubmit, setFieldValue, isValid, errors }) => (
          <Flex column>
            <DSNewHeader
              parent={{
                titleUrl: "/responsable/dashboard/training",
                title: t("training.dashboard.title", { defaultValue: "Dashboard formation" }),
                onClick: () => {
                  if (!compareDashboardConfig(initialValue, values)) {
                    setOpenModal(true);
                  } else {
                    history.push("/responsable/dashboard/training");
                  }
                },
              }}
              title={t("training.dashboard.configuration.title", {
                defaultValue: "Personnalisation du dashboard",
              })}
              header={
                <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.FULL_BLEED}>
                  {t("training.dashboard.configuration.banner.alert", {
                    defaultValue:
                      "La personnalisation est répercutée à tous les utilisateurs ayant accès au dashboard",
                  })}
                </DSAlert>
              }
              layouts={[
                {
                  primaryButton: (
                    <DSTooltip
                      label={t("training.dashboard.configuration.submit.tooltip.invalid", {
                        defaultValue:
                          "Votre configuration contient une erreur. Vous devez la corriger pour enregistrer vos modifications.",
                      })}
                      disabled={
                        isValid || (!isValid && compareDashboardConfig(initialValue, values))
                      }
                    >
                      <DSNewHeaderButton
                        type="submit"
                        darkMode={true}
                        emphasis="High"
                        label={t("training.dashboard.configuration.submit", {
                          defaultValue: "Enregistrer les modifications",
                        })}
                        disabled={!isValid || compareDashboardConfig(initialValue, values)}
                        onClick={() => handleSubmit()}
                      />
                    </DSTooltip>
                  ),
                },
              ]}
            />

            <CancelModal
              openModal={openModal}
              setOpenModal={setOpenModal}
              onClose={() => setOpenModal(false)}
              onCancel={() => history.push("/responsable/dashboard/training")}
            />
            <Flex column>
              <AddPanelDropdown
                offset={0}
                onChange={(value) => setFieldValue("panels", value)}
                panels={values.panels}
              />
              <SortablePanels
                panels={values.panels}
                onChange={(value) => setFieldValue("panels", value)}
              />
            </Flex>
          </Flex>
        )}
      </Formik>
    </Flex>
  );
};
