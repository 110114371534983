import React from "react";

import esES from "date-fns/locale/es";
import { type Themes } from "commons";
import frFR from "date-fns/locale/fr";
import itIT from "date-fns/locale/it";
import enBG from "date-fns/locale/en-GB";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { frFR as frFRText } from "@mui/x-date-pickers/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { type LocalizationProviderProps } from "@mui/x-date-pickers";

import { type Locales } from "@skillup/shared-utils";

import { MuiThemeProvider } from "./mui-theme";
import { DarkModeContextProvider } from "./dark-mode";
import { DesignSystemThemesProvider } from "./themes";
import { type TranslationFunction, DesignSystemTranslationsProvider } from "./translations";

const dateLocales: { [language in Locales]: Locale } = {
  cimode: enBG,
  de: enBG,
  en: enBG,
  es: esES,
  fr: {
    ...frFR,
    options: {
      ...frFR.options,
      weekStartsOn: 1, // Sunday = 0, Monday = 1.
    },
  },
  it: itIT,
};

const localTexts: { [language in Locales]?: LocalizationProviderProps<any, any>["localeText"] } = {
  fr: frFRText.components.MuiLocalizationProvider.defaultProps.localeText,
};

export type DesignSystemProviderProps = {
  theme: Themes;
  language: Locales;
  darkMode?: boolean;
  t: TranslationFunction;
  children: React.ReactNode;
};

export const DesignSystemProvider = ({
  children,
  darkMode = false,
  language,
  t,
  theme,
}: DesignSystemProviderProps) => {
  return (
    <DarkModeContextProvider value={darkMode}>
      <DesignSystemThemesProvider value={theme}>
        <MuiThemeProvider>
          <DesignSystemTranslationsProvider value={t}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              localeText={localTexts[language]}
              adapterLocale={dateLocales[language]}
            >
              {children}
            </LocalizationProvider>
          </DesignSystemTranslationsProvider>
        </MuiThemeProvider>
      </DesignSystemThemesProvider>
    </DarkModeContextProvider>
  );
};
