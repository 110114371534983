import { type ISO8601Date } from "..";
import {
  type ICollabRowForManager,
  type ICollabRowForEmployee,
  type ICollabRowForHR,
} from "./ITrainningFront";

export type Completion = {
  max: number;
  value: number;
};

type HTMLString = string;

export interface Campaign {
  uuid: string;
  titleForHR: string;
  titleForAttendees: string;
  managerOnly?: boolean;
  preparationTime: number;
  duration: number;
  template?: string;
  hideFromEmployeeUntil?: {
    managerState: InterviewUser["state"];
  };
  hideFromManagerUntil?: {
    employeeState: InterviewUser["state"];
  };
}

export interface AdHocCampaign extends Campaign {
  type: "adHoc";
}

export interface LegacyCampaign extends Campaign {
  type: "legacy";
  startDate: ISO8601Date;
}

export interface OngoingCampaign extends Campaign {
  type: "ongoing";
  frequency: number;
}

export interface Interview {
  uuid: string;
  title: string;
  managerOnly?: boolean;
  startDate: ISO8601Date;
  preparationEndDate: ISO8601Date;
  endDate: ISO8601Date;
  choicesForOrdinalTargets?: string[];
}

export interface InterviewUser {
  uuid: string;
  fullName: string;
  initials: string;
  state: "pending" | "shared" | "signed" | "unsigned";
  completion?: Completion;
  signedDate?: ISO8601Date;
  signature?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
  contract?: string;
  joinDate?: ISO8601Date;
  joinRole?: string;
  roleDate?: ISO8601Date;
  notified: boolean;
  noEmail?: boolean;
  userAreas?: string[];
  userAreasNames?: string[];
}

enum InterviewRoles {
  EMPLOYEE = "employee",
  MANAGER = "manager",
  OBSERVER = "observer",
}

export type InterviewActiveAttendeeRoles = InterviewRoles.EMPLOYEE | InterviewRoles.MANAGER;

export type InterviewWithStates = Interview & {
  employee: InterviewUser;
  manager: InterviewUser;
};

export type Disallow = {
  [role in InterviewActiveAttendeeRoles]?: {
    reply?: boolean;
    comment?: boolean;
  };
};

export interface Question {
  uuid?: string;
  kind: "question";
  label: string;
  description?: string;
  replyPlaceholder?: string;
  disallow?: Disallow;
  required?: boolean;
}

export interface TextQuestion extends Question {
  type: "text";
}

export interface QuestionChoice {
  label: string;
  help?: string;
}

export interface RadioQuestion extends Question {
  type: "radio";
  horizontal?: boolean;
  choices: QuestionChoice[];
}

export interface CheckboxQuestion extends Question {
  type: "checkbox";
  choices: QuestionChoice[];
}

export interface QuestionReply<T = string> {
  value: T | null;
  updatedAt?: ISO8601Date;
}

export type InterviewQuestion = TextQuestion | RadioQuestion | CheckboxQuestion;

export type QuestionWithReplies<T = InterviewQuestion, U = string> = T & {
  data: {
    [role in InterviewActiveAttendeeRoles]?: {
      reply?: QuestionReply<U>;
      comment?: QuestionReply<string>;
    };
  };
};

export interface InterviewSection {
  uuid?: string;
  title: string;
  pages: InterviewSectionPage[];
}

type InterviewPageChildCommonKeys = {
  commentPlaceholder?: string;
};

export interface InterviewGuidelines {
  uuid: string;
  kind: "guidelines";
  description?: HTMLString;
  title?: string;
}

export interface InterviewGuidelinesWithData extends InterviewGuidelines {
  data: {
    employee: {
      fullName: string;
      role?: string;
      joinDate?: string;
      joinRole?: string;
      roleDate?: string;
      noEmail?: boolean;
    };
    manager: {
      fullName: string;
      role?: string;
      noEmail?: boolean;
    };
  };
}

export type PersonalisableFields =
  | {
      type: "field";
      label: string;
      fieldName: string;
      fieldType: "string" | "date" | "number" | "monetary" | "boolean";
    }
  | {
      type: "const";
      label: string;
      value: string;
    };

export type PersonalisableFieldsWithData =
  | {
      readonly label: string;
      readonly value?: number;
      readonly fieldType: "number" | "monetary";
    }
  | {
      readonly label: string;
      readonly value?: string;
      readonly fieldType: "string" | "date";
    }
  | {
      readonly label: string;
      readonly value?: boolean;
      readonly fieldType: "boolean";
    };

export interface InterviewPersonalisableGuidelines {
  uuid: string;
  kind: "personnalisableGuidelines";
  description?: HTMLString;
  richDescription?: HTMLString;
  employee: {
    title: string;
    fields: Array<PersonalisableFields>;
  };
  manager: {
    title: string;
    fields: Array<PersonalisableFields>;
  };
  title?: string;
}

export interface InterviewPersonalisableGuidelinesWithData
  extends InterviewPersonalisableGuidelines {
  data: {
    employee: Array<PersonalisableFieldsWithData>;
    manager: Array<PersonalisableFieldsWithData>;
  };
}

export interface InterviewPageTitle {
  uuid: string;
  kind: "title";
  variant: "H1" | "H2" | "H3" | "H4";
  title: string;
}

export interface InterviewPageHTML {
  uuid: string;
  kind: "html";
  variant?: "info" | "warning" | "error";
  content: HTMLString;
}

export interface InterviewUserSpecificData {
  uuid: string;
  kind: "userSpecificData";
  userSpecificDataKey: string;
}

export interface InterviewSkills {
  uuid: string;
  kind: "skills";
  description?: HTMLString;
  disallow?: {
    [role in InterviewActiveAttendeeRoles]?: {
      evaluation?: boolean;
      comment?: boolean;
      commentEvaluation?: boolean;
    };
  };
  groupByCategoryUuid?: string;
  categoryValuesOrder?: string[];
}

export interface InterviewTrainingsCollection {
  uuid: string;
  kind: "trainingsCollection";
  disallow?: {
    employee?: {
      comment?: boolean;
      requestTraining?: boolean;
    };
    manager?: {
      comment?: boolean;
      requestTraining?: boolean;
    };
  };
}

export type InterviewJob = {
  uuid: string;
  kind: "job";
  title?: string;
  disallow?: {
    employee?: {
      comment?: boolean;
    };
    manager?: {
      comment?: boolean;
    };
  };
};

export interface InterviewFileUpload {
  uuid: string;
  kind: "upload";
  disallow?: {
    [role in InterviewActiveAttendeeRoles]?: {
      upload?: boolean;
      comment?: boolean;
    };
  };
}

export type InterviewPageChild = (
  | InterviewGuidelines
  | InterviewPersonalisableGuidelines
  | InterviewPageTitle
  | InterviewPageHTML
  | InterviewUserSpecificData
  | InterviewSkills
  | InterviewsHistory
  | InterviewQuestion
  | Targets
  | GlobalTargetsCompletion
  | InterviewConclusion
  | TrainingsHistory
  | InterviewTable
  | InterviewTrainingsCollection
  | InterviewFileUpload
  | InterviewJob
) &
  InterviewPageChildCommonKeys;

export interface InterviewSectionPage {
  uuid?: string;
  title?: string;
  hideInBuilder?: true;
  children: InterviewPageChild[];
}

type InterviewType = "Entretien annuel" | "Entretien professionnel" | "Bilan à 6 ans" | "Autre";

export interface InterviewsHistory {
  uuid: string;
  kind: "interviewsHistory";
  maxAge?: number;
  ageUnit?: "years" | "months";
  disallow?: {
    employee?: {
      comment?: boolean;
      downloadPDF?: boolean;
    };
    manager?: {
      comment?: boolean;
      downloadPDF?: boolean;
    };
  };
  description?: HTMLString;
  types: InterviewType[];
}

export interface InterviewHistoryItem {
  campaignTitle: string;
  interviewEndDate?: ISO8601Date;
  uuid: string;
  isPDFAvailable: boolean;
  dateFormat?: {
    day?: "numeric";
    month?: "numeric";
    year?: "numeric";
  };
  manager: Omit<InterviewUser, "state" | "completion">;
}

export type InterviewsHistoryWithItems = InterviewsHistory & {
  data: {
    history: InterviewHistoryItem[];
  } & {
    [role in InterviewActiveAttendeeRoles]?: {
      comment?: QuestionReply<string>;
    };
  };
};

export type InterviewSkillsWithItems = InterviewSkills & {
  jobUuid: string;
  data: {
    [role in InterviewActiveAttendeeRoles]?: {
      comment?: QuestionReply<string>;
    };
  };
};

export type TargetDisallow = {
  [role in InterviewActiveAttendeeRoles]?: Disallow[InterviewRoles.EMPLOYEE] & {
    // same for both roles
    creation?: boolean;
    deletion?: boolean;
    evaluation?: boolean;
    edition?: boolean;
    commentTarget?: boolean;
  };
};

export type TemplateTargetCategory = {
  uuid: string;
  label: string;
};

type ReviewType = "evaluation" | "comment";

type TargetType = "current" | "next";
export interface Targets {
  uuid: string;
  kind: "targets";
  type: TargetType;
  description?: string;
  disallow?: TargetDisallow;
  choices?: string[];
  creationCategories?: string[]; // [TCY-1] legacy doc
  creationTargetCategories?: TemplateTargetCategory[];
  displayCategories?: string[]; // [TCY-1] legacy doc
  displayTargetCategories?: TemplateTargetCategory[];
  restrictScaleTo?: "ordinal" | "numeric";
  disableGoalSelection?: boolean;
  hidePeriod?: boolean;
  hideWeight?: boolean;
  targetNamePlaceholder?: string;
  targetDescriptionPlaceholder?: string;
  reviewType?: ReviewType;
  obsoletedAt?: ISO8601Date;
}

export interface GlobalTargetsCompletion {
  uuid: string;
  kind: "globalTargetsCompletion";
  forcePercentWeight?: boolean;
  required?: boolean;
  disallow?: Disallow;
}

export type TargetState = "future" | "to-review";

export interface Target {
  uuid: string;
  name: string;
  description?: string;
  generatedBy?: InterviewActiveAttendeeRoles;
  period?: {
    startDate: ISO8601Date;
    endDate: ISO8601Date;
  };
  completion?: {
    employee?: Partial<Completion>;
    manager?: Partial<Completion>;
  };
  weight?: number;
  isWeightAutoCalculated?: boolean;
  category: string;
  activities?: TargetActivity[];
}

export interface OrdinalTarget extends Target {
  scale: "ordinal";
  choices: string[];
  completionRate?: number | undefined;
}

export interface NumericTarget extends Target {
  scale: "numeric";
  unit: string;
  goal?: number;
}

export interface NumericTargetCreationData
  extends Omit<NumericTarget, "uuid" | "completion" | "category"> {
  state: TargetState;
  category?: string;
}

export interface OrdinalTargetCreationData
  extends Omit<Target, "uuid" | "completion" | "category"> {
  scale: "ordinal";
  state: TargetState;
  category?: string; // [TCY-1] linked to edit of targets in espace collab ?
}

export type TargetCreationData = NumericTargetCreationData | OrdinalTargetCreationData;

export type TargetComment = {
  comment: string;
};

export type TargetItem = (OrdinalTarget | NumericTarget) & {
  data?: {
    [role in InterviewActiveAttendeeRoles]?: {
      comment?: QuestionReply<string>;
    };
  };
};

export type TargetWithCompletion = TargetItem & {
  completionRate?: number;
  readOnly: boolean;
  invalidRate?: boolean;
  invalidWeight?: boolean;
};

export interface TargetActivity {
  context: {
    campaignName: string;
    interviewUuid: string;
    signedAt: string | null;
  };
  logs: TargetLog[];
}

export type TargetHistory = Partial<Omit<TargetItem, "logs" | "data">>;

export type TargetLogAuthor = {
  fullName: string;
  initials: string;
  role: InterviewActiveAttendeeRoles;
};

export interface TargetLog {
  createdAt: string;
  type: "create" | "update" | "comment" | "evaluation";
  author: TargetLogAuthor;
  data: { before: TargetHistory; after: TargetHistory } | TargetHistory | string | null;
}

export type TargetGroup = {
  category: string;
  targets: TargetWithCompletion[];
  total: {
    weight: number;
    sum: number;
    avg?: number;
  };
};

export type TargetGroups = {
  groups: TargetGroup[];
  displayGlobalCompletion: boolean;
  total: {
    weight: number;
    sum: number;
    avg?: number;
  };
  errors: {
    invalidRate: boolean;
    invalidWeight: boolean;
  };
};

export type TargetsWithItems = Targets & {
  data: {
    targets: TargetItem[];
  } & {
    [role in InterviewActiveAttendeeRoles]?: {
      comment?: QuestionReply<string>;
    };
  };
};
export type GlobalTargetsCompletionWithItems = GlobalTargetsCompletion & {
  data: {
    [role in InterviewActiveAttendeeRoles]?: {
      comment?: QuestionReply<string>;
    };
  };
  completionTable: TargetGroups;
};

export interface InterviewConclusion {
  kind: "conclusion";
  title?: string;
  uuid: string;
}

export type TableCellValue = string | number | ISO8601Date | null;

// Fix values, allow fixing values of table's rows
export type TableColumnFixed = {
  type: "fixed";
  values: TableCellValue[];
};

// Cells are dropdown
export type TableColumnDropdown = {
  type: "dropdown";
  choices: string[];
  defaultValue?: string; // Must be one of the choices
  id?: string;
};

// Classic excel-like cells
export type TableColumnText = {
  type: "text";
  placeholder?: string;
  defaultValue?: string;
};

export type TableColumn = {
  title: string;
  width?: number; // pixels;
  uuid: string;
} & (TableColumnFixed | TableColumnDropdown | TableColumnText) & {
    disallow?: {
      [role in InterviewActiveAttendeeRoles]?: {
        reply?: boolean;
      };
    };
  };

export interface InterviewTable {
  uuid: string;
  kind: "table";
  columns: TableColumn[];
  rowsCount: number;
  disallow?: {
    [role in InterviewActiveAttendeeRoles]: {
      comment?: boolean;
    };
  };
  label?: string;
  description?: HTMLString;
}

export type TableWithData = InterviewTable & {
  data: {
    tableData: TableCellValue[][];
  } & {
    [role in InterviewActiveAttendeeRoles]?: {
      comment?: QuestionReply<string>;
    };
  };
};

export interface TrainingsHistoryRow {
  name: string;
  trainingOrganization: string;
  mode: string;
  duration: string;
  certification: string | null;
  startDate?: ISO8601Date;
  endDate?: ISO8601Date;
  year: number;
}

export interface TrainingsHistory {
  uuid: string;
  kind: "trainingsHistory";
  maxAge: number;
  ageUnit: "years" | "months";
  disallow?: Disallow;
  description?: HTMLString;
}

export interface TrainingsHistoryWithItems {
  kind: "trainingsHistory";
  data: {
    history: TrainingsHistoryRow[];
  } & {
    [role in InterviewActiveAttendeeRoles]?: {
      comment?: QuestionReply<string>;
    };
  };
}

export interface InterviewTrainingsCollectionWithData extends InterviewTrainingsCollection {
  data: {
    employee?: {
      comment?: QuestionReply<string>;
    };
    manager?: {
      comment?: QuestionReply<string>;
    };
    requests: Array<ICollabRowForManager | ICollabRowForEmployee | ICollabRowForHR>;
  };
}

export interface CampaignTemplate {
  uuid: string;
  title: string;
  companyUuid?: string;
  sections: InterviewSection[];
  choicesForOrdinalTargets?: string[]; // [TCY-1] legacy doc
  targetCategories?: TemplateTargetCategory[];
  targetsCategories?: string[];
  type: InterviewType;
}

export type InterviewFileUploadWithData = InterviewFileUpload & {
  data: {
    employee?: {
      comment?: QuestionReply<string>;
    };
    manager?: {
      comment?: QuestionReply<string>;
    };
    files: {
      name: string;
      key: string;
      uuid: string;
      uploadedByAttendee: InterviewActiveAttendeeRoles;
    }[];
  };
};

export type InterviewJobWithData = InterviewJob & {
  data: {
    employee?: {
      comment?: QuestionReply<string>;
    };
    manager?: {
      comment?: QuestionReply<string>;
    };
    description: string;
    jobTitle: string;
  };
};

export type InterviewPageChildWithData = (
  | InterviewGuidelinesWithData
  | InterviewPersonalisableGuidelinesWithData
  | InterviewPageTitle
  | InterviewPageHTML
  | InterviewUserSpecificData
  | InterviewSkillsWithItems
  | InterviewsHistoryWithItems
  | QuestionWithReplies
  | TargetsWithItems
  | GlobalTargetsCompletionWithItems
  | InterviewConclusion
  | TrainingsHistoryWithItems
  | TableWithData
  | InterviewTrainingsCollectionWithData
  | InterviewFileUploadWithData
  | InterviewJobWithData
) &
  InterviewPageChildCommonKeys;

export type CampaignsWithInterviewsDataCall = Array<
  (LegacyCampaign | OngoingCampaign) & {
    interviews: InterviewWithStates[];
  }
>;

export type CampaignInterviewDataCall = (LegacyCampaign | OngoingCampaign) & {
  interview: InterviewWithStates & {
    sections: Array<
      Omit<InterviewSection, "pages"> & {
        pages: Array<
          Omit<InterviewSectionPage, "children"> & {
            children: InterviewPageChildWithData[];
          }
        >;
      }
    >;
  };
};

// Lean types
export type LeanCampaignsWithInterviewsDataCall = Array<{
  title: string;
  uuid: string;
  interviews: InterviewWithStates[];
}>;

export type LeanCampaignInterviewDataCall = {
  title: string;
  uuid: string;
  interview: InterviewWithStates & {
    sections: Array<
      Omit<InterviewSection, "pages"> & {
        pages: Array<
          Omit<InterviewSectionPage, "children"> & {
            children: InterviewPageChildWithData[];
          }
        >;
      }
    >;
  };
};
