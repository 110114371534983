import React, { useCallback, useEffect, useState } from "react";

import {
  type PickerValidDate,
  type DateRange,
  MultiInputTimeRangeField,
  type TimePickerSlotProps,
} from "@mui/x-date-pickers-pro";
import ArrowForward from "@mui/icons-material/ArrowForward";

import TimePicker from "../TimePicker";
import { SkuiSpacing } from "commons/spacing";
import styled from "styled-components";

const Component = styled(MultiInputTimeRangeField)`
  gap: ${SkuiSpacing.xxs};

  > .MuiSvgIcon-root,
  > .MuiFormControl-root {
    margin-left: 0;
  }
`;

export type TimeRangePickerProps = {
  onChange?: (dates: DateRange<PickerValidDate>) => void;
  value?: DateRange<PickerValidDate>;
  disabled?: boolean;
  className?: string;
  slotProps?: TimePickerSlotProps<Date, false> | undefined;
};

const TimeRangePicker = ({
  disabled,
  onChange: onTimeRangePickerChange,
  value = [null, null],
  className,
  slotProps,
}: TimeRangePickerProps) => {
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);

  const [startTime, setStartTime] = useState(value[0]);
  const [endTime, setEndTime] = useState(value[1]);

  useEffect(() => {
    if (onTimeRangePickerChange && (startTime !== value[0] || endTime !== value[1])) {
      onTimeRangePickerChange([startTime, endTime]);
    }
  }, [startTime, endTime]);

  const textField = useCallback(
    (props) => {
      if (props.ownerState.position === "start") {
        return (
          <TimePicker
            {...props.inputProps}
            defaultValue={startTime}
            open={isStartOpen}
            disabled={disabled}
            onClose={() => {
              setStartTime((startTime) => {
                if (startTime) {
                  setIsEndOpen(true);
                }

                return startTime;
              });

              setIsStartOpen(false);
            }}
            onOpen={() => setIsStartOpen(true)}
            onChange={setStartTime}
            slotProps={slotProps}
          />
        );
      }

      return (
        <TimePicker
          {...props.inputProps}
          defaultValue={endTime}
          open={isEndOpen}
          disabled={disabled}
          onClose={() => {
            setIsEndOpen(false);
          }}
          onOpen={() => setIsEndOpen(true)}
          onChange={setEndTime}
          slotProps={slotProps}
        />
      );
    },
    [isStartOpen, isEndOpen, disabled]
  );

  return (
    <Component
      value={value}
      className={className}
      slots={{
        separator: ArrowForward,
        textField,
      }}
    />
  );
};

export default TimeRangePicker;
