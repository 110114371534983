import { type TranslationFunction } from "@skillup/design-system";
import { type PanelConfigType, PanelConfigTypeEnum } from "@skillup/training-bridge";

export const ConfigurationTypeTranslationMap: (t: TranslationFunction) => {
  [type in PanelConfigType]: string;
} = (t) => ({
  [PanelConfigTypeEnum.KEY_INDICATORS]: t(
    "training.dashboard.configuration.panel.type.key-indicator",
    {
      defaultValue: "Indicateurs clés",
    }
  ),
  [PanelConfigTypeEnum.DETAIL_INDICATORS]: t(
    "training.dashboard.configuration.panel.type.detailed-indicator",
    {
      defaultValue: "Indicateurs détaillés",
    }
  ),
  [PanelConfigTypeEnum.SUMMARY]: t("training.dashboard.configuration.panel.type.summary", {
    defaultValue: "Résumé du plan de formation",
  }),
  [PanelConfigTypeEnum.MONTHLY]: t("training.dashboard.configuration.panel.type.monthly", {
    defaultValue: "Dépenses mensuelles",
  }),
  [PanelConfigTypeEnum.SUMMARY_WITH_TAX]: t(
    "training.dashboard.configuration.panel.type.summary-with-tax",
    {
      defaultValue: "Résumé du plan de formation TTC",
    }
  ),
  [PanelConfigTypeEnum.MONTHLY_WITH_TAX]: t(
    "training.dashboard.configuration.panel.type.monthly-with-tax",
    {
      defaultValue: "Dépenses mensuelles TTC",
    }
  ),
});
