import { GridColDef } from "@mui/x-data-grid-pro";

import { DSTooltip } from "@skillup/ui";

import { Review } from "../../../types";

import styles from "../../../components/TalentGrid/TalentGrid.module.scss";

export const coordinatorCommentColDef: GridColDef<Review> = {
  type: "string",
  cellClassName: "fake-editable--cell",
  field: "coordinatorComment",
  filterable: false,
  flex: 1,
  headerName: "Commentaire coordinateur",
  minWidth: 200,
  renderCell: ({ row }) => (
    <DSTooltip
      withPortal
      enabledOnOverflow
      cropLongLabel={false}
      className={styles.tooltip}
      label={row.coordinatorComment ?? ""}
    >
      {row.coordinatorComment ?? ""}
    </DSTooltip>
  ),
  sortable: false,
};
