import { MdDelete as Delete } from "react-icons/md";
import { useCallback, useMemo } from "react";

import { UserRoutes } from "@skillup/espace-rh-bridge";
import {
  Label as DSLabel,
  DSTextArea,
  DSTextInput,
  DSButton,
  Flex,
  Select,
  DSTooltip,
} from "@skillup/ui";

import { useTypedFetch } from "hooks";
import { InterviewRoles } from "types/Interview";

import { BuilderFormInput } from "../../BuilderFormInput";
import type { Child } from "../../../../reducer";
import styles from "./PersonnalizedGuidelines.module.scss";

type PersonnalisableGuidelines = Extract<Child, { kind: "personnalisableGuidelines" }>;

type GuidelinesProps = {
  child: PersonnalisableGuidelines;
  updateStructure: (data: PersonnalisableGuidelines) => void;
};
type PersonnalizedField = PersonnalisableGuidelines[InterviewRoles.EMPLOYEE]["fields"][0];
enum PersonnalizedFieldType {
  UNKNOWN_FIELD = "const",
  KNOWN_FIELD = "field",
}
const PersonnalizedGuidelines = ({ child, updateStructure }: GuidelinesProps) => {
  const { data: userFetchedProperties = [] } = useTypedFetch<UserRoutes.GetUserProperties>({
    method: "GET",
    path: "/user/properties",
  });

  const userProperties = useMemo(() => {
    return userFetchedProperties.map(({ label, field, fieldType }) => ({
      label,
      value: field,
      fieldType,
    }));
  }, [userFetchedProperties]);

  const handleDescriptionChange = useCallback(
    (value: string) => {
      const newData = { ...child, description: undefined, richDescription: value };
      updateStructure(newData);
    },
    [child, updateStructure]
  );

  const handleTitleChange = useCallback(
    (params: { for: "collab" | "manager"; value: string }) => {
      const newData = { ...child };

      if (params.for === "collab") {
        const newEmployee = { ...child.employee, title: params.value };
        newData.employee = newEmployee;
      }
      if (params.for === "manager") {
        const newManager = { ...child.manager, title: params.value };
        newData.manager = newManager;
      }

      updateStructure(newData);
    },
    [child, updateStructure]
  );

  const addPersonnalizedField = useCallback(
    (params: { for: "collab" | "manager"; type: PersonnalizedFieldType }) => {
      if (params.for === "collab") {
        const newFields = [...child.employee.fields];
        if (params.type === PersonnalizedFieldType.UNKNOWN_FIELD) {
          newFields.push({ type: PersonnalizedFieldType.UNKNOWN_FIELD, label: "", value: "" });
        }
        if (params.type === PersonnalizedFieldType.KNOWN_FIELD) {
          newFields.push({
            type: PersonnalizedFieldType.KNOWN_FIELD,
            label: "",
            fieldName: "",
            fieldType: "string",
          });
        }
        updateStructure({
          ...child,
          employee: { ...child.employee, fields: newFields },
        });
      }

      if (params.for === "manager") {
        const newFields = [...child.manager.fields];
        if (params.type === PersonnalizedFieldType.UNKNOWN_FIELD) {
          newFields.push({ type: PersonnalizedFieldType.UNKNOWN_FIELD, label: "", value: "" });
        }
        if (params.type === PersonnalizedFieldType.KNOWN_FIELD) {
          newFields.push({
            type: PersonnalizedFieldType.KNOWN_FIELD,
            label: "",
            fieldName: "",
            fieldType: "string",
          });
        }
        updateStructure({
          ...child,
          manager: { ...child.manager, fields: newFields },
        });
      }
    },
    [child, updateStructure]
  );

  const removePersonnalizedField = useCallback(
    (params: { for: "collab" | "manager"; index: number }) => {
      if (params.for === "collab") {
        const newFields = [...child.employee.fields];
        newFields.splice(params.index, 1);

        updateStructure({
          ...child,
          employee: { ...child.employee, fields: newFields },
        });
      }

      if (params.for === "manager") {
        const newFields = [...child.manager.fields];
        newFields.splice(params.index, 1);

        updateStructure({
          ...child,
          manager: { ...child.manager, fields: newFields },
        });
      }
    },
    [child, updateStructure]
  );

  const handleChangePersonnalizedField = useCallback(
    (params: { for: "collab" | "manager"; indexToUpdate: number; value: PersonnalizedField }) => {
      if (params.for === "collab") {
        const newFields = [...child.employee.fields];

        newFields[params.indexToUpdate] = params.value;
        updateStructure({ ...child, employee: { ...child.employee, fields: newFields } });
      }

      if (params.for === "manager") {
        const newFields = [...child.manager.fields];

        newFields[params.indexToUpdate] = params.value;
        updateStructure({ ...child, manager: { ...child.manager, fields: newFields } });
      }
    },
    [child, updateStructure]
  );
  const onTitleChangeForCollab = useCallback(
    (value: string) => {
      handleTitleChange({ for: "collab", value });
    },
    [handleTitleChange]
  );

  const onTitleChangeForManager = useCallback(
    (value: string) => {
      handleTitleChange({ for: "manager", value });
    },
    [handleTitleChange]
  );

  return (
    <div className={styles.container}>
      <div>
        <DSLabel label="Texte d’introduction" required />
        <DSTextArea
          value={child.richDescription ?? child.description}
          onChange={handleDescriptionChange}
          placeholder={"Introduction"}
          debounceValue={50}
          toolbarButtons={[
            "bold",
            "italic",
            "underline",
            "paragraphFormat",
            "|",
            "textColor",
            "backgroundColor",
            "|",
            "insertLink",
            "insertVideo",
            "|",
            "formatUL",
            "formatOL",
            "|",
            "outdent",
            "indent",
            "|",
            "alignLeft",
            "alignCenter",
            "alignJustify",
            "|",
            "selectAll",
            "clearFormatting",
            "|",
            "help",
            "html",
            "|",
            "undo",
            "redo",
          ]}
        />
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.subSection}>
          <div className={styles.labelCollab}>
            <BuilderFormInput
              type="text"
              value={child.employee.title}
              onChange={onTitleChangeForCollab}
              debounceValue={300}
              label="Libellé du bloc Collaborateur"
              name={"collab_label"}
              placeholder="Collaborateur"
              required
            />
          </div>
          <div className={styles.dataCollab}>
            <div>
              <DSLabel label="Données à afficher pour le collaborateur" required />
              {child.employee.fields.map((field, index) => (
                <PersonalisableFieldBlock
                  key={index}
                  value={field}
                  onChange={(value) =>
                    handleChangePersonnalizedField({ for: "collab", indexToUpdate: index, value })
                  }
                  onDelete={() => removePersonnalizedField({ for: "collab", index })}
                  userProperties={userProperties}
                />
              ))}
            </div>
            <div className={styles.buttons}>
              <DSButton
                label={"Ajouter une donnée propre au collaborateur"}
                emphasis="Low"
                buttonSize="S"
                className={styles.firstButton}
                onClick={() =>
                  addPersonnalizedField({ for: "collab", type: PersonnalizedFieldType.KNOWN_FIELD })
                }
                disabled // [CSB] permission changes disabled
                tooltip="Cet élément ne peut pas être modifié sur une campagne en cours." // [CSB] permission changes disabled
              />
              <DSButton
                label={"Ajouter une donnée générique"}
                emphasis="Low"
                buttonSize="S"
                className={styles.button}
                onClick={() =>
                  addPersonnalizedField({
                    for: "collab",
                    type: PersonnalizedFieldType.UNKNOWN_FIELD,
                  })
                }
                disabled // [CSB] permission changes disabled
                tooltip="Cet élément ne peut pas être modifié sur une campagne en cours." // [CSB] permission changes disabled
              />
            </div>
          </div>
        </div>
        <div className={styles.subSection}>
          <div className={styles.labelCollab}>
            <BuilderFormInput
              value={child.manager.title}
              onChange={onTitleChangeForManager}
              debounceValue={300}
              label="Libellé du bloc Responsable de l'entretien"
              name={"manager_label"}
              placeholder="Responsable de l'entretien"
              required
            />
          </div>
          <div className={styles.dataCollab}>
            <div>
              <DSLabel label="Données à afficher pour le responsable de l’entretien" required />
              {child.manager.fields.map((field, index) => (
                <PersonalisableFieldBlock
                  key={index}
                  value={field}
                  onChange={(value) =>
                    handleChangePersonnalizedField({ for: "manager", indexToUpdate: index, value })
                  }
                  onDelete={() => removePersonnalizedField({ for: "manager", index })}
                  userProperties={userProperties}
                />
              ))}
            </div>
            <div className={styles.buttons}>
              <DSButton
                label={"Ajouter une donnée propre au responsable de l’entretien"}
                emphasis="Low"
                buttonSize="S"
                className={styles.firstButton}
                onClick={() =>
                  addPersonnalizedField({
                    for: "manager",
                    type: PersonnalizedFieldType.KNOWN_FIELD,
                  })
                }
                disabled // [CSB] permission changes disabled
                tooltip="Cet élément ne peut pas être modifié sur une campagne en cours." // [CSB] permission changes disabled
              />
              <DSButton
                label={"Ajouter une donnée générique"}
                emphasis="Low"
                buttonSize="S"
                className={styles.button}
                onClick={() =>
                  addPersonnalizedField({
                    for: "manager",
                    type: PersonnalizedFieldType.UNKNOWN_FIELD,
                  })
                }
                disabled // [CSB] permission changes disabled
                tooltip="Cet élément ne peut pas être modifié sur une campagne en cours." // [CSB] permission changes disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PersonnalizedGuidelines };

type PersonalisableFieldBlockProps = {
  value: PersonnalizedField;
  userProperties: Array<{
    label: string;
    value: string;
    fieldType: "date" | "string" | "number" | "monetary" | "boolean";
  }>;
  onChange: (value: PersonnalizedField) => void;
  onDelete: () => void;
};

const PersonalisableFieldBlock = ({
  value,
  userProperties,
  onChange,
  onDelete,
}: PersonalisableFieldBlockProps) => {
  const handleChange = useCallback(
    (params: { key: "label" | "value"; value: string }) => {
      const newValue = { ...value };
      newValue[params.key] = params.value;
      onChange(newValue);
    },
    [value, onChange]
  );

  const handleChangeFromUserProperties = useCallback(
    (fieldNameValue: string) => {
      const { value: fieldName, fieldType } = userProperties.find(
        (e) => e.value === fieldNameValue
      ) ?? {
        fieldName: "",
        fieldType: "string",
      };
      const newValue = { ...value, fieldName, fieldType };
      onChange(newValue);
    },
    [value, userProperties, onChange]
  );

  return (
    <Flex className={styles.personalisableFieldBlock}>
      <DSTooltip
        /* [CSB] permission changes disabled */
        label={"Cet élément ne peut pas être modifié sur une campagne en cours."}
        className={styles.fieldBlockTooltip}
      >
        {value.type === "const" && (
          <>
            <DSTextInput
              name={`label_${value.label}`}
              className={styles.labelInput}
              value={value.label}
              placeholder="Libellé du champ"
              onChange={(e) => handleChange({ key: "label", value: e })}
              disabled // [CSB] permission changes disabled
            />
            <DSTextInput
              name={`label_${value.value}`}
              className={styles.labelInput}
              value={value.value}
              placeholder="Libellé du champ"
              onChange={(e) => handleChange({ key: "value", value: e })}
              disabled // [CSB] permission changes disabled
            />
          </>
        )}
        {value.type === "field" && (
          <>
            <DSTextInput
              name={`label_${value.label}`}
              className={styles.labelInput}
              value={value.label}
              placeholder="Libellé du champ"
              onChange={(e) => handleChange({ key: "label", value: e })}
              disabled // [CSB] permission changes disabled
            />
            <Select
              placeholder="Champ de User"
              className={styles.labelInput}
              options={userProperties}
              onChange={handleChangeFromUserProperties}
              value={value.fieldName}
              disabled // [CSB] permission changes disabled
            />
          </>
        )}

        <div className={styles.delete} aria-label="Supprimer ligne">
          <DSButton
            iconOnly
            icon={<Delete />}
            onClick={onDelete}
            disabled // [CSB] permission changes disabled
          />
        </div>
      </DSTooltip>
    </Flex>
  );
};
