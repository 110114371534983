import { Either } from "@skillup/monads";
import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export async function setNonArbitratedReviewsAsDone(params: { campaignID: string }) {
  try {
    const request = buildRequest<CampaignsRoutes.SetNonArbitratedReviewsAsDone>({
      method: "PUT",
      params: {
        campaignID: params.campaignID,
      },
      path: "/campaign/{campaignID}/set-non-arbitrated-reviews-as-done",
      target: "PEOPLE_REVIEW",
    });

    await request();
    return Either.right(undefined);
  } catch (err) {
    return Either.left(err);
  }
}
