import { buildRequest } from "utils/buildRequest";

import { ListUtils } from "@skillup/shared-utils";
import { FilterProp } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";
import type {
  GetDashboardConfigRouteType,
  GetDashboardFiltersRouteType,
  GetDashboardQuestionDataRouteType,
  QuestionData,
  DashboardCardData,
  RefreshDataRouteType,
  GetFreshnessDataRouteType,
} from "../types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { saveDefaultDashboardConfiguration } from "./config";

export const fetchDashboardPanels = async (retry = false) => {
  try {
    const layout = await buildRequest<GetDashboardConfigRouteType>({
      method: "GET",
      path: "/config",
      target: "INSIGHTS_SERVICE_V2",
    })();

    return layout.data.attributes.dashboards.find((dashboard) =>
      dashboard.feature_tags.includes("training")
    )?.panels;
  } catch {
    if (retry) {
      throw new Error("Failed to fetch dashboard panels");
    }

    await saveDefaultDashboardConfiguration();

    return fetchDashboardPanels(true);
  }
};

export type FilterConfigType = {
  config: ListUtils.FilterConfigurationMap;
  props: FilterProp<any>;
};

export const getFiltersConfig = async (
  activeScheduleUuid: string,
  t: TranslationType
): Promise<FilterConfigType> => {
  const filters = await buildRequest<GetDashboardFiltersRouteType>({
    method: "GET",
    path: "/config/dashboards_filters",
    target: "INSIGHTS_SERVICE_V2",
  })();

  const dashboard = filters.data.attributes.dashboards.find((dashboard) =>
    dashboard.feature_tags.includes("training")
  );

  return dashboard.filters.reduce(
    (acc, filter) => {
      const ignoredFilters = [];
      const isPlanFilter = filter.id === "filter_plan_uuid_select";
      const isStatusFilter = filter.id === "filter_plan_line_completion_phase_multi_select";

      if (ignoredFilters.includes(filter.id)) {
        return acc;
      }

      if (filter.fingerprint.distinct_values === null) {
        return acc;
      }

      acc.config[filter.id] = {
        type: isPlanFilter ? ListUtils.FilterType.SINGLESELECT : ListUtils.FilterType.MULTISELECT,
      };

      let defaultValue: string[] | string | undefined;
      let sortPosition: number | undefined;
      if (isPlanFilter) {
        defaultValue = activeScheduleUuid;
        sortPosition = 0;
      }

      if (isStatusFilter) {
        defaultValue = filter.fingerprint.distinct_values.filter(
          (option) =>
            option === null || (!option.startsWith("collection-") && option !== "plan-denied")
        );
        sortPosition = 1;
      }

      const storedFilters = getLocalStorageFilters();
      const value = storedFilters?.[filter.id]?.value;

      acc.props[filter.id] = {
        label: t(`trainings.view.dashboard.filter_label.${filter.field_id}`, {
          defaultValue: filter.axis_short_name,
        }),
        type: acc.config[filter.id].type,
        operator: isPlanFilter
          ? ListUtils.FilterOperator.EQUALS
          : ListUtils.FilterOperator.CONTAINS,
        value,
        sortPosition,
        options: filter.axis_value_bindings
          ? // TODO: everything should be in thie axis value bindings and not in the fingerprint
            Object.entries(filter.axis_value_bindings ?? {})
              .filter(([key]) => !isStatusFilter || !key.startsWith("collection-"))
              .map(([key, value]) => ({
                label:
                  value === null
                    ? t("trainings.view.dashboard.empty_label", { defaultValue: "N/A" })
                    : isStatusFilter
                      ? t(value)
                      : value,
                value: key === null ? "empty_value" : key,
              }))
          : filter.fingerprint.distinct_values
              .filter(
                (option) => !isStatusFilter || option === null || !option.startsWith("collection-")
              )
              .map((option) => ({
                label:
                  option === null
                    ? t("trainings.view.dashboard.empty_label", { defaultValue: "N/A" })
                    : option,
                value: option === null ? "empty_value" : option,
              })),
        defaultValue,
      };

      return acc;
    },
    { props: {}, config: {} } as FilterConfigType
  );
};

export const getCardData = async (
  card: DashboardCardData,
  filters: ListUtils.FilterValues<any>
): Promise<QuestionData> => {
  const getValue = (value: string | string[] | undefined) => {
    if (Array.isArray(value)) {
      return value.map((v) => getValue(v));
    }

    if (value === "empty_value") {
      return "";
    }

    return value;
  };

  const filtersObject = Object.keys(filters ?? {})
    .filter((key) => filters[key]?.value && card.filters.includes(key))
    .reduce((acc, key) => {
      if (filters[key].type === ListUtils.FilterType.SINGLESELECT) {
        return { ...acc, [key]: [getValue(filters[key].value)] };
      }

      return { ...acc, [key]: getValue(filters[key].value) };
    }, {});

  const data = await buildRequest<GetDashboardQuestionDataRouteType>({
    method: "POST",
    path: `/indicator/{questionName}`,
    params: { questionName: card.question_meta.id },
    payload: {
      filter: filtersObject,
    },
    target: "INSIGHTS_SERVICE_V2",
  })();

  return data.data[0]?.attributes;
};

const getLocalStorageFilters = () => {
  const filters = JSON.parse(localStorage.getItem("filters"));
  if (filters && Object.keys(filters).length === 0) {
    return null;
  }
  return filters;
};

export const refreshRequest = buildRequest<RefreshDataRouteType>({
  target: "INSIGHTS_SERVICE_V2",
  method: "GET",
  path: "/feeding-pipeline/refresh",
});
export const freshnessRequest = buildRequest<GetFreshnessDataRouteType>({
  target: "INSIGHTS_SERVICE_V2",
  method: "GET",
  path: "/feeding-pipeline/freshness",
});

export const useDashboardFreshness = () =>
  useQuery(
    ["dashboard_trainings_freshness"],
    async () => {
      try {
        const data = await freshnessRequest();

        return data.data[0].attributes;
      } catch (e) {
        console.error(e);

        return undefined;
      }
    },
    { refetchInterval: 60000 }
  );

export const useRequestRefresh = () => {
  const queryClient = useQueryClient();

  return async () => {
    await refreshRequest();

    await queryClient.invalidateQueries(["dashboard_trainings_freshness"]);
  };
};
