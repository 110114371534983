import styled from "styled-components";

import { Flex, DSColor } from "@skillup/design-system";
import { DSTooltip, EspaceFont, SkuiSpacing } from "@skillup/ui";

export const HeaderSection = styled(Flex)`
  border-bottom: 0.5rem solid ${DSColor["decoration-yellow-base"]};
`;

export const Link = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;

  > svg {
    width: 16px;
    margin-left: ${SkuiSpacing.xs};
  }
`;

export const KPIContainer = styled.div`
  margin: ${SkuiSpacing.m} 0;
  flex-flow: row wrap;
  gap: ${SkuiSpacing.s};
  grid-template-columns: repeat(auto-fit, minmax(min(100%, max(12.5rem, 100%/5)), 1fr));
  display: grid;
`;

export const RadarContainer = styled(Flex)`
  flex: 1;
  margin-top: ${SkuiSpacing.s};
  flex-direction: column;
  border-radius: ${SkuiSpacing.s};
  justify-content: space-between;
  ${EspaceFont.rh.captionRegular};
`;

export const StyledTooltip = styled(DSTooltip)`
  width: fit-content;
`;
