import { useSetState } from "react-use";
import { get } from "lodash";

import Header from "./components/Header";
import FooterNav from "./components/FooterNav";
import { Step } from "./models/Step";

import DSLayout from "components/DSLayout";
import { generatePath } from "react-router-dom";
import styles from "./CreateNewInterviewCampaign.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  readonly steps: Step[];
  readonly title: string;

  readonly sendButtonLabel: string;
  readonly createNewCampaign: (toggleLoading: (nextValue?: boolean) => void) => Promise<void>;
  readonly canCreateCampaign?: boolean;
}

interface State {
  currentStep: number;
}

const CreateFormInterviewWrapper = ({
  steps,
  sendButtonLabel,
  title,
  createNewCampaign,
  canCreateCampaign,
}: Props): JSX.Element => {
  const [state, setState] = useSetState<State>({
    currentStep: 0,
  });

  const { currentStep } = state;

  const { t } = useTranslation();

  return (
    <DSLayout
      title={title}
      parent={{
        title: t("interviews.title", {
          defaultValue: "Entretiens",
        }),
        titleUrl: generatePath("/responsable/campagnes"),
      }}
    >
      <div className={styles.CreateNewCampaign}>
        <Header
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={(currentStep) => setState({ currentStep })}
        />
        <main className={styles[steps[currentStep].id]}>
          {get(steps[currentStep], "component", null)}
          <FooterNav
            currentStep={currentStep}
            setCurrentStep={(currentStep) => setState({ currentStep })}
            steps={steps}
            createNewCampaign={createNewCampaign}
            sendButtonLabel={sendButtonLabel}
            canCreateCampaign={canCreateCampaign}
          />
        </main>
      </div>
    </DSLayout>
  );
};

export default CreateFormInterviewWrapper;
