import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";

import { MdExpandMore as ExpandMore } from "react-icons/md";
import { MdExpandLess as ExpandLess } from "react-icons/md";
import { MdTipsAndUpdates as TipsAndUpdates } from "react-icons/md";

import { DSColor, generateModalContext } from "@skillup/ui";
import { Text, Flex, Title, Loader } from "@skillup/design-system";

import DSLayout from "components/DSLayout";
import type { SuggestionsJob } from "types/skills";

import SuggestionCard from "./components/SuggestionCard";
import ApproveSuggestionModal from "./components/ApproveSuggestionModal";
import { SuggestionsProvider, useSuggestionsContext } from "./SuggestionsContext";
import { Container, CardContainer, ExpandDeclinedButton } from "./Suggestions.styled";

const Suggestions = () => {
  return (
    <SuggestionsProvider>
      <Layout />
    </SuggestionsProvider>
  );
};

const Layout = () => {
  const { t } = useTranslation();
  const [showDeclined, setShowDeclined] = useState(false);
  const [selectedRole, setSelectedRole] = useState<null | string>(null);
  const [selectedEmployees, setSelectedEmployees] = useState<SuggestionsJob["employees"]>([]);
  const toggleShowDeclined = () => {
    setShowDeclined(!showDeclined);
  };

  const useModal = generateModalContext();
  const {
    closeModal: closeApproveModal,
    isOpen: isModalOpen,
    openModal: openApproveModalWithContext,
  } = useModal(false);

  const openApproveModal = (role: string, employees: SuggestionsJob["employees"]) => {
    setSelectedRole(role);
    setSelectedEmployees(employees);
    openApproveModalWithContext();
  };

  const closeApproveModalHandler = () => {
    setSelectedRole(null);
    closeApproveModal();
  };

  const suggestionsJobs = useSuggestionsContext();
  const { data: pendingSuggestionsRaw, status: pendingStatus } =
    suggestionsJobs.getJobSuggestions("pending");
  const { data: cancelledSuggestions, status: cancelledStatus } =
    suggestionsJobs.getJobSuggestions("cancelled");
  const { data: jobList } = suggestionsJobs.getJobs();
  const collaboratorCount = pendingSuggestionsRaw?.reduce(
    (acc, { employees }) => acc + employees.length,
    0
  );
  const declinedCount = cancelledSuggestions?.length;

  if (pendingStatus === "loading" || cancelledStatus === "loading") {
    return <Loader fillSpace />;
  }
  const pendingSuggestions = pendingSuggestionsRaw?.sort((a, b) => {
    return b.employees.length - a.employees.length;
  });

  return (
    <DSLayout
      title={t("skills.suggestions.layout.name", {
        defaultValue: "Suggestions d'association de fiches de poste",
      })}
      parent={{
        title: t("skills.title.skills", {
          defaultValue: "Compétences",
        }),
        titleUrl: generatePath("/responsable/referentiels/compétences"),
      }}
    >
      <Flex width="100%" alignItems="center" flexDirection="column">
        <Container>
          <Flex width="100%" flexDirection="column">
            <Flex alignItems="center">
              <TipsAndUpdates size="1.5rem" color={DSColor["plainText-onLight-lighter"]} />
              <Title h4 marginLeft="xs" color="plainText-onLight-lighter">
                {collaboratorCount > 1
                  ? t("skills.suggestions.title.plurial", {
                      collaboratorCount,
                      defaultValue:
                        "Suggestions d'association pour {{collaboratorCount}} collaborateurs sans fiche de poste",
                    })
                  : t("skills.suggestions.title.single", {
                      defaultValue: `Suggestions d'association pour ${collaboratorCount} collaborateur sans fiche de poste`,
                    })}
              </Title>
            </Flex>
            <Text espaceFont="body2Regular" color="plainText-onLight-lighter">
              {t("skills.suggestions.info", {
                defaultValue: "Suggestions regroupées en fonction du rôle des collaborateurs",
              })}
            </Text>
          </Flex>
          <CardContainer>
            {pendingSuggestions?.map(
              ({ employees, mostRelevantJobId, mostRelevantJobLabel, role }) => (
                <SuggestionCard
                  key={role}
                  role={role}
                  employees={employees}
                  openModal={openApproveModal}
                  mostRelevantJobId={mostRelevantJobId}
                  mostRelevantJobLabel={mostRelevantJobLabel}
                />
              )
            )}
          </CardContainer>
          {declinedCount > 0 && (
            <>
              <ExpandDeclinedButton onClick={toggleShowDeclined}>
                {showDeclined ? (
                  <ExpandLess size="1.5rem" color={DSColor["action-onLight-darkest"]} />
                ) : (
                  <ExpandMore size="1.5rem" color={DSColor["action-onLight-darkest"]} />
                )}
                <Text marginLeft="xxs" espaceFont="body2Regular" color="action-onLight-darkest">
                  {declinedCount > 1
                    ? t("skills.suggestions.showMore.plurial", {
                        declinedCount,
                        defaultValue: "{{declinedCount}} suggestions refusées",
                      })
                    : t("skills.suggestions.showMore.single", {
                        defaultValue: "1 suggestion refusée",
                      })}
                </Text>
              </ExpandDeclinedButton>
              {showDeclined && (
                <>
                  <CardContainer>
                    {cancelledSuggestions?.map(
                      ({ employees, mostRelevantJobId, mostRelevantJobLabel, role }) => {
                        return (
                          <SuggestionCard
                            key={role}
                            isDeclined
                            role={role}
                            employees={employees}
                            openModal={openApproveModal}
                            mostRelevantJobId={mostRelevantJobId}
                            mostRelevantJobLabel={mostRelevantJobLabel}
                          />
                        );
                      }
                    )}
                  </CardContainer>
                </>
              )}
            </>
          )}
          {isModalOpen && selectedRole && (
            <ApproveSuggestionModal
              role={selectedRole}
              isOpen={isModalOpen}
              employees={selectedEmployees}
              closeModal={closeApproveModalHandler}
              jobList={jobList.map((job) => ({
                label: job.name,
                value: job.uuid,
              }))}
            />
          )}
        </Container>
      </Flex>
    </DSLayout>
  );
};

export default Suggestions;
