import { useState } from "react";
import { useTranslation } from "react-i18next";

import LoadingIA from "assets/skill/loading_ia.gif";

import { SkillAI } from "@skillup/gepp";
import { Flex, Text } from "@skillup/design-system";
import { DSModal, AssistiveArea } from "@skillup/ui";

import { trpc } from "utils/trpc";
import { SelectedSkills } from "types/skills";
 
import { Checkbox, AiJobModal, LoadingImage } from "./GenerateJobDataIA.styled";

interface GenerateJobDataIAModalProps {
  open: boolean;
  jobName: string;
  close: () => void;
  onConfirm: (selectedAISkills: SelectedSkills, selectedSkills: SelectedSkills) => void;
}

interface SkillSortedByAI {
  uuid: string;
  score: number;
  message: string;
}

const MAX_SKILLS_DISPLAYED = 10;

const GenerateJobDataIAModal = ({
  close,
  jobName,
  onConfirm,
  open,
}: GenerateJobDataIAModalProps) => {
  const { t } = useTranslation();

  const [selectedAISkills, setSelectedAISkills] = useState<SelectedSkills>({});
  const [selectedSkills, setSelectedSkills] = useState<SelectedSkills>({});

  const {
    data: sortedSkills,
    isLoading: getSortedLoading,
    status: getSortedStatus,
  } = trpc.jobWithAi.getSortedSkillsByJob.useQuery({ jobName }, { refetchOnWindowFocus: false });
  const {
    data: generatedSkills,
    isLoading: generateSkillsLoading,
    status: generateStatus,
  } = trpc.jobWithAi.generateListOfSkills.useQuery({ jobName }, { refetchOnWindowFocus: false });

  if (getSortedStatus === "error" || generateStatus === "error") {
    return (
      <DSModal isOpen={open} portalTarget={document.body}>
        <DSModal.Header onClose={() => close()}>
          <DSModal.Header.Title
            title={t("jobs.generateDataIa.title", {
              defaultValue: "Génération via IA",
            })}
          />
        </DSModal.Header>
        <DSModal.Content>
          <Flex alignItems="center" flexDirection="column">
            <Text>
              {t("jobs.generateDataIa.errorText", {
                defaultValue:
                  "Une erreur est survenue lors de la génération des données. Veuillez réessayer ultérieurement.",
              })}
            </Text>
          </Flex>
        </DSModal.Content>
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            onClick={() => close()}
            label={t("jobs.generateDataIa.buttonCancel", {
              defaultValue: "Retour",
            })}
          />
          <DSModal.Footer.PrimaryButton
            disabled={true}
            label={t("jobs.generateDataIa.buttonConfirm", {
              defaultValue: "Générer la fiche de poste",
            })}
          />
        </DSModal.Footer>
      </DSModal>
    );
  }

  const handleAISkillChange = (skill: SkillAI) => (isChecked: boolean) => {
    setSelectedAISkills((prev: SelectedSkills) => {
      const newSkillSelection = { ...prev, [skill.message]: { name: skill.message } };
      if (!isChecked) {
        delete newSkillSelection[skill.message];
      }
      return newSkillSelection;
    });
  };

  const handleSkillChange = (skill: SkillSortedByAI) => (isChecked: boolean) => {
    setSelectedSkills((prev: SelectedSkills) => {
      const newSkillSelection = {
        ...prev,
        [skill.message]: { uuid: skill.uuid, name: skill.message },
      };
      if (!isChecked) {
        delete newSkillSelection[skill.message];
      }
      return newSkillSelection;
    });
  };

  return (
    <AiJobModal isOpen={open} portalTarget={document.body}>
      <DSModal.Header onClose={() => close()}>
        <DSModal.Header.Title
          title={t("jobs.generateDataIa.title", {
            defaultValue: "Génération via IA",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        {getSortedLoading || generateSkillsLoading ? (
          <Flex gap="l" paddingVertical="s" paddingHorizontal="m" flexDirection="column">
            <Flex paddingTop="l" flexDirection="column">
              <LoadingImage src={LoadingIA} alt="chargement des données" />
            </Flex>
            <Flex flexDirection="column">
              <Text espaceFont="body2Regular" color="plainText-onLight-default">
                {t("jobs.generateDataIa.loadingText1", {
                  defaultValue:
                    "Afin de rédiger une fiche de poste la plus pertinente possible, nous allons vous soumettre une liste de compétences issues de votre référentiel et suggérées par l’IA.",
                })}
              </Text>
              <br />
              <Text espaceFont="body2Regular" color="plainText-onLight-default">
                {t("jobs.generateDataIa.loadingText2", {
                  defaultValue:
                    "Sélectionnez celles qui vous semblent importantes pour ce poste afin de guider l’IA dans la rédaction de la fiche de poste.",
                })}
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Flex gap="s" paddingVertical="s" flexDirection="column">
            <Flex flexDirection="column">
              <Text espaceFont="body1Bold" color="plainText-onLight-default">
                {t("jobs.generateDataIa.skillChoiceTitle", {
                  defaultValue:
                    "Parmi ces compétences, lesquelles vous semblent pertinentes pour ce poste ?",
                })}
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Text espaceFont="body2Regular" color="plainText-onLight-lighter">
                {t("jobs.generateDataIa.skillChoiceExplanationText", {
                  defaultValue:
                    "La sélection des compétences à cette étape nous permet de générer une fiche de poste plus pertinente pour votre entreprise.",
                })}
              </Text>
            </Flex>
            <Flex flexDirection="row">
              <Flex flex={"1"} flexBasis={"0px"} flexDirection="column">
                <Flex flexDirection="column">
                  <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                    {t("jobs.generateDataIa.skillSuggestedFromRepository", {
                      defaultValue: "Compétences issues de votre référentiel",
                    })}
                  </Text>
                </Flex>
                <Flex flexDirection="column">
                  {sortedSkills?.length > 0 ? (
                    sortedSkills?.map((sortedSkill, idx) => (
                      <Flex flexDirection="column" key={sortedSkill.uuid}>
                        {idx < MAX_SKILLS_DISPLAYED && (
                          <Checkbox
                            name={sortedSkill.uuid}
                            label={sortedSkill.message}
                            checked={!!selectedSkills[sortedSkill.uuid]}
                            onChange={(isChecked) =>
                              handleSkillChange(sortedSkill as SkillSortedByAI)(isChecked)
                            }
                          />
                        )}
                      </Flex>
                    ))
                  ) : (
                    <AssistiveArea
                      text={t("jobs.generateDataIa.noRelevantSkillFound", {
                        defaultValue:
                          "Aucune compétence de votre référentiel ne semble pertinente dans le cadre de ce poste.",
                      })}
                    ></AssistiveArea>
                  )}
                </Flex>
              </Flex>
              <Flex flex={"1"} flexBasis={"0px"} flexDirection="column">
                <Flex flexDirection="column">
                  <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                    {t("jobs.generateDataIa.skillSuggestedFromAI", {
                      defaultValue: "Compétences suggérées par l’IA",
                    })}
                  </Text>
                </Flex>
                <Flex flexDirection="column">
                  {generatedSkills?.length > 0 ? (
                    generatedSkills?.map((generatedSkill, idx) => (
                      <Flex flexDirection="column" key={generatedSkill.message}>
                        {idx < MAX_SKILLS_DISPLAYED && (
                          <Checkbox
                            name={generatedSkill.message}
                            label={generatedSkill.message}
                            checked={!!selectedAISkills[generatedSkill.message]}
                            onChange={(isChecked) =>
                              handleAISkillChange(generatedSkill as SkillAI)(isChecked)
                            }
                          />
                        )}
                      </Flex>
                    ))
                  ) : (
                    <AssistiveArea
                      text={t("jobs.generateDataIa.noRelevantAiSkillFound", {
                        defaultValue:
                          "L’IA n’a identifiée aucune compétence pertinente pour ce poste.",
                      })}
                    ></AssistiveArea>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={() => close()}
          label={t("jobs.generateDataIa.buttonCancel", {
            defaultValue: "Retour",
          })}
        />
        <DSModal.Footer.PrimaryButton
          disabled={getSortedLoading || generateSkillsLoading}
          onClick={() => onConfirm(selectedAISkills, selectedSkills)}
          label={t("jobs.generateDataIa.buttonConfirm", {
            defaultValue: "Générer la fiche de poste",
          })}
        />
      </DSModal.Footer>
    </AiJobModal>
  );
};

export default GenerateJobDataIAModal;
