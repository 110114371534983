import styled from "styled-components";

import { Flex } from "@skillup/design-system";
import { Select, Switch, DSColor, DSButton, SkuiSpacing } from "@skillup/ui";

export const ModalContainer = styled(Flex)`
  background-color: ${DSColor["surface-light"]};
  flex-direction: column;
  width: 650px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${DSColor["border-onLight"]};
`;

export const SwitchButton = styled(Switch)`
  justify-content: flex-start !important;
  margin: ${SkuiSpacing.s} 0;
`;

export const PopoverContent = styled(Flex)`
  background-color: ${DSColor["surface-light"]};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: ${SkuiSpacing.xs};
  flex-direction: column;
  width: 270px;
  max-height: 350px;
  overflow: hidden;
`;

export const TopShadow = styled.div`
  position: absolute;
  bottom: -4px;
  width: 100%;
  display: flex;
  height: 1px;
  box-shadow: rgba(0, 0, 0, 0.4) -8px -6px 6px;
`;

export const FullSelect = styled(Select)`
  width: 100%;
`;

export const CardContainer = styled(Flex)`
  background-color: ${DSColor["surface-light"]};
  padding: ${SkuiSpacing.s};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 48%;
  justify-content: space-between;
  flex-direction: column;
`;

export const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  weight: 600;
  margin-left: ${SkuiSpacing.m};
  color: ${DSColor["action-onLight-darker"]};

  &:hover {
    text-decoration: underline;
  }
`;

export const RoleContainer = styled(Flex)`
  justify-content: space-between;
  padding: ${SkuiSpacing.s};
  border-radius: ${SkuiSpacing.xxs};
  border: 1px solid #e0e0e0;
  background-color: ${DSColor["surface-light-darker"]};
`;

export const DeclineButton = styled(DSButton)`
  margin-right: ${SkuiSpacing.s};

  > button {
    border: none !important;
  }
`;
