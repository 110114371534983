import { Either } from "@skillup/monads";
import { ListUtils } from "@skillup/shared-utils";
import { CampaignsRoutes } from "@skillup/people-review-bridge";

import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";
/**
 * @wip rename me to mutation
 * @wip rename me to mutation
 * @wip rename me to mutation
 * @wip rename me to mutation
 */

export const exportableColumns: CampaignsRoutes.Export["query"]["columns"] = [
  "areas",
  "branch",
  "contract",
  "division",
  "email",
  "firstName",
  "fullName",
  "joinDate",
  "lastName",
  "registrationNumber",
  "role",
  "service",
  "site",
];
export async function exportPPRCampaign({
  campaignID,
  columnsToExport,
  filters,
}: {
  campaignID: string;
  columnsToExport: CampaignsRoutes.Export["query"]["columns"];
  filters?: ListUtils.FilterValues<ListUtils.FilterConfigurationMap>;
}) {
  try {
    await downloadTypedFileAsUser<CampaignsRoutes.Export>(
      {
        method: "POST",
        params: { campaignID },
        path: "/campaigns/{campaignID}/export",
        payload: { columns: columnsToExport, filters },
      },
      {
        deduceFileDataFromResponseHeaders: true,
        target: "PEOPLE_REVIEW",
      }
    );
    return Either.right(undefined);
  } catch (err) {
    return Either.left(err);
  }
}
