import type { CampaignsRoutes, InterviewPageChild } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

export const getStructuresTargetsChildren = async () => {
  try {
    const result = await buildRequest<CampaignsRoutes.GetStructures>({
      method: "GET",
      path: "/structures",
      query: {
        type: "PageChild",
        kind: "targets",
      },
    })();

    return result as Record<string, InterviewPageChild[]>;
  } catch (error) {
    throw new Error("No campaign Id in response");
  }
};
