import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { TrainingFields } from "@skillup/training-bridge";
import { Duration } from "@skillup/shared-utils";

import { buildRequest } from "utils/buildRequest";
import { IntraRoutes } from "types/api";
import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import useTranslation from "hooks/useTranslation";
import { isValidIntraData, State, TrainingForm, fetchData } from "./utils";

import { IForm } from "../../User/components/UserForm";
import { IntraFormV2 } from "../components/IntraFormV2";

import styles from "./IntraEditionView.module.scss";
import ConfirmModal from "./ConfirmModal";
import { useIntras } from "services/intras";

export default function IntraEditionView() {
  const { t } = useTranslation();
  const history = useHistory();
  const { addToast } = useToasts();

  const params = useParams<{ intraUuid }>();

  const { invalidateList } = useIntras();

  const [state, setState] = useState<State>({ status: "loading" });
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  const [fields, setFields] = useState<TrainingFields>();
  const [fieldsForm, setFieldsForm] = useState<IForm>();
  const [trainingForm, setTrainingForm] = useState<TrainingForm>();

  const updateTrainingWithFields = async () => {
    const newFields = Object.keys(fieldsForm).map((key) => {
      return {
        binding: key,
        value: fieldsForm[key].value,
      };
    });

    const {
      objectives,
      program,
      prerequisites,
      description,
      targetAudience,
      trainingOrganization,
      duration,
      tag,
      trailer,
      version,
    } = trainingForm;

    try {
      const request = buildRequest<IntraRoutes.SET_INTRA_FIELDS>({
        path: "/trainings/{trainingUuid}/fields",
        method: "POST",
        params: { trainingUuid: params.intraUuid },
        payload: {
          trainingData: {
            ...trainingForm,
            objectives: objectives ?? undefined,
            program: program ?? undefined,
            prerequisites: prerequisites ?? undefined,
            description: description ?? undefined,
            targetAudience: targetAudience ?? undefined,
            uuid: params.intraUuid,
            trainingOrganizationName: trainingOrganization,
            duration: Duration.encodeObjectToString({ hours: duration }),
            tag: {
              uuid: tag?.value,
              name: tag?.label,
              seoSlug: tag?.label.replace(" ", "-"),
            },
            trailer: trailer ?? undefined,
          },
          fieldsData: newFields.filter((f) => f.value),
          version,
        },
      });
      await request();

      await invalidateList();

      addToast(
        t("trainings.intra.edition.submitSuccess", {
          defaultValue: "Modifications effectuées avec succèss",
        }),
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      history.push("/responsable/programmes/gestion-intras/");
    } catch (err) {
      addToast(
        t("trainings.intra.edition.submitError", {
          defaultValue: "Erreur lors de la modification des détails",
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  };

  useEffect(() => {
    fetchData({
      intraUuid: params.intraUuid,
      setState,
      setFields,
      setFieldsForm,
      setTrainingForm,
    });
  }, [params.intraUuid]);

  const canSave = useMemo(() => {
    return trainingForm ? isValidIntraData(trainingForm) : false;
  }, [trainingForm]);

  const fieldsToUpdate = useMemo(() => {
    const result = [];
    if (fields && fieldsForm)
      for (const key of Object.keys(fields)) {
        if (fields[key].value !== fieldsForm[key].value) {
          result.push(fields[key].label);
        }
      }
    return result;
  }, [fields, fieldsForm]);

  return (
    <DSLayout
      title={trainingForm?.name ?? ""}
      parent={{
        title: t("trainings.intra.edition.title", {
          defaultValue: "Catalogue entreprise",
        }),
        titleUrl: generatePath("/responsable/programmes/gestion-intras"),
      }}
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              disabled={!canSave}
              tooltip={
                !canSave
                  ? t("trainings.intra.edition.error.mandatory", {
                      defaultValue: "Veuillez remplir tous les champs obligatoires",
                    })
                  : undefined
              }
              label={t("trainings.intra.edition.submit", {
                defaultValue: "Enregistrer les modifications",
              })}
              onClick={(e) => {
                e.preventDefault();
                if (fieldsToUpdate.length > 0) {
                  setConfirmModalIsOpen(true);
                } else {
                  updateTrainingWithFields();
                }
              }}
              loading={state.status === "saving" || state.status === "loading"}
            />
          ),
        },
      ]}
    >
      <div className={styles.editionView}>
        {fields && fieldsForm && trainingForm && (
          <IntraFormV2
            t={t}
            fields={fields}
            trainingForm={trainingForm}
            setTrainingForm={setTrainingForm}
            fieldsForm={fieldsForm}
            setFieldsForm={setFieldsForm}
          />
        )}
        {confirmModalIsOpen && (
          <ConfirmModal
            fields={fieldsToUpdate}
            onSubmit={() => {
              updateTrainingWithFields();
            }}
            onClose={() => {
              setConfirmModalIsOpen(false);
            }}
          />
        )}
      </div>
    </DSLayout>
  );
}
