import { MdDelete as Delete } from "react-icons/md";
import { Label as DSLabel } from "@skillup/ui";

import { FullWidth, Flex, Text, FormikInputText } from "@skillup/design-system";

import {
  EvaluationScaleLevel,
  Description,
  DeleteLevelButton,
} from "./EvaluationScaleLevel.styled";

import useTranslation from "hooks/useTranslation";
interface EvaluationLevel {
  level: number;
  name: string;
  description?: string;
  deletable?: boolean;
  placeholder?: string;
}

interface Props {
  readonly index: number;
  readonly item: EvaluationLevel;
  readonly activeDescription: boolean;
  readonly deleteEvaluationLevel: () => void;
}

export default ({ index, item, activeDescription, deleteEvaluationLevel }: Props) => {
  const { t } = useTranslation();
  return (
    <EvaluationScaleLevel key={index}>
      <Flex alignItems="center" justifyContent="flex-end">
        <Text espaceFont="h3" marginTop="xs" marginRight="s" marginBottom="xs" textAlign="center">
          {item.level}
        </Text>
        <FullWidth>
          <DSLabel label={`Intitulé`} required />
          <FormikInputText
            name={`levels.${index}.name`}
            placeholder={item.placeholder}
            value={item.name || ""}
          />

          {activeDescription && (
            <>
              <Description label={`Description`} />
              <FormikInputText
                name={`levels.${index}.description`}
                placeholder={t("portal.config.skills.createEvaluationScale.defaultPlaceholder", {
                  defaultValue: "Ex: ...",
                })}
                value={item.description || ""}
              />
            </>
          )}
        </FullWidth>
        {item.deletable && (
          <DeleteLevelButton
            onClick={(e) => {
              e.preventDefault();
              deleteEvaluationLevel();
            }}
            buttonSize="M"
            icon={<Delete />}
            darkMode={false}
            iconOnly
          />
        )}
      </Flex>
    </EvaluationScaleLevel>
  );
};
