import { MdHelpOutline as HelpOutline } from "react-icons/md";
import { Status, StatusType, Tooltip } from "@skillup/ui";

import styles from "../ConnectionModes.module.scss";
import { ConnectionModeProps } from "../types";

const CompanyConnectionMode = ({ label, helpText, enabled }: ConnectionModeProps) => {
  return (
    <div className={styles.companyConnectionMode}>
      <div className={styles.connectionModeName}>
        <div className={styles.label}>{label}</div>
        <Tooltip content={helpText} position="bottom">
          <HelpOutline size={13} />
        </Tooltip>
      </div>
      <Status
        label={enabled ? "Actif" : "Inactif"}
        type={enabled ? StatusType.SUCCESS : StatusType.DONE}
        withIcon
      />
    </div>
  );
};

export default CompanyConnectionMode;
