import { DSDataGrid } from "@skillup/ui";
import { CardProps } from "../Card";
import styles from "./Summary.module.scss";
import { generateColumns, generateRows } from "./utils";
import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";
import { useMemo } from "react";

export const Summary = ({ data, loading }: CardProps) => {
  const { t } = useTranslation();
  const {
    settings: { currency },
  } = useSettings();

  const columns = useMemo(
    () => generateColumns(t, data, currency),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, currency]
  );
  const rows = useMemo(
    () => generateRows(t, data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <DSDataGrid
      className={styles.Summary}
      loading={loading}
      columns={columns ?? []}
      rows={rows}
      disableColumnSelector
      disableColumnMenu
      disableRowSelectionOnClick
      disableToolbar
      hideFooter
      getRowClassName={() => styles.Row}
      getCellClassName={({ field }) => {
        if (field === "training_plan_plan_completion_phase") {
          return styles.StatusCell;
        }
        return null;
      }}
      initialState={{ pinnedColumns: { left: ["Status"] } }}
    />
  );
};
