import { MdEdit as Edit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { DSButton, DSTextInput, DSFormGroupTextInput, AssistiveArea } from "@skillup/ui";

import {
  CategorySelect,
  DurationInput,
  ModeSelect,
  PriceInput,
  PropertyInput,
  PropertyWYSIWYG,
  TrainingOrganizationSelect,
} from "./FormElements";

import User from "utils/User";
import { useTags } from "../utils/useTags";

import { IntraProperties } from "../CreationView/IntraCreationView";
import {
  ErrorCode,
  MAX_REFERENCE_LENGTH,
  MIN_REFERENCE_LENGTH,
  ReferenceValidityError,
  useIntras,
} from "services/intras/useIntras";

import styles from "./IntraForm.module.scss";

export type IntraData = {
  properties: Partial<IntraProperties>;
  tag: {
    label: string;
    value: string;
  };
};

type IntraFormProps = {
  intraData: IntraData;
  setProperty: <T extends keyof IntraProperties>(
    property: T
  ) => (value: IntraProperties[T]) => void;
  setTag: (newTag: { label: string; value: string }) => void;
};

export function IntraForm({ intraData, setProperty, setTag }: IntraFormProps) {
  const { tags } = useTags();
  const { trainingOrganizations } = useIntras();
  const { intraUuid } = useParams<{ intraUuid: string }>();

  const canShowTrailer = useMemo(() => {
    return User.isSkillupAdmin() || User.isDemoUser();
  }, []);

  return (
    <form>
      <PropertyInput
        label="Titre"
        name="name"
        placeholder="Ex : Anglais avancé"
        value={intraData?.properties.name}
        onChange={setProperty("name")}
        required
      />

      <TrainingOrganizationSelect
        trainingOrganizations={trainingOrganizations}
        onChange={setProperty("trainingOrganization")}
        selectedTrainingOrganization={intraData?.properties.trainingOrganization}
      />

      <ModeSelect selectedMode={intraData.properties.mode} onChange={setProperty("mode")} />

      <CategorySelect tags={tags} selectedTag={intraData?.tag?.value} onChange={setTag} />

      <div className={styles.doubleInput}>
        <DurationInput
          label="Durée (en heures)"
          name="duration"
          placeholder="Ex : 7"
          onChange={(newDuration) => {
            setProperty("duration")(newDuration);
          }}
          rawValue={intraData?.properties.duration}
          disablePadding
          value={
            intraData?.properties.duration != null
              ? intraData?.properties.duration.toString()
              : undefined
          }
        />

        <PriceInput
          label="Prix prévisionnel par participant HT"
          name="price"
          placeholder="Ex : 200"
          onChange={(newPrice) => {
            setProperty("price")(newPrice);
          }}
          rawValue={intraData?.properties.price}
          disablePadding
          value={
            intraData?.properties.price != null ? intraData?.properties.price.toString() : undefined
          }
        />

        {User.isCompanyWithTax() && (
          <PriceInput
            label="Prix prévisionnel par participant TTC"
            name="priceWithTax"
            placeholder="Ex : 200"
            onChange={(newPrice) => {
              setProperty("priceWithTax")(newPrice);
            }}
            rawValue={intraData?.properties.priceWithTax}
            disablePadding
            value={
              intraData?.properties.priceWithTax != null
                ? intraData?.properties.priceWithTax.toString()
                : undefined
            }
          />
        )}
      </div>

      <ReferenceBlock
        intraUuid={intraUuid}
        reference={intraData?.properties?.reference}
        setProperty={setProperty}
      />

      {canShowTrailer ? (
        <PropertyInput
          name="trailer"
          label="Vidéo de présentation"
          placeholder="Ex : https://www.youtube.com/embed/dQw4w9wgXcQ"
          value={intraData?.properties.trailer}
          onChange={setProperty("trailer")}
        />
      ) : null}

      <PropertyWYSIWYG
        label="Description"
        name="description"
        value={intraData?.properties.description}
        onChange={setProperty("description")}
      />

      <PropertyWYSIWYG
        label="Public cible"
        name="targetAudience"
        value={intraData?.properties.targetAudience}
        onChange={setProperty("targetAudience")}
      />

      <PropertyWYSIWYG
        label="Objectifs"
        name="objectives"
        value={intraData?.properties.objectives}
        onChange={setProperty("objectives")}
      />

      <PropertyWYSIWYG
        label="Programme"
        name="program"
        value={intraData?.properties.program}
        onChange={setProperty("program")}
      />

      <PropertyWYSIWYG
        label="Prérequis"
        name="prerequisites"
        value={intraData?.properties.prerequisites}
        onChange={setProperty("prerequisites")}
      />
    </form>
  );
}

type ReferenceInputState =
  | {
      step: "editing";
      value: string;
    }
  | { step: "displaying" };

function ReferenceBlock({
  reference,
  intraUuid,
  setProperty,
}: {
  reference?: string;
  intraUuid?: string;
  setProperty: IntraFormProps["setProperty"];
}) {
  const [state, setState] = useState<ReferenceInputState>({
    step: "displaying",
  });

  const { validateReference } = useIntras();
  const referenceInvalidError = useMemo(() => {
    const { step } = state;
    if (step === "editing") {
      const { value } = state;
      const error = validateReference(value, intraUuid);
      if (error.isLeft()) return error.left();
      return undefined;
    }

    return { error: ErrorCode.UNKNOWN };
  }, [validateReference, state, intraUuid]);

  const referenceIsEmpty = reference === "" || reference == null;
  const userHasNotFilledProgramYet = state.step === "displaying" && referenceIsEmpty;

  if (userHasNotFilledProgramYet) {
    return (
      <div className={styles.reference}>
        <p>_</p>
        <AssistiveArea text="Cet identifiant unique est généré à la création du programme" />
      </div>
    );
  }

  if (state.step === "displaying") {
    return (
      <div className={styles.reference}>
        <div className={styles.referenceDisplay}>
          <div>{reference}</div>
          <DSButton
            iconOnly
            icon={<Edit />}
            onClick={() =>
              setState({
                step: "editing",
                value: reference || "",
              })
            }
          />
        </div>
        <AssistiveArea text="Cet identifiant unique est généré à la création du programme" />
      </div>
    );
  }

  return (
    <div className={styles.reference}>
      <DSFormGroupTextInput label="ID" required>
        <>
          <div className={styles.referenceEdit}>
            <DSTextInput
              name="reference"
              value={state.value}
              error={!!referenceInvalidError}
              onChange={(newValue) => {
                setState({ step: "editing", value: newValue });
              }}
              actionButton={
                <DSButton
                  label="Valider"
                  buttonSize="S"
                  disabled={!!referenceInvalidError}
                  tooltip={getErrorTooltipLabel(referenceInvalidError)}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setProperty("reference")(state.value);
                    setState({ step: "displaying" });
                  }}
                />
              }
            />
          </div>
          {getErrorTooltipLabel(referenceInvalidError) === undefined ? (
            <AssistiveArea text="Cet identifiant unique est généré à la création du programme" />
          ) : (
            <AssistiveArea text={getErrorTooltipLabel(referenceInvalidError)} mode="error" />
          )}
        </>
      </DSFormGroupTextInput>
    </div>
  );
}

function getErrorTooltipLabel(error: ReferenceValidityError) {
  switch (error?.error) {
    case ErrorCode.ALREADY_USED:
      return `L'ID de programme est déjà utilisé par le programme "${error.data?.programName}"`;
    case ErrorCode.UNKNOWN:
      return "Cet ID n'est pas valide";
    case ErrorCode.TOO_LONG:
      return `L'ID de programme est limité à ${MAX_REFERENCE_LENGTH} caractères`;
    case ErrorCode.TOO_SHORT:
      return `L'ID de programme doit contenir au moins ${MIN_REFERENCE_LENGTH} caractères`;
    case ErrorCode.EMPTY:
      return "Veuillez renseigner un ID de programme";
    default:
      return undefined;
  }
}
